import React from "react";
import Commons from "../../Commons";
import Icons from "../../icons";
import { Link, Toolbar, Badge, Icon } from "framework7-react";
import localeStrings from "./toolbar-local";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import {
  isAndroid,
  isBrowser,
  isIOS,
  isMacOs,
  isWindows,
} from "react-device-detect";
import f7 from "framework7";

export const getLinkId = (pageId) => {
  return "tablink-" + pageId;
};

class MenuToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let bottomMenu = null;

    if (!Commons.isPortrait(f7)) {
      let cameraSettings = null;
      if (Commons.hasIpCam() && isAndroid) {
        cameraSettings = (
          <Link
            data-testid={getLinkId(Commons.MAINPAGEINDEX_IPCAMSETTINGS)}
            className="tab-link flex-direction-column"
            text={this.showMenuLabels() ? localeStrings.tab_bottom_glasses : ""}
            iconIos="f7:camera_viewfinder"
            iconAurora="f7:camera_viewfinder"
            iconMd="material:linked_camera"
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_IPCAMSETTINGS)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_IPCAMSETTINGS);
            }}
          ></Link>
        );
      }

      let chat = null;
      if (process.env.REACT_APP_CHAT_ENABLED === "true") {
        let unreadMessages = Commons.getUnreadMessages(this.props.contactRooms);
        let badge =
          unreadMessages > 0 ? (
            <Badge color="red">{unreadMessages}</Badge>
          ) : null;
        let text = this.showMenuLabels() ? (
          <span className="tabbar-label">{localeStrings.tab_bottom_chat}</span>
        ) : null;
        chat = (
          <Link
            data-testid={getLinkId(Commons.MAINPAGEINDEX_CHAT)}
            className="tab-link flex-direction-column"
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_CHAT)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_CHAT);
            }}
          >
            <Icon
              className="icon-fill"
              ios="f7:chat_bubble_text"
              aurora="f7:chat_bubble_text"
              md="f7:chat_bubble_text"
            >
              {badge}
            </Icon>
            {text}
          </Link>
        );
      }

      let documents = null;
      if (process.env.REACT_APP_DOCUMENTS_ENABLED === "true") {
        documents = (
          <Link
            data-testid={getLinkId(Commons.MAINPAGEINDEX_DOCUMENTS)}
            className="tab-link flex-direction-column"
            text={
              this.showMenuLabels() ? localeStrings.tab_bottom_documents : ""
            }
            iconIos="f7:paperclip"
            iconAurora="f7:paperclip"
            iconMd="material:attach_file"
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_DOCUMENTS)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_DOCUMENTS);
            }}
          ></Link>
        );
      }

      let recents = null;
      if (
        process.env.REACT_APP_RECENTS_ENABLED === "true" &&
        process.env.REACT_APP_VIDEOCALL_ENABLED === "true"
      ) {
        recents = (
          <Link
            data-testid={getLinkId(Commons.MAINPAGEINDEX_RECENTS)}
            className="tab-link flex-direction-column"
            text={this.showMenuLabels() ? localeStrings.tab_bottom_recents : ""}
            iconIos="f7:clock"
            iconAurora="f7:clock"
            iconMd="material:query_builder"
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_RECENTS)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_RECENTS);
            }}
          ></Link>
        );
      }

      let settings = null;
      if (process.env.REACT_APP_SETTINGS_ENABLED === "true" && isBrowser) {
        settings = (
          <Link
            data-testid={getLinkId(Commons.MAINPAGEINDEX_SETTINGS)}
            className="tab-link flex-direction-column"
            text={
              this.showMenuLabels() ? localeStrings.tab_bottom_settings : ""
            }
            iconMd="material:settings"
            iconIos="material:settings"
            iconAurora="material:settings"
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_SETTINGS)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_SETTINGS);
            }}
          ></Link>
        );
      }

      let credits = null;
      if (process.env.REACT_APP_CREDITS_ENABLED === "true") {
        credits = (
          <Link
            data-testid={getLinkId(Commons.MAINPAGEINDEX_CREDITS)}
            className="tab-link flex-direction-column"
            text={this.showMenuLabels() ? localeStrings.tab_bottom_credits : ""}
            iconIos="f7:info_circle"
            iconAurora="f7:info_circle"
            iconMd="material:info"
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_CREDITS)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_CREDITS);
            }}
          ></Link>
        );
      }

      let addressBook = null;
      if (Commons.addressBookEnabled() && !isIOS) {
        addressBook = (
          <Link
            data-testid={getLinkId(Commons.MAINPAGEINDEX_ADDRESSBOOK)}
            className="tab-link flex-direction-column"
            text={
              this.showMenuLabels() ? localeStrings.tab_bottom_addressbook : ""
            }
            iconIos={Icons.ADDRESSBOOK_IOS}
            iconAurora={Icons.ADDRESSBOOK_AURORA}
            iconMd={Icons.ADDRESSBOOK_ANDROID}
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_ADDRESSBOOK)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_ADDRESSBOOK);
            }}
          ></Link>
        );
      }

      let bottomMenuIcon = "phone";
      if (process.env.REACT_APP_VIDEOCALL_ENABLED !== "true") {
        bottomMenuIcon = "chat_bubble_text";
      }
      bottomMenu = (
        <Toolbar
          tabbar
          labels
          position="bottom"
          data-testid="main-toolbar"
          className={"mainToolBar" + (isIOS ? " mainToolBarIos" : "")}
        >
          <Link
            data-testid={getLinkId(Commons.MAINPAGEINDEX_HOME)}
            className="tab-link flex-direction-column"
            text={this.showMenuLabels() ? localeStrings.tab_bottom_home : ""}
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_HOME)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_HOME);
            }}
            iconIos={"f7:" + bottomMenuIcon}
            iconAurora={"f7:" + bottomMenuIcon}
            iconMd={"material:" + bottomMenuIcon}
          ></Link>
          {chat}
          {recents}
          {documents}
          {addressBook}
          {cameraSettings}
          {settings}
          <Link
            className="tab-link flex-direction-column"
            text={this.showMenuLabels() ? localeStrings.tab_bottom_profile : ""}
            tabLinkActive={this.isPage(Commons.MAINPAGEINDEX_PROFILE)}
            onClick={() => {
              this.setPage(Commons.MAINPAGEINDEX_PROFILE);
            }}
            iconIos="f7:person"
            iconAurora="f7:person"
            iconMd="f7:person"
          ></Link>
          {credits}
        </Toolbar>
      );
    }

    return bottomMenu;
  }

  showMenuLabels = () => {
    return isWindows || isMacOs || isIOS;
  };

  isPage = (pageIndex) => {
    return this.props.mainPageIndex === pageIndex;
  };

  setPage = (pageIndex) => {
    this.props.setMainPageIndex(pageIndex);
    let page = null;
    if (pageIndex === Commons.MAINPAGEINDEX_PROFILE) {
      page = "/profile/";
    } else if (pageIndex === Commons.MAINPAGEINDEX_HOME) {
      page = "/main/";
    } else if (pageIndex === Commons.MAINPAGEINDEX_RECENTS) {
      page = "/recents/";
    } else if (pageIndex === Commons.MAINPAGEINDEX_DOCUMENTS) {
      page = "/documents/";
    } else if (pageIndex === Commons.MAINPAGEINDEX_CHAT) {
      page = "/chat/";
    } else if (pageIndex === Commons.MAINPAGEINDEX_IPCAMSETTINGS) {
      page = "/ipcam-settings/";
    } else if (pageIndex === Commons.MAINPAGEINDEX_SETTINGS) {
      page = "/settings/";
    } else if (pageIndex === Commons.MAINPAGEINDEX_CREDITS) {
      page = "/credits/";
    } else if (pageIndex === Commons.MAINPAGEINDEX_ADDRESSBOOK) {
      page = "/addressbook/";
    }

    this.props.router.navigate(page, { reloadAll: true });
  };

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    mainPageIndex: state.app.mainPageIndex,
    incomingCall: state.remoteAssistance.incomingCall,
    contactRooms: state.remoteAssistance.contactRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainPageIndex: (mainPageIndex) =>
      dispatch(actions.setMainPageIndex(mainPageIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuToolbar);
