import { Ra } from "../index";
import { RaLog } from "../log";
import { RaCommons } from "../commons";

export class RaArOpenCv {
  static load = () => {
    return new Promise((resolve, reject) => {
      RaCommons.injectJsScript("opencv-injected-js", Ra.getConfig().openCvUrl)
        .then(() => {
          RaLog.log("success to load " + Ra.getConfig().openCvUrl);
          // eslint-disable-next-line no-undef
          if (window.cv.getBuildInformation) {
            RaLog.log(window.cv.getBuildInformation());
            resolve();
          } else {
            // WASM
            window.cv["onRuntimeInitialized"] = () => {
              RaLog.log(window.cv.getBuildInformation());
              resolve();
            };
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          RaLog.error("failed to load " + Ra.getConfig().openCvUrl);
          reject(error);
        });
    });
  };
}
export default RaArOpenCv;
