import { connect } from 'react-redux';
import f7 from 'framework7';
import { f7 as f7React } from 'framework7-react';
import React from 'react';
import RaCordova from '../../ra-lib/cordova';
import { isAndroid, isIOS } from 'react-device-detect';


export class RedirectEvents extends React.Component {



  render() {

    return null;
  }

  componentDidMount = () => {

  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.incomingCall != null && prevProps.incomingCall === null) {

      let popups = f7.$('.ra-popup');
      popups.forEach(popup => {
        f7React.popup.close('#' + popup.id, true);
      })

      if (!(RaCordova.isCordova() && isIOS)) {
        if (this.props.router) {
          this.props.router.navigate('/incomingcall/', { transition: 'f7-cover-v' });  // non devo mettere reladAll a true altrimenti no vanno le transizioni
        }
      }
    }
  }
}

// #region Redux
const mapStateToProps = state => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    incomingCall: state.remoteAssistance.incomingCall,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RedirectEvents);