import React from "react";
import f7 from 'framework7';
import { connect } from "react-redux";
import localeStrings from "./inputaudio-options-local";

import { RaMedia } from "../../ra-lib/media/media";
class InputAudioOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const audioInputDevices =
      this.props.audioInputDevices &&
      this.props.audioInputDevices.length > 0 ? (
        this.props.audioInputDevices.map((item, index) => {
          return (
            <option key={index} value={item.deviceId}>
              {item.label
                ? item.label
                : "Camera" +
                  (this.props.audioInputDevices.length > 1 ? " #" + index : "")}
            </option>
          );
        })
      ) : (
        <option key="video_null" value="">
          {localeStrings.inputAudioNoOptions}
        </option>
      );

    return audioInputDevices;
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      this.props.audioInputDevices &&
      (!prevProps ||
        !prevProps.audioInputDevices ||
        JSON.stringify(this.props.audioInputDevices) !==
          JSON.stringify(prevProps.audioInputDevices))
    ) {
      let selectedDevice = RaMedia.getSelectedAudioInputDevice();
      if (this.props.containerSelector && selectedDevice) {
        f7.$(this.props.containerSelector).val(selectedDevice.deviceId);
      }
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    audioInputDevices: state.remoteAssistance.audioInputDevices,
    selectedAudioInputDevice: state.remoteAssistance.selectedAudioInputDevice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(InputAudioOptions);
