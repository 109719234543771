import { RaApiCalls } from "../api/calls/calls";
import { RaLog } from "../log";
import { RaReduxActionTypes } from "./actionTypes";

export class RaReduxReducer {
  static getDefaultReducer = () => {
    return {
      loggedIn: false,
      profile: null,
      callingState: false,
      activeCall: null,
      activeCallParticipants: [],
      activeCallTargets: [],
      dataTrack: null,
      comunicationProviderRoom: null,
      comunicationProviderError: null,
      takenSnapshots: [],
      selectedSnapshot: null,
      selectedSnapshotImgSrc: null,
      unreadMessages: [],
      notSeenPhotos: [],
      incomingCall: null,
      isCordovaReady: null,
      videoScreenState: null,
      firebaseToken: null,
      deletedSnapshot: null,
      callGUIMode: RaApiCalls.GUIMODE_LIVECAMERA,
      callerVideoTrackShareAllowed: false,
      callerVideoTrackShared: false,
      callController: RaApiCalls.CallUserType.OPERATOR,
      videoInputDevices: [],
      selectedVideoInputDevice: null,
      arVideoInputDevices: [],
      selectedArVideoInputDevice: null,
      isArVideoInputDeviceEnable: false,
      audioInputDevices: [],
      selectedAudioInputDevice: null,
      audioOutputDevices: [],
      selectedAudioOutputDevice: null,
      inputDevicesDetectionCompleated: false,
      selectVideoInputIpCam: null,
      callTracksNodes: null,
      callTimeBeforeHangup: 30,
      productCode: null,
      contactRooms: null,
      contactRoomsLoadingPerc: 0,
      appCompany: null,
      userAddressBook: [],
      arVideoProcessing: false,
      arVideoTrainingStarted: false,
      arVideoTrainingProgress: 0,
      arVideoTrainingEnded: false,
    };
  };

  static getReducer = (state = RaReduxReducer.getDefaultReducer(), action) => {
    switch (action.type) {
      case RaReduxActionTypes.SET_CONTACT_ROOMS_LOADING_PREC:
        return {
          ...state,
          contactRoomsLoadingPerc: action.contactRoomsLoadingPerc,
        };
      case RaReduxActionTypes.SET_USER_ADDRESSBOOK:
        return {
          ...state,
          userAddressBook: action.userAddressBook,
        };
      case RaReduxActionTypes.SET_CORDOVA_FIREBASE_TOKEN:
        //RaLog.log("setCordovaReady:" + action.isReady);
        return {
          ...state,
          firebaseToken: action.firebaseToken,
        };
      case RaReduxActionTypes.SET_CORDOVA_READY:
        RaLog.log("setCordovaReady:" + action.isReady);
        return {
          ...state,
          isCordovaReady: action.isReady,
        };
      case RaReduxActionTypes.SET_USER_INFO:
        let newProps = {
          ...state,
          profile: action.profile,
        };
        if (state.loggedIn === false && action.profile) {
          newProps.loggedIn = true;
        } else if (state.profile && !action.profile) {
          newProps = RaReduxReducer.getDefaultReducer();
        }
        return newProps;

      case RaReduxActionTypes.SET_ACTIVE_CALL:
        return {
          ...state,
          activeCall: action.call,
          activeCallTargets: action.activeCallTargets,
        };
      case RaReduxActionTypes.SET_DATA_TRACK:
        return {
          ...state,
          dataTrack: action.dataTrack,
        }; /*
            case RaReduxActionTypes.SET_VIDEO_TRACK:
                return {
                    ...state,
                    videoTrack: action.videoTrack,
                }*/
      case RaReduxActionTypes.SET_COMUNICATION_PROVIDER_DATA:
        return {
          ...state,
          comunicationProviderRoom: action.comunicationProviderRoom,
          comunicationProviderError: null,
        };
      case RaReduxActionTypes.SET_COMUNICATION_PROVIDER_ERROR:
        return {
          ...state,
          comunicationProviderRoom: null,
          comunicationProviderError:
            action.takenSnapshotscomunicationProviderError,
        };
      case RaReduxActionTypes.SET_INCOMING_CALL:
        return {
          ...state,
          incomingCall: action.incomingCall,
        };
      case RaReduxActionTypes.SET_ACTIVE_CALL_PARTICIPANTS:
        let newActiveCallParticipants = [];
        let participantsUniqueIds = [];

        //pulisco l'array per evitare che un partecipante sia presente più volte per errore
        for (let i = 0; i < action.activeCallParticipants.length; i++) {
          if (
            !participantsUniqueIds.includes(
              action.activeCallParticipants[i].UniqueId
            )
          ) {
            newActiveCallParticipants.push(action.activeCallParticipants[i]);
            participantsUniqueIds.push(
              action.activeCallParticipants[i].UniqueId
            );
          }
        }

        return {
          ...state,
          activeCallParticipants: newActiveCallParticipants,
        };
      case RaReduxActionTypes.SET_ACTIVE_CALL_TARGETS:
        return {
          ...state,
          activeCallTargets: action.activeCallTargets,
        };
      case RaReduxActionTypes.SET_VIDEO_SCREEN_STATE:
        return {
          ...state,
          videoScreenState: action.videoScreenState,
        };
      case RaReduxActionTypes.SET_UNREAD_MESSAGES:
        return {
          ...state,
          unreadMessages: action.unreadMessages,
        };
      case RaReduxActionTypes.SET_NOT_SEEN_PHOTOS:
        return {
          ...state,
          notSeenPhotos: action.notSeenPhotos,
        };
      case RaReduxActionTypes.SET_TAKEN_SNAPSHOTS:
        return {
          ...state,
          takenSnapshots: action.takenSnapshots,
        };
      case RaReduxActionTypes.SET_SELECTED_SNAPSHOT:
        return {
          ...state,
          selectedSnapshot: action.selectedSnapshot,
          selectedSnapshotImgSrc: action.selectedSnapshotImgSrc,
        };
      case RaReduxActionTypes.SET_DELETED_SNAPSHOT:
        return {
          ...state,
          deletedSnapshot: action.deletedSnapshot,
        };
      case RaReduxActionTypes.SET_CALL_GUI_MODE:
        return {
          ...state,
          callGUIMode: action.callGUIMode,
        };
      case RaReduxActionTypes.SET_CALLER_VIDEOTRACK_SHARE_ALLOWED:
        return {
          ...state,
          callerVideoTrackShareAllowed: action.callerVideoTrackShareAllowed,
        };
      case RaReduxActionTypes.SET_CALLER_VIDEOTRACK_SHARED:
        return {
          ...state,
          callerVideoTrackShared: action.callerVideoTrackShared,
        };

      case RaReduxActionTypes.SET_CALL_CONTROLLER:
        return {
          ...state,
          callController: action.callController,
        };

      case RaReduxActionTypes.SET_ARVIDEOINPUTDEVICE_ENABLE:
        return {
          ...state,
          isArVideoInputDeviceEnable: action.isArVideoInputDeviceEnable,
        };

      case RaReduxActionTypes.SET_ARVIDEOTRAINING_STATE:
        return {
          ...state,
          arVideoTrainingStarted: action.arVideoTrainingStarted,
          arVideoTrainingProgress: action.arVideoTrainingProgress,
          arVideoTrainingEnded: action.arVideoTrainingEnded,
        };

      case RaReduxActionTypes.SET_ARVIDEOPROCESS_STATE:
        return {
          ...state,
          arVideoProcessing: action.arVideoProcessing,
        };

      case RaReduxActionTypes.SET_VIDEOINPUTDEVICES:
        let arVideoInputDevices = [...action.videoInputDevices];
        let selectedArVideoInputDevice = state.selectedArVideoInputDevice;
        if (
          arVideoInputDevices &&
          arVideoInputDevices.length > 0 &&
          state.selectedVideoInputDevice
        ) {
          for (let i = 0; i < arVideoInputDevices.length; i++) {
            if (
              arVideoInputDevices[i].deviceId ===
              state.selectedVideoInputDevice.deviceId
            ) {
              arVideoInputDevices.splice(i, 1);

              //se come input si è impostato lo stesso device utilizzato per l'ar, deseleziono il device per l'ar
              if (
                selectedArVideoInputDevice &&
                selectedArVideoInputDevice.deviceId ===
                  state.selectedVideoInputDevice.deviceId
              ) {
                selectedArVideoInputDevice = null;
              }

              break;
            }
          }
        }

        return {
          ...state,
          videoInputDevices: action.videoInputDevices,
          arVideoInputDevices: arVideoInputDevices,
          selectedArVideoInputDevice: selectedArVideoInputDevice,
        };

      case RaReduxActionTypes.SET_SELECTED_VIDEOINPUTDEVICE: {
        //se si è selezionato un video di input, lo tolgo tra quelli disponibili per l'AR
        let arVideoInputDevices = [...state.videoInputDevices];
        let selectedArVideoInputDevice = state.selectedArVideoInputDevice;
        if (action.selectedVideoInputDevice) {
          if (arVideoInputDevices && arVideoInputDevices.length > 0) {
            for (let i = 0; i < arVideoInputDevices.length; i++) {
              if (
                arVideoInputDevices[i].deviceId ===
                action.selectedVideoInputDevice.deviceId
              ) {
                arVideoInputDevices.splice(i, 1);
                break;
              }
            }
          }

          //se come input si è impostato lo stesso device utilizzato per l'ar, deseleziono il device per l'ar
          if (
            selectedArVideoInputDevice &&
            selectedArVideoInputDevice.deviceId ===
              action.selectedVideoInputDevice.deviceId
          ) {
            if (arVideoInputDevices.length > 0) {
              selectedArVideoInputDevice = arVideoInputDevices[0];
            } else {
              selectedArVideoInputDevice = null;
            }
          }
        }
        //se si è impostato a null il video di input, rimetto i video in AR uguali a quelli di input
        else {
          arVideoInputDevices = [...state.videoInputDevices];
        }

        return {
          ...state,
          selectedVideoInputDevice: action.selectedVideoInputDevice,
          selectVideoInputIpCam: null,
          arVideoInputDevices: arVideoInputDevices,
          selectedArVideoInputDevice: selectedArVideoInputDevice,
        };
      }

      case RaReduxActionTypes.SET_SELECTED_ARVIDEOINPUTDEVICE:
        //se come input si è impostato lo stesso device utilizzato per l'ar, deseleziono il device per l'ar
        if (
          action.selectedArVideoInputDevice &&
          state.selectedVideoInputDevice &&
          action.selectedArVideoInputDevice.deviceId ===
            state.selectedVideoInputDevice.deviceId
        ) {
          action.selectedArVideoInputDevice = null;
        }

        return {
          ...state,
          selectedArVideoInputDevice: action.selectedArVideoInputDevice,
        };

      case RaReduxActionTypes.SET_AUDIOINPUTDEVICES:
        return {
          ...state,
          audioInputDevices: action.audioInputDevices,
        };

      case RaReduxActionTypes.SET_SELECTED_AUDIOINPUTDEVICE:
        return {
          ...state,
          selectedAudioInputDevice: action.selectedAudioInputDevice,
        };

      case RaReduxActionTypes.SET_AUDIOOUTPUTDEVICES:
        return {
          ...state,
          audioOutputDevices: action.audioOutputDevices,
        };

      case RaReduxActionTypes.SET_SELECTED_AUDIOOUTPUTDEVICE:
        return {
          ...state,
          selectedAudioOutputDevice: action.selectedAudioOutputDevice,
        };

      case RaReduxActionTypes.SET_INPUTDEVICESDETECTIONCOMPLEATED:
        return {
          ...state,
          inputDevicesDetectionCompleated:
            action.inputDevicesDetectionCompleated,
        };

      case RaReduxActionTypes.SET_SELECTED_VIDEOINPUTIPCAM:
        return {
          ...state,
          selectVideoInputIpCam: action.selectVideoInputIpCam,
          selectedVideoInputDevice: null,
        };

      case RaReduxActionTypes.SET_CALL_TRACKS_NODE:
        return {
          ...state,
          callTracksNodes: action.callTracksNodes,
        };

      case RaReduxActionTypes.SET_CALL_TIME_BEFORE_HANG_UP:
        return {
          ...state,
          callTimeBeforeHangup: action.callTimeBeforeHangup,
        };

      case RaReduxActionTypes.SET_CALLING_STATE:
        return {
          ...state,
          callingState: action.callingState,
        };

      case RaReduxActionTypes.SET_PRODUCT_CODE:
        return {
          ...state,
          productCode: action.productCode,
        };

      case RaReduxActionTypes.SET_CONTACT_ROOMS:
        return {
          ...state,
          contactRooms: action.contactRooms,
        };

      case RaReduxActionTypes.SET_APP_COMPANY:
        return {
          ...state,
          appCompany: action.appCompany,
        };
      default:
      //          RaLog.log("Action sconosciuta: " + action.type);
    }
    return state;
  };

  static _reducerName = null;
  static setReducerName = (name) => {
    this._reducerName = name;
  };
  static getReducerName = () => {
    return this._reducerName;
  };
}
export default RaReduxReducer;
