export class Icons {
  static OPTIONS_ANDROID = "material:more_vert";
  static OPTIONS_IOS = "material:more_vert";
  static OPTIONS_AURORA = "material:more_vert";

  static FILTERS_ANDROID = "material:filter_list";
  static FILTERS_IOS = "material:filter_list";
  static FILTERS_AURORA = "material:filter_list";

  static CLOSE_ANDROID = "material:close";
  static CLOSE_IOS = "f7:xmark";
  static CLOSE_AURORA = "f7:xmark";

  static CHAT_ANDROID = "material:chat";
  static CHAT_IOS = "material:chat";
  static CHAT_AURORA = "material:chat";

  static ANNOTATIONS_ANDROID = "material:photo_library";
  static ANNOTATIONS_IOS = "f7:photo_on_rectangle";
  static ANNOTATIONS_AURORA = "f7:photo_on_rectangle";

  static MENULEFT_ANDROID = "material:menu";
  static MENULEFT_IOS = "f7:menu";
  static MENULEFT_AURORA = "f7:menu";

  static ADD_ANDROID = "material:add";
  static ADD_IOS = "f7:plus";
  static ADD_AURORA = "f7:plus";

  static BACK_ANDROID = "material:arrow_back";
  static BACK_IOS = "material:arrow_back_ios";
  static BACK_AURORA = "material:arrow_back";

  static SEARCH_ANDROID = "material:search";
  static SEARCH_IOS = "f7:search";
  static SEARCH_AURORA = "f7:search";

  static EDIT_ANDROID = "material:mode_edit";
  static EDIT_IOS = "material:mode_edit";
  static EDIT_AURORA = "material:mode_edit";

  static DELETE_ANDROID = "material:delete_outline";
  static DELETE_IOS = "material:delete_outline";
  static DELETE_AURORA = "material:delete_outline";

  /**********************
   * SEZIONI
   *********************/

  static ADDRESSBOOK_ANDROID = "material:people_alt";
  static ADDRESSBOOK_IOS = "material:people_alt";
  static ADDRESSBOOK_AURORA = "material:people_alt";
}

export default Icons;
