import React from "react";
import {
  Block,
  Popup,
  Popover,
  Link,
  Icon,
  Fab,
  Preloader,
  Col,
  f7 as f7React,
} from "framework7-react";
import { connect } from "react-redux";
import ContactsList from "../../components/commons/contacts-list";
import Commons from "../../Commons";
import * as actions from "../../redux/actions";
import VideoStream from "../../components/videostream/local-video-stream";
import VideoStreamSwitchButton from "../videocalls/buttons/local-video-stream-switch-button";

import { RaStorage } from "../../ra-lib/storage";
import { RaApiAuth } from "../../ra-lib/api/auth";
import { RaApiCalls } from "../../ra-lib/api/calls/calls";

import localeStrings from "./home-local";

import f7 from "framework7";
//import IpCamRender from '../components/ipcam/ipcam-render'

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      spinner: false,
      updateIntervalId: null,
    };

    this.landingImageLoadedInvoked = false;
  }

  render() {
    let errorMessage = "";
    if (this.state.errormessage !== null) {
      errorMessage = (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <p style={{ color: "red" }}>
              Error loading calls: {this.state.errormessage}
            </p>
          </Col>
        </Block>
      );
    }

    if (this.state.spinner === true) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center spinner">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    let loginCompany = RaStorage.getLoginCompany();

    let switchCameraButton = null;
    if (Commons.homeWithCamera()) {
      switchCameraButton = (
        <VideoStreamSwitchButton
          position="left-bottom"
          slot="fixed"
          style={{ marginBottom: "60px", position: "fixed" }}
          isVideoStreamSwitchButtonEnabled={() => true}
        ></VideoStreamSwitchButton>
      );
    }
    let title =
      loginCompany && loginCompany.LandingTitle ? (
        <h1>{loginCompany.LandingTitle}</h1>
      ) : null;
    let mainView = (
      <Block style={{ textAlign: "center", marginTop: 0 }}>
        <img
          id="img-landing"
          alt="landing"
          style={{ width: "70%", maxWidth: "400px", maxHeight: "400px" }}
          onLoad={() => {
            if (
              this.props.landingImageLoaded &&
              !this.landingImageLoadedInvoked
            ) {
              this.landingImageLoadedInvoked = true;
              this.props.landingImageLoaded();
            }
          }}
          src={
            loginCompany && loginCompany.LandingLogoUrl
              ? loginCompany.LandingLogoUrl
              : "./img/landing-image.png"
          }
        ></img>
        <div>
          {title}
          <p id="payoff">
            {loginCompany && loginCompany.LandingDescription
              ? loginCompany.LandingDescription
              : ""}
          </p>
        </div>
      </Block>
    );

    if (Commons.homeWithCamera() && !RaApiCalls.getActiveCall()) {
      mainView = <VideoStream online={false}></VideoStream>;
    }

    let newCallIcon = null;
    if (false)
      newCallIcon = (
        <Preloader
          style={{ marginLeft: "8px" }}
          size={20}
          color="white"
        ></Preloader>
      );
    else
      newCallIcon = (
        <Icon
          ios="f7:videocam_fill"
          aurora="f7:videocam_fill"
          md="material:videocam"
        ></Icon>
      );

    return (
      <div>
        {switchCameraButton}

        <Fab
          id="fab-new-call"
          text={localeStrings.newcall_button}
          onClick={(e) => {
            this.addContact();
          }}
          position="right-bottom"
          style={{
            display: RaApiAuth.allowedToCall() ? "inherits" : "none",
            position: "fixed",
            marginBottom: Commons.isPortrait(f7) ? "inhertis" : "60px",
            opacity: !RaApiAuth.serviceAvailable() ? 0.3 : 1,
          }}
        >
          {newCallIcon}
        </Fab>

        <Popover id="popover-new-call">
          <Block>
            <p>
              <strong>{localeStrings.popup_title}</strong>
            </p>
            <p style={{ textAlign: "justify" }}>{localeStrings.popup_text}</p>
            <div style={{ textAlign: "right" }}>
              <Link
                popoverClose
                onClick={(e) => {
                  localStorage.setItem("gotNewCall", "true");
                }}
              >
                {localeStrings.popup_button}
              </Link>
            </div>
          </Block>
        </Popover>

        {mainView}

        {errorMessage}

        <Popup
          className="contacts-popup ra-popup"
          id="newcall-contacts-list-popup"
          colorTheme="custom"
          onPopupClose={(e) => {
            this.closeContactList();
          }}
        >
          <ContactsList
            id="main-contacts-list-calls"
            key="main-contacts-list-calls"
            backPageClicked={(contact) => {
              this.newInstantCallHandler(contact);
            }}
            closeContacts={(e) => {
              this.closeContactList();
            }}
            onAddressbookClick={() => {
              this.closeContactList();
              this.props.f7router.navigate("/addressbook/", {
                reloadAll: true,
                props: { fade: true },
              });
            }}
            isCallContactList={true}
          />
        </Popup>
      </div>
    );
  }

  closeContactList = () => {
    if (this.contactsPopup && this.contactsPopup.$el) {
      this.contactsPopup.close(true);
    }
    this.props.setNewCallContactListOpen(false);
  };

  showContactList() {
    this.contactsPopup = f7React.popup.open(
      f7.$("#newcall-contacts-list-popup"),
      true
    );
    this.props.setNewCallContactListOpen(true);
  }

  newInstantCallHandler = (contact) => {
    if (contact === null) {
      return;
    }
    this.closeContactList();

    this.props.newInstantCallHandler(contact);
  };

  addContact() {
    if (!RaApiAuth.serviceAvailable()) {
      f7React.dialog.alert(
        localeStrings.nominutes_alert_text,
        localeStrings.nominutes_alert_title
      );
    }
    this.showContactList();
  }

  componentDidMount = () => {
    let imgLanding = document.getElementById("img-landing");
    if (imgLanding && imgLanding.complete) {
      if (this.props.landingImageLoaded && !this.landingImageLoadedInvoked) {
        this.landingImageLoadedInvoked = true;
        setTimeout(this.props.landingImageLoaded, 1000);
      }
    }

    var payoff = document.getElementById("payoff");
    if (payoff) {
      payoff.innerText.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    incomingCall: state.remoteAssistance.incomingCall,
    profile: state.remoteAssistance.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewCallContactListOpen: (newCallContactsListOpen) =>
      dispatch(actions.setNewCallContactListOpen(newCallContactsListOpen)),
  };
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Home);
