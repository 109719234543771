import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    signIn: "Sign in",
    next: "NEXT",
    login: "LOGIN",
    description_signin:
      "Please insert your e-mail to access to the remote assistance.\nIf you have been registered in our customer database you will receive a confirm code to the e-mail address specified.",
    description_selectCompany:
      "Please select the company from which you need assistance.",
    description_code:
      "You will now receive a temporary access code at the e-mail you specified.",
    code_sent: "Code has been sent to",
    unknown_email: "unknown e-mail",
    notification_error:
      "In order to receive call notifications, please enabled notification for this app",
    sample_email: "johndoe@mail.com",
    input_label_code: "Confirm code",
    input_label_email: "E-mail",
    resend_code_label: "Resend code",
    change_email_label: "Change e-mail",
    invalidCodeMessage: "Invalid code",
    signup_label: "Sign up",
  },
  it: {
    signIn: "Accedi",
    next: "AVANTI",
    login: "ENTRA",
    description_signin:
      "Inserisci il tuo indirizzo e-mail per accedere.\nSe sei registrato nei nostri archivi riceverai un codice di conferma all'indirizzo e-mail che hai indicato.",
    description_selectCompany:
      "Seleziona l'azienda da cui vuoi ricevere assistenza",
    description_code:
      "Riceverai a breve un codice di accesso temporaneo all'indirizzo e-mail che hai indicato.",
    code_sent: "Il codice di accesso è stato inviato a",
    unknown_email: "e-mail sconosciuta",
    notification_error:
      "Per ricevere le telefonate devi autorizzare l'app a ricevere le notifiche",
    sample_email: "mariorossi@mail.com",
    input_label_code: "Codice di conferma",
    input_label_email: "E-mail",
    resend_code_label: "Rinvia il codice",
    change_email_label: "Cambia e-mail",
    invalidCodeMessage: "Il codice inserito non è valido",
    signup_label: "Registrati",
  },
});

export default localeStrings;
