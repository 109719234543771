import { RaApiAuth } from "./ra-lib/api/auth";
import { RaStorage } from "./ra-lib/storage";
import { RaCordova } from "./ra-lib/cordova";
import { RaUI } from "./ra-lib/ui/ui";
import { RaLog } from "./ra-lib/log";
import { RaApiCalls } from "./ra-lib/api/calls/calls";
import { CONTACT_ROLE } from "./ra-lib/api/contacts";
import moment from "moment-timezone";
import appLocaleStrings from "./app-local";
import { isIOS } from "react-device-detect";
import f7 from 'framework7';
import {f7 as f7React} from 'framework7-react';

export class Commons {
  static MAINPAGEINDEX_HOME = 0;
  static MAINPAGEINDEX_RECENTS = 1;
  static MAINPAGEINDEX_DOCUMENTS = 2;
  static MAINPAGEINDEX_IPCAMSETTINGS = 3;
  static MAINPAGEINDEX_DOCUMENTS = 4;
  static MAINPAGEINDEX_PROFILE = 5;
  static MAINPAGEINDEX_CHAT = 6;
  static MAINPAGEINDEX_SETTINGS = 7;
  static MAINPAGEINDEX_CREDITS = 8;
  static MAINPAGEINDEX_ADDRESSBOOK = 9;

  static getDeviceId = () => {
    return "1234567890";
  };

  static addressBookEnabled = (profile) => {
    if (!profile) {
      profile = RaStorage.getProfile();
    }
    return (
      process.env.REACT_APP_ADDRESSBOOK_ENABLED === "true" &&
      profile &&
      profile.Role &&
      profile.Role.Code !== CONTACT_ROLE.ASSISTED
    );
  };

  static scrollTo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = Commons.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  };

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  static easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  static checkOpenerMessage = (router) => {
    if (RaCordova.isCordova()) {
      RaCordova.getAppOpenerMessage()
        .then((message) => {
          RaLog.log("getAppOpenerMessage", message);
          if (
            message &&
            message.RoomUniqueId &&
            router.currentRoute.url !== "/rooms/"
          ) {
            //          router.navigate('/rooms/', {reloadAll: true, props : {roomId: message.RoomUniqueId }});
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  static getUnreadMessages = (rooms) => {
    let count = 0;
    if (rooms) {
      rooms.forEach((room) => {
        count += room.UnreadMessages;
      });
    }
    return count;
  };

  static isPortrait = (f7) => {
    return (
      !isIOS &&
      (f7.$(window).width() < f7.$(window).height() ||
        Commons.homeWithCamera())
    );
  };

  static groupItemsByDate = (items, datePropName, tz, format) => {
    let result = [];
    let date = new Date();
    let today = moment(date).tz("Europe/Rome").format("DD/MM/YYYY");
    let yesterday = moment(date.setDate(date.getDate() - 1))
      .tz("Europe/Rome")
      .format("DD/MM/YYYY");

    let lastDateName = null;
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      let dateToCheck = moment(item[datePropName])
        .tz("Europe/Rome")
        .format("DD/MM/YYYY");
      let dateName = dateToCheck;
      if (dateName === today) dateName = appLocaleStrings.today;
      else if (dateName === yesterday) dateName = appLocaleStrings.yesterday;
      if (lastDateName !== dateName)
        result.push({
          dateName: moment(item[datePropName]).tz(tz).format(format),
          items: [item],
        });
      else result[result.length - 1].items.push(item);
      lastDateName = dateName;
    }

    return result;
  };

  static getContact = (contactId, contacts) => {
    let contact = null;

    if (contacts) {
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].UniqueId === contactId) {
          contact = contacts[i];
          break;
        }
      }
    }

    return contact;
  };

  static isValidUrl = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  static getDateDescription = (date, tz, format, timeIfToday) => {
    let currDate = new Date();

    let today = moment.utc(currDate).tz(tz).format("DD/MM/YYYY");
    let yesterday = moment
      .utc(currDate.setDate(currDate.getDate() - 1))
      .tz(tz)
      .format("DD/MM/YYYY");
    let dateDescription = moment.utc(date).tz(tz).format("DD/MM/YYYY");

    if (dateDescription === today) {
      if (timeIfToday === true) {
        dateDescription = moment.utc(date).tz(tz).format("HH:mm");
      } else {
        dateDescription = appLocaleStrings.today;
      }
    } else if (dateDescription === yesterday) {
      dateDescription = appLocaleStrings.yesterday;
    } else if (currDate.getUTCFullYear() === moment.utc(date).year()) {
      dateDescription = moment.utc(date).tz(tz).format("D MMM");
    } else {
      dateDescription = moment.utc(date).tz(tz).format(format);
    }
    return dateDescription;
  };

  static showErrorMessage = (self, text) => {
    //if (!this.toastBottom) {
    let toastBottom = f7React.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    //}
    toastBottom.open();
  };

  static showConfirm = (self, text, title, callbackOk, callbackCancel) => {
    f7React.dialog.confirm(text, title, callbackOk, callbackCancel);
  };

  static showAlert = (self, text, title) => {
    f7React.dialog.alert(text, title);
  };

  static _lastCheckResultAuthenticated = null;
  static checkAuthentication = (self, router) => {
    if (!router) {
      console.error("router param is null");
      return;
    } else if (!router.currentRoute) {
      console.error("router.currentRoute param is null");
      return;
    }

    let authenticated = RaApiAuth.isAuthenticated();

    if (!authenticated && router.currentRoute.url !== "/login/") {
      if (
        Commons._lastCheckResultAuthenticated === null ||
        Commons._lastCheckResultAuthenticated === true
      ) {
        RaLog.log("checkAuthentication failed...going to login");

        //chiudo tutti i popup
        let popups = f7.$(".ra-popup");
        popups.forEach((popup) => {
          f7React.popup.close("#" + popup.id, true);
        });

        //rimando al login
        router.navigate("/login/", { reloadAll: true });
      }
    }
    Commons._lastCheckResultAuthenticated = authenticated;
  };

  static hasIpCam = () => {
    //return true;
    let profile = RaStorage.getProfile();
    return (
      RaCordova.isCordova() &&
      profile &&
      profile.IpCamsEnabled &&
      profile.IpCamsEnabled.length > 0
    );
  };

  static homeWithCamera = () => {
    let profile = RaStorage.getProfile();
    return profile && profile.Role && profile.Role.UxStartsWithCamera;
  };

  static shareUserVideoTrack = () => {
    /*  let profile = RaStorage.getProfile();
    return profile && profile.Role && profile.Role.Code === CONTACT_ROLE.ASSISTED;*/
    return true;
  };

  static getVideoCropSizeCallback = (self) => {
    let profile = RaStorage.getProfile();
    if (
      profile &&
      profile.Role &&
      profile.Role.Code === CONTACT_ROLE.OPERATOR
    ) {
      let minSize = f7.$(window).width() / 4;
      if (minSize > f7.$(window).height() / 6) {
        minSize = f7.$(window).height() / 6;
      }
      return { width: minSize, height: minSize };
    } else {
      return {
        width: f7.$(window).width(),
        height: f7.$(window).height(),
      };
    }
  };

  static getVideoCropData = (
    isAssistedContact,
    isLocalView,
    cropSize,
    trackWidth,
    trackHeight
  ) => {
    let videoCropData = null;
    if (isAssistedContact) {
      if (isLocalView) {
        videoCropData = RaUI.getElementCropData(
          cropSize.width,
          cropSize.height,
          trackWidth,
          trackHeight
        );
        if (cropSize.width > 1025) {
          videoCropData.margin.left = 1025 * 0.15 * -1;
        }
      } else {
        videoCropData = RaUI.getElementFitData(
          cropSize.width,
          cropSize.height,
          trackWidth,
          trackHeight
        );
      }
    } else {
      videoCropData = RaUI.getElementCropData(
        cropSize.width,
        cropSize.height,
        trackWidth,
        trackHeight
      );
    }
    return videoCropData;
  };

  static setVideoTrackNodeStyle = (
    self,
    nodeSelector,
    videoCropData,
    isAssistedContact,
    isLocalView
  ) => {
    self
      .$$(nodeSelector)
      .attr(
        "style",
        "width:" +
          videoCropData.width +
          "px;height:" +
          videoCropData.height +
          "px;margin-top:" +
          videoCropData.margin.top +
          "px;margin-left:" +
          videoCropData.margin.left +
          "px;overflow: hidden"
      );
  };

  static callContactErrorHandler = (
    self,
    targetContact,
    errorMsg,
    errorCode,
    appLocaleStrings
  ) => {
    let message = null;

    if (
      targetContact &&
      targetContact.Company &&
      targetContact.Company.UnavailableMessage
    ) {
      message = targetContact.Company.UnavailableMessage;
    }

    if (!message) {
      if (errorCode === 404) {
        message =
          (targetContact ? targetContact.Name : "") +
          appLocaleStrings.callResult_NoTargetAvailable;
      } else {
        message = errorMsg;
      }
    }

    if (errorCode === 409) {
      message =
        (targetContact ? targetContact.Name : "") +
        appLocaleStrings.callResult_TargetOccupied;
    }

    if (errorCode === 412) {
      message = appLocaleStrings.callResult_userUnavailable;
    }

    if (errorCode === 417) {
      message = appLocaleStrings.callResult_subscriptionRequired;
    }

    if (message && message.length > 100) {
      message = message.substring(0, 100) + "...";
    }

    f7React.dialog.alert(message, appLocaleStrings.callResult_ErrorTitle);
  };

  static acceptedCall = (router) => {
    RaApiCalls.acceptIncomingCall()
      .then(()=>Commons.goToCallPage(router))
      .catch(function (errorMsg) {
        RaLog.log("onAcceptedCall error:", errorMsg);

        Commons.showErrorMessage(errorMsg);
        Commons.declinedCall();
      });
  }

  static declinedCall = (router, isPopup) => {
    RaApiCalls.declineIncomingCall()
      .catch(function (errorMsg) {
        console.error(errorMsg);
      })
      .then(() => {
        if (!isPopup) {
          router.back("/main/");
        }
      });
  }

  static hangupCall = () => {
    RaApiCalls.closeActiveCall();
  }

  static goToCallPage = (router) => {
    Commons.wait(1000).then(function () {
      if (router) {
        router.navigate("/main-calls/", { reloadAll: true });
      } else {
        console.error("[goToCallPage] no router props found");
      }
    });
  }

  static wait = function (time) {
    return new Promise(function (resolve) {
      setTimeout(resolve, time);
    });
  };
}

export default Commons;
