import React from "react";
import {
  PageContent,
  Page,
  Block,
  CardContent,
  Card,
  BlockTitle,
} from "framework7-react";
import { connect } from "react-redux";
import AppPageComponent from "../../components/commons/app-page-component";
import MenuLeftButton from "../../components/menu/left-menu-button";
import MenuToolbar from "../../components/menu/toolbar";
import Commons from "../../Commons";
import LogoutLoader from "../../components/logout/logout-loader";
import RedirectEvents from "../../components/commons/redirect-events";
import SettingsForm from "./settings-form";
import localeStrings from "./settings-local";

import { RaApiAuth } from "../../ra-lib/api/auth";

class Settings extends AppPageComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let pageContent = null;
    if (!RaApiAuth.isAuthenticated()) {
      pageContent = <LogoutLoader router={this.props.f7router}></LogoutLoader>;
    } else {
      pageContent = (
        <Block className="setting-cards-containers">
          <BlockTitle>{localeStrings.formTitle}</BlockTitle>
          <Card noShadow outline style={{ margin: 0 }}>
            <CardContent>
              <SettingsForm></SettingsForm>
            </CardContent>
          </Card>
        </Block>
      );
    }

    return (
      <Page pageContent={false} colorTheme="custom">
        <MenuLeftButton></MenuLeftButton>

        <PageContent>{pageContent}</PageContent>

        <RedirectEvents router={this.props.f7router}></RedirectEvents>
        <MenuToolbar router={this.props.f7router}></MenuToolbar>
      </Page>
    );
  }

  componentDidMount = () => {
    Commons.checkAuthentication(this, this.props.f7router);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    Commons.checkAuthentication(this, this.props.f7router);
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    mainPageIndex: state.app.mainPageIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
