import React from "react";
import { ListInput } from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./invitation-add-roles-listinput-local";
import * as actions from "../../redux/actions";

import { RaApiRoles } from "../../ra-lib/api/roles";
import { CONTACT_ROLE } from "../../ra-lib/api/contacts";
import { RaLog } from "../../ra-lib/log";

class RolesListInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      errorMessage: null,
    };
  }

  render() {
    if (this.state.items !== null && this.state.items.length === 0) {
      return null;
    }

    const preloader =
      this.state.items === null ? (
        <option value={null}>{localeStrings.loading}</option>
      ) : null;

    const errorMessage = this.state.errorMessage ? (
      <option value={null}>{this.state.errorMessage}</option>
    ) : null;

    const listItems =
      this.state.items !== null && this.state.items.length > 0
        ? this.state.items.map((item, index) => (
            <option key={item.UniqueId} value={item.UniqueId}>
              {item.Name}
            </option>
          ))
        : null;

    return (
      <ListInput
        type="select"
        inputId={this.props.inputId}
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {preloader}
        {errorMessage}
        {listItems}
      </ListInput>
    );
  }

  loadRoles = () => {
    let items = null;
    let self = this;
    RaApiRoles.getRoles(CONTACT_ROLE.ASSISTED)
      .then((response) => {
        if (response.result.success === true) {
          items = response.data;
          self.setState({ items: items, errorMessage: null });
        } else {
          self.setState({ items: null, errorMessage: localeStrings.api_error });
          RaLog.error(response.result.message);
        }
      })
      .catch((error) => {
        self.setState({ items: null, errorMessage: localeStrings.api_error });
        RaLog.error(error);
      });
  };

  componentDidMount = () => {
    this.loadRoles();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAddressBookInvitation: (invitation) =>
      dispatch(actions.addAddressBookInvitation(invitation)),
  };
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(RolesListInput);
