import React from "react";
import {
  BlockTitle,
  Button,
  List,
  ListInput,
  Preloader,
} from "framework7-react";
import RolesListInput from "./invitation-add-roles-listinput";
import { connect } from "react-redux";
import localeStrings from "./invitation-add-local";
import * as actions from "../../redux/actions";

import { RaApiAddressBook } from "../../ra-lib/api/addressbook";
import { RaLog } from "../../ra-lib/log";

class InvitationAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adding: false,
      errorMessage: null,
    };

    this.selectedRole = null;
  }

  render() {
    const preloader =
      this.state.adding === true ? (
        <Preloader size={28} style={{ position: "absolute" }}></Preloader>
      ) : null;

    const errorMessage = this.state.errorMessage ? (
      <span style={{ color: "red" }}>{localeStrings.api_errorMessage}</span>
    ) : null;

    return (
      <div>
        <BlockTitle
          style={{
            whiteSpace: "normal",
          }}
        >
          {localeStrings.title}
        </BlockTitle>
        <List noHairlinesMd>
          <ListInput
            type="text"
            inputId="txt-name"
            placeholder={localeStrings.name_palceholder}
            clearButton
            required
            validate
            errorMessage={localeStrings.name_errorMessage}
            disabled={this.state.adding}
          />
        </List>

        <BlockTitle
          style={{
            whiteSpace: "normal",
          }}
        >
          {localeStrings.roles_title}
        </BlockTitle>
        <List noHairlinesMd>
          <RolesListInput slot="list" inputId="role-list"></RolesListInput>
        </List>

        <div style={{ textAlign: "center", width: "100%" }}>
          {preloader}
          {errorMessage}
          <Button
            raised
            fill
            style={{
              display: "inline-block",
              float: "right",
              marginRight: "15px",
              minWidth: "100px",
            }}
            onClick={() => {
              this.submitInvitation();
            }}
          >
            {localeStrings.button_label}
          </Button>
        </div>
      </div>
    );
  }

  submitInvitation = () => {
    if (this.state.adding === true) {
      return;
    }

    let inputEl = document.getElementById("txt-name");
    let inputRoleEl = document.getElementById("role-list");
    let selectedRole =
      inputRoleEl && inputRoleEl.options.length > 0
        ? inputRoleEl.options[inputRoleEl.selectedIndex].value
        : null;

    if (inputEl && inputEl.value && selectedRole) {
      this.setState({ adding: true, errorMessage: null });
      let errorMessage = null;
      let self = this;
      RaApiAddressBook.submitAddressBookInvitation(
        self.props.email,
        inputEl.value,
        selectedRole
      )
        .then((response) => {
          if (response.result.success === true) {
            if (response.data) {
              this.props.addAddressBookInvitation(response.data);
            }
            setTimeout(() => {
              //chiamo al funzione onEmailChecked del parent passando il risultato true o false
              if (self.props.onInvitationSubmitted) {
                self.props.onInvitationSubmitted(response.data);
              }
            }, 100);
          } else {
            errorMessage = localeStrings.api_errorMessage;
            RaLog.error(response.result.message);
          }
        })
        .catch((error) => {
          errorMessage = localeStrings.api_errorMessage;
          RaLog.error(error);
        })
        .finally(() => {
          self.setState({ adding: false, errorMessage: errorMessage });
        });
    }
  };

  componentDidMount = () => {
    // Commons.checkAuthentication(this, this.props.f7router);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //  Commons.checkAuthentication(this, this.props.f7router);
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAddressBookInvitation: (invitation) =>
      dispatch(actions.addAddressBookInvitation(invitation)),
  };
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(InvitationAdd);
