import React from "react";
import {
  Page,
  LoginScreenTitle,
  ListItem,
  Button,
  Link,
  List,
  ListInput,
  PageContent,
  BlockFooter,
  f7 as f7React,
} from "framework7-react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

import { RaNotifications } from "../../ra-lib/notifications";
import { RaApiAuth } from "../../ra-lib/api/auth";
import { RaCordova } from "../../ra-lib/cordova/index";
import { RaLog } from "../../ra-lib/log";

import localeStrings from "./login-local";
import Commons from "../../Commons";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //username: 'giovanni.zuffolini@pikkart.com',
      //    username: 'patrizio.boccazzi@pikkart.com',
      username: "",
      usernamevalid: false,
      selectedCustomer: null,
      customers: [],
      confirmcode: "",
      errormessage: null,
    };
  }

  render() {
    // WARNING QUESTO CAUSAVA BUONA PARTE DEL PROBLEMA
    // if (RaApiAuth.isAuthenticated()) {
    //   return <div></div>;
    // }

    const signinmessage =
      this.state.usernamevalid === false || this.state.selectedCustomer == null
        ? localeStrings.next
        : localeStrings.login;

    let li = (
      <ListInput
        inputId="emailli"
        label={localeStrings.input_label_email}
        type="email"
        validate={true}
        placeholder={localeStrings.sample_email}
        value={this.state.username === null ? "" : this.state.username}
        onInput={(e) => {
          const username = e.target.value === "" ? null : e.target.value;
          this.setState({ username: username });
        }}
      />
    );

    if (this.state.usernamevalid === true) {
      if (this.state.selectedCustomer == null) {
        li = this.state.customers.map((item, index) => {
          return (
            <ListItem
              style={{ cursor: "pointer" }}
              key={"liCustomer" + index}
              title={item.Name}
              onClick={(e) => {
                this.selectCustomer(item);
              }}
              link="#"
            >
              <div slot="media" style={{ height: "60px", width: "100px" }}>
                <img
                  src={item.LoginLogoUrl}
                  style={{ maxHeight: "60px", maxWidth: "100px" }}
                  alt={item.Name}
                />
              </div>
            </ListItem>
          );
        });
      } else {
        li = (
          <ListInput
            label={localeStrings.input_label_code}
            type="tel"
            placeholder="******"
            value={
              this.state.confirmcode === null ? "" : this.state.confirmcode
            }
            onInput={(e) => {
              this.setState({ confirmcode: e.target.value });
            }}
          />
        );
      }
    }

    let backButton = (
      <Link
        onClick={(e) => {
          this.resetCredentials();
        }}
      >
        {localeStrings.change_email_label}
      </Link>
    );

    if (this.state.usernamevalid === false) {
      backButton = null;
    }

    const resendCode =
      this.state.usernamevalid === true &&
      this.state.selectedCustomer != null ? (
        <Link
          iconIos="f7:arrow_clockwise"
          iconAurora="f7:arrow_clockwise"
          iconMd="f7:arrow_clockwise"
          onClick={(e) => {
            this.sendCode();
          }}
        >
          {localeStrings.resend_code_label}
        </Link>
      ) : null;

    const signupLink =
      this.state.usernamevalid === false &&
      (RaCordova.isCordova() || process.env.REACT_APP_WEB_DEBUG === "true") ? (
        <Link href="/signup/" reloadAll={true} view={".view-main"}>
          {localeStrings.signup_label}
        </Link>
      ) : null;

    return (
      // <Page noToolbar noNavbar noSwipeback loginScreen>
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <PageContent>
          <LoginScreenTitle>{localeStrings.signIn}</LoginScreenTitle>
          <List
            id="login-form"
            mediaList={
              this.state.usernamevalid === false ||
              this.state.selectedCustomer != null
            }
            form={
              this.state.usernamevalid && this.state.selectedCustomer == null
            }
          >
            {li}
            <BlockFooter>
              <div style={{ textAlign: "right" }}>
                {resendCode}
                {signupLink}
              </div>
              <br></br>
              <br></br>
              <p style={{ textAlign: "center" }}>{backButton}</p>
              <Button
                fill
                onClick={(e) => {
                  this.signIn();
                }}
              >
                {signinmessage}
              </Button>
              <p style={{ color: "red" }}>
                {this.state.errormessage
                  ? this.state.errormessage.message
                  : null}
              </p>
              <p
                style={{
                  display: this.state.usernamevalid === true ? "none" : "block",
                }}
              >
                {localeStrings.description_signin}
              </p>
              <p
                style={{
                  display:
                    this.state.usernamevalid === true &&
                    this.state.selectedCustomer == null
                      ? "block"
                      : "none",
                }}
              >
                {localeStrings.description_selectCompany}
              </p>
              <p
                style={{
                  display:
                    this.state.usernamevalid === true &&
                    this.state.selectedCustomer != null
                      ? "block"
                      : "none",
                }}
              >
                {localeStrings.description_code}{" "}
              </p>
            </BlockFooter>
          </List>
        </PageContent>
      </Page>
    );
  }

  selectCustomer = (item) => {
    RaLog.log(item);
    this.setState({ selectedCustomer: item });
    this.sendCode();
  };

  resetCredentials = () => {
    this.setState({
      username: null,
      usernamevalid: false,
      confirmcode: null,
      errormessage: null,
      selectedCustomer: null,
    });
  };

  sendCode = () => {
    const errorFn = (errorMsg) => {
      this.setState({
        errormessage: errorMsg,
      });

      this.showToastBottom(errorMsg);
    };

    const successFn = () => {
      this.setState({
        errormessage: localeStrings.code_sent + " " + this.state.username,
      });

      this.showToastBottom(localeStrings.code_sent + " " + this.state.username);
    };

    RaApiAuth.sendNewCode(
      this.state.username,
      this.state.selectedCustomer.Code,
      ""
    )
      .then(function (response) {
        //handle success
        RaLog.log(response);

        if (response.result.success === true) {
          // handle success
          successFn();
        } else {
          // handle error
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        //handle error
        errorFn(errorMessage);
      });
  };

  signIn = () => {
    if (this.state.usernamevalid === false) {
      const emailli = document.getElementById("emailli");

      if (
        emailli !== null &&
        emailli.value &&
        emailli.validity.valid === true &&
        this.state.username !== null
      ) {
        const self = this;
        this.checkEmail()
          .then(function (customers) {
            self.showPreloader(false);
            if (customers.length === 1) {
              self.setState({
                usernamevalid: true,
                customers: customers,
                selectedCustomer: customers[0],
                errormessage: null,
              });
              self.sendCode();
            } else if (customers.length >= 1) {
              self.setState({
                usernamevalid: true,
                customers: customers,
                selectedCustomer: null,
                errormessage: null,
              });
            } else {
              self.setState({
                usernamevalid: false,
                customers: [],
                selectedCustomer: null,
                errormessage: { message: localeStrings.unknown_email },
              });
            }
          })
          .catch(function (errorMsg) {
            RaLog.log(errorMsg);
            if (errorMsg !== self.state.errormessage) {
              self.setState({
                errormessage: errorMsg,
                //spinner: false,
              });
            }

            self.showPreloader(false);
          });
      }

      return;
    }

    if (this.state.confirmcode !== null) {
      //this.props.setConfirmcode(this.state.confirmcode);

      //this.setState({ spinner: true });
      this.showPreloader(true);
      this.performLogin();
    }
  };

  checkEmail = () => {
    return new Promise((resolve, reject) => {
      this.showPreloader(true);

      const errorFn = (errorMsg) => {
        reject(new Error(errorMsg));
      };

      const successFn = (customers) => {
        resolve(customers);
      };

      RaApiAuth.checkEmail(this.state.username)
        .then(function (response) {
          if (response.result.success === true) {
            // handle success
            successFn(response.data);
          } else {
            // handle error
            errorFn(response.result.message);
          }
        })
        .catch(function (errorMessage) {
          //handle error
          errorFn(errorMessage);
        });
    });
  };

  performLogin = () => {
    let self = this;

    const errorFn = (errorMessage, code) => {
      if (code === 403) {
        errorMessage = localeStrings.invalidCodeMessage;
      }

      if (errorMessage !== this.state.errormessage) {
        this.setState({
          errormessage: errorMessage,
          //spinner: false,
        });
      }

      this.showPreloader(false);
      this.showToastBottom(errorMessage);
    };

    const successFn = () => {
      //this.setState({ spinner: false });

      this.props.setNotificationServiceError(null);

      this.showPreloader(false);
      this.resetCredentials();
    };

    const hidePreloader = () => {
      this.showPreloader(false);
    };

    RaApiAuth.doLogin(
      this.state.username,
      this.state.confirmcode,
      Commons.getDeviceId(),
      this.state.selectedCustomer
    )
      .then(function (response) {
        if (response.result.success === true) {
          // handle success
          RaNotifications.checkServiceRegistration()
            .then(function (successResult) {
              if (successResult === true) successFn();
              else {
                const errorMsg = localeStrings.notification_error;
                self.props.setNotificationServiceError(errorMsg);
                errorFn(errorMsg);
              }
            })
            .catch(function (errorMessage) {
              //handle error
              self.props.setNotificationServiceError(errorMessage);
              errorFn(errorMessage);
            });
        } else {
          // handle error
          errorFn(response.result.message, response.result.code);
        }
        hidePreloader();
      })
      .catch(function (errorMessage) {
        RaLog.log("FIREBASE TOKEN to server: error " + errorMessage);
        //handle error
        errorFn(errorMessage);
      });
  };

  showToastBottom = (text) => {
    //if (!this.toastBottom) {
    this.toastBottom = f7React.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    //}
    this.toastBottom.open();
  };

  showPreloader = (show) => {
    if (show) {
      f7React.preloader.show("black");
    } else {
      f7React.preloader.hide();
    }
  };

  isUserAuthenticated = () => {
    if (
      RaApiAuth.isAuthenticated() &&
      this.props.f7router.currentRoute.url !== "/main/"
    ) {
      this.showPreloader(false);
      this.props.f7router.navigate("/main/", {
        reloadAll: true,
        props: { fade: true },
      });
      return true;
    }
    return false;
  };

  componentDidMount = () => {
    if (this.isUserAuthenticated()) {
      return;
    }
  };

  // componentDidUpdate = () => {
  //   if (this.props.username !== null && this.props.confirmcode !== null) {
  //     // this.props.f7router.back();
  //     const self = this;
  //     const router = f7router;
  //     router.back();
  //   }
  // }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.isUserAuthenticated()) {
      return;
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    firebaseToken: state.remoteAssistance.firebaseToken,
    isCordovaReady: state.remoteAssistance.isCordovaReady,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationServiceError: (notificationServiceError) =>
      dispatch(actions.setNotificationServiceError(notificationServiceError)),
  };
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Login);
