import React from "react";
import { Icon, Fab, Preloader } from "framework7-react";
import { connect } from "react-redux";
import ArLoader from "../components/ar-loader";

import { RaMedia } from "../../../ra-lib/media/media";
class SnapshotButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      takingSnapshot: false,
    };
  }

  render() {
    let fabIcon = (
      <Icon
        ios="material:camera"
        aurora="material:camera"
        md="material:camera"
      ></Icon>
    );
    if (this.state.takingSnapshot === true) {
      fabIcon = <Preloader color="white" size="20"></Preloader>;
    }

    let fab = null;
    if (
      !RaMedia.isArVideoInputDeviceEnable() ||
      this.props.arVideoTrainingEnded === true
    ) {
      fab = (
        <Fab
          onClick={(e) => {
            if (this.props.onClick && !this.state.takingSnapshot) {
              this.setState({ takingSnapshot: true });
              this.props.onClick().then(() => {
                this.setState({ takingSnapshot: false });
              });
            }
          }}
          position="center-bottom"
          style={{ position: "fixed", bottom: "100px" }}
          slot="fixed"
        >
          {fabIcon}
        </Fab>
      );
    } else {
      fab = <ArLoader></ArLoader>;
    }

    return fab;
  }

  componentDidMount = () => {};

  componentWillUnmount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    arVideoTrainingEnded: state.remoteAssistance.arVideoTrainingEnded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotButton);
//export default connect(mapStateToProps)(CurrentCalls);
