import React from "react";
import f7 from 'framework7';
import { connect } from "react-redux";
import { ListInput } from "framework7-react";
import localeStrings from "./inputarvideo-listitem-local";
import InputArVideoOptions from "./inputarvideo-options";
import InputArVideoPreview from "./inputarvideo-preview";
import InputArVideoStatus from "./inputarvideo-status";

import { Ra } from "../../ra-lib/index";
import { RaMedia } from "../../ra-lib/media/media";

class InputArVideoListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return Ra.getConfig().arEnabled ? (
      <ListInput
        disabled={
          !this.props.isArVideoInputDeviceEnable ||
          this.props.callContext ||
          (this.props.arVideoTrainingStarted &&
            !this.props.arVideoTrainingEnded)
        }
        label={localeStrings.inputArVideoLabel}
        type="select"
        id="arvideo_input"
        defaultValue={
          RaMedia.getSelectedArVideoInputDevice()
            ? RaMedia.getSelectedArVideoInputDevice().deviceId
            : null
        }
        onChange={() => {
          RaMedia.selectArVideoInputDeviceId(
            f7.$("#arvideo_input select").val()
          );
        }}
      >
        <InputArVideoOptions containerSelector="#arvideo_input select"></InputArVideoOptions>
        <InputArVideoPreview slot="content"></InputArVideoPreview>
        <InputArVideoStatus slot="root"></InputArVideoStatus>
      </ListInput>
    ) : null;
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    selectedArVideoInputDevice:
      state.remoteAssistance.selectedArVideoInputDevice, //necessari per cambiare il valore del menù a tendina, le cui voci possono variare senza interazione da parte dell'utente
    activeCall: state.remoteAssistance.activeCall,
    isArVideoInputDeviceEnable:
      state.remoteAssistance.isArVideoInputDeviceEnable,

    arVideoTrainingStarted: state.remoteAssistance.arVideoTrainingStarted,
    arVideoTrainingEnded: state.remoteAssistance.arVideoTrainingEnded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputArVideoListItem);
