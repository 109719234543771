import { RaReduxReducer } from "./reducer";

export class RaStore {
  static _store = null;

  static set = (store) => {
    this._store = store;
  };
  static get = () => {
    return this._store;
  };
  static getState = () => {
    return this.get().getState()[RaReduxReducer.getReducerName()];
  };
}

export default RaStore;
