import React from "react";
import f7 from 'framework7';
import {
  Block,
  Page,
  Link,
  Sheet,
  BlockTitle,
  PageContent,
  Swiper,
  SwiperSlide,
  Row,
  Col,
  Button,
  Preloader,
  List,
  ListInput,
} from "framework7-react";
import { connect } from "react-redux";

import { RaStorage } from "../../ra-lib/storage";
import { RaApiIpCam } from "../../ra-lib/api/ipcam";
import { RaCordova } from "../../ra-lib/cordova/index";
import { RaCordovaNet } from "../../ra-lib/cordova/net";
import { RaLog } from "../../ra-lib/log";

import Commons from "../../Commons";
import IpCamStream from "../../components/ipcam/ipcam-stream";
import localeStrings from "./ipcam-settings-local";
import MenuLeftButton from "../../components/menu/left-menu-button";
import MenuToolbar from "../../components/menu/toolbar";
import RedirectEvents from "../../components/commons/redirect-events";

class IpCamSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      somethingWrongSheetOpened: false,
      stepInprogress: false,
      connected: false,
      connecting: false,
      renderingError: null,
      ipCamIpAddress: null,
    };

    this.slider = null;

    this.ipCamWifiName = null;
    this.ipCam = null;
    this.ipCamStreamDimensions = null;

    this.STEP_CONFIG_IPCAM = 2;
  }

  render() {
    if (this.state.errormessage !== null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3>{String(this.state.errormessage)}</h3>
          </Col>
        </Block>
      );
    }

    const ipCamList =
      RaStorage.getProfile() === null
        ? []
        : RaStorage.getProfile().IpCamsEnabled.map((item, index) => {
            return (
              <option key={"ipca_" + index} value={item.Code}>
                {item.Name}
              </option>
            );
          });

    let ipCamStream = null;
    if (this.props.selectVideoInputIpCam && this.state.ipCamIpAddress) {
      ipCamStream = (
        <IpCamStream
          imgId="ipcam-settings-image"
          canvasId="ipcam-settings-canvas"
          deviceIp={this.state.ipCamIpAddress}
          setRenderingError={this.setIpCamRenderingErrorHandler}
          setIpCamStreamDimensions={this.setIpCamStreamDimensionsHandler}
          ipCamConnecting={this.ipCamConnectingHandler}
          ipCamConnected={this.ipCamConnectedHandler}
        ></IpCamStream>
      );
    }

    return (
      <Page pageContent={false} colorTheme="custom">
        <MenuLeftButton></MenuLeftButton>

        <PageContent>
          <Swiper
            className="ipcam-settings-swiper"
            pagination
            params={{
              speed: 500,
              observer: true,
              observeParents: true,
              allowTouchMove: false,
            }}
          >
            <SwiperSlide>
              <Block strong>
                <Row>
                  <Col>
                    <h2>{localeStrings.slide1_title}</h2>
                    <p>
                      <i>{localeStrings.slide1_subtitle}</i>
                    </p>
                    <p>{localeStrings.slide1_description1}</p>
                    <p>{localeStrings.slide1_description2}</p>
                  </Col>
                </Row>
                <Row className="stepButtons">
                  <Col></Col>
                  <Col></Col>
                  <Col style={{ textAlign: "right" }}>
                    <Button
                      fill
                      onClick={(e) => {
                        this.slideTo(1);
                      }}
                    >
                      {localeStrings.button_next}
                    </Button>
                  </Col>
                </Row>
              </Block>
            </SwiperSlide>
            <SwiperSlide>
              <Block strong>
                <Row>
                  <Col>
                    <h2>{localeStrings.slide2_title}</h2>
                    <p>{localeStrings.slide2_description1}</p>
                    <p>{localeStrings.slide2_description2}</p>
                    <div style={{ textAlign: "center" }}></div>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                    <Button
                      fill
                      color="gray"
                      onClick={(e) => {
                        this.settingsButtonClick();
                      }}
                    >
                      {localeStrings.button_settings}
                    </Button>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className="stepButtons">
                  <Col style={{ textAlign: "left" }}>
                    <Button
                      fill
                      onClick={(e) => {
                        this.slideTo(0);
                      }}
                    >
                      {localeStrings.button_prev}
                    </Button>
                  </Col>
                  <Col style={{ textAlign: "center", display: "contents" }}>
                    <Preloader
                      id="spinner-step1"
                      style={{ display: "none" }}
                      size={21}
                    ></Preloader>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <Button
                      fill
                      onClick={(e) => {
                        this.connectToIpCamWifiStep(this.STEP_CONFIG_IPCAM);
                      }}
                    >
                      {localeStrings.button_next}
                    </Button>
                  </Col>
                </Row>
              </Block>
            </SwiperSlide>
            <SwiperSlide>
              <Block strong>
                <Row>
                  <Col>
                    <h2>{localeStrings.slide3_title}</h2>
                    <p>{localeStrings.slide3_description1}</p>
                    <List noHairlinesMd>
                      <ListInput
                        id="ipCam"
                        label="Ip Cam"
                        type="select"
                        required
                        placeholder="Please choose..."
                      >
                        {ipCamList}
                      </ListInput>

                      <ListInput
                        inputId="wifi-name"
                        label="WI-FI name"
                        type="text"
                        placeholder="wi-fi name"
                        defaultValue="AndroidAP"
                        required
                        clearButton
                      />

                      <ListInput
                        inputId="wifi-pw"
                        label="Password"
                        type="password"
                        placeholder="Your password"
                        defaultValue="hmxv0200"
                        required
                        clearButton
                      />
                    </List>
                  </Col>
                </Row>
                <Row className="stepButtons">
                  <Col style={{ textAlign: "left" }}>
                    <Button
                      fill
                      onClick={(e) => {
                        this.slideTo(1);
                      }}
                    >
                      {localeStrings.button_prev}
                    </Button>
                  </Col>
                  <Col style={{ textAlign: "center", display: "contents" }}>
                    <Preloader
                      id="spinner-step2"
                      style={{ display: "none" }}
                      size={21}
                    ></Preloader>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <Button
                      fill
                      onClick={(e) => {
                        this.configIpCamStep(3);
                      }}
                    >
                      {localeStrings.button_next}
                    </Button>
                  </Col>
                </Row>
              </Block>
            </SwiperSlide>
            <SwiperSlide>
              <Block strong>
                <Row>
                  <Col>
                    <h2>{localeStrings.slide4_title}</h2>
                    <p>{localeStrings.slide4_description1}</p>
                    <p>{localeStrings.slide4_description2}</p>
                    <p>
                      {localeStrings.slide4_description3}
                      <br></br>
                      <Link sheetOpen=".something-wrong-sheet">
                        {localeStrings.slide4_sheet_linktext}
                      </Link>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                    <Button
                      fill
                      color="gray"
                      onClick={(e) => {
                        this.settingsButtonClick();
                      }}
                    >
                      {localeStrings.button_settings}
                    </Button>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className="stepButtons">
                  <Col style={{ textAlign: "left" }}>
                    <Button
                      fill
                      onClick={(e) => {
                        this.slideTo(2);
                      }}
                    >
                      {localeStrings.button_prev}
                    </Button>
                  </Col>
                  <Col style={{ textAlign: "center", display: "contents" }}>
                    <Preloader
                      id="spinner-step3"
                      style={{ display: "none" }}
                      size={21}
                    ></Preloader>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <Button
                      fill
                      onClick={(e) => {
                        this.checkIpCamConnectionStep(4);
                      }}
                    >
                      {localeStrings.button_connect}
                    </Button>
                  </Col>
                </Row>
              </Block>
            </SwiperSlide>
            <SwiperSlide>
              <Block strong>
                <Row>
                  <Col>
                    <h2>{localeStrings.slide5_title}</h2>
                    <p
                      style={{
                        color: "red",
                        display:
                          this.state.renderingError !== null ? "block" : "none",
                      }}
                    >
                      {this.state.renderingError}
                      <br></br>
                      <Link
                        onClick={(e) => {
                          this.retryConnection();
                        }}
                      >
                        {localeStrings.button_retry}
                      </Link>
                      <br></br>
                      <br></br>
                      <Link sheetOpen=".something-wrong-sheet">
                        Doesn't work?
                      </Link>
                    </p>
                    <p
                      style={{
                        display:
                          this.state.connecting === true ? "block" : "none",
                      }}
                    >
                      {localeStrings.slide5_connecting}
                      <Preloader
                        id="spinner-step3"
                        style={{ display: "none" }}
                        size={21}
                      ></Preloader>
                    </p>
                    <div
                      style={{
                        display:
                          this.state.connected === true ? "block" : "none",
                      }}
                    >
                      <p>
                        {localeStrings.slide5_connected1}
                        <br></br>
                        {localeStrings.slide5_connected2}
                      </p>
                      <div id="ip-cam-div" style={{ width: "100%" }}>
                        {ipCamStream}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </Block>
            </SwiperSlide>
          </Swiper>
          <Sheet
            className="something-wrong-sheet"
            opened={this.state.somethingWrongSheetOpened}
            onSheetClosed={() => {
              this.setState({ somethingWrongSheetOpened: false });
            }}
            swipeToClose
            backdrop
          >
            <BlockTitle large>{localeStrings.slide4_sheet_title}</BlockTitle>
            <PageContent>
              <Block>
                <p>{localeStrings.slide4_sheet_description}</p>
                <ol>
                  <li>{localeStrings.slide4_sheet_option1}</li>
                  <li>{localeStrings.slide4_sheet_option2}</li>
                  <li>{localeStrings.slide4_sheet_option3}</li>
                </ol>
                <br></br>
                <br></br>
                <br></br>
              </Block>
            </PageContent>
          </Sheet>
        </PageContent>
        <RedirectEvents router={this.props.f7router}></RedirectEvents>
        <MenuToolbar router={this.props.f7router}></MenuToolbar>
      </Page>
    );
  }

  ipCamConnectingHandler = () => {
    this.setState({ renderingError: null, connected: false, connecting: true });
  };

  ipCamConnectedHandler = () => {
    this.setState({ connected: true, connecting: false, renderingError: null });
  };

  setIpCamRenderingErrorHandler = (message, error) => {
    this.setState({
      connected: false,
      connecting: false,
      renderingError: message,
    });
  };

  setIpCamStreamDimensionsHandler = (dimensions) => {
    RaLog.log("setIpCamStreamDimensionsHandler");
    this.ipCamStreamDimensions = dimensions;

    let canvas = document.getElementById("ipcam-settings-canvas");
    canvas.setAttribute("style", "width: 100%; max-width: 500px");
  };

  retryConnection = () => {
    RaApiIpCam.selectVideoInputIpCam(null);
    RaApiIpCam.selectVideoInputIpCam(this.ipCam);
  };

  closeInfoSheets = () => {
    if (this.state.somethingWrongSheetOpened) {
      this.setState({ somethingWrongSheetOpened: false });
    }
  };

  getSelectedCam = (selectedCamCode) => {
    let ipCam = null;
    RaStorage.getProfile().IpCamsEnabled.forEach((item) => {
      if (item.Code === selectedCamCode) {
        ipCam = item;
      }
    });
    return ipCam;
  };

  configIpCamStep = (nextSlideIndex) => {
    if (this.state.stepInprogress) {
      return;
    }
    this.closeInfoSheets();

    this.ipCam = this.getSelectedCam(
      f7.$("#ipCam option:checked").attr("value")
    );
    let wifiName = document.getElementById("wifi-name").value;
    let wifiPw = document.getElementById("wifi-pw").value;
    let spinner = document.getElementById("spinner-step2");
    this.stepStarted(spinner);

    let self = this;
    RaApiIpCam.configCam(this.ipCam.Code, wifiName, wifiPw)
      .then(function () {
        self.stepFinished(spinner);
        self.slideTo(nextSlideIndex);
      })
      .catch(function (errorMsg) {
        self.stepFinished(spinner);
        self.slideTo(nextSlideIndex);
        //l'operazione va sempre in errore perché l'ip cam si resetta, quindi non risponde
        //un modo per capire se l'operazione è andata a buon fine è verificare se si è ancora connessi alla stessa wifi di prima.
        //Se la wifi precedente non è più disponibile allora significa che l'ip cam si è resettata.

        //Aspetto qualche secondo in modo che il sistema operativo si renda conto che la wifi non è più disponibile
        /*  setTimeout(function() {
            self.isDeviceConnectedToIpCamWifi(function(isConnected) {
                spinner.style.display = 'none';
                if (isConnected) {
                  //si restituisce errore, perché significa che l'ip cam non si è resettata, quindi la richiesta non è andata a buon fine
                  Commons.showErrorMessage(self, 'Ip Cam not configured: '+errorMsg);
                }
                else {
                  //il dispositivo ha cambiato rete, si presuppone che sia andato tutto bene
                  self.slideTo(nextSlideIndex);
                }
            }, function (error) {
              spinner.style.display = 'none';
              //non è stato possibile leggere il nome della wifi, si presuppone sia andato tutto bene...ma è strano.
              //E' bene chiedere conferma all'utente che gli occhiali si siano spenti
              console.error('isDeviceConnectedToIpCamWifi error', error);
              self.slideTo(nextSlideIndex);
            });
        }, 5000);*/
      });
  };

  stepStarted = (spinner) => {
    if (spinner) {
      spinner.style.display = "block";
    }
    this.setState({ stepInprogress: true });
  };

  stepFinished = (spinner) => {
    if (spinner) {
      spinner.style.display = "none";
    }
    this.setState({ stepInprogress: false });
  };

  connectToIpCamWifiStep = (nextSlideIndex) => {
    if (this.state.stepInprogress) {
      return;
    }
    this.closeInfoSheets();

    let spinner = document.getElementById("spinner-step1");
    this.stepStarted(spinner);
    //utilizzare un plugin di phonegap per sapere il nome della wifi della ip cam (wifi a cui è connesso l'utente)
    let self = this;
    self.getIpCamWifiName(
      function (name) {
        self.stepFinished(spinner);
        self.ipCamWifiName = name;
        if (!name) {
          Commons.showAlert(
            self,
            localeStrings.connectToIpCamWifi_alert1_text,
            localeStrings.connectToIpCamWifi_alert1_title
          );
        } else {
          self.slideTo(nextSlideIndex);
        }
      },
      function (error) {
        self.stepFinished(spinner);
        Commons.showErrorMessage(
          self,
          "error retrieving ip cam wifi name: " + error
        );
      }
    );
  };

  isDeviceConnectedToIpCamWifi = (successCallback, errorCallback) => {
    //utilizzare un plugin di phonegap per sapere il nome della wifi della ip cam (wifi a cui è connesso l'utente)
    let self = this;
    self.getCurrentWifiName(
      function (wifiName) {
        if (wifiName && wifiName === self.ipCamWifiName) successCallback(true);
        else successCallback(false);
      },
      function (error) {
        errorCallback(error);
      }
    );
  };

  settingsButtonClick = () => {
    let self = this;
    this.openDeviceSettings(
      function () {
        RaLog.log("device settings opened");
      },
      function () {
        Commons.showErrorMessage(self, "Error opening device settinmgs");
      }
    );
  };

  openDeviceSettings = (successCallback, errorCallback) => {
    //INSERIRE QUI LA CHIAMATA AL PLUGIN
    if (RaCordova.isCordova()) {
      RaCordovaNet.openWiFiSettings(successCallback, errorCallback);
    }
  };

  getCurrentWifiName = (successCallback, errorCallback) => {
    //INSERIRE QUI LA CHIAMATA AL PLUGIN
    //  successCallback('CM2FE2-4A7D5C78BED3-A892FD');
    RaLog.log("getConnectionInfo");
    if (RaCordova.isCordova()) {
      RaCordovaNet.getConnectionInfo(function (result) {
        RaLog.log(result);
        if (result.supplicantState === "DISCONNECTED") {
          successCallback(null); // "Connection info: not on wifi";
        } else {
          successCallback(result.SSID); // "Connection info: SSID " + result.SSID + " BSSID " + result.BSSID + " ipAddress " + result.ipAddress;
        }
      }, errorCallback);
    } else {
      successCallback("TEST");
    }
  };

  getIpCamWifiName = (successCallback, errorCallback) => {
    this.getCurrentWifiName(function (wifiName) {
      successCallback(wifiName);
    }, errorCallback);
  };

  getIpCamIpAddress = (successCallback, errorCallback) => {
    RaLog.log("ListOfConnectedDevice");
    //INSERIRE QUI LA CHIAMATA AL PLUGIN
    if (RaCordova.isCordova()) {
      RaCordovaNet.getListOfConnectedDevice(function (devices) {
        RaLog.log(devices);
        if (devices && devices[0]) {
          successCallback(devices[0].ip);
        } else {
          errorCallback(localeStrings.getIpCamIpAddress_alert);
        }
      }, errorCallback);
    } else {
      successCallback("192.168.1.239");
      // errorCallback('It is not possibile to check connected devices in a web context');
    }
    /*
    setTimeout(function(){
      successCallback('192.168.1.127');
    }, 3000);*/
  };

  isRouterWifiActive = (successCallback, errorCallback) => {
    RaLog.log("isRouterWifiActive");
    //INSERIRE QUI LA CHIAMATA AL PLUGIN
    // successCallback(true);
    if (RaCordova.isCordova()) {
      RaCordovaNet.isWifiHotspotEnabled(function (result) {
        RaLog.log("isRouterWifiActive: " + result);
        successCallback(result);
      }, errorCallback);
    } else {
      successCallback(true);
    }
  };

  checkIpCamConnectionStep = (nextSlideIndex) => {
    if (this.state.stepInprogress) {
      return;
    }

    this.closeInfoSheets();

    RaLog.log("checkIpCamConnection");
    let spinner = document.getElementById("spinner-step3");
    this.stepStarted(spinner);

    let self = this;

    self.isRouterWifiActive(
      function (isActive) {
        if (!isActive) {
          Commons.showErrorMessage(
            self,
            localeStrings.isRouterWifiActive_error1
          );
          self.stepFinished(spinner);
        } else {
          self.getIpCamIpAddress(
            function (ip) {
              self.stepFinished(spinner);

              if (ip) {
                RaApiIpCam.selectVideoInputIpCam(self.ipCam);
                self.setState({ ipCamIpAddress: ip });
                self.slideTo(nextSlideIndex);
              } else {
                RaLog.log("getIpCamIpAddress - ip is empty");
                Commons.showErrorMessage(self, "Error connecting ip cam");
              }
            },
            function (error) {
              self.stepFinished(spinner);
              RaLog.log("getIpCamIpAddress - ", error);
              Commons.showErrorMessage(
                self,
                "Error connecting ip cam: " + error
              );
            }
          );
        }
      },
      function (error) {
        self.stepFinished(spinner);
        Commons.showErrorMessage(
          self,
          localStorage.isRouterWifiActive_error2 + ": " + error
        );
      }
    );
  };

  slideTo = (slideIndex) => {
    if (this.state.stepInprogress) {
      return;
    }

    this.closeInfoSheets();

    if (this.state.ipCamIpAddress && slideIndex <= this.STEP_CONFIG_IPCAM) {
      this.setState({ ipCamIpAddress: null });
    }

    this.slider.slideTo(slideIndex);
  };

  getSlider = () => {
    //var mySwiper = new Swiper('.ipcam-settings-swiper', {speed:500, observer: true, observeParents: true, allowTouchMove: false});

    return document.querySelector(".ipcam-settings-swiper").swiper;
  };

  onTabFocus = () => {
    //this.slider = this.getSlider();
    this.slideTo(0);
  };

  componentDidMount = () => {
    this.slider = this.getSlider();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    let stepButtons = f7.$(".stepButtons .button");
    if (this.state.stepInprogress) {
      stepButtons.attr("style", "opacity: 0.3");
    } else {
      stepButtons.attr("style", "opacity: 1");
    }

    if (
      prevProps &&
      prevProps.mainPageIndex !== Commons.MAINPAGEINDEX_IPCAMSETTINGS &&
      this.props.mainPageIndex === Commons.MAINPAGEINDEX_IPCAMSETTINGS
    ) {
      this.onTabFocus();
    }

    if (!prevState.connected && this.state.connected) {
      /* let canvas = document.getElementById("ipcam-settings-canvas");
      let mediaStream = canvas.captureStream();
      let tracks = mediaStream.getTracks();//.map(track => track.kind === 'audio' ? RaApiCallsCommunicationProvider.getLocalAudioTrack(track) : RaApiCallsCommunicationProvider.getLocalVideoTrack(track));
  
      RaLog.log('ip cam track detected', tracks);*/
      //  this.cropLocalVideoPlaceHolder(this.getVideoTrack(tracks), '#ipcam-settings-canvas', this.isTrackSourceAvailableCallback, this.getVideoCropSizeCallback, this.getVideoTrackSizeCallback);
    }
  };

  getVideoTrack = (tracks) => {
    let videoTrack = null;
    tracks.forEach(function (track) {
      if (track.kind === "video") {
        videoTrack = track;
      }
    });
    return videoTrack;
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    selectVideoInputIpCam: state.remoteAssistance.selectVideoInputIpCam,
    mainPageIndex: state.app.mainPageIndex,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     cambiaTestoDummy: (testo) => dispatch(actions.cambiaTestoDummy(testo)),
//   }
// };

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps)(IpCamSettings);
