import { RaApiNet } from "../../../api/net";
import { Ra } from "../../../index";
import { RaStore } from "../../../redux/store";

export class RaApiCallsPicsAnnotations {
  static getPicAnnotations = (callId, imageId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url:
          Ra.getConfig().apiUrl +
          "/services/calls/" +
          callId +
          "/pics/" +
          imageId +
          "/annotations",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static addPicAnnotations = (
    callId,
    imageId,
    points,
    strokeWidth,
    canvasWidth,
    canvasHeight
  ) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("points", points);
      bodyFormData.set("width", strokeWidth);
      bodyFormData.set("canvasWidth", canvasWidth);
      bodyFormData.set("canvasHeight", canvasHeight);

      RaApiNet.callApi({
        method: "post",
        url:
          Ra.getConfig().apiUrl +
          "/services/calls/" +
          callId +
          "/pics/" +
          imageId +
          "/annotations",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static deletePicAnnotation = (callId, imageId, annotationId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "delete",
        url:
          Ra.getConfig().apiUrl +
          "/services/calls/" +
          callId +
          "/pics/" +
          imageId +
          "/annotations/" +
          annotationId,
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getParticipantLineColor = () => {
    return RaStore.getState().activeCall != null
      ? RaStore.getState().activeCall.AnnotationsColor
      : null;
  };
}

export default RaApiCallsPicsAnnotations;
