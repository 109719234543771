import React from 'react';
import f7 from 'framework7';
import { Page, Navbar, List, ListInput, Button, Link, ListItem, Block, Col, Row } from 'framework7-react';
import { connect } from 'react-redux';
import localeStrings from './documents-filters-local';

class DocumentsFilter extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      
    };

    this.dateFrom = null;
    this.dateTo = null;
  }

  render() {

    if (this.props.username === null || this.props.confirmcode === null) {
      return null;
    }

    const contactList = this.props.userAddressBook.map((item, index) => {
      return (
        <option key={'contact_'+item.UniqueId} value={item.UniqueId}>{item.Name}</option>
      )
    });

    return (
      <Page>
        <Navbar title={localeStrings.title}>
            <Link slot="left" iconMd="material:close" iconAurora="material:close" iconIos="material:close" onClick={(e) => { this.props.onCloseFiltersPopup(); }}></Link>
        </Navbar>

        <List inlineLabels noHairlinesMd>

          <ListInput
            label={localeStrings.fileName}
            id="itemFileName"
            inputId="fileName"
            type="text"
            clearButton
          >
          </ListInput>

          <ListItem
            title={localeStrings.contact}
            smartSelect
            smartSelectParams={{openIn: 'popup', closeOnSelect: true, searchbar: true, searchbarPlaceholder: localeStrings.contactSearchPlaceHolder, routableModals: false, popupCloseLinkText: localeStrings.contactSearchClose}}
          >
          <select name="contact" id="contact" defaultValue="">
            <option value="">{localeStrings.contactSearchEverybody}</option>
            {contactList}
          </select>
          </ListItem>

          <ListItem
            title={localeStrings.sendReceived}
            smartSelect
            smartSelectParams={{openIn: 'popup', closeOnSelect: true, searchbarPlaceholder: localeStrings.contactSearchPlaceHolder, routableModals: false, popupCloseLinkText: localeStrings.contactSearchClose}}
          >
          <select name="sentReceived" id="sentReceived" defaultValue="">
            <option value="all">{localeStrings.sendReceived_all}</option>
            <option value="sent">{localeStrings.sendReceived_sent}</option>
            <option value="received">{localeStrings.sendReceived_received}</option>
          </select>
          </ListItem>

          <ListInput
            label={localeStrings.dateFrom}
            id="itemDateFrom"
            inputId="dateFrom"
            type="datepicker"
            defaultValue={[new Date().setMonth(-12)]}
            calendarParams={{openIn: 'customModal', footer: true, closeOnSelect: true, locale: localeStrings.getLanguage(), dateFormat: {year: 'numeric', month: 'numeric', day: 'numeric'}, value:[new Date().setMonth(new Date().getMonth() - 12)], maxDate: new Date(), on: {change: (object, values) => {this.dateFrom = values[0];}}}}
            placeholder={localeStrings.datePlaceHolder}
          >
          </ListInput>

          <ListInput
            label={localeStrings.dateTo}
            id="itemDateTo"
            inputId="dateTo"
            type="datepicker"
            calendarParams={{openIn: 'customModal', footer: true, closeOnSelect: true, locale: localeStrings.getLanguage(), dateFormat: {year: 'numeric', month: 'numeric', day: 'numeric'}, value:[new Date()], maxDate: new Date(), on: {change: (object, values) => {this.dateTo = values[0];}}}}
            placeholder={localeStrings.datePlaceHolder}
          >
          </ListInput>
        </List>

        <Block>
          <Row noGap>
            <Col width="25"></Col>
            <Col><Button raised fill onClick={(e) => {this.applyFilters();}}>{localeStrings.applyButton}</Button></Col>
            <Col width="25"></Col>
          </Row>
        </Block>
        
      </Page>

    );
  }

  applyFilters = () => {
    //RaLog.log('dateFrom='+f7.$('#dateFrom').val() + 'dateTo='+f7.$('#dateTo').val() + 'sentReceived='+f7.$('#sentReceived').val() + 'contact='+f7.$('#contact').val() + 'fileName='+f7.$('#fileName').val());
    if (this.props.onApplyFilters) {
      this.props.onApplyFilters({
        dateFrom: (this.dateFrom) ? Date.UTC(this.dateFrom.getFullYear(), this.dateFrom.getMonth(), this.dateFrom.getDate()): null,
        dateTo: (this.dateTo) ? Date.UTC(this.dateTo.getFullYear(), this.dateTo.getMonth(), this.dateTo.getDate()): null,
        sentReceived: f7.$('#sentReceived').val(),
        contactId: f7.$('#contact').val(),
        fileName: f7.$('#fileName').val(),
      });
    }
  }

  componentDidMount = () => {
    
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    userAddressBook: state.remoteAssistance.userAddressBook,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     
//   }
// };

// #endregion

export default connect(mapStateToProps, null)(DocumentsFilter);
