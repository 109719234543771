export class RaCordovaPayments {
  static askPaymentMethodNonce = (token) => {
    return new Promise((resolve, reject) => {
      var methodNonce = window.cordova.plugins.PikkartPaymentBrainTree;
      methodNonce.getMethodNonce(token, success, error);

      function error(errorMessage) {
        reject(errorMessage);
      }

      function success(paymentMethodNonce) {
        resolve(paymentMethodNonce);
      }
    });
  };
}
export default RaCordovaPayments;
