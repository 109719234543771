import React from "react";
import { Link } from "framework7-react";
import { connect } from "react-redux";

import { RaApiRooms } from "../../ra-lib/api/rooms/rooms";

class RoomsMuteButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      roomMute: false,
    };
  }

  render() {
    return (
      <Link
        iconMd={this.state.roomMute ? "f7:bell_fill" : "f7:bell_slash"}
        onClick={(e) => {
          this.changeRoomMute(this.props.room);
        }}
      ></Link>
    );
  }

  changeRoomMute = (room) => {
    let self = this;
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    };

    let newMuteState = !room.Mute;
    RaApiRooms.setMute(room.UniqueId, newMuteState)
      .then(function (response) {
        if (response.result.success === true) {
          room.Mute = newMuteState;
          self.setState({ errormessage: null, roomMute: newMuteState });
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  };

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      this.props.room &&
      (!prevProps.room ||
        (prevProps.room && prevProps.room.Mute !== this.props.room.Mute))
    ) {
      this.setState({ roomMute: this.props.room.Mute });
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {};
};

// #endregion

export default connect(mapStateToProps)(RoomsMuteButton);
