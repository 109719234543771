import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    name_palceholder: "Your contact's name",
    title: "Please type your contact name",
    button_label: "Submit invitation",
    name_errorMessage: "Please type a name",
    api_errorMessage:
      "An error occurred submitting the invitation, please try later.",
    roles_title: "Select his role",
  },
  it: {
    name_palceholder: "Nome del tuo contatto",
    title: "Inserisci il nome del tuo contatto",
    button_label: "Manda l'invito",
    name_errorMessage: "Inserisci un nome",
    api_errorMessage:
      "Si è verificato un errore nel tentativo di inviare l'invito, riprova più tardi.",
    roles_title: "Indica il suo ruolo",
  },
});

export default localeStrings;
