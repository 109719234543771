import { connect } from "react-redux";
import { RaUiTrackRenderer } from "../../ra-lib/ui/tracksRenderer";

export class TrackRender extends RaUiTrackRenderer {
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.selectedAudioOutputDevice) {
      let selectedAudioOutputDeviceChanged =
        !prevProps ||
        !prevProps.selectedAudioOutputDevice ||
        prevProps.selectedAudioOutputDevice.deviceId !==
          this.props.selectedAudioOutputDevice.deviceId;

      if (selectedAudioOutputDeviceChanged) {
        this.updateOutputAudioDevice();
      }
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    callTracksNodes: state.remoteAssistance.callTracksNodes,
    selectedAudioOutputDevice: state.remoteAssistance.selectedAudioOutputDevice,
    selectedAudioInputDevice: state.remoteAssistance.selectedAudioInputDevice,
    selectedVideoInputDevice: state.remoteAssistance.selectedVideoInputDevice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(TrackRender);
