import axios from "axios";

import { RaApiNet } from "../api/net";
import { Ra } from "../index";
import { RaStorage } from "../storage";
import { RaReduxActions } from "../redux/actions";
import { RaStore } from "../redux/store";

export class RaApiIpCam {
  static getCams = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/ipcams",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getCam = (camCode) => {
    for (var i = 0; i < RaStorage.getProfile().IpCamsEnabled.length; i++) {
      if (RaStorage.getProfile().IpCamsEnabled[i].Code === camCode)
        return RaStorage.getProfile().IpCamsEnabled[i];
    }
    return null;
  };

  static configCam = (camCode, wifiName, wifiPw) => {
    return new Promise((resolve, reject) => {
      let cam = RaApiIpCam.getCam(camCode);
      if (cam == null) reject(new Error("invalid cam code"));
      else if (cam.ConfigRequest == null)
        reject(new Error("config request is null"));
      else {
        let postData = cam.ConfigRequest.Body
          ? cam.ConfigRequest.Body.replace("<WIFINAME>", wifiName).replace(
              "<WIFIPW>",
              wifiPw
            )
          : null;
        let headerData =
          cam.ConfigRequest.Headers !== "" && cam.ConfigRequest.Headers != null
            ? JSON.parse("[" + cam.ConfigRequest.Headers + "]")[0]
            : null;
        axios({
          url: cam.ConfigRequest.Url,
          method: cam.ConfigRequest.Method,
          data: postData,
          headers: headerData,
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (response) {
            reject(new Error(response));
          });
      }
    });
  };

  static getCamIp = (camId) => {};

  static isCamConnected = (docId) => {};

  static getSelectedVideoInputIpCam = () => {
    return RaStore.getState().selectVideoInputIpCam;
  };

  static selectVideoInputIpCam = (ipCam) => {
    RaStorage.setMediaInputVideo(null);
    RaStore.get().dispatch(RaReduxActions.setSelectVideoInputIpCam(ipCam));
  };
}
export default RaApiIpCam;
