import { RaApiNet } from "../api/net";
import { RaLog } from "../log";
import { RaCrypto } from "../crypto/crypto";

export class RaCordovaUi {
  static openDocumentFromLink = (docName, url) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: url,
      })
        // .then(res => res.blob()) // Gets the response and returns it as a blob
        .then((response) => {
          const download = (filename, blob, mimeType) => {
            /* var blob = new Blob([data], {
              type: mimeType
            });*/
            if (window.cordova && window.cordova.platformId !== "browser") {
              document.addEventListener("deviceready", function () {
                var storageLocation = "";

                switch (window.device.platform) {
                  case "Android":
                    storageLocation = window.cordova.file.externalDataDirectory;
                    break;

                  case "iOS":
                    storageLocation = window.cordova.file.documentsDirectory;
                    break;

                  default:
                    break;
                }

                var folderPath = storageLocation;

                window.resolveLocalFileSystemURL(
                  folderPath,
                  function (dir) {
                    dir.getFile(
                      filename,
                      {
                        create: true,
                      },
                      function (file) {
                        file.createWriter(
                          function (fileWriter) {
                            fileWriter.write(blob);

                            fileWriter.onwriteend = function () {
                              var url = file.toURL();
                              window.cordova.plugins.fileOpener2.open(
                                url,
                                mimeType,
                                {
                                  error: function error(err) {
                                    RaLog.error(err);
                                    //    alert("Unable to download");
                                    resolve({ success: false, code: 500 });
                                  },
                                  success: function success() {
                                    RaLog.log("success with opening the file");
                                    resolve({ success: true });
                                  },
                                }
                              );
                            };

                            fileWriter.onerror = function (err) {
                              //     alert("Unable to download");
                              RaLog.error(err);
                              resolve({ success: false, code: 500 });
                            };
                          },
                          function (err) {
                            // failed
                            RaLog.error(err);
                            resolve({ success: false, code: 500 });
                          }
                        );
                      },
                      function (err) {
                        RaLog.error(err);
                        resolve({ success: false, code: 500 });
                      }
                    );
                  },
                  function (err) {
                    RaLog.error(err);
                    resolve({ success: false, code: 500 });
                  }
                );
              });
            } /*else {
        saveAs(blob, filename);
      }*/
          };

          if (response.result.success === true) {
            let fileName = response.data.FileName;
            let fileExtension = fileName.substring(
              fileName.lastIndexOf(".") + 1
            );
            let contentType = RaApiNet.getContentType(fileExtension);

            let cryptedFile = JSON.parse(atob(response.data.Base64));
            let fileBase64 = RaCrypto.decrypt(
              cryptedFile,
              response.data.CryptoSchema
            );
            let blob = RaApiNet.b64toBlob(fileBase64, contentType);

            download(fileName, blob, contentType);
            resolve(true);
          } else {
            resolve(false, response.result.code);
          }
        });
    });
  };

  static enableProximitySensor = () => {
    window.navigator.proximity.enableSensor();
    window.navigator.proximity.enableProximityScreenOff();
  };

  static disableProximitySensor = () => {
    window.navigator.proximity.disableProximityScreenOff();
    window.navigator.proximity.disableSensor();
  };

  static stopAudioAndVibration = () => {
    return new Promise((resolve, reject) => {
      function success() {
        resolve();
      }
      function error(errorMessage) {
        reject(errorMessage);
      }
      window.cordova.plugins.firebase.messaging
        .stopCallSoundAndVibration()
        .then(() => {
          success();
        })
        .catch(() => {
          error("cannot stop audio and vibration");
        });
    });
  };
}
export default RaCordovaUi;
