import reducer from "./index";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { RaStore } from "../ra-lib/redux/store";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer(), composeEnhancers(applyMiddleware(thunk)));

RaStore.set(store);

export default store;

/*
Per usare redux fuori da un componente:

import store from './redux/store';
import * as actions from '../redux/actions';

store.dispatch(actions.nomedellaaction(parametri));
store.getState();
*/
