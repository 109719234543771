export class appActionTypes {
  static SET_ORIENTATION = "SET_ORIENTATION";
  static SET_MUST_UPDATE_TARGETS = "SET_MUST_UPDATE_TARGETS";
  static SET_NOTIFICATION_SERVICE_ERROR = "SET_NOTIFICATION_SERVICE_ERROR";
  static SET_MAINPAGE_INDEX = "SET_MAINPAGE_INDEX";
  static SET_NEWROOM_CONTACT_LIST_OPEN = "SET_NEWROOM_CONTACT_LIST_OPEN";
  static SET_NEWCALL_CONTACT_LIST_OPEN = "SET_NEWCALL_CONTACT_LIST_OPEN";
  static SET_LOADING_RECENTS = "SET_LOADING_RECENTS";
  static SET_RECENTS = "SET_RECENTS";
  static SET_APP_INIT = "SET_APP_INIT";
  static SET_ADDRESSBOOK_INVITATIONS = "SET_ADDRESSBOOK_INVITATIONS";
  static ADD_ADDRESSBOOK_INVITATION = "ADD_ADDRESSBOOK_INVITATION";
  static REMOVE_ADDRESSBOOK_INVITATION = "REMOVE_ADDRESSBOOK_INVITATION";
  static SET_ADDRESSBOOK_INVITATION = "SET_ADDRESSBOOK_INVITATION";
}
export default appActionTypes;
