import React from "react";
import f7 from "framework7";
import {
  Block,
  Col,
  Preloader,
  Fab,
  Icon,
  f7 as f7React,
} from "framework7-react";
import { connect } from "react-redux";
import AnnotationCanvas from "../../components/commons/annotation-canvas";

import { RaApiCallsPics } from "../../ra-lib/api/calls/pics/pics";
import { RaApiCalls } from "../../ra-lib/api/calls/calls";
import { RaLog } from "../../ra-lib/log";
class CallAnnotations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      spinner: false,
      annotations: [],
    };

    this.showingImage = false;
  }

  render() {
    if (this.state.errormessage !== null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3> {"Error loading images list:" + this.state.errormessage}</h3>
          </Col>
        </Block>
      );
    }

    if (this.props.takenSnapshots === null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    return (
      <div>
        <div style={{ display: "inline-block" }}>
          <AnnotationCanvas
            live={this.props.live}
            cancelAnnotationButton={
              <Fab
                position="right-top"
                slot="fixed"
                color="gray"
                style={{ marginTop: 0 }}
              >
                <Icon
                  ios="f7:arrow_uturn_left"
                  aurora="f7:arrow_uturn_left"
                  md="material:undo"
                ></Icon>
              </Fab>
            }
            callId={this.props.callId}
            dataTrack={this.props.dataTrack}
            myProfile={this.props.myProfile}
            marginBottom={310}
            $$={f7.$}
            alt="annotabile"
          ></AnnotationCanvas>
        </div>
        {!this.props.live && this.getPictures()}
      </div>
    );
    // {this.state.selectedImage}
  }

  showImage = (img) => {
    if (this.showingImage === false) {
      this.showingImage = true;
      // this.setState({ spinner: true });

      let imgSrc = null;
      if (img !== null) {
        //   this.fetchAnnotationsForImage(img);
        let htmlImage = document.getElementById("img_" + img.UniqueId);
        if (htmlImage) {
          imgSrc = htmlImage.src;
        }
      }

      if (
        (this.props.live && RaApiCalls.amICallController()) ||
        !this.props.live
      ) {
        RaApiCallsPics.selectPic(img, imgSrc);
      }

      this.showingImage = false;
    }
  };

  getDefaultImageUrl = () => {
    return "img/loader.gif";
  };

  getPictures = () => {
    if (
      !this.props.takenSnapshots ||
      this.props.takenSnapshots === null ||
      this.props.takenSnapshots.length === 0 ||
      this.props.shown !== true
    ) {
      return null;
    }

    const images = this.props.takenSnapshots.map((item, index) => {
      if (this.props.callId) {
        RaApiCallsPics.getPicBase64(this.props.callId, item).then((base64) => {
          if (base64) {
            RaLog.log("loaded image " + item.UniqueId);
            let img = document.getElementById("img_" + item.UniqueId);
            if (img) {
              if (img.complete) {
                img.src = "data:image/jpg;base64," + base64;
              } else {
                img.addEventListener("load", () => {
                  img.src = "data:image/jpg;base64," + base64;
                });
              }
            }
          }
        });
      }

      return (
        <div
          key={"swiperslide" + index}
          style={{ textAlign: "initial", marginRight: "15px" }}
        >
          <span className="picIndex"># {index + 1}</span>
          <br></br>
          <img
            id={"img_" + item.UniqueId}
            src={this.getDefaultImageUrl()}
            alt="placeholder"
            onClick={(e) => {
              if (e.target.src.indexOf(this.getDefaultImageUrl()) < 0) {
                if (
                  (this.props.live && RaApiCalls.amICallController()) ||
                  !this.props.live
                ) {
                  this.showImage(item);
                }
              }
            }}
          />

          {this.props.live && RaApiCalls.amICallController() && (
            <span
              class="badge"
              onClick={(e) => {
                this.deletePic(item.UniqueId);
              }}
            >
              X
            </span>
          )}
        </div>
      );
    });

    return (
      <div className="gallery-tabbar">
        <div className="picsGallery" style={{ position: "fixed" }}>
          {images}
        </div>
      </div>
    );
  };

  componentDidMount = () => {
    if (
      !this.props.live &&
      this.props.takenSnapshots === null &&
      this.props.callId
    ) {
      this.fetchPicturesList();
    } else if (
      this.props.live &&
      this.props.takenSnapshots &&
      this.props.takenSnapshots.length > 0
    ) {
      /*    if (this.props.onPicSelectedHandler)
        this.props.onPicSelectedHandler(this.props.selectedSnapshot);*/
    }

    if (this.props.live) {
      RaApiCallsPics.sendSelectedPicRequestMessage();
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      !this.props.live &&
      (prevProps === null || this.props.callId !== prevProps.callId)
    )
      this.fetchPicturesList();
    else {
      if (
        prevProps === null ||
        this.props.takenSnapshots !== prevProps.takenSnapshots
      )
        this.setState({ errormessage: null });
    }
    /*
    if (prevProps.selectedSnapshot !== this.props.selectedSnapshot && this.props.selectedSnapshot) {
      this.showImage(this.props.selectedSnapshot);
    }*/
  };

  fetchPicturesList = () => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    };

    const successFn = (images) => {
      this.setState({ errormessage: null });
    };

    RaApiCallsPics.getCallPics(this.props.callId)
      .then(function (response) {
        //handle success
        RaLog.log(response);

        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        //handle error
        errorFn(response.message);
      });
  };
  /*
  fetchAnnotationsForImage = (image) => {

    let self = this;
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        self.setState({
          spinner: false,
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (annotations) => {
      self.setState({ annotations: annotations, spinner: false });
    }

    RaApiCallsPicsAnnotations.getPicAnnotations(this.props.callId, image.UniqueId).then(function (response) {
      if (response.result.success === true) {
        successFn(response.data);
      } else {
        errorFn(response.result.message);
      }
    })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  }*/

  deletePic = (picId) => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          spinner: false,
          errormessage: errorMsg,
        });
      }
    };

    const successFn = () => {
      this.setState({ spinner: false, errormessage: null });
    };

    f7React.dialog.confirm("Do you want to delete this pic?", "Pics", () => {
      this.setState({ spinner: true });
      RaApiCallsPics.deletePic(picId)
        .then(function (response) {
          if (response.result.success === true) {
            successFn();
          } else {
            errorFn(response.result.message);
          }
        })
        .catch(function (response) {
          //handle error
          errorFn(response.message);
        });
    });
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    takenSnapshots: state.remoteAssistance.takenSnapshots,
    /* selectedSnapshot: state.remoteAssistance.selectedSnapshot,
    selectedSnapshotImgSrc: state.remoteAssistance.selectedSnapshotImgSrc,*/
    dataTrack: state.remoteAssistance.dataTrack,
    myProfile: state.remoteAssistance.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CallAnnotations);
