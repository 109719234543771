import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    email_palceholder: "Your contact's e-mail",
    title:
      "Check if your contact's e-mail has already been inserted by anyone in your organization",
    button_label: "Check",
    email_errorMessage: "Please type a valid e-mail",
    api_errorMessage:
      "An error occurred while checking the e-mail, please try later.",
  },
  it: {
    email_palceholder: "e-mail del tuo contatto",
    title:
      "verifica se l'email del tuo contatto è già stata inserita da qualcuno all'interno della tua organizzazione",
    button_label: "Verifica",
    email_errorMessage: "Inserisci un indirizzo e-mail valido",
    api_errorMessage:
      "Si è verificato un errore nel tenativo di verificare l'e-mail, riprova più tardi.",
  },
});

export default localeStrings;
