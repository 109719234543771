import { isIOS } from "react-device-detect";

import { RaApiNet } from "../api/net";
import { Ra } from "../index";
import { RaStore } from "../redux/store";
import { RaStorage } from "../storage";
import { RaReduxActions } from "../redux/actions";
import { RaCommons } from "../commons";
import { RaApiContacts, AVAILABILITY } from "../api/contacts";
import { RaCordova } from "../cordova";
import { RaLog } from "../log";
import { RaApiAppCompany } from "../api/appCompany";
import { RaApiRooms } from "../api/rooms/rooms";
import { RaApiAddressBook } from "../api/addressbook";

export class RaApiAuth {
  static getToken = () => {
    let token = null;
    try {
      token = RaStorage.getToken();
      if (token) {
        return JSON.parse(token);
      }
    } catch {}
    return null;
  };

  static isAuthenticated = () => {
    if (
      RaApiAuth.getToken() &&
      RaStorage.getProfile() &&
      RaStorage.getPrivateKey() &&
      RaStore.getState().loggedIn === true
    )
      return true;
    else return false;
  };

  static doLogout = () => {
    return new Promise((resolve, reject) => {
      RaApiContacts.doLogout()
        .then(function (result) {})
        .catch(function () {})
        .finally(function () {
          RaStorage.doLogout();
          RaStore.get().dispatch(RaReduxActions.setUserInfo(null));
          resolve();
        });
    });
  };

  static doLogin = (email, password, deviceId, customer, deviceModel) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("customCode", customer.Code);
      bodyFormData.set("email", email); // massimiliano.marsiglietti@pikkart.com
      bodyFormData.set("password", password); // 420752
      bodyFormData.set("deviceId", deviceId); // TODO implementare riconoscimento DUID
      bodyFormData.set(
        "appOs",
        RaCordova.isCordova() ? (isIOS ? "ios" : "android") : "web"
      );
      bodyFormData.set("model", deviceModel);

      RaApiNet.callApi({
        method: "post",
        url: Ra.getConfig().apiUrl + "/services/auth?token",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            const token = response.data.Token;
            const pk = response.data.PrivateKey;

            RaApiAuth.saveAuthInfo(token, pk, customer)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                RaApiAuth.doLogout().then(() => {
                  resolve(response);
                });
              });
          } else resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static registerFromInvitation = (
    email,
    invitationCode,
    deviceId,
    appOS,
    deviceModel
  ) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);
      bodyFormData.set("invitationCode", invitationCode);
      bodyFormData.set("deviceId", deviceId);
      bodyFormData.set("appOS", appOS);
      bodyFormData.set("model", deviceModel);

      RaApiNet.callApi({
        method: "post",
        url: Ra.getConfig().apiUrl + "/services/auth?register",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            const token = response.data.LoginResult.LoginData.Token;
            const pk = response.data.LoginResult.LoginData.PrivateKey;

            RaApiAuth.saveAuthInfo(token, pk, response.data.Customer)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                RaApiAuth.doLogout().then(() => {
                  resolve(response);
                });
              });
          } else resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static saveAuthInfo = (token, privateKey, customer) => {
    return new Promise((resolve, reject) => {
      RaStorage.setToken(JSON.stringify(token));
      if (!privateKey) {
        RaLog.error("privateKey not received");
      }
      RaStorage.setPrivateKey(privateKey);
      RaStorage.setLoginCompany(customer);

      RaApiAuth.getProfile()
        .then((response) => {
          if (RaApiAuth.isAuthenticated()) {
            RaApiAuth.loadAllUserData()
              .then(function (result) {})
              .catch(function (errorMessage) {
                RaLog.error(errorMessage);
              })
              .finally(() => {
                if (!Ra.getConfig().callsEnabled) {
                  RaApiContacts.updateAvailability(AVAILABILITY.OFFLINE)
                    .then()
                    .catch(function (errorMessage) {
                      RaLog.error(errorMessage);
                    })
                    .finally(() => {
                      resolve(response);
                    });
                } else {
                  resolve(response);
                }
              });
          } else {
            //TODO: stringa di errore
            reject(new Error("[saveAuthInfo] your not authenticated"));
          }
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  //TODO: da testare
  static changeLoginCustomer = (deviceId, customer, appOS, deviceModel) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("deviceId", deviceId);
      bodyFormData.set("customerCode", customer.Code);
      bodyFormData.set("appOS", appOS);
      bodyFormData.set("model", deviceModel);

      RaApiNet.callApi({
        method: "post",
        url: Ra.getConfig().apiUrl + "/services/auth?changeCustomer",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then((response) => {
          if (response.result.success === true) {
            const token = response.data.Token;
            const pk = response.data.PrivateKey;

            RaStorage.doLogout();

            RaApiAuth.saveAuthInfo(token, pk, customer)
              .then((response) => {
                resolve(response);
              })
              .catch((errorMessage) => {
                //TODO: verificare i vari codici di risposta
                reject(new Error(errorMessage));
                /* RaApiAuth.doLogout().then(() => {
                                 resolve(response);
                             });*/
              });
          } else {
            reject(new Error(response.result.message));
          }
        })
        .catch((errorMessage) => {
          reject(new Error(errorMessage));
        });
    });
  };

  static _executingRefreshToken = false;
  static refreshToken = () => {
    return new Promise((resolve, reject) => {
      const finish = (success) => {
        RaApiAuth._executingRefreshToken = false;
        resolve(success);
      };

      console.log(
        "executingRefreshToken value: ",
        RaApiAuth._executingRefreshToken
      );

      if (!RaApiAuth._executingRefreshToken) {
        RaApiAuth._executingRefreshToken = true;
        let token = RaApiAuth.getToken();
        if (!token) {
          finish(false);
        }
        const bodyFormData = new FormData();
        bodyFormData.set("refreshToken", token.RefreshToken);
        bodyFormData.set("deviceId", token.DeviceId);

        RaApiNet.callApi({
          method: "post",
          url: Ra.getConfig().apiUrl + "/services/auth?refreshToken",
          data: bodyFormData,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
          .then(function (response) {
            if (response.result.success === true) {
              let token = response.data;
              RaStorage.setToken(JSON.stringify(token));

              RaApiAuth.getProfile()
                .then()
                .catch(function (errorMessage) {
                  RaLog.error(errorMessage);
                })
                .finally(() => {
                  if (RaApiAuth.isAuthenticated()) {
                    RaApiAuth.loadAllUserData()
                      .then()
                      .catch(function (errorMessage) {
                        RaLog.error(errorMessage);
                      })
                      .finally(() => {
                        finish(true);
                      });
                  } else {
                    finish(true);
                  }
                });
            } else {
              RaLog.error(response.result.message);
              finish(false);
            }
          })
          .catch(function (errorMessage) {
            RaLog.error(errorMessage);
            finish(false);
          });
      } else {
        RaCommons.waitUntil(
          () => {
            console.log("waiting... executingRefreshToken");
            return RaApiAuth._executingRefreshToken === false;
          },
          50,
          10
        )
          .then(() => {
            console.log("executingRefreshToken finished");
            const token = RaStorage.getToken();
            if (token) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((errorMsg) => {
            reject(errorMsg);
          });
      }
    });
  };

  static _loadingAllUserData = false;
  static loadAllUserData = () => {
    let result = { appCompany: false, rooms: false, addressBook: false };
    return new Promise((resolve, reject) => {
      if (RaApiAuth._loadingAllUserData === false) {
        RaApiAuth._loadingAllUserData = true;

        //dopo la prima chiamata l'utente potrebbe risultare non loggato (token salvato non più valido),
        //quindi prima di proseguire controllo la sua autenticazione
        if (RaApiAuth.isAuthenticated()) {
          //aggiorno i dati della società che esegue assistenza
          RaApiAppCompany.getAppCompany()
            .then(function (response) {
              if (response.result.success === false) {
                RaLog.error(
                  "[loadAllUserData]getAppCompany: " + response.result.message
                );
              } else {
                result.appCompany = true;
              }
            })
            .catch(function (response) {
              RaLog.error(
                "[loadAllUserData]getAppCompany: " + response.message
              );
            })
            .then(() => {
              //aggiorno le chat
              RaApiAddressBook.getAddressBook()
                .then(function (response) {
                  if (response.result.success === false) {
                    RaLog.error(
                      "[loadAllUserData]getAddressBook: " +
                        response.result.message
                    );
                  } else {
                    result.addressBook = true;
                  }
                })
                .catch(function (response) {
                  RaLog.error(
                    "[loadAllUserData]getAddressBook: " + response.message
                  );
                })
                .then(() => {
                  //aggiorno la rubrica
                  RaApiRooms.getContactRooms()
                    .then(function (response) {
                      if (response.result.success === false) {
                        RaLog.error(
                          "[loadAllUserData]getContactRooms: " +
                            response.result.message
                        );
                      } else {
                        result.rooms = true;
                      }
                    })
                    .catch(function (response) {
                      RaLog.error(
                        "[loadAllUserData]getContactRooms: " + response.message
                      );
                    })
                    .then(() => {
                      RaApiAuth._loadingAllUserData = false;
                      resolve(result);
                    });
                });
            });
        } else {
          RaApiAuth._loadingAllUserData = false;
          resolve(result);
        }
      }
    });
  };

  static getProfile = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/contacts?profile",
      })
        .then(function (response) {
          if (response.result.success === true) {
            RaStorage.setProfile(response.data);
            RaStore.get().dispatch(RaReduxActions.setUserInfo(response.data));
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          //handle error
          reject(new Error(errorMessage));
        });
    });
  };

  static sendNewCode = (email, customerCode, deviceId) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);
      bodyFormData.set("customCode", customerCode);
      if (deviceId) bodyFormData.set("deviceId", deviceId);

      RaApiNet.callApi({
        method: "post",
        url: Ra.getConfig().apiUrl + "/services/auth?confirmCode",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static checkEmail = (email, deviceId) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);
      if (deviceId) bodyFormData.set("deviceId", deviceId); // TODO implementare riconoscimento DUID
      bodyFormData.set("appCode", Ra.getConfig().appCode);
      if (Ra.getConfig().customerCode) {
        bodyFormData.set("companyCode", Ra.getConfig().customerCode);
      }

      RaApiNet.callApi({
        method: "post",
        url: Ra.getConfig().apiUrl + "/services/auth?checkEmail",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static serviceAvailable = () => {
    let profile = RaStorage.getProfile();
    if (profile) {
      if (
        !profile.PaidService ||
        (profile.PurchasedMinutes - profile.ServiceUsageMinutes > 0 &&
          (!profile.ExpirationDate ||
            new Date(profile.ExpirationDate) - new Date() > 0))
      ) {
        return true;
      }
    }
    return false;
  };

  static allowedToCall = () => {
    let profile = RaStorage.getProfile();
    return Ra.getConfig().callsEnabled && profile && profile.Role.AllowedToCall;
  };
}

export default RaApiAuth;
