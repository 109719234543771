import React from "react";
import f7 from "framework7";
import {
  PageContent,
  Page,
  NavLeft,
  Link,
  Navbar,
  NavTitle,
  Fab,
  Icon,
  Toolbar,
  Tabs,
  Tab,
  Popup,
  NavRight,
  Popover,
  List,
  ListItem,
  f7 as f7React,
} from "framework7-react";
import { connect } from "react-redux";
import AppPageComponent from "../../components/commons/app-page-component";
import localeStrings from "./addressbook-local";
import MenuToolbar from "../../components/menu/toolbar";
import Commons from "../../Commons";
import LogoutLoader from "../../components/logout/logout-loader";
import RedirectEvents from "../../components/commons/redirect-events";

import { RaApiAuth } from "../../ra-lib/api/auth";
import { RaApiAddressBook } from "../../ra-lib/api/addressbook";

import Contacts from "./contacts";
import Icons from "../../icons";
import Invitation from "./invitation";
import Invitations from "./invitations";

class AddressBook extends AppPageComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedContact: null,
      selectedContactEl: null,
    };
    this.invitationPopup = null;
  }

  render() {
    const portrait = Commons.isPortrait(f7);

    let pageContent = null;
    if (!RaApiAuth.isAuthenticated()) {
      pageContent = <LogoutLoader router={this.props.f7router}></LogoutLoader>;
    } else {
      pageContent = (
        <Tabs animated>
          <Tab tabActive id="tab-contacts">
            <Contacts
              id="main-contacts-list"
              key="main-contacts-list"
              className="contacts-list"
              onContactSelected={(selectedContact, el) => {
                this.setState({
                  selectedContact: selectedContact,
                  selectedContactEl: el,
                });
              }}
            />
          </Tab>
          <Tab id="tab-invitations">
            <Invitations className="invitations-list"></Invitations>
          </Tab>
        </Tabs>
      );
    }

    let navLeft = null;
    if (this.props.popupToClose || portrait) {
      navLeft = (
        <NavLeft>
          <Link
            panelOpen={this.props.popupToClose ? false : "left"}
            popupClose={
              this.props.popupToClose ? this.props.popupToClose : false
            }
            iconIos={
              this.props.popupToClose ? Icons.CLOSE_IOS : Icons.MENULEFT_IOS
            }
            iconAurora={
              this.props.popupToClose
                ? Icons.CLOSE_AURORA
                : Icons.MENULEFT_AURORA
            }
            iconMd={
              this.props.popupToClose
                ? Icons.CLOSE_ANDROID
                : Icons.MENULEFT_ANDROID
            }
          ></Link>
        </NavLeft>
      );
    }

    const toolBar = this.props.popupToClose ? null : (
      <MenuToolbar router={this.props.f7router}></MenuToolbar>
    );

    return (
      <Page pageContent={false} colorTheme="custom">
        <Navbar noShadow={true} noHairline={true} sliding={false}>
          {navLeft}
          <NavTitle>{localeStrings.title}</NavTitle>
          <NavRight>
            <Link
              popoverOpen={".addressbook-update"}
              iconMd={Icons.OPTIONS_ANDROID}
              iconIos={Icons.OPTIONS_IOS}
              iconAurora={Icons.OPTIONS_AURORA}
            ></Link>
          </NavRight>
          <Popover id="addressbook-update" className="addressbook-update">
            <List>
              <ListItem
                key="addressbook-update"
                className="no-chevron"
                link="#"
                popoverClose
                title={localeStrings.options_update}
                onClick={(e) => {
                  RaApiAddressBook.getAddressBook().then(() => {});
                }}
              ></ListItem>
            </List>
          </Popover>
        </Navbar>

        <Toolbar tabbar top>
          <Link tabLink="#tab-contacts" tabLinkActive>
            {localeStrings.tab_contacts}
          </Link>
          <Link tabLink="#tab-invitations">
            {localeStrings.tab_invitations}
          </Link>
        </Toolbar>
        <PageContent>{pageContent}</PageContent>
        <Fab
          position="right-bottom"
          slot="fixed"
          style={{
            position: "fixed",
            marginBottom: portrait ? "inhertis" : "60px",
          }}
          onClick={() => {
            this.showInvitationPopup();
          }}
        >
          <Icon
            ios={Icons.ADD_IOS}
            aurora={Icons.ADD_AURORA}
            md={Icons.ADD_ANDROID}
          ></Icon>
        </Fab>
        <Popup className="invitation-popup">
          <Invitation
            onCloseClick={() => {
              this.hideInvitationPopup();
            }}
            onContactAdded={() => {
              this.hideInvitationPopup();
            }}
            onInvitationSubmitted={(invitation) => {
              this.onInvitationSubmitted(invitation);
            }}
          ></Invitation>
        </Popup>
        <RedirectEvents router={this.props.f7router}></RedirectEvents>
        {toolBar}
      </Page>
    );
  }

  onInvitationSubmitted(invitation) {
    if (this.invitationPopup) {
      this.invitationPopup = f7React.popup.close(
        f7.$(".invitation-popup"),
        true
      );
    }

    f7React.tab.show("#tab-invitations");
  }

  showInvitationPopup() {
    this.invitationPopup = f7React.popup.open(f7.$(".invitation-popup"), true);
  }

  hideInvitationPopup() {
    if (this.invitationPopup) {
      this.invitationPopup.close();
    }
  }

  componentDidMount = () => {
    //potrebbe non essere valorizzato su iOS, dato che è inserito come popup in un'altra pagina
    if (this.props.f7router) {
      Commons.checkAuthentication(this, this.props.f7router);
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //potrebbe non essere valorizzato su iOS, dato che è inserito come popup in un'altra pagina
    if (this.props.f7router) {
      Commons.checkAuthentication(this, this.props.f7router);
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    mainPageIndex: state.app.mainPageIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(AddressBook);
