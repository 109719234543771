import React from "react";
import f7 from 'framework7';
import { Icon, Fab, FabButtons, FabButton } from "framework7-react";
import { connect } from "react-redux";

import Commons from "../../../Commons";
import { isAndroid, isIOS } from "react-device-detect";
import localeStrings from "./local-video-stream-switch-button-local";

import { RaMedia } from "../../../ra-lib/media/media";
import { RaStorage } from "../../../ra-lib/storage";
import { RaApiIpCam } from "../../../ra-lib/api/ipcam";
import { RaCordova } from "../../../ra-lib/cordova/index";
import { RaCordovaNet } from "../../../ra-lib/cordova/net";

class LocalVideoStreamSwitchButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: props.position ? props.position : "right-bottom",
      slot: props.slot ? props.slot : "fixed",
      buttonEnabled: props.isVideoStreamSwitchButtonEnabled(),
      connectedIpCam: null,
    };
    this.profile = RaStorage.getProfile();

    this.userIpCams = [];
    this.dinamicIpCam = null;
    this.profile.IpCamsEnabled.forEach((item) => {
      if (item.StreamRequest.Url) {
        item.dynamic = item.StreamRequest.Url.includes("<IP>");
        if (item.dynamic) {
          this.dinamicIpCam = item;
        }
        this.userIpCams.push(item);
      }
    });
  }

  render() {
    let self = this;
    if (self.state.buttonEnabled === true) {
      RaMedia.getDevices()
        .then((devices) => {
          if (
            devices.video &&
            devices.video.length <= 1 &&
            self.state.buttonEnabled === true
          ) {
            self.setState({ buttonEnabled: false });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    let ipCamsList = [];
    if (Commons.hasIpCam()) {
      ipCamsList = this.userIpCams;
    }

    let ipCamsButtons = null;
    ipCamsButtons = ipCamsList.map((item, index) => {
      let itemName =
        item.dynamic === true && !this.state.connectedIpCam
          ? item.Name + " (" + localeStrings.ipCam_notConnectedLabel + ") "
          : item.Name;
      return (
        <FabButton
          key={index}
          label={itemName}
          onClick={(e) => {
            if (!item.dynamic || this.state.connectedIpCam) {
              this.selectIpCam(item);
            }
          }}
        >
          <Icon
            md={
              this.getSelectedIpCamCode() === item.Code
                ? "material:videocam"
                : "material:videocam_off"
            }
            aurora={
              this.getSelectedIpCamCode() === item.Code
                ? "f7:videocam_fill"
                : "f7:videocam"
            }
            ios={
              this.getSelectedIpCamCode() === item.Code
                ? "f7:videocam_fill"
                : "f7:videocam"
            }
            size="20"
          />
        </FabButton>
      );
    });

    let showSwitchCamera = this.userIpCams.length === 0 && (isAndroid || isIOS); //this.props.videoInputDevices.length == 2 && this.userIpCams.length === 0;
    let closeIcon = null;
    let fabButtons = null;

    if (!showSwitchCamera) {
      const videoInputDevices = this.props.videoInputDevices.map(
        (item, index) => {
          return (
            <FabButton
              key={index}
              label={
                item.label
                  ? item.label
                  : "Camera" +
                    (this.props.videoInputDevices.length > 1
                      ? " #" + index
                      : "")
              }
              onClick={(e) => {
                this.selectVideoInputDevice(item);
              }}
            >
              <Icon
                md={
                  RaMedia.getSelectedVideoInputDevice() &&
                  item.deviceId ===
                    RaMedia.getSelectedVideoInputDevice().deviceId
                    ? "material:videocam"
                    : "material:videocam_off"
                }
                aurora={
                  RaMedia.getSelectedVideoInputDevice() &&
                  item.deviceId ===
                    RaMedia.getSelectedVideoInputDevice().deviceId
                    ? "f7:videocam_fill"
                    : "f7:videocam"
                }
                ios={
                  RaMedia.getSelectedVideoInputDevice() &&
                  item.deviceId ===
                    RaMedia.getSelectedVideoInputDevice().deviceId
                    ? "f7:videocam_fill"
                    : "f7:videocam"
                }
                size="20"
              />
            </FabButton>
          );
        }
      );

      if (
        videoInputDevices.length + ipCamsList.length > 1 &&
        this.state.buttonEnabled
      ) {
        closeIcon = (
          <Icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></Icon>
        );
        fabButtons = (
          <FabButtons position="top">
            {videoInputDevices}
            {ipCamsButtons}
          </FabButtons>
        );
      }
    }

    let style = this.props.style
      ? this.props.style
      : this.state.buttonEnabled
      ? { opacity: 1 }
      : { opacity: 0.4 };
    style.left = 0;
    return (
      <Fab
        position={this.state.position}
        className="transparent-fab cameraSwitch left-bottom"
        slot={this.state.slot}
        style={style}
        onClick={() => {
          if (showSwitchCamera) {
            this.switchMobileCamera();
          }
        }}
      >
        <Icon
          ios="f7:camera_rotate_fill"
          aurora="f7:camera_rotate_fill"
          md="material:switch_camera"
        ></Icon>
        {closeIcon}
        {fabButtons}
      </Fab>
    );
  }

  getSelectedIpCamCode = () => {
    return this.props.selectVideoInputIpCam
      ? this.props.selectVideoInputIpCam.Code
      : null;
  };

  selectIpCam = (ipCam) => {
    let self = this;
    if (RaCordova.isCordova()) {
      RaCordovaNet.isWifiHotspotEnabled(
        function (result) {
          if (result === true) {
            /* if (isAndroid && window.device && window.device.version && window.device.version.startsWith("1")) { //android 10 e 11
                        Commons.showAlert(self, localeStrings.ipCam_androidNotSupportedAlert_text, localeStrings.ipCam_androidNotSupportedAlert_title);
                    }
                    else {*/
            RaApiIpCam.selectVideoInputIpCam(ipCam);
            //}
          } else {
            Commons.showAlert(
              self,
              localeStrings.ipCam_enableWifiAlert_text,
              localeStrings.ipCam_enableWifiAlert_title
            );
          }
        },
        function (error) {
          console.error("isWifiHotspotEnabled error", error);
        }
      );
    } else {
      RaApiIpCam.selectVideoInputIpCam(ipCam);
    }
  };

  isMobile = () => {
    return f7.device.ios || f7.device.android;
  };

  switchMobileCamera = () => {
    if (
      this.props.videoInputDevices &&
      this.props.videoInputDevices.length === 2
    ) {
      let currentVideo = this.props.videoInputDevices[0];
      if (
        RaMedia.getSelectedVideoInputDevice() &&
        currentVideo.deviceId === RaMedia.getSelectedVideoInputDevice().deviceId
      ) {
        RaMedia.selectVideoInputDevice(this.props.videoInputDevices[1]);
      } else {
        RaMedia.selectVideoInputDevice(currentVideo);
      }
    }
  };

  startCheckConnectedIpCam = () => {
    let self = this;
    let gettingConnectedDeviceCheck = false;
    let lastConnectedDeviceCheck = null;
    return setInterval(() => {
      if (
        gettingConnectedDeviceCheck === false ||
        !lastConnectedDeviceCheck ||
        new Date().getTime() - lastConnectedDeviceCheck > 5000
      ) {
        gettingConnectedDeviceCheck = true;
        lastConnectedDeviceCheck = new Date().getTime();
        self
          .getConnectedDevice()
          .then((device) => {
            if (device) {
              if (!self.state.connectedIpCam) {
                self.setState({ connectedIpCam: self.dinamicIpCam });
              }
            } else {
              if (self.state.connectedIpCam) {
                self.setState({ connectedIpCam: null });
              }
            }
          })
          .finally(() => {
            gettingConnectedDeviceCheck = false;
          });
      }
    }, 1000);
  };

  getConnectedDevice = () => {
    return new Promise((resolve, reject) => {
      if (RaCordova.isCordova()) {
        RaCordovaNet.isWifiHotspotEnabled(
          function (isEnabled) {
            if (!isEnabled) {
              resolve(null);
            } else {
              RaCordovaNet.getListOfConnectedDevice(
                function (devices) {
                  if (devices && devices[0]) {
                    resolve(devices[0]);
                  } else {
                    resolve(null);
                  }
                },
                function (error) {
                  resolve(null);
                }
              );
            }
          },
          function (error) {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  };

  selectVideoInputDevice = (device) => {
    RaMedia.selectVideoInputDevice(device);
  };

  componentDidMount = () => {
    if (this.dinamicIpCam) {
      this.getConnectedDeviceCheck = this.startCheckConnectedIpCam();
    }
  };

  componentWillUnmount = () => {
    if (this.dinamicIpCam && this.getConnectedDeviceCheck) {
      clearInterval(this.getConnectedDeviceCheck);
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    videoInputDevices: state.remoteAssistance.videoInputDevices,
    inputDevicesDetectionCompleated:
      state.remoteAssistance.inputDevicesDetectionCompleated,
    selectVideoInputIpCam: state.remoteAssistance.selectVideoInputIpCam,
    selectedVideoInputDevice: state.remoteAssistance.selectedVideoInputDevice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalVideoStreamSwitchButton);
//export default connect(mapStateToProps)(CurrentCalls);
