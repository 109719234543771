import { RaReduxActionTypes } from "./actionTypes";
import { RaLog } from "../log";

export class RaReduxActions {
  static setFirebaseToken = (token) => {
    RaLog.log("setFirebaseToken " + token);
    return {
      type: RaReduxActionTypes.SET_CORDOVA_FIREBASE_TOKEN,
      firebaseToken: token,
    };
  };

  static setCordovaReady = (isReady) => {
    return {
      type: RaReduxActionTypes.SET_CORDOVA_READY,
      isReady: isReady,
    };
  };

  static setUserAdressBook = (userAddressBook) => {
    return {
      type: RaReduxActionTypes.SET_USER_ADDRESSBOOK,
      userAddressBook: userAddressBook,
    };
  };

  static setUserInfo = (profile) => {
    return {
      type: RaReduxActionTypes.SET_USER_INFO,
      profile: profile,
    };
  };

  static setActiveCall = (call, targets) => {
    if (call == null) {
      return {
        type: RaReduxActionTypes.SET_ACTIVE_CALL,
        call: call,
        activeCallParticipants: [],
        activeCallTargets: [],
      };
    } else {
      return {
        type: RaReduxActionTypes.SET_ACTIVE_CALL,
        call: call,
        activeCallTargets: targets,
      };
    }
  };

  static setActiveCallParticipants = (participants) => {
    return {
      type: RaReduxActionTypes.SET_ACTIVE_CALL_PARTICIPANTS,
      activeCallParticipants: participants,
    };
  };

  static setActiveCallTargets = (activeCallTargets) => {
    RaLog.log(
      "[setActiveCallTargets] activeCallTargets.length=" +
        activeCallTargets.length
    );
    return {
      type: RaReduxActionTypes.SET_ACTIVE_CALL_TARGETS,
      activeCallTargets: activeCallTargets,
    };
  };

  static setDataTrack = (dataTrack) => {
    return {
      type: RaReduxActionTypes.SET_DATA_TRACK,
      dataTrack: dataTrack,
    };
  };

  static connectedToComunicationProviderError = (error) => {
    return {
      type: RaReduxActionTypes.SET_COMUNICATION_PROVIDER_ERROR,
      comunicationProviderError: error,
    };
  };

  static setComunicationProviderRoom = (comunicationProviderRoom) => {
    return {
      type: RaReduxActionTypes.SET_COMUNICATION_PROVIDER_DATA,
      comunicationProviderRoom: comunicationProviderRoom,
    };
  };

  static setIncomingCall = (call) => {
    return {
      type: RaReduxActionTypes.SET_INCOMING_CALL,
      incomingCall: call,
    };
  };

  static setVideoScreenState = (videoScreenState) => {
    return {
      type: RaReduxActionTypes.SET_VIDEO_SCREEN_STATE,
      videoScreenState: videoScreenState,
    };
  };

  static setDeletedSnapshot = (deletedSnapshot) => {
    return {
      type: RaReduxActionTypes.SET_DELETED_SNAPSHOT,
      deletedSnapshot: deletedSnapshot,
    };
  };

  static setUnreadMessages = (messages) => {
    return {
      type: RaReduxActionTypes.SET_UNREAD_MESSAGES,
      unreadMessages: messages,
    };
  };

  static setNotSeenPhotos = (photos) => {
    return {
      type: RaReduxActionTypes.SET_NOT_SEEN_PHOTOS,
      notSeenPhotos: photos,
    };
  };

  static setTakenSnapshots = (takenSnapshots) => {
    return {
      type: RaReduxActionTypes.SET_TAKEN_SNAPSHOTS,
      takenSnapshots: takenSnapshots,
    };
  };

  static setSelectedSnapshot = (selectedSnapshot, selectedSnapshotImgSrc) => {
    return {
      type: RaReduxActionTypes.SET_SELECTED_SNAPSHOT,
      selectedSnapshot: selectedSnapshot,
      selectedSnapshotImgSrc:
        selectedSnapshot && selectedSnapshotImgSrc
          ? selectedSnapshotImgSrc
          : null,
    };
  };

  static setCallGUIMode = (GUIMode) => {
    return {
      type: RaReduxActionTypes.SET_CALL_GUI_MODE,
      callGUIMode: GUIMode,
    };
  };

  static setCallerVideoTrackShareAllowed = (value) => {
    return {
      type: RaReduxActionTypes.SET_CALLER_VIDEOTRACK_SHARE_ALLOWED,
      callerVideoTrackShareAllowed: value,
    };
  };

  static setCallerVideoTrackShared = (value) => {
    return {
      type: RaReduxActionTypes.SET_CALLER_VIDEOTRACK_SHARED,
      callerVideoTrackShared: value,
    };
  };

  static setCallController = (callController) => {
    return {
      type: RaReduxActionTypes.SET_CALL_CONTROLLER,
      callController: callController,
    };
  };

  static setArVideoInputDeviceEnable = (enable) => {
    return {
      type: RaReduxActionTypes.SET_ARVIDEOINPUTDEVICE_ENABLE,
      isArVideoInputDeviceEnable: enable,
    };
  };

  static setArVideoTrainingState = (
    arVideoTrainingStarted,
    arVideoTrainingProgress,
    arVideoTrainingEnded
  ) => {
    return {
      type: RaReduxActionTypes.SET_ARVIDEOTRAINING_STATE,
      arVideoTrainingStarted: arVideoTrainingStarted,
      arVideoTrainingEnded: arVideoTrainingEnded,
      arVideoTrainingProgress: arVideoTrainingProgress,
    };
  };

  static setArVideoProcessState = (arVideoProcessing) => {
    return {
      type: RaReduxActionTypes.SET_ARVIDEOPROCESS_STATE,
      arVideoProcessing: arVideoProcessing,
    };
  };

  static setVideoInputDevices = (devices) => {
    return {
      type: RaReduxActionTypes.SET_VIDEOINPUTDEVICES,
      videoInputDevices: devices,
    };
  };

  static setSelectedVideoInputDevice = (device) => {
    return {
      type: RaReduxActionTypes.SET_SELECTED_VIDEOINPUTDEVICE,
      selectedVideoInputDevice: device,
    };
  };

  static setSelectedArVideoInputDevice = (device) => {
    return {
      type: RaReduxActionTypes.SET_SELECTED_ARVIDEOINPUTDEVICE,
      selectedArVideoInputDevice: device,
    };
  };

  static setAudioInputDevices = (devices) => {
    return {
      type: RaReduxActionTypes.SET_AUDIOINPUTDEVICES,
      audioInputDevices: devices,
    };
  };

  static setSelectVideoInputIpCam = (ipCam) => {
    return {
      type: RaReduxActionTypes.SET_SELECTED_VIDEOINPUTIPCAM,
      selectVideoInputIpCam: ipCam,
    };
  };

  static setSelectedAudioInputDevice = (device) => {
    return {
      type: RaReduxActionTypes.SET_SELECTED_AUDIOINPUTDEVICE,
      selectedAudioInputDevice: device,
    };
  };

  static setAudioOutputDevices = (devices) => {
    return {
      type: RaReduxActionTypes.SET_AUDIOOUTPUTDEVICES,
      audioOutputDevices: devices,
    };
  };

  static setSelectedAudioOutputDevice = (device) => {
    return {
      type: RaReduxActionTypes.SET_SELECTED_AUDIOOUTPUTDEVICE,
      selectedAudioOutputDevice: device,
    };
  };

  static setInputDevicesDetectionCompleated = () => {
    return {
      type: RaReduxActionTypes.SET_INPUTDEVICESDETECTIONCOMPLEATED,
      inputDevicesDetectionCompleated: true,
    };
  };

  static setCallTracksNodes = (callTracksNodes) => {
    return {
      type: RaReduxActionTypes.SET_CALL_TRACKS_NODE,
      callTracksNodes: callTracksNodes,
    };
  };

  static setCallTimeBeforeHangup = (seconds) => {
    return {
      type: RaReduxActionTypes.SET_CALL_TIME_BEFORE_HANG_UP,
      callTimeBeforeHangup: seconds,
    };
  };

  static setCallingState = (callingState) => {
    return {
      type: RaReduxActionTypes.SET_CALLING_STATE,
      callingState: callingState,
    };
  };

  static setProductCode = (productCode) => {
    return {
      type: RaReduxActionTypes.SET_PRODUCT_CODE,
      productCode: productCode,
    };
  };

  static setContactRooms = (contactRooms) => {
    return {
      type: RaReduxActionTypes.SET_CONTACT_ROOMS,
      contactRooms: contactRooms,
    };
  };

  static setContactRoomsLoadingPerc = (contactRoomsLoadingPerc) => {
    return {
      type: RaReduxActionTypes.SET_CONTACT_ROOMS_LOADING_PREC,
      contactRoomsLoadingPerc: contactRoomsLoadingPerc,
    };
  };

  static setAppCompany = (appCompany) => {
    return {
      type: RaReduxActionTypes.SET_APP_COMPANY,
      appCompany: appCompany,
    };
  };
}
export default RaReduxActions;
