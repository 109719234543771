import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: "Select a contact",
    no_contacts: "Nothing found",
    search_placeholder: "Search...",
    popover_contacts: "Address book",
    popover_update: "Update",
  },
  it: {
    title: "Seleziona il contatto",
    no_contacts: "Nessun contatto trovato",
    search_placeholder: "Cerca...",
    popover_contacts: "Rubrica",
    popover_update: "Aggiorna",
  },
});

export default localeStrings;
