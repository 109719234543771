import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: "Address Book",
    no_calls: "No contacts to display",
    list_error: "Oops...something went wrong",
    search_placeholder: "search...",
    tab_contacts: "Contacts",
    tab_invitations: "Invited contacts",
    options_update: "Update",
  },
  it: {
    title: "Rubrica",
    no_calls: "Non hai alcun contatto in rubrica",
    list_error: "Oops...qualcosa è andato storto",
    tab_contacts: "Contatti",
    tab_invitations: "Invitati",
    options_update: "Aggiorna",
  },
});

export default localeStrings;
