import React from "react";
import f7 from 'framework7';
import {
  List,
  ListItem,
  Col,
  Block,
  Preloader,
  Searchbar,
  Popover,
  Badge,
  Link,
  f7 as f7React,
  theme
} from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./invitations-local";
import Commons from "../../Commons";
import * as actions from "../../redux/actions";
import Icons from "../../icons";

import { RaApiAddressBook } from "../../ra-lib/api/addressbook";
import { RaLog } from "../../ra-lib/log";

// import moment from 'moment-timezone';

class Invitations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: false,
    };

    this.selectedInvitation = null;
  }

  render() {
    if (this.props.addressBookInvitations === null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center contacts">
          <Col>
            <Preloader size={28}></Preloader>
          </Col>
        </Block>
      );
    }

    const loadingPreloader =
      this.state.updating === true ? (
        <Block
          className="row demo-preloaders align-items-stretch text-align-center spinner"
          style={{
            marginTop: "50px",
            position: "fixed",
            width: "100%",
            zIndex: 999,
          }}
        >
          <Col>
            <Preloader size={28} style={{ marginTop: "30px" }}></Preloader>
          </Col>
        </Block>
      ) : null;

    if (this.props.addressBookInvitations.length === 0) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>{localeStrings.no_invitations}</Col>
          {loadingPreloader}
        </Block>
      );
    }

    return (
      <div>
        {loadingPreloader}
        {this.getInvitations()}
      </div>
    );
  }

  updateInvitations = () => {
    if (this.state.updating === true) {
      return;
    }

    this.setState({ updating: true });
    let self = this;
    RaApiAddressBook.getAddressBookInvitations().then((response) => {
      if (response.result.success === true) {
        this.props.setAddressBookInvitations(response.data);
      } else {
        RaLog.error(response.result.message);
        Commons.showErrorMessage(self, localeStrings.get_invitation_api_error);
      }
      self.setState({ updating: false });
    });
  };

  onIndexSelect(itemContent, el) {}

  getInvitations = () => {
    const invitations =
      this.props.addressBookInvitations == null
        ? null
        : this.props.addressBookInvitations.map((item, index) => {
            let submissionDate = null;
            if (item.SubmissionDate != null) {
              submissionDate = Commons.getDateDescription(
                item.SubmissionDate,
                "Europe/Rome",
                "DD/MM/YYYY",
                false
              );
            }
            const options =
              item.Registered === true ? null : (
                <Link
                  popoverOpen=".popover-invitation"
                  key={"invitation-" + item.UniqueId}
                  id={item.UniqueId}
                  iconIos={Icons.OPTIONS_IOS}
                  iconAurora={Icons.OPTIONS_AURORA}
                  iconMd={Icons.OPTIONS_ANDROID}
                  onClick={() => {
                    this.selectedInvitation = item;
                  }}
                ></Link>
              );

            const registered =
              item.Registered === true ? (
                <Badge color="gray">{localeStrings.registered}</Badge>
              ) : null;

            return (
              <ListItem
                key={"invitation_" + item.UniqueId}
                onClick={() => {
                  this.onIndexSelect(item, "invitation_" + item.UniqueId);
                }}
                title={item.ContactName}
                header={item.Email}
                footer={
                  localeStrings.invitation_submitted_on + " " + submissionDate
                }
              >
                <span slot="after" className="item-header">
                  <div style={{ display: "flex" }}>
                    {options}
                    {registered}
                  </div>
                </span>
              </ListItem>
            );
          });

    return (
      <div>
        <List className={this.props.className} style={{ marginTop: 0 }}>
          <Searchbar
            slot="before-list"
            className="searchbar"
            searchContainer={"." + this.props.className}
            searchIn=".item-title"
            disableButton={!theme.aurora}
            placeholder={localeStrings.search_placeholder}
          ></Searchbar>

          {invitations}
        </List>

        <Popover className={"popover-invitation"} key={"popover-invitation"}>
          <List>
            <ListItem
              link="#"
              className="no-chevron"
              popoverClose
              title={localeStrings.resend}
              key={"resend"}
              onClick={() => {
                this.resendInvitation(this.selectedInvitation);
                f7React.popup.close(f7.$(".popover-invitation"), true);
              }}
            />
            <ListItem
              link="#"
              className="no-chevron"
              popoverClose
              title={localeStrings.delete}
              key={"delete"}
              onClick={() => {
                this.deleteInvitation(this.selectedInvitation);
                f7React.popup.close(f7.$(".popover-invitation"), true);
              }}
            />
          </List>
        </Popover>
      </div>
    );
  };

  deleteInvitation = (invitation) => {
    const errorFn = (errorMessage) => {
      RaLog.error(errorMessage);
      Commons.showErrorMessage(self, localeStrings.delete_error);
    };

    const self = this;
    Commons.showConfirm(
      self,
      localeStrings.delete_confirm_text,
      localeStrings.delete_confirm_title,
      () => {
        RaApiAddressBook.deleteAddressBookInvitation(invitation.UniqueId)
          .then((response) => {
            if (response.result.success === true) {
              this.props.removeAddressBookInvitation(invitation);
              Commons.showErrorMessage(self, localeStrings.delete_ok);
            } else {
              errorFn(response.result.message);
            }
          })
          .catch((errorMessage) => {
            errorFn(errorMessage);
          });
      },
      () => {}
    );
  };

  resendInvitation = (invitation) => {
    const errorFn = (errorMessage) => {
      RaLog.error(errorMessage);
      Commons.showErrorMessage(self, localeStrings.resend_error);
    };

    const self = this;
    Commons.showConfirm(
      self,
      localeStrings.resend_confirm_text,
      localeStrings.resend_confirm_title,
      () => {
        RaApiAddressBook.resendAddressBookInvitation(invitation.UniqueId)
          .then((response) => {
            if (response.result.success === true) {
              this.props.setAddressBookInvitation(response.data);
              Commons.showErrorMessage(self, localeStrings.resend_ok);
            } else {
              errorFn(response.result.message);
            }
          })
          .catch((errorMessage) => {
            errorFn(errorMessage);
          });
      },
      () => {}
    );
  };

  componentDidMount = () => {
    this.updateInvitations();
  };

  componentDidUpdate = () => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    userAddressBook: state.remoteAssistance.userAddressBook,
    addressBookInvitations: state.app.addressBookInvitations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAddressBookInvitations: (addressBookInvitations) =>
      dispatch(actions.setAddressBookInvitations(addressBookInvitations)),
    setAddressBookInvitation: (addressBookInvitation) =>
      dispatch(actions.setAddressBookInvitation(addressBookInvitation)),
    removeAddressBookInvitation: (addressBookInvitation) =>
      dispatch(actions.removeAddressBookInvitation(addressBookInvitation)),
  };
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Invitations);
