import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    delete_confirm_title: "Delete",
    delete_confirm_text:
      "Do you want to remove this contact from your address book?",
    remove_api_errorMessage: "An error occurred while removing this contact...",
  },
  it: {
    delete_confirm_title: "Elimina",
    delete_confirm_text: "Vuoi rimuovere questo contatto dalla tua rubrica?",
    remove_api_errorMessage:
      "Si è verificato un errore nella rimozione di questo contatto...",
  },
});

export default localeStrings;
