import React from "react";
import ReactDOM from "react-dom";
import Framework7 from 'framework7/lite-bundle';
import Framework7React from "framework7-react";
import AppBoot from "./master-page/appboot";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store";

Framework7.use(Framework7React);

// Mount React App
ReactDOM.render(
  <Provider store={store}>
    <AppBoot />
  </Provider>,
  document.getElementById("app")
);

// Trasforma .register in .unregister per disabilitare la PWA
if (!window.cordova || !window.cordova.version) serviceWorker.register();

var app = {
  // Application Constructor
  initialize: function () {},
};

app.initialize();
