import React from "react";
import f7 from 'framework7';
import { connect } from "react-redux";
import localeStrings from "./outputaudio-options-local";

import { RaMedia } from "../../ra-lib/media/media";
class OutputAudioOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const audioOutputDevices =
      this.props.audioOutputDevices &&
      this.props.audioOutputDevices.length > 0 ? (
        this.props.audioOutputDevices.map((item, index) => {
          return (
            <option key={index} value={item.deviceId}>
              {item.label
                ? item.label
                : "Camera" +
                  (this.props.audioOutputDevices.length > 1
                    ? " #" + index
                    : "")}
            </option>
          );
        })
      ) : (
        <option key="video_null" value="">
          {localeStrings.inputAudioNoOptions}
        </option>
      );

    return audioOutputDevices;
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      this.props.audioOutputDevices &&
      (!prevProps ||
        !prevProps.audioOutputDevices ||
        JSON.stringify(this.props.audioOutputDevices) !==
          JSON.stringify(prevProps.audioOutputDevices))
    ) {
      let selectedDevice = RaMedia.getSelectedAudioOutputDevice();
      if (this.props.containerSelector && selectedDevice) {
        f7.$(this.props.containerSelector).val(selectedDevice.deviceId);
      }
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    audioOutputDevices: state.remoteAssistance.audioOutputDevices,
    selectedAudioOutputDevice: state.remoteAssistance.selectedAudioOutputDevice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(OutputAudioOptions);
