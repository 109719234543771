import React from "react";
import {
  Page,
  List,
  Link,
  Navbar,
  ListItem,
  Col,
  Block,
  Preloader,
  ListIndex,
  ListGroup,
  Popover,
} from "framework7-react";
import { connect } from "react-redux";

import { RaApiAddressBook } from "../../ra-lib/api/addressbook";
import { CONTACT_TYPE } from "../../ra-lib/api/contacts";

import localeStrings from "./contacts-list-local";
import Icons from "../../icons";
import Commons from "../../Commons";

// import moment from 'moment-timezone';

class ContactsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: null,
      errormessage: null,
      updating: false,
    };

    this.fetchingContactsList = false;
  }

  getPopoverKey = () => {
    return (
      "popover-contacts-menu-" +
      (this.props.isRoomContactList === true ? "rooms" : "calls")
    );
  };

  render() {
    const addressBook = Commons.addressBookEnabled() ? (
      <ListItem
        key="addressbook-page"
        className="no-chevron"
        link="#"
        popoverClose
        title={localeStrings.popover_contacts}
        onClick={(e) => {
          if (this.props.onAddressbookClick) {
            this.props.onAddressbookClick();
            //devo eliminare manualmente il popover, perché il popup che lo contiene non lo distrugge,
            //e come conseguenza quando ritorno alla main mi ritrovo questo popover duplicato che mi impedisce di intercettare l'evento click
            //suppongo sia dovuto al fatto che il popup che lo contiene sia gestito da script e non da render di react.
            document.getElementById(popoverKey).remove();
          }
        }}
      ></ListItem>
    ) : null;

    let popoverKey = this.getPopoverKey();
    let navbar =
      this.props.hideNavbar === true ? null : (
        <Navbar title={localeStrings.title}>
          <Link
            slot="left"
            iconMd={Icons.CLOSE_ANDROID}
            iconIos={Icons.CLOSE_IOS}
            iconAurora={Icons.CLOSE_AURORA}
            onClick={(e) => {
              this.props.closeContacts();
            }}
          ></Link>
          <Link
            slot="right"
            popoverOpen={"." + popoverKey}
            iconMd={Icons.OPTIONS_ANDROID}
            iconIos={Icons.OPTIONS_IOS}
            iconAurora={Icons.OPTIONS_AURORA}
          ></Link>
          <Popover id={popoverKey} key={popoverKey} className={popoverKey}>
            <List>
              {addressBook}
              <ListItem
                key="update"
                popoverClose
                className="no-chevron"
                link="#"
                title={localeStrings.popover_update}
                onClick={() => {
                  this.updateAddressbook();
                }}
              ></ListItem>
            </List>
          </Popover>
        </Navbar>
      );

    if (this.state.errormessage !== null) {
      return (
        <Page>
          {navbar}
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>{this.state.errormessage}</Col>
          </Block>
        </Page>
      );
    }

    if (this.props.userAddressBook === null) {
      return (
        <Page>
          {navbar}
          <Block className="row demo-preloaders align-items-stretch text-align-center contacts">
            <Col>
              <Preloader size={42}></Preloader>
            </Col>
          </Block>
        </Page>
      );
    }

    const preloaderKey =
      "preloader-contacts-" +
      (this.props.isRoomContactList === true ? "rooms" : "calls");
    const loadingPreloader =
      this.state.updating === true ? (
        <Block
          className="row demo-preloaders align-items-stretch text-align-center spinner"
          style={{
            marginTop: "50px",
            position: "fixed",
            width: "100%",
            zIndex: 999,
          }}
        >
          <Col>
            <Preloader
              size={28}
              style={{ marginTop: "30px" }}
              key={preloaderKey}
              id={preloaderKey}
            ></Preloader>
          </Col>
        </Block>
      ) : null;

    if (this.props.userAddressBook.length === 0) {
      return (
        <Page>
          {navbar}
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>{localeStrings.no_contacts}</Col>
          </Block>
          {loadingPreloader}
        </Page>
      );
    }

    return (
      // onListIndexSelect={this.onIndexSelect.bind(this)}
      <Page>
        {navbar}
        {loadingPreloader}

        <ListIndex
          indexes="auto"
          listEl=".list.contacts-list"
          scrollList={true}
          label={true}
        ></ListIndex>
        {(this.props.newRoomContactsListOpen === true &&
          this.props.isRoomContactList === true) ||
        (this.props.newCallContactsListOpen === true &&
          this.props.isCallContactList === true)
          ? this.getContacts()
          : null}
      </Page>
    );
  }

  updateAddressbook = () => {
    if (this.state.updating === true) {
      return;
    }

    this.setState({ updating: true });
    let self = this;
    RaApiAddressBook.getAddressBook().then(() => {
      self.setState({ updating: false });
    });
  };

  onIndexSelect(itemContent) {
    //RaLog.log(itemContent);
    this.props.closeContacts();
    this.props.backPageClicked(itemContent);
  }

  getContacts = () => {
    if (
      this.props.userAddressBook === null ||
      this.props.userAddressBook.length === 0
    ) {
      return null;
    }

    const getGroupContact = (firstLetter) => {
      let contacts = [];
      this.props.userAddressBook.forEach((item) => {
        if (item.Name.indexOf(firstLetter) === 0) {
          if (this.props.noGroups !== true || item.Type === CONTACT_TYPE.USER) {
            contacts.push(
              <ListItem
                key={"contact_" + item.UniqueId}
                onClick={() => {
                  this.onIndexSelect(item);
                }}
                title={item.Name}
                footer={
                  (item.Company != null
                    ? item.Company.Name + (item.CompanyRole ? " - " : "")
                    : "") + item.CompanyRole
                }
                href=""
              ></ListItem>
            );
          }
        }
      });

      return contacts;
    };

    let lastLetter = null;
    let groups = [];
    this.props.userAddressBook.forEach((item) => {
      let firstLetter = item.Name.substring(0, 1);
      if (groups.length === 0 || lastLetter !== firstLetter) {
        if (this.props.noGroups !== true || item.Type === CONTACT_TYPE.USER) {
          groups.push(
            <ListGroup key={"group_" + firstLetter}>
              <ListItem
                title={firstLetter}
                groupTitle
                key={"item_" + firstLetter}
              ></ListItem>
              {getGroupContact(firstLetter)}
            </ListGroup>
          );
        }

        lastLetter = firstLetter;
      }
    });

    return (
      <List
        contactsList
        className={
          "searchbar-found contacts-found-" +
          (this.props.newRoomContactsListOpen === true ? "rooms" : "calls")
        }
      >
        {groups}
      </List>
    );
  };

  componentDidMount = () => {};

  componentDidUpdate = () => {};
  /*
  fetchContactsList = () => {
    let self = this;

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (contacts) => {
      this.setState({ errormessage: null });
    }


    if (self.fetchingContactsList !== true) {
      self.fetchingContactsList = true;

      RaApiAddressBook.getAddressBook()
      .then(function (response) {

        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      })
      .then(() => {
        self.fetchingContactsList = false;
      });
    }

  };*/
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    userAddressBook: state.remoteAssistance.userAddressBook,
    newRoomContactsListOpen: state.app.newRoomContactsListOpen,
    newCallContactsListOpen: state.app.newCallContactsListOpen,
  };
};

const mapDispatchToProps = () => {
  return {
    //    setToken: (token) => dispatch(RaReduxActions.setToken(token)),
  };
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
