import { combineReducers } from "redux";
import appReducer from "./appReducer";
import { RaReduxReducer } from "../ra-lib/redux/reducer";

export const reducers = () => {
  return combineReducers({
    app: appReducer,
    remoteAssistance: raReducer,
  });

  function raReducer(state, action) {
    RaReduxReducer.setReducerName("remoteAssistance");
    return RaReduxReducer.getReducer(state, action);
  }
};

export default reducers;
