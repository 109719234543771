import appActionTypes from "./actionTypes";

const initialState = {
  appInit: false,
  mustUpdateTargets: null,
  orientation: null,
  notificationServiceError: null,
  mainPageIndex: 0,
  newRoomContactsListOpen: false,
  newCallContactsListOpen: false,
  loadingRecents: { loading: false, loadingPerc: 0 },
  recents: null,
  addressBookInvitations: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.SET_APP_INIT:
      return {
        ...state,
        appInit: action.appInit,
      };

    case appActionTypes.SET_RECENTS:
      return {
        ...state,
        recents: action.recents,
      };

    case appActionTypes.SET_LOADING_RECENTS:
      return {
        ...state,
        loadingRecents: action.loadingRecents,
      };

    case appActionTypes.SET_NEWCALL_CONTACT_LIST_OPEN:
      return {
        ...state,
        newCallContactsListOpen: action.newCallContactsListOpen,
      };

    case appActionTypes.SET_NEWROOM_CONTACT_LIST_OPEN:
      return {
        ...state,
        newRoomContactsListOpen: action.newRoomContactsListOpen,
      };

    case appActionTypes.SET_ORIENTATION:
      return {
        ...state,
        orientation: action.orientation,
      };
    case appActionTypes.SET_MUST_UPDATE_TARGETS:
      return {
        ...state,
        mustUpdateTargets: action.mustUpdateTargets,
      };
    case appActionTypes.SET_NOTIFICATION_SERVICE_ERROR:
      return {
        ...state,
        notificationServiceError: action.notificationServiceError,
      };
    case appActionTypes.SET_MAINPAGE_INDEX:
      return {
        ...state,
        mainPageIndex: action.mainPageIndex,
      };
    case appActionTypes.SET_ADDRESSBOOK_INVITATIONS:
      return {
        ...state,
        addressBookInvitations: action.addressBookInvitations,
      };
    case appActionTypes.ADD_ADDRESSBOOK_INVITATION:
      let addressBookInvitations = [...state.addressBookInvitations];
      addressBookInvitations.splice(0, 0, action.invitation);
      return {
        ...state,
        addressBookInvitations: addressBookInvitations,
      };
    case appActionTypes.REMOVE_ADDRESSBOOK_INVITATION:
      let newInvitations = [...state.addressBookInvitations];
      for (let i = 0; i < newInvitations.length; i++) {
        if (newInvitations[i].UniqueId === action.invitation.UniqueId) {
          newInvitations.splice(i, 1);
          break;
        }
      }
      return {
        ...state,
        addressBookInvitations: newInvitations,
      };

    case appActionTypes.SET_ADDRESSBOOK_INVITATION:
      let setInvitations = [...state.addressBookInvitations];
      for (let i = 0; i < setInvitations.length; i++) {
        if (setInvitations[i].UniqueId === action.invitation.UniqueId) {
          setInvitations[i] = action.invitation;
          break;
        }
      }
      return {
        ...state,
        addressBookInvitations: setInvitations,
      };
    default:
    //     RaLog.log("Action sconosciuta: " + action.type);
  }
  return state;
};

export default appReducer;
