//import Main from './pages/main.jsx';
import Login from "./pages/login/login.jsx";
import Profile from "./pages/profile/profile.jsx";
import Settings from "./pages/settings/settings.jsx";
import Credits from "./pages/credits/credits.jsx";
import Recents from "./pages/recents/recents.jsx";
import Documents from "./pages/documents/documents.jsx";
import Rooms from "./pages/chat/rooms";
import Landing from "./pre-pages/landing.jsx";
import Splash from "./pre-pages/splash.jsx";
import MainCalls from "./pages/videocalls/main-calls.jsx";
import Main from "./pages//main/main.jsx";
import IncomingCall from "./pages/incoming-call/incomingcall.jsx";
import IpCamSettings from "./pages/ipcam-settings/ipcam-settings";
import AddressBook from "./pages/addressbook/addressbook.jsx";
import Signup from "./pages/signup/signup.jsx";

//import ErrorBoundary from './error-boundary';

import NotFound from "./pages/404.jsx";

// Pages
export const routes = [
  // Index page
  {
    name: "main",
    path: "/main/",
    component: Main,
    //component: ErrorBoundary,
  },
  {
    name: "main-calls",
    path: "/main-calls/",
    component: MainCalls,
  },
  {
    name: "ipcam-settings",
    path: "/ipcam-settings/",
    component: IpCamSettings,
  },
  {
    name: "incomingcall",
    path: "/incomingcall/",
    component: IncomingCall,
  },
  {
    name: "splash",
    path: "/splash/",
    component: Splash,
  },
  {
    name: "root",
    path: "/",
    component: Landing,
  },
  {
    name: "login",
    path: "/login/",
    component: Login,
  },
  {
    name: "rooms",
    path: "/rooms/",
    component: Rooms,
  },
  {
    name: "profile",
    path: "/profile/",
    component: Profile,
  },
  {
    name: "settings",
    path: "/settings/",
    component: Settings,
  },
  {
    name: "credits",
    path: "/credits/",
    component: Credits,
  },
  {
    name: "recents",
    path: "/recents/",
    component: Recents,
  },
  {
    name: "documents",
    path: "/documents/",
    component: Documents,
  },
  {
    name: "addressbook",
    path: "/addressbook/",
    component: AddressBook,
  },
  {
    name: "signup",
    path: "/signup/",
    component: Signup,
  },

  // Default route (404 page). MUST BE THE LAST
  {
    path: "(.*)",
    component: NotFound,
  },
];
export default routes;
