import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: "Invite a contact",
  },
  it: {
    title: "Invita un contatto",
  },
});

export default localeStrings;
