import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: "Sign up",
    input_label_email: "e-mail",
    sample_email: "johndoe@mail.com",
    email_info:
      "Please type the e-mail address where you received the invitation",
    signup_button: "Sign up",
    code_info:
      "Please type the invitation code, you can find it in your invitaion e-mail",
    register_error_wrongdata: "Invalid e-mail or code",
    register_error_generic: "An error occurred, please try later",
    register_error_alreadyregistered:
      "This e-mail has been alredy registered, please sign in",
    login_label: "Already registered? Please login",
    formdata_missing: "Please type e-mail and invitation code",
  },
  it: {
    title: "Registrati",
    input_label_email: "e-mail",
    sample_email: "mariorossi@mail.com",
    email_info: "Digita l'indirizzo e-mail in cui hai ricevuto l'invito",
    signup_button: "Registrati",
    code_info: "Digita il codice che trovi nell'e-mail di invito",
    register_error_wrongdata: "L'e-mail o il codice indicati sono errati",
    register_error_generic: "Si è verificato un errore, riprova più tardi",
    register_error_alreadyregistered:
      "Questa e-mail risulta essere già registrata, puoi effettuare il login",
    login_label: "Già registrato? Accedi",
    formdata_missing: "Inserisci l'e-mail e il codice di invito",
  },
});

export default localeStrings;
