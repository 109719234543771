import React from "react";
import { connect } from "react-redux";
import { Chip, Icon, Preloader } from "framework7-react";
import localeStrings from "./inputarvideo-status-local";

import { RaMedia } from "../../ra-lib/media/media";
class InputArVideoStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.videoTrack = null;
  }

  render() {
    let status = null;

    if (RaMedia.isArVideoInputDeviceEnable()) {
      status = (
        <div style={{ marginLeft: "12px" }}>
          <Chip
            text={localeStrings.inputArVideoNotReady}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (this.props.arVideoTrainingStarted === false) {
                RaMedia.getArVideoProcessedTrack(true);
              }
            }}
          >
            <Icon
              slot="media"
              ios="f7:multiply"
              aurora="f7:multiply"
              md="material:close"
            ></Icon>
          </Chip>
        </div>
      );

      if (this.props.arVideoTrainingEnded) {
        status = (
          <Chip
            text={localeStrings.inputArVideoReady}
            color="green"
            style={{ marginLeft: "12px" }}
          >
            <Icon
              slot="media"
              ios="f7:checkmark_alt"
              aurora="f7:checkmark_alt"
              md="material:check"
            ></Icon>
          </Chip>
        );
      } else if (this.props.arVideoTrainingStarted) {
        status = (
          <Chip
            text={
              localeStrings.inputArVideoTraining +
              this.props.arVideoTrainingProgress +
              "%"
            }
            color="orange"
            style={{ marginLeft: "12px" }}
          >
            &nbsp;&nbsp;&nbsp;<Preloader size='24px' color="white"></Preloader>
          </Chip>
        );
      }
    }

    return status;
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {};

  componentWillUnmount = () => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    selectedArVideoInputDevice:
      state.remoteAssistance.selectedArVideoInputDevice,
    arVideoTrainingStarted: state.remoteAssistance.arVideoTrainingStarted,
    arVideoTrainingProgress: state.remoteAssistance.arVideoTrainingProgress,
    arVideoTrainingEnded: state.remoteAssistance.arVideoTrainingEnded,
    isArVideoInputDeviceEnable:
      state.remoteAssistance.isArVideoInputDeviceEnable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(InputArVideoStatus);
