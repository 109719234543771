import React from "react";
import { Icon, Fab, Badge } from "framework7-react";
import { connect } from "react-redux";
import Icons from "../../../icons";

import { RaApiCallsMessages } from "../../../ra-lib/api/calls/message";
class ChatButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: props.position ? props.position : "bottom",
      slot: props.slot ? props.slot : "fixed",
    };
  } 

  render() {
    let unreadMessagesBadge;
    if (this.props.unreadMessages.length > 0) {
      unreadMessagesBadge = (
        <Badge color="red">{this.props.unreadMessages.length}</Badge>
      );
    }

    return (
      <Fab
        position={this.state.position}
        slot={this.state.slot}
        onClick={() => {
          RaApiCallsMessages.clearUnreadMessages();
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
      >
        <Icon
          ios={Icons.CHAT_IOS}
          aurora={Icons.CHAT_AURORA}
          md={Icons.CHAT_ANDROID}
        >
          {unreadMessagesBadge}
        </Icon>
      </Fab>
    );
  }

  componentDidMount = () => {};

  componentWillUnmount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    unreadMessages: state.remoteAssistance.unreadMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ChatButton);
//export default connect(mapStateToProps)(CurrentCalls);
