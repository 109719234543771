import React from "react";
import {
  Block,
  Navbar,
  List,
  ListGroup,
  PageContent,
  ListItem,
  Link,
  Popup,
  Page,
  Col,
  Icon,
} from "framework7-react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import AppPageComponent from "../../components/commons/app-page-component";
import CallAnnotations from "./call-annotations";
import ChatMessages from "../../components/commons/chat-messages";

import { RaApiCallsPics } from "../../ra-lib/api/calls/pics/pics";
import { RaApiCalls } from "../../ra-lib/api/calls/calls";
import { RaStorage } from "../../ra-lib/storage";
import { RaApiAuth } from "../../ra-lib/api/auth";

import localeStrings from "./recents-local";
import appLocaleStrings from "../../app-local";
import MenuLeftButton from "../../components/menu/left-menu-button";
import MenuToolbar from "../../components/menu/toolbar";
import RecentsLoader from "./recents-loader";
import * as actions from "../../redux/actions";
import Commons from "../../Commons";
import Icons from "../../icons";
import LogoutLoader from "../../components/logout/logout-loader";
import RedirectEvents from "../../components/commons/redirect-events";
//import { start } from 'repl';
//import RouterModule from 'framework7/modules/router/router';

class Recents extends AppPageComponent {
  constructor(props) {
    super(props);

    this.state = {
      calls: null,
      errormessage: null,
      messagescallid: null,
      updateIntervalId: null,
      showChat: false,
      showAnnotations: false,
      callerId: null,
      clickedCallId: null,
      clickedCallerId: null,
    };
    this.fetchingCalls = false;
  }

  render() {
    if (this.state.errormessage !== null) {
      console.error(this.state.errormessage);
    }

    let pageContent = null;
    if (!RaApiAuth.isAuthenticated()) {
      pageContent = <LogoutLoader router={this.props.f7router}></LogoutLoader>;
    } else {
      pageContent = this.state.errormessage ? (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3>{localeStrings.list_error}</h3>
          </Col>
        </Block>
      ) : (
        this.getCallsHistory()
      );
    }

    return (
      <Page pageContent={false} colorTheme="custom">
        <MenuLeftButton></MenuLeftButton>

        <PageContent>
          {pageContent}

          <Popup
            id="recents-annotations-popup"
            className="annotations-popup ra-popup"
            colorTheme="custom"
            opened={this.state.showAnnotations === true}
            onPopupClosed={() => {
              this.closeAnnotationPopup();
            }}
          >
            <Page style={{ backgroundColor: "black" }}>
              <Navbar title="Annotations">
                <Link
                  slot="right"
                  iconMd={Icons.CLOSE_ANDROID}
                  iconIos={Icons.CLOSE_IOS}
                  iconAurora={Icons.CLOSE_AURORA}
                  onClick={(e) => {
                    this.closeAnnotationPopup();
                  }}
                ></Link>
              </Navbar>
              <div style={{ textAlign: "center" }}>
                <CallAnnotations
                  shown={this.state.showAnnotations === true}
                  callId={this.state.clickedCallId}
                  callerId={this.state.clickedCallerId}
                  live={false}
                ></CallAnnotations>
              </div>
            </Page>
          </Popup>
          <Popup
            id="recents-chat-popup"
            className="chat-popup ra-popup"
            opened={this.state.showChat === true}
          >
            <Page>
              <Navbar title="Messages">
                <Link
                  slot="right"
                  iconMd={Icons.CLOSE_ANDROID}
                  iconIos={Icons.CLOSE_IOS}
                  iconAurora={Icons.CLOSE_AURORA}
                  onClick={(e) => {
                    this.setState({ showChat: false });
                  }}
                ></Link>
              </Navbar>
              <div style={{ textAlign: "center" }}>
                <ChatMessages
                  callId={this.state.clickedCallId}
                  sendMessageEnabled={false}
                ></ChatMessages>
              </div>
            </Page>
          </Popup>
        </PageContent>

        <RedirectEvents router={this.props.f7router}></RedirectEvents>
        <MenuToolbar router={this.props.f7router}></MenuToolbar>
      </Page>
    );
  }

  closeAnnotationPopup = () => {
    if (this.state.showAnnotations === true) {
      this.setState({ showAnnotations: false });
    }
    RaApiCallsPics.selectPic(null);
  };

  getCallsHistory = () => {
    if (this.props.recents === null) {
      return <RecentsLoader></RecentsLoader>;
    } else if (this.props.recents.length === 0) {
      return (
        <Block className="no-contents-message">{localeStrings.no_calls}</Block>
      );
    }

    const ret = this.props.recents.map((item, index) => {
      return (
        <ListGroup key={"group" + index}>
          <ListItem
            key={"callp" + index}
            title={item.dateName}
            groupTitle
          ></ListItem>
          {this.getCallsGroup(item.calls)}
        </ListGroup>
      );
    });

    return <List style={{ marginTop: 0 }}>{ret}</List>;
  };

  CALL_FEEDBACK_UNANSWERED = 0;
  CALL_FEEDBACK_ACCEPTED = 1;
  CALL_FEEDBACK_DECLINED = 2;
  CALL_FEEDBACK_HANDLEDBYGROUPMEMBER = 3;

  getIcon = (call) => {
    if (call.CallerUniqueId === RaStorage.getProfile().UniqueId)
      return "f7:phone_fill_arrow_up_right";
    else if (call.Feedback === this.CALL_FEEDBACK_ACCEPTED)
      return "f7:phone_fill_arrow_down_left";
    else if (
      call.Feedback === this.CALL_FEEDBACK_DECLINED ||
      call.Feedback === this.CALL_FEEDBACK_UNANSWERED
    )
      return "material:phone_missed";
    else if (call.Feedback === this.CALL_FEEDBACK_HANDLEDBYGROUPMEMBER)
      return "f7:phone_fill_arrow_right";
  };

  getIconColor = (call) => {
    if (
      call.CallerUniqueId !== RaStorage.getProfile().UniqueId &&
      (call.Feedback === this.CALL_FEEDBACK_DECLINED ||
        call.Feedback === this.CALL_FEEDBACK_UNANSWERED)
    )
      return "red";
  };

  showCallData = (call) => {
    return call.Answered === true;
  };

  getCallsGroup = (callGroup) => {
    return callGroup.map((call, index) => (
      <ListItem
        key={"call" + call.UniqueId + index}
        title={
          call.CallerUniqueId === RaStorage.getProfile().UniqueId
            ? call.Subject
            : call.Caller.Name
        }
        footer={moment
          .utc(call.StartedAt)
          .local()
          .format("DD/MM/YYYY HH:mm")
          .concat(
            this.showCallData(call)
              ? " - " + call.Duration.substring(0, call.Duration.indexOf("."))
              : ""
          )}
      >
        <Icon
          slot="media"
          color={this.getIconColor(call)}
          ios={this.getIcon(call)}
          aurora={this.getIcon(call)}
          md={this.getIcon(call)}
        ></Icon>

        <span
          style={{
            visibility: this.showCallData(call) ? "visible" : "hidden",
            opacity: call.HasPics === true ? "1" : "0.3",
          }}
          slot="after"
          onClick={(e) => {
            this.selectedAnnotationsHandler(call);
          }}
        >
          <Icon
            large={true}
            ios={Icons.ANNOTATIONS_IOS}
            aurora={Icons.ANNOTATIONS_AURORA}
            md={Icons.ANNOTATIONS_ANDROID}
          ></Icon>
        </span>
        <span slot="after">&nbsp;&nbsp;</span>
        <span
          style={{
            visibility: this.showCallData(call) ? "visible" : "hidden",
            opacity: call.HasMessages === true ? "1" : "0.3",
          }}
          slot="after"
          onClick={(e) => {
            this.selectedChatHandler(call);
          }}
        >
          <Icon
            large={true}
            ios={Icons.CHAT_IOS}
            aurora={Icons.CHAT_AURORA}
            md={Icons.CHAT_ANDROID}
          ></Icon>
        </span>
      </ListItem>
    ));
  };

  selectedChatHandler = (call) => {
    if (call.HasMessages === true) {
      this.setState({
        clickedCallId: call.UniqueId,
        clickedCallerId: call.CallerUniqueId,
        showChat: true,
      });
    }
  };

  selectedAnnotationsHandler = (call) => {
    if (call.HasPics === true) {
      this.setState({
        clickedCallId: call.UniqueId,
        clickedCallerId: call.CallerUniqueId,
        showAnnotations: true,
      });
    }
  };

  componentDidMount = () => {
    Commons.checkAuthentication(this, this.props.f7router);
    if (this.props.recents !== null) {
      this.props.setRecents(null);
    }
    if (
      !this.props.loadingRecents ||
      this.props.loadingRecents.loading === false
    ) {
      this.fetchCalls();
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    Commons.checkAuthentication(this, this.props.f7router);
    if (
      this.props.recents === null &&
      (!this.props.loadingRecents ||
        this.props.loadingRecents.loading === false)
    ) {
      this.fetchCalls();
    }
  };

  finishLoadingRecents = () => {
    this.props.setLoadingRecents({ loading: false, loadingPerc: 0 });
    this.loadingRecents = false;
  };

  startLoadingRecents = () => {
    this.props.setLoadingRecents({ loading: true, loadingPerc: 0 });
    this.loadingRecents = true;
  };

  fetchCalls = () => {
    if (this.loadingRecents === true) {
      return;
    }

    this.startLoadingRecents();
    let self = this;

    const errorFn = (errorMsg) => {
      self.finishLoadingRecents();

      if (errorMsg !== this.state.errormessage) {
        if (this.props.recents === null) {
          self.setState({
            errormessage: errorMsg,
          });
        }
      }
    };

    const successFn = (calls) => {
      self.finishLoadingRecents();

      if (this.state.errormessage !== null) {
        self.setState({ errormessage: null });
      }

      const downloadedCalls = groupCallsByDate(calls.slice(0, 50));
      if (self.props.recents === null) {
        self.props.setRecents(downloadedCalls);
      } else if (
        JSON.stringify(self.props.recents) !== JSON.stringify(downloadedCalls)
      ) {
        self.props.setRecents(downloadedCalls);
        clearHistoryInterval(self.state.updateIntervalId);
      }
    };

    const clearHistoryInterval = (intervalId) => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        this.setState({ updateIntervalId: null });
      }
    };

    const groupCallsByDate = (calls) => {
      let result = [];
      let date = new Date();
      let today = moment(date).tz("Europe/Rome").format("DD/MM/YYYY");
      let yesterday = moment(date.setDate(date.getDate() - 1))
        .tz("Europe/Rome")
        .format("DD/MM/YYYY");

      let lastDateName = null;
      for (var i = 0; i < calls.length; i++) {
        let call = calls[i];
        let startedAt = moment(call.StartedAt)
          .tz("Europe/Rome")
          .format("DD/MM/YYYY");
        let dateName = startedAt;
        if (dateName === today) dateName = appLocaleStrings.today;
        else if (dateName === yesterday) dateName = appLocaleStrings.yesterday;
        if (lastDateName !== dateName)
          result.push({ dateName: dateName, calls: [call] });
        else result[result.length - 1].calls.push(call);
        lastDateName = dateName;
      }

      return result;
    };

    if (self.fetchingCalls !== true) {
      self.fetchingCalls = true;

      RaApiCalls.getCallHistory((progress) => {
        this.props.setLoadingRecents({ loading: true, loadingPerc: progress });
      })
        .then(function (response) {
          if (response.result.success === true) {
            successFn(response.data);
          } else if (response.result.code !== 409) {
            errorFn(response.result.message);
          }
        })
        .catch(function (errorMessage) {
          errorFn(errorMessage);
        })
        .then(() => {
          self.fetchingCalls = false;
        });
    }
  };

  checkRecentCalls = () => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    mainPageIndex: state.app.mainPageIndex,
    recents: state.app.recents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoadingRecents: (loadingRecents) =>
      dispatch(actions.setLoadingRecents(loadingRecents)),
    setRecents: (recents) => dispatch(actions.setRecents(recents)),
  };
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Recents);
