import { RaApiContacts } from "../../api/contacts";
import { RaApiCalls } from "../../api/calls/calls";
import { Ra } from "../../index";
import { RaLog } from "../../log";
import { RaStore } from "../../redux/store";
import { RaReduxActions } from "../../redux/actions";
import { RaApiNet } from "../../api/net";

export class RaApiCallsParticipants {
  static startToPartecipate = (call) => {
    RaLog.log("[startToPartecipate]");
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "post",
        url:
          Ra.getConfig().apiUrl +
          "/services/calls/" +
          call.UniqueId +
          "/participants",
        data: new FormData(),
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            RaLog.log(
              "[startToPartecipate] success - response.data.CallTargets:",
              response.data.CallTargets
            );
            RaStore.get().dispatch(
              RaReduxActions.setActiveCall(
                {
                  CallToken: response.data.Token,
                  Call: call,
                  AnnotationsColor: response.data.AnnotationsColor,
                },
                response.data.CallTargets
              )
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
  static stopPartecipation = () => {
    return new Promise((resolve, reject) => {
      if (!RaApiCalls.getActiveCall()) {
        resolve(null);
      } else {
        RaApiNet.callApi({
          method: "delete",
          url:
            Ra.getConfig().apiUrl +
            "/services/calls/" +
            RaApiCalls.getActiveCall().UniqueId +
            "/participants",
          data: new FormData(),
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (errorMessage) {
            reject(new Error(errorMessage));
          });
      }
    });
  };

  static getCallParticipants = (callId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url:
          Ra.getConfig().apiUrl + "/services/calls/" + callId + "/participants",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static isAssistedCallParticipant = (contactId) => {
    //  RaLog.error('[isAssistedCallParticipant] ' + contactId);
    let isAssisted = false;
    let activeCallTargets = RaStore.getState().activeCallTargets;
    RaLog.log(
      "[isAssistedCallParticipant] activeCallTargets.length=" +
        activeCallTargets.length,
      RaStore.getState().activeCallTargets
    );

    if (activeCallTargets && activeCallTargets.length > 0) {
      for (let i = 0; i < activeCallTargets.length; i++) {
        let contact = activeCallTargets[i].Contact;

        //     RaLog.log('[isAssistedCallParticipant] contact.UniqueId=' + contact.UniqueId);

        if (contact && contact.UniqueId === contactId) {
          if (RaApiContacts.isAssistedContact(contact)) {
            isAssisted = true;
            break;
          }
        }
      }
    }

    return isAssisted;
  };
}

export default RaApiCallsParticipants;
