import { RaApiCalls } from "../../api/calls/calls";
import { RaLog } from "../../log";
import { RaStore } from "../../redux/store";
import { RaReduxActions } from "../../redux/actions";
import { RaApiNet } from "../../api/net";
import { Ra } from "../../index";

export class RaApiCallsTargets {
  static getTargetStatus = (targetStatusCode) => {
    if (targetStatusCode === 1) return "unknown";
    else if (targetStatusCode === 2) return "waiting for connection";
    else if (targetStatusCode === 3) return "ringing";
    else if (targetStatusCode === 4) return "connecting";
    else if (targetStatusCode === 5) return "online";
    else if (targetStatusCode === 6) return "refused";
    else if (targetStatusCode === 7) return "closed";
  };

  static addTargetToActiveCall = (contactUniqueId) => {
    return new Promise((resolve, reject) => {
      RaApiCalls.thereArePeopleInCall(RaStore.getState().activeCall.Call)
        .then(function (response) {
          RaLog.log("addTargetToActiveCall " + Ra.getConfig().maxUsersInCall);
          if (
            Ra.getConfig().maxUsersInCall < 0 ||
            response.length < Ra.getConfig().maxUsersInCall
          ) {
            const bodyFormData = new FormData();
            bodyFormData.set("contactUniqueId", contactUniqueId);

            RaApiNet.callApi({
              method: "post",
              url:
                Ra.getConfig().apiUrl +
                "/services/calls/" +
                RaStore.getState().activeCall.Call.UniqueId +
                "/targets",
              data: bodyFormData,
              config: {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              },
            })
              .then(function (response) {
                resolve(response);
              })
              .catch(function (errorMessage) {
                reject(new Error(errorMessage));
              });
          } else {
            reject(new Error("Reached the maximum number of participants"));
          }
        })
        .catch(function (errorMsg) {
          reject(new Error(errorMsg));
        });
    });
  };

  static getActiveCallTargets = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url:
          Ra.getConfig().apiUrl +
          "/services/calls/" +
          RaStore.getState().activeCall.Call.UniqueId +
          "/targets",
      })
        .then(function (response) {
          if (response.result.success === true) {
            let targets = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                response.data[i].Status !== TARGET_STATUS.ENDED &&
                response.data[i].Status !== TARGET_STATUS.REFUSED
              ) {
                targets.push(response.data[i]);
              }
            }
            if (
              RaStore.getState().activeCallTargets === null ||
              JSON.stringify(RaStore.getState().activeCallTargets) !==
                JSON.stringify(targets)
            ) {
              RaStore.get().dispatch(
                RaReduxActions.setActiveCallTargets(targets)
              );
            }
          } else {
            RaStore.get().dispatch(RaReduxActions.setActiveCallTargets(null));
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          RaStore.get().dispatch(RaReduxActions.setActiveCallTargets(null));
          reject(new Error(errorMessage));
        });
    });
  };
}

export const TARGET_STATUS = {
  UNKNOWN: 1,
  WAITING_FOR_CONNECTION: 2,
  RINGING: 3,
  CONNECTING: 4,
  ONLINE: 5,
  REFUSED: 6,
  ENDED: 7,
};

export default RaApiCallsTargets;
