import React, { Component } from "react";

import { RaApiCallsPicsAnnotations } from "../api/calls/pics/annotations";
import { RaUI } from "../ui/ui";
import { RaMedia } from "../media/media";
import { RaStorage } from "../storage";
import { RaUiTrackRenderer } from "../ui/tracksRenderer";
import { RaUITracksNodesManager } from "../ui/tracksNodesManager";
import { RaApiCallsCommunicationProvider } from "../api/calls/communicationProvider";
import { RaCommons } from "../commons";
import { RaLog } from "../log";

export class RaUIAnnotationCanvas extends Component {
  constructor(props) {
    super(props);

    let bgImage = null;

    if (props.backgroundImage) {
      bgImage = new Image();
      bgImage.src = this.props.backgroundImageSrc; /*
            RaApiCallsPics.getPicBase64(props.callId, props.backgroundImage)
            .then((base64) => {
              if (base64) {
                  bgImage.src = 'data:image/jpg;base64,'+ base64;
                  this.setState({ backgroundImage: bgImage });
              }
            }); */
    }

    const annotationColor = RaApiCallsPicsAnnotations.getParticipantLineColor()
      ? RaApiCallsPicsAnnotations.getParticipantLineColor()
      : "#000000";

    this.state = this.getDefaultState(bgImage, annotationColor);
  }

  getDefaultState = (bgImage, annotationColor) => {
    return {
      segments: [],
      backgroundImage: bgImage,
      paint: false,
      context: null,
      clear: false,
      undo: [],
      lastDrawnSegmentIndex: 0,
      annotationColor: annotationColor
        ? annotationColor
        : this.state.annotationColor,
      lastUserSegment: null,
    };
  };

  clearFirma = () => {
    //this.undo();
    this.setState({
      segments: [
        {
          uniqueId: this.props.myProfile.UniqueId,
          clickX: [],
          clickY: [],
          clickDrag: [],
          color: this.state.annotationColor,
          width: 6,
          style: "round",
        },
      ],
      paint: false,
      clear: true,
    });
  };

  addClick = (x, y, dragging) => {
    const rsis = this.state.segments.find((segment) => {
      return segment.uniqueId === this.props.myProfile.UniqueId;
    });

    if (rsis) {
      rsis.clickX = [...rsis.clickX, x];
      rsis.clickY = [...rsis.clickY, y];
      rsis.clickDrag = [...rsis.clickDrag, dragging];
    } else {
      const segments = this.state.segments;
      const segment = {
        uniqueId: this.props.myProfile.UniqueId,
        clickX: [x],
        clickY: [y],
        clickDrag: [dragging],
        color: this.state.annotationColor,
        width: 6,
        style: "round",
      };
      segments.push(segment);
      this.setState({
        segments: segments,
      });
    }
  };

  render() {
    const w = this.state.backgroundImage.naturalWidth;
    const h = this.state.backgroundImage.naturalHeight;

    const marginBottom = this.props.marginBottom
      ? this.props.marginBottom
      : 210;

    const fitData =
      w && h
        ? RaUI.getElementFitData(
            window.innerWidth,
            window.innerHeight - marginBottom,
            w,
            h
          )
        : { width: 0, height: 0 };
    return (
      <span>
        <span
          style={{
            display:
              this.state.lastUserSegment && this.props.live ? "block" : "none",
          }}
          onClick={() => {
            this.cancelLastAnnotation();
          }}
        >
          {this.props.cancelAnnotationButton}
        </span>
        <div
          id="canvasContainer"
          width={fitData.width}
          height={fitData.height}
          className="modal-body row"
          style={{
            width: fitData.width,
            height: fitData.height,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            style={{
              width: fitData.width,
              height: fitData.height,
              padding: "0px",
              margin: "0px",
            }}
          >
            <div
              className="arVideoTracks"
              style={{
                display: "none",
                position: "absolute",
                width: fitData.width + "px",
                height: fitData.height + "px",
              }}
            ></div>
            <canvas
              id="canvasFirma"
              width={fitData.width}
              height={fitData.height}
            ></canvas>
          </div>
        </div>
      </span>
    );
  }

  salvaFirma = () => {
    const firmaBase64 = document
      .getElementById("canvasFirma")
      .toDataURL("image/png");
    const firmaVuota = this.state.segments[0].clickX.length === 0;
    this.props.onFirma(
      firmaBase64.substr(firmaBase64.indexOf(",") + 1),
      firmaVuota
    );
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.state.context === null && document.getElementById("canvasFirma")) {
      this.setState({
        context: document.getElementById("canvasFirma").getContext("2d"),
      });
    }
    if (this.state.clear === true) {
      this.redraw();
      this.setState({ clear: false });
    } else if (prevProps.backgroundImageSrc !== this.props.backgroundImageSrc) {
      if (this.props.backgroundImageSrc) {
        let bgImage = new Image();
        bgImage.src = this.props.backgroundImageSrc;
        this.setState(this.getDefaultState(bgImage));
        bgImage.onload = this.redraw;

        this.drawImageAnnotations();
      }
    } else if (
      this.state.backgroundImage !== null &&
      prevState.context === null &&
      this.state.context !== null
    ) {
      if (this.state.backgroundImage.complete) {
        this.redraw();
      } else {
        const pic = this.state.backgroundImage;
        pic.onload = this.redraw;
      }
    }

    this.setArVideoSize();

    const arEnableChanged =
      prevProps.isArVideoInputDeviceEnable !==
      this.props.isArVideoInputDeviceEnable;
    const arDeviceChanged = !RaMedia.equalDevice(
      prevProps.selectedArVideoInputDevice,
      this.props.selectedArVideoInputDevice
    );

    if (this.props.live && (arEnableChanged || arDeviceChanged)) {
      this.arLocalSettingsChanged();
    }

    if (
      this.props.dataTrack !== null &&
      this.props.dataTrack !== prevProps.dataTrack
    ) {
      const jsonDataTrack = JSON.parse(this.props.dataTrack);
      if (jsonDataTrack.type === "annotation") {
        const remoteSegment = jsonDataTrack.data;
        const rsis = this.state.segments.find((segment) => {
          return segment.uniqueId === remoteSegment.uniqueId;
        });

        if (rsis) {
          rsis.clickX = remoteSegment.clickX;
          rsis.clickY = remoteSegment.clickY;
          rsis.clickDrag = remoteSegment.clickDrag;
        } else {
          const segments = this.state.segments;
          segments.push(remoteSegment);
          this.setState({
            segments: segments,
          });
        }

        this.redraw();
      } else if (jsonDataTrack.type === "annotation-delete") {
        this.undo(jsonDataTrack.data);
      } else if (jsonDataTrack.type === "ar") {
        this.handleParticipantArTrack(
          jsonDataTrack.data.participantId,
          jsonDataTrack.data.enabled,
          jsonDataTrack.data.trackName
        );
      }
    }
  };

  componentWillUnmount = () => {
    if (this.arVideoSegmentation) {
      this.arVideoSegmentation.stopProcessing();
    }
  };

  /************************
   *  AR TRACK HANDLER
   ************************/

  arLocalSettingsChanged = () => {
    const self = this;
    if (RaMedia.isArVideoInputDeviceEnable()) {
      // let videosMainContainer = this.getArVideoNode();
      /*   const width = parseInt(videosMainContainer.style.width);
      const height = parseInt(videosMainContainer.style.height);
*/
      RaMedia.getArVideoProcessedTrack().then((track) => {
        if (track) {
          self.handleParticipantArTrack(
            RaStorage.getProfile().UniqueId,
            true,
            track.name
          );
        }
      });
    }
  };

  handleParticipantArTrack = (participantId, enableTrack, trackName) => {
    let videosMainContainer = this.getArVideoNode();
    const width = parseInt(videosMainContainer.style.width);
    const height = parseInt(videosMainContainer.style.height);
    let participantArVideoNodeContainer =
      this.getParticipantArVideoNodeContainer(
        videosMainContainer,
        participantId
      );
    let participantArCanvasNodes = this.getParticipantArCanvasNodes(
      participantId,
      participantArVideoNodeContainer,
      width,
      height
    );

    const self = this;
    //devo portare la traccia video sul canvas
    if (enableTrack) {
      videosMainContainer.style.display = "block";
      let callVideoNode = RaUiTrackRenderer.getParticipantTrackNode(
        participantId,
        "video"
      );
      var bufferContext =
        participantArCanvasNodes.bufferCanvas.getContext("2d");
      var arContext = participantArCanvasNodes.arCanvas.getContext("2d");
      var previewContext =
        participantArCanvasNodes.previewCanvas.getContext("2d");

      if (callVideoNode && bufferContext && arContext && previewContext) {
        //aspetto che arrivi la traccia di AR (quella attuale è ancora la traccia video normale)
        RaCommons.waitUntil(() => {
          callVideoNode = RaUiTrackRenderer.getParticipantTrackNode(
            participantId,
            "video"
          );
          if (callVideoNode) {
            return (
              (callVideoNode.getAttribute("track-name") === trackName ||
                !trackName) &&
              !callVideoNode.paused &&
              !callVideoNode.ended
            );
          } else {
            return false;
          }
        }, 100).then(() => {
          self.startArVideo(
            callVideoNode,
            bufferContext,
            arContext,
            previewContext,
            participantArCanvasNodes.arCanvas,
            document.getElementById("canvasFirma"),
            participantArCanvasNodes.bufferCanvas.width,
            participantArCanvasNodes.bufferCanvas.height,
            () => {
              self.removeParticipantArCanvasNodes(
                videosMainContainer,
                participantArVideoNodeContainer,
                participantArCanvasNodes
              );
            }
          );
        });
      }
    }
    //devo riportare la traccia video sulla video chiamata
    else {
      this.removeParticipantArCanvasNodes(
        videosMainContainer,
        participantArVideoNodeContainer,
        participantArCanvasNodes
      );
    }
  };

  startArVideo = (
    video,
    bufferContext,
    arContext,
    previewContext,
    arCanvas,
    imageCanvas,
    width,
    height,
    videoStoppedCallback
  ) => {
    if (!video || video.paused || video.ended) {
      videoStoppedCallback();
      return;
    }

    this.generateArFrame(
      video,
      bufferContext,
      arContext,
      previewContext,
      arCanvas,
      imageCanvas,
      width,
      height
    );
    const self = this;
    setTimeout(function () {
      try {
        self.startArVideo(
          video,
          bufferContext,
          arContext,
          previewContext,
          arCanvas,
          imageCanvas,
          width,
          height,
          videoStoppedCallback
        );
      } catch (e) {
        RaLog.error(e);
      }
    }, 0);
  };

  cropAr(snapshotWidth, snapshotHeight, videoWidth, videoHeight) {
    let snapshotAspectRatio = snapshotWidth / snapshotHeight;

    let cropWidth = videoWidth,
      cropHeight = videoHeight,
      top = 0,
      left = 0;

    //snapshot landscape
    if (snapshotWidth > snapshotHeight) {
      cropWidth = videoWidth;
      cropHeight = videoWidth / snapshotAspectRatio;

      top = (videoHeight - cropHeight) / 2;
    }
    //snapshot portrait
    else if (snapshotWidth < snapshotHeight) {
      cropHeight = videoHeight;
      cropWidth = videoHeight * snapshotAspectRatio;

      left = (videoWidth - cropWidth) / 2;
    }
    return {
      width: cropWidth,
      height: cropHeight,
      aspectRatio: snapshotAspectRatio,
      margin: { top: top, left: left },
    };
  }

  generateArFrame = (
    video,
    bufferContext,
    arContext,
    previewContext,
    arCanvas,
    imageCanvas,
    width,
    height
  ) => {
    let videoWidth = video.videoWidth;
    let videoHeight = video.videoHeight;

    if (!videoHeight || !videoWidth) {
      videoWidth = width;
      videoHeight = height;
    }
    let cropParams = this.cropAr(width, height, videoWidth, videoHeight);

    //bufferContext.drawImage(video, 0, 0, width, height);
    bufferContext.drawImage(
      video,
      cropParams.margin.left,
      cropParams.margin.top,
      cropParams.width,
      cropParams.height,
      0,
      0,
      width,
      height
    );

    var frame = bufferContext.getImageData(0, 0, width, height);
    var len = frame.data.length / 4;

    for (var i = 0; i < len; i++) {
      var r = frame.data[i * 4 + 0];
      var g = frame.data[i * 4 + 1];
      var b = frame.data[i * 4 + 2];
      if (g < 10 && r < 10 && b < 10) {
        frame.data[i * 4 + 3] = 0;
      }
    }
    arContext.putImageData(frame, 0, 0);
    if (imageCanvas instanceof HTMLCanvasElement) {
      previewContext.drawImage(imageCanvas, 0, 0);
    }
    if (arCanvas instanceof HTMLCanvasElement) {
      previewContext.drawImage(arCanvas, 0, 0);
    }
  };

  setArVideoSize = () => {
    let arVideoNode = this.getArVideoNode();
    if (this.state.context && arVideoNode) {
      for (var i = 0; i < arVideoNode.childNodes.length; i++) {
        let participantNode = arVideoNode.childNodes[i];
        for (let v = 0; v < participantNode.childNodes.length; v++) {
          if (
            participantNode.childNodes[v].nodeName.toLowerCase() === "video"
          ) {
            participantNode.childNodes[v].style.width =
              this.state.context.width;
            participantNode.childNodes[v].style.height =
              this.state.context.height;
          }
        }
      }
    }
  };

  getArVideoNode = () => {
    let arVideoNode = document.getElementsByClassName("arVideoTracks");
    if (arVideoNode.length > 0) {
      arVideoNode = arVideoNode[0];
    }
    return arVideoNode;
  };

  getParticipantArVideoNodeContainer = (arVideoTracksNode, participantId) => {
    let participantArVideoNodeContainerClassName =
      RaUITracksNodesManager.getTrackNodeId(participantId);
    let participantArVideoNodeContainer = RaUI.getFirstElementByClassName(
      participantArVideoNodeContainerClassName,
      arVideoTracksNode
    );
    if (!participantArVideoNodeContainer) {
      participantArVideoNodeContainer = document.createElement("div");
      participantArVideoNodeContainer.className =
        participantArVideoNodeContainerClassName;
      arVideoTracksNode.appendChild(participantArVideoNodeContainer);
    }
    return participantArVideoNodeContainer;
  };

  getParticipantArCanvasNodes = (
    participantId,
    participantArVideoNodeContainer,
    width,
    height
  ) => {
    let participantArVideoNodes = {};
    if (participantArVideoNodeContainer) {
      let canvasNodes = RaUI.getSubNodes(
        participantArVideoNodeContainer,
        "canvas"
      );
      canvasNodes.forEach((canvas) => {
        canvas.width = width;
        canvas.height = height;
        if (canvas.getAttribute("kind") === "buffer") {
          participantArVideoNodes.bufferCanvas = canvas;
        } else if (canvas.getAttribute("kind") === "ar") {
          participantArVideoNodes.arCanvas = canvas;
        } else if (canvas.getAttribute("kind") === "preview") {
          participantArVideoNodes.previewCanvas = canvas;
        }
      });

      if (!participantArVideoNodes.bufferCanvas) {
        let bufferVideoCanvas = document.createElement("canvas");
        bufferVideoCanvas.setAttribute("kind", "buffer");
        bufferVideoCanvas.width = width;
        bufferVideoCanvas.height = height;
        bufferVideoCanvas.style.display = "none";
        participantArVideoNodeContainer.appendChild(bufferVideoCanvas);
        participantArVideoNodes.bufferCanvas = bufferVideoCanvas;
      }
      if (!participantArVideoNodes.arVideoCanvas) {
        let arVideoCanvas = document.createElement("canvas");
        arVideoCanvas.setAttribute("kind", "ar");
        arVideoCanvas.width = width;
        arVideoCanvas.height = height;
        arVideoCanvas.style.display = "none";
        participantArVideoNodeContainer.appendChild(arVideoCanvas);
        participantArVideoNodes.arCanvas = arVideoCanvas;
      }
      if (!participantArVideoNodes.previewCanvas) {
        let previewCanvas = document.createElement("canvas");
        previewCanvas.setAttribute("kind", "preview");
        previewCanvas.width = width;
        previewCanvas.height = height;

        this.registraEventiTouch(previewCanvas);
        this.registraEventiMouse(previewCanvas);

        participantArVideoNodeContainer.appendChild(previewCanvas);
        participantArVideoNodes.previewCanvas = previewCanvas;
      }
    }
    return participantArVideoNodes;
  };

  removeParticipantArCanvasNodes = (
    videosMainContainer,
    participantArVideoNodeContainer,
    participantArCanvasNodes
  ) => {
    if (participantArCanvasNodes) {
      if (participantArCanvasNodes.bufferCanvas) {
        participantArCanvasNodes.bufferCanvas.remove();
      }
      if (participantArCanvasNodes.arCanvas) {
        participantArCanvasNodes.arCanvas.remove();
      }
      if (participantArCanvasNodes.previewCanvas) {
        participantArCanvasNodes.previewCanvas.remove();
      }
    }
    if (participantArVideoNodeContainer) {
      participantArVideoNodeContainer.remove();
    }
    videosMainContainer.style.display = "none";
  };

  /************************
   * FINE AR TRACK HANDLER
   ************************/

  drawImageAnnotations = () => {
    const self = this;
    this.fetchAnnotationsForImage(this.props.imageUniqueId).then(function (
      annotations
    ) {
      const cmsAnnotations = self.groupAnnotationsByUserId(annotations);
      const annotationsData = self.state.segments;
      let mLastDrawnSegmentIndex = 0;

      let annotationsDivideForParticipant = Object.keys(cmsAnnotations);
      for (let i = 0; i < annotationsDivideForParticipant.length; i++) {
        let key = annotationsDivideForParticipant[i];

        let annotationsForSingleParticipant = cmsAnnotations[key];
        const xArray = [];
        const yArray = [];
        const clickDrag = [];
        let participantColor = "#000000";
        let canvasWidth = 0;
        let canvasHeight = 0;
        annotationsForSingleParticipant.forEach((singleAnnotation, index) => {
          participantColor = singleAnnotation.Color;
          canvasWidth = singleAnnotation.CanvasWidth;
          canvasHeight = singleAnnotation.CanvasHeight;
          singleAnnotation.Points.forEach((point, index) => {
            xArray.push(point.X);
            yArray.push(point.Y);
            clickDrag.push(
              index !== 0 && index !== singleAnnotation.Points.length - 1
            );
          });
        });

        annotationsData.push({
          uniqueId: key,
          clickX: xArray,
          clickY: yArray,
          clickDrag: clickDrag,
          color: participantColor,
          width: 6,
          style: "round",
          canvasWidth: canvasWidth,
          canvasHeight: canvasHeight,
        });

        if (self.props.myProfile.UniqueId === key) {
          mLastDrawnSegmentIndex = clickDrag.length;
        }
      }

      self.setState(
        {
          segments: annotationsData,
          context: document.getElementById("canvasFirma")
            ? document.getElementById("canvasFirma").getContext("2d")
            : null,
          lastDrawnSegmentIndex: mLastDrawnSegmentIndex,
        },
        self.redraw
      );

      if (self.props.live) {
        self.registraEventiTouch(document.getElementById("canvasFirma"));
        self.registraEventiMouse(document.getElementById("canvasFirma"));
      }
    });
  };

  componentDidMount = () => {
    if (this.props.imageUniqueId) {
      this.drawImageAnnotations();
    }

    if (this.props.live) {
      this.setArVideoSize();
      this.arLocalSettingsChanged();
    }
  };

  /**
   * rirenderizzo solo se c'è qualcosa da modificare effettivamente
   * @param {*} nextProps
   * @param {*} nextState
   */
  /*shouldComponentUpdate(nextProps, nextState) {
        if (!this.state.context) {
            return true;
        }
        if (nextProps.dataTrack != this.props.dataTrack){
            return true;
        }
        if (nextProps.segmen)

        return false;
    }*/

  fetchAnnotationsForImage = (imageUniqueId) => {
    return new Promise((resolve, reject) => {
      const errorFn = (errorMsg) => {
        RaLog.error(errorMsg);
      };

      const successFn = (annotations) => {
        resolve(annotations);
      };

      RaApiCallsPicsAnnotations.getPicAnnotations(
        this.props.callId,
        imageUniqueId
      )
        .then(function (response) {
          if (response.result.success === true) {
            successFn(response.data);
          } else {
            errorFn(response.result.message);
          }
        })
        .catch(function (errorMessage) {
          errorFn(errorMessage);
        });
    });
  };

  sendSegment = () => {
    const mySegment = this.state.segments.find((segment) => {
      return segment.uniqueId === this.props.myProfile.UniqueId;
    });
    if (mySegment) {
      mySegment.canvasWidth = this.state.context.canvas.width;
      mySegment.canvasHeight = this.state.context.canvas.height;

      const mySegmentXInterval = mySegment.clickX.slice(
        this.state.lastDrawnSegmentIndex + 1,
        mySegment.clickX.length
      );
      const mySegmentYInterval = mySegment.clickY.slice(
        this.state.lastDrawnSegmentIndex + 1,
        mySegment.clickY.length
      );

      this.setState({ lastDrawnSegmentIndex: mySegment.clickDrag.length - 1 });

      const points = [];
      mySegmentXInterval.forEach((xValue, index) => {
        const point = { x: xValue, y: mySegmentYInterval[index] };
        points.push(point);
      });

      if (points.length > 0) {
        const self = this;
        RaApiCallsPicsAnnotations.addPicAnnotations(
          this.props.callId,
          this.props.backgroundImage.UniqueId,
          JSON.stringify(points),
          mySegment.width,
          mySegment.canvasWidth,
          mySegment.canvasHeight
        )
          .then(function (response) {
            if (response.result.success === true) {
              RaLog.log("lastUserSegment");
              self.setState({ lastUserSegment: response.data });
              self.onPointsSentToCms(response.data, mySegment);
            } else {
              self.errorFn(response.result.message);
            }
          })
          .catch(function (errorMessage) {
            self.errorFn(errorMessage);
          });
      }
    }
  };

  onPointsSentToCms = (cmsSegment, appSegment) => {
    RaApiCallsCommunicationProvider.sendMessage(
      '{"type": "annotation", "data": ' + JSON.stringify(appSegment) + " }"
    );
  };

  onCancelLastAnnotation = (cmsSegment) => {
    RaApiCallsCommunicationProvider.sendMessage(
      '{"type": "annotation-delete", "data": ' +
        JSON.stringify(cmsSegment) +
        " }"
    );
  };

  cancelLastAnnotation = () => {
    if (this.state.lastUserSegment) {
      let self = this;
      RaApiCallsPicsAnnotations.deletePicAnnotation(
        this.props.callId,
        this.props.backgroundImage.UniqueId,
        this.state.lastUserSegment.Id
      )
        .then(function (response) {
          if (response.result.success === true) {
            self.onCancelLastAnnotation(response.data);
            self.undo(response.data);
            self.setState({ lastUserSegment: null });
          } else {
            self.errorFn(response.result.message);
          }
        })
        .catch(function (errorMessage) {
          self.errorFn(errorMessage);
        });
    }
  };

  groupAnnotationsByUserId = (xs) => {
    return xs.reduce(function (rv, x) {
      (rv[x["ParticipantUniqueId"]] = rv[x["ParticipantUniqueId"]] || []).push(
        x
      );
      return rv;
    }, {});
  };

  undo = (newFullAnnotation) => {
    /*if (newFullSegment.length === 0) {
            this.clearFirma();

            return;
        }*/

    const cmsAnnotations = this.groupAnnotationsByUserId(newFullAnnotation);
    const annotationsData = [];
    let mLastDrawnSegmentIndex = 0;

    let annotationsDivideForParticipant = Object.keys(cmsAnnotations);

    for (let i = 0; i < annotationsDivideForParticipant.length; i++) {
      let key = annotationsDivideForParticipant[i];

      let annotationsForSingleParticipant = cmsAnnotations[key];
      const xArray = [];
      const yArray = [];
      const clickDrag = [];
      let participantColor = "#000000";
      let canvasWidth = 0;
      let canvasHeight = 0;
      annotationsForSingleParticipant.forEach((singleAnnotation, index) => {
        participantColor = singleAnnotation.Color;
        canvasWidth = singleAnnotation.CanvasWidth;
        canvasHeight = singleAnnotation.CanvasHeight;
        singleAnnotation.Points.forEach((point, index) => {
          xArray.push(point.X);
          yArray.push(point.Y);
          clickDrag.push(
            index !== 0 && index !== singleAnnotation.Points.length - 1
          );
        });
      });

      annotationsData.push({
        uniqueId: key,
        clickX: xArray,
        clickY: yArray,
        clickDrag: clickDrag,
        color: participantColor,
        width: 6,
        style: "round",
        canvasWidth: canvasWidth,
        canvasHeight: canvasHeight,
      });

      if (this.props.myProfile.UniqueId === key) {
        mLastDrawnSegmentIndex = clickDrag.length;
      }
    }

    this.setState(
      {
        segments: annotationsData,
        //context: document.getElementById('canvasFirma').getContext("2d"),
        lastDrawnSegmentIndex: mLastDrawnSegmentIndex,
      },
      this.redraw
    );

    /*const clickX = this.state.segments[0].clickX;
        const clickY = this.state.segments[0].clickY;
        const clickDrag = this.state.segments[0].clickDrag;

        while (clickX.length > 0) {
            clickX.pop();
            clickY.pop();
            clickDrag.pop();

            if (clickDrag[clickDrag.length - 1] === false) {
                clickX.pop();
                clickY.pop();
                clickDrag.pop();
                break;
            }
        }

        const remoteUserSegment = this.state.segments.find((segment) => {
            return segment.uniqueId === deletedUniqueId;
        });
        let segments = this.state.segments;
        segments = segments.filter(function (segments) {
            return segments !== remoteUserSegment;
        });

        this.setState({
            segments: [{
                uniqueId: deletedUniqueId,
                clickX: clickX,
                clickY: clickY,
                clickDrag: clickDrag,
                color: this.state.annotationColor,
                width: 6,
                style: "round",
            }]
            segments: segments
        });

        this.redraw();*/
  };

  registraEventiTouch = (canvas) => {
    /*    const container = document.getElementById("canvasContainer");
            const canvas = document.getElementById('canvasFirma');
    
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight;*/

    if (canvas) {
      const fnts = (mouseX, mouseY) => {
        this.setState({ paint: true });
        this.addClick(mouseX, mouseY, false);
        this.redraw();
      };

      canvas.addEventListener(
        "touchstart",
        function (e) {
          // Mouse down location
          var mouseX =
            (e.changedTouches ? e.changedTouches[0].clientX : e.clientX) -
            this.offsetLeft;
          var el = this;
          while (el.offsetParent !== null) {
            mouseX -= el.offsetParent.offsetLeft;
            el = el.offsetParent;
          }

          var mouseY =
            (e.changedTouches ? e.changedTouches[0].clientY : e.clientY) -
            this.offsetTop;
          el = this;
          while (el.offsetParent !== null) {
            mouseY -= el.offsetParent.offsetTop;
            el = el.offsetParent;
          }

          fnts(mouseX, mouseY);
        },
        false
      );

      const fntm = (mouseX, mouseY) => {
        this.addClick(mouseX, mouseY, true);
        this.redraw();
      };

      canvas.addEventListener(
        "touchmove",
        function (e) {
          e.preventDefault();

          var mouseX =
            (e.changedTouches ? e.changedTouches[0].clientX : e.clientX) -
            this.offsetLeft;
          var el = this;
          while (el.offsetParent !== null) {
            mouseX -= el.offsetParent.offsetLeft;
            el = el.offsetParent;
          }

          var mouseY =
            (e.changedTouches ? e.changedTouches[0].clientY : e.clientY) -
            this.offsetTop;
          el = this;
          while (el.offsetParent !== null) {
            mouseY -= el.offsetParent.offsetTop;
            el = el.offsetParent;
          }

          fntm(mouseX, mouseY);
        },
        false
      );

      const fnte = () => {
        this.sendSegment();
        this.setState({ paint: false });
        this.redraw();
      };

      canvas.addEventListener(
        "touchend",
        function (e) {
          fnte();
        },
        false
      );

      /*const fntc = () => {
                this.setState({ paint: false });
            }*/

      canvas.addEventListener(
        "touchcancel",
        function (e) {
          //fntc();
        },
        false
      );
    }
  };

  registraEventiMouse = (canvas) => {
    /* const container = document.getElementById("canvasContainer");
         const canvas = document.getElementById('canvasFirma');
 
         canvas.width = container.clientWidth;
         canvas.height = container.clientHeight;*/

    if (canvas) {
      const fnts = (mouseX, mouseY) => {
        this.setState({ paint: true });
        this.addClick(mouseX, mouseY, false);
        this.redraw();
      };

      canvas.addEventListener(
        "mousedown",
        function (e) {
          // Mouse down location
          var mouseX = e.clientX - this.offsetLeft;
          var el = this;
          while (el.offsetParent !== null) {
            mouseX -= el.offsetParent.offsetLeft;
            el = el.offsetParent;
          }

          var mouseY = e.clientY - this.offsetTop;
          el = this;
          while (el.offsetParent !== null) {
            mouseY -= el.offsetParent.offsetTop;
            el = el.offsetParent;
          }

          fnts(mouseX, mouseY);
        },
        false
      );

      const fntm = (mouseX, mouseY) => {
        if (this.state.paint === false) {
          return;
        }
        this.addClick(mouseX, mouseY, true);
        this.redraw();
      };

      canvas.addEventListener(
        "mousemove",
        function (e) {
          e.preventDefault();

          var mouseX = e.clientX - this.offsetLeft;
          var el = this;
          while (el.offsetParent !== null) {
            mouseX -= el.offsetParent.offsetLeft;
            el = el.offsetParent;
          }

          var mouseY = e.clientY - this.offsetTop;
          el = this;
          while (el.offsetParent !== null) {
            mouseY -= el.offsetParent.offsetTop;
            el = el.offsetParent;
          }

          fntm(mouseX, mouseY);
        },
        false
      );

      const fnte = () => {
        this.sendSegment();
        this.setState({ paint: false });
        this.redraw();
      };

      canvas.addEventListener(
        "mouseup",
        function (e) {
          fnte();
        },
        false
      );

      /*const fntc = () => {
                this.sendSegment();
                this.setState({ paint: false });
            }*/

      canvas.addEventListener(
        "mouseleave",
        function (e) {
          //fntc();
        },
        false
      );
    }
  };

  //visualizzare errore nel caso vada in errore l'invio delle coordinate
  errorFn = (errorMsg) => {
    if (errorMsg !== this.state.errormessage) {
    }
  };

  redraw = () => {
    let context = this.state.context;
    let canvasWidth = this.props.$$("#canvasFirma").prop("width");
    let canvasHeight = this.props.$$("#canvasFirma").prop("height");

    if (context) {
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      if (
        this.state.backgroundImage !== null &&
        this.state.backgroundImage.complete
      ) {
        context.drawImage(
          this.state.backgroundImage,
          0,
          0,
          canvasWidth,
          canvasHeight
        );
      }

      this.state.segments.forEach((segment, index) => {
        context.strokeStyle = segment.color;
        context.lineJoin = segment.style;
        context.lineWidth = segment.width;

        let cx = 1;
        let cy = 1;
        if (segment.canvasWidth) {
          cx = canvasWidth / segment.canvasWidth;
          cy = canvasHeight / segment.canvasHeight;
          context.lineWidth = segment.width * cx;
        }

        for (var i = 0; i < segment.clickX.length; i++) {
          context.beginPath();
          if (segment.clickDrag[i] && i) {
            context.moveTo(
              segment.clickX[i - 1] * cx,
              segment.clickY[i - 1] * cy
            );
          } else {
            context.moveTo(segment.clickX[i] * cx - 1, segment.clickY[i] * cy);
          }
          context.lineTo(segment.clickX[i] * cx, segment.clickY[i] * cy);
          context.closePath();
          context.stroke();
        }
      });

      this.setState({ context: context });
    }
  };
}
/*
// #region Redux
const mapStateToProps = state => {
    return {
        dataTrack: state.remoteAssistance.dataTrack,
        myProfile: state.remoteAssistance.profile,
    };
};*/

// #endregion

//export default connect(mapStateToProps, null)(RaUIAnnotationCanvas);
