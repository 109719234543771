import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    no_invitations: "Nothing found",
    search_placeholder: "Search...",
    get_invitation_api_error: "Unable di read invitations list",
    invitation_submitted_on: "Submitted: ",
    invitation_resend: "Resend",
    registered: "registered",
    resend: "Resend",
    resend_confirm_text: "Do you want to resend the invitation?",
    resend_confirm_title: "Invitation",
    resend_confirm_error: "An error occurred, please try later",
    resend_ok: "Invitation sent succesfully",
    delete: "Delete",
    delete_confirm_text: "Do you want to delete the invitation?",
    delete_confirm_title: "Invitation",
    delete_error: "An error occurred, please try later",
    delete_ok: "Invitation succesfully deleted",
  },
  it: {
    no_invitations: "Nessun invito trovato",
    search_placeholder: "Cerca...",
    get_invitation_api_error: "Impossibile leggere la lista degli inviti",
    invitation_submitted_on: "Inviato: ",
    invitation_resend: "Rispedisci",
    registered: "registrato",
    resend: "Invia di nuovo",
    resend_confirm_text: "Vuoi rimandare l'invito?",
    resend_confirm_title: "Invito",
    resend_confirm_error: "Si è verificato un errore, riprovare più tardi",
    resend_ok: "Invito mandato correttamente",
    delete: "Elimina",
    delete_confirm_text: "Vuoi annullare l'invito?",
    delete_confirm_title: "Invito",
    delete_error: "Si è verificato un errore, riprovare più tardi",
    delete_ok: "Invitato eliminato",
  },
});

export default localeStrings;
