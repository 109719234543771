import moment from "moment-timezone";
import {
  isAndroid,
  isIOS,
  isBrowser,
  isTablet,
  isChrome,
  isMacOs,
  isWindows,
} from "react-device-detect";

import { RaApiNet } from "./api/net";
import { RaStorage } from "./storage";
import { Ra } from "./index";
import { RaCryptoAES } from "./crypto/aes";

export const LOG_TYPE = {
  NONE: 0,
  ERROR: 10,
  VERBOSE: 30,
};

export class RaLog {
  static _internalLog = [];

  static _archiveLog = (method, arg1, arg2) => {
    const logDate = moment
      .utc(new Date())
      .tz("Europe/Rome")
      .format("YYYY-MM-DD HH:mm:ss.SSS");
    let logText = RaLog._objToString(arg1);
    if (arg2) {
      logText += " " + RaLog._objToString(arg2);
    }
    RaLog._internalLog.push(logDate + ": [" + method + "] - " + logText);
  };

  static _objToString = (obj) => {
    if (typeof obj === "object") {
      return JSON.stringify(obj);
    } else {
      return obj;
    }
  };

  static _writeLogInConsole = (method, arg1, arg2) => {
    console[method](arg1);
    if (arg2) {
      console[method](arg2);
    }
  };

  static _log = (arg1, arg2) => {
    RaLog._writeLogInConsole("log", arg1, arg2);
  };
  static log = (arg1, arg2) => {
    RaLog._log(arg1, arg2);
    RaLog._archiveLog("VERBOSE", arg1, arg2);
  };

  static _error = (arg1, arg2) => {
    RaLog._writeLogInConsole("error", arg1, arg2);
  };
  static error = (arg1, arg2) => {
    RaLog._error(arg1, arg2);
    RaLog._archiveLog("ERROR", arg1, arg2);
  };

  static init = () => {
    if (Ra.getConfig().logType < LOG_TYPE.VERBOSE) {
      RaLog._log = () => {};
    }
    if (Ra.getConfig().logType < LOG_TYPE.ERROR) {
      RaLog._error = () => {};
    }
  };

  static sendLog = (errorMsg, errorInfo) => {
    return new Promise((resolve, reject) => {
      if (!Ra.getConfig()) {
        console.log("remote-assistance not yet initialized");
        resolve();
      } else {
        const bodyFormData = new FormData();
        const now = new Date();
        const requestTime = Date.UTC(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours(),
          now.getMinutes(),
          now.getSeconds()
        );
        const data = {
          appCode: Ra.getConfig().appCode,
          time: requestTime,
          errorMsg: RaLog._objToString(errorMsg),
          errorInfo: RaLog._objToString(errorInfo),
          appLog: RaLog._internalLog.join("\n"),
          username: RaStorage.getProfile()
            ? RaStorage.getProfile().UniqueId
            : null,
          isBrowser: isBrowser,
          isChrome: isChrome,
          isIOS: isIOS,
          isAndroid: isAndroid,
          isMacOs: isMacOs,
          isWindows: isWindows,
          isTablet: isTablet,
        };

        bodyFormData.set("appCode", Ra.getConfig().appCode);
        bodyFormData.set("now", requestTime);
        bodyFormData.set(
          "data",
          RaCryptoAES.encryptAES(
            JSON.stringify(data),
            Ra.getConfig().appCode + "-" + requestTime
          )
        );

        RaApiNet.callApi({
          method: "post",
          data: bodyFormData,
          url: Ra.getConfig().apiUrl + "/services/log",
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (errorMessage) {
            reject(new Error(errorMessage));
          });
      }
    });
  };
}
export default RaLog;
