import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    existingContact_cardHeader: "This e-mail is already in use by this user",
    existingContact_addButton: "Add to your address book",
    email_errorMessage: "Please type a valid e-mail",
    api_errorMessage:
      "An error occurred while checking the e-mail, please try later.",
  },
  it: {
    existingContact_cardHeader:
      "Questa e-mail è già utilizzata da questo utente",
    existingContact_addButton: "Aggiungi alla rubrica",
    email_errorMessage: "Inserisci un indirizzo e-mail valido",
    api_errorMessage:
      "Si è verificato un errore nel tenativo di verificare l'e-mail, riprova più tardi.",
  },
});

export default localeStrings;
