import React from "react";
import { connect } from "react-redux";
import { Popup } from "framework7-react";
import IncomingCall from "./incomingcall";

import { RaApiCalls } from "../../ra-lib/api/calls/calls";

class IncomingCallPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Popup
        className="incomingcall-popup"
        closeByBackdropClick={false}
        closeOnEscape={false}
        swipeToClose={false}
        colorTheme="custom"
        opened={
          this.props.incomingCall != null &&
          RaApiCalls.getIncomingCall() != null
        }
      >
        <IncomingCall router={this.props.router}></IncomingCall>
      </Popup>
    );
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    incomingCall: state.remoteAssistance.incomingCall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(IncomingCallPopup);
