import React from "react";
import {
  Block,
  /* PageContent,
  Page,*/
  Col,
  Link,
  /* NavLeft,
  Link,
  Navbar,
  NavRight,
  NavTitle,*/
} from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./contact-local";
import ProfileInfo from "../profile/profile-info";
import Icons from "../../icons";
import Commons from "../../Commons";

import { RaApiAddressBook } from "../../ra-lib/api/addressbook";
import { RaLog } from "../../ra-lib/log";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getPageContent = (selectedContact) => {
    const removeContact =
      selectedContact &&
      selectedContact.InheritedFromRoleAddressBook === false ? (
        <Block
          style={{
            float: "right",
            overflow: "hidden",
            margin: "0 0 15px 15px",
          }}
        >
          <Link
            size="20"
            iconIos={Icons.DELETE_IOS}
            iconAurora={Icons.DELETE_AURORA}
            iconMd={Icons.DELETE_ANDROID}
            onClick={() => {
              Commons.showConfirm(
                this,
                localeStrings.delete_confirm_text,
                localeStrings.delete_confirm_title,
                () => {
                  this.removeContactFromAddressBook(selectedContact);
                }
              );
            }}
          ></Link>
        </Block>
      ) : null;

    return (
      <div>
        <ProfileInfo contact={selectedContact}></ProfileInfo>
        {removeContact}
      </div>
    );
  };

  render() {
    const selectedContact = this.props.contact;

    let pageContent =
      this.state.errormessage || !selectedContact ? (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>
            <h3>{localeStrings.list_error}</h3>
          </Col>
        </Block>
      ) : (
        this.getPageContent(selectedContact)
      );

    return (
      /*  <Page pageContent={false} colorTheme="custom">
        <Navbar noShadow={true} noHairline={true} sliding={false}>
          <NavLeft>
            <Link
              back
              iconIos={Icons.CLOSE_IOS}
              iconAurora={Icons.CLOSE_AURORA}
              iconMd={Icons.CLOSE_ANDROID}
              onClick={() => {
                if (this.props.onCloseClick) {
                  this.props.onCloseClick();
                }
              }}
            ></Link>
          </NavLeft>
          <NavTitle>{selectedContact ? selectedContact.Name : null}</NavTitle>
          <NavRight>
            <Link
              iconIos={Icons.EDIT_IOS}
              iconAurora={Icons.EDIT_AURORA}
              iconMd={Icons.EDIT_ANDROID}
              onClick={(contact) => {}}
            ></Link>
          </NavRight>
        </Navbar>

        <PageContent>{pageContent}</PageContent>
      </Page>*/
      <div>{pageContent}</div>
    );
  }

  removeContactFromAddressBook = (selectedContact) => {
    if (selectedContact) {
      let errorMessage = null;
      let self = this;
      RaApiAddressBook.removeFromAddressBook(selectedContact.UniqueId)
        .then((response) => {
          if (response.result.success === false) {
            errorMessage = localeStrings.remove_api_errorMessage;
            RaLog.error(response.result.message);
          }
        })
        .catch((error) => {
          errorMessage = localeStrings.remove_api_errorMessage;
          RaLog.error(error);
        })
        .finally(() => {
          if (errorMessage) {
            Commons.showErrorMessage(self, errorMessage);
          } else {
            self.props.onRemoveContactFromAddressBook(true);
          }
        });
    }
  };

  componentDidMount = () => {
    // Commons.checkAuthentication(this, this.props.f7router);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //  Commons.checkAuthentication(this, this.props.f7router);
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    mainPageIndex: state.app.mainPageIndex,
    userAddressBook: state.remoteAssistance.userAddressBook,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
