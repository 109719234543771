import { RaCordova } from "../../cordova/index";
import { RaCordovaUi } from "../../cordova/ui";
import { RaLog } from "../../log";
import { RaApiNet } from "../../api/net";
import { RaApiAuth } from "../../api/auth";
import { Ra } from "../../index";
import { RaStore } from "../../redux/store";
import { RaApiCallsPics } from "../../api/calls/pics/pics";
import { RaApiContacts, CONTACT_ROLE } from "../../api/contacts";
import { RaStorage } from "../../storage";
import { RaMedia } from "../../media/media";
import { RaReduxActions } from "../../redux/actions";
import { RaUITracksNodesManager } from "../../ui/tracksNodesManager";
import { RaApiCallsCommunicationProvider } from "../../api/calls/communicationProvider";
import { RaCommons } from "../../commons";
import { RaCrypto } from "../../crypto/crypto";
import { RaArVideoSegmentation } from "../../ar/segmentation";
import { RaApiCallsParticipants } from "../../api/calls/participants";
import { RaApiCallsMessages } from "../../api/calls/message";
import { RaApiCallsInvitations } from "../../api/calls/invitations";
import RaVoipNotifications from "../../voip-notifications";
import { isIOS } from "react-device-detect";

let phoneCallingAudioRef;
let phoneBusyAudioRef;
let phoneRingAudioRef;
let phoneUnavailableAudioRef;

const PHONE_STATE = {
  PHONE_CALLING: "PHONE_CALLING",
  PHONE_BUSY: "PHONE_BUSY",
  PHONE_RING: "PHONE_RING",
  PHONE_UNAVAILABLE: "PHONE_UNAVAILABLE",
};

const CALL_USER_TYPE = {
  ASSISTED: "ASSISTED",
  OPERATOR: "OPERATOR",
  GENERIC: "GENERIC",
};

export class RaApiCalls {
  static get PhoneState() {
    return PHONE_STATE;
  }

  static setPhoneCallingAudio = (phoneCallingAudio) => {
    phoneCallingAudioRef = new Audio(phoneCallingAudio);
    phoneCallingAudioRef.load();
  };

  static setPhoneBusyAudio = (phoneBusyAudio) => {
    phoneBusyAudioRef = new Audio(phoneBusyAudio);
    phoneBusyAudioRef.load();
  };

  static setPhoneRingAudio = (phoneRingAudio) => {
    phoneRingAudioRef = new Audio(phoneRingAudio);
    phoneRingAudioRef.load();
  };

  static setPhoneUnavailableAudio = (phoneUnavailableAudio) => {
    phoneUnavailableAudioRef = new Audio(phoneUnavailableAudio);
    phoneUnavailableAudioRef.load();
  };

  static setCallTimeBeforeHangUp = (seconds) => {
    RaStore.get().dispatch(RaReduxActions.setCallTimeBeforeHangup(seconds));
  };

  static getCallTimeBeforeHangUp = () => {
    return RaStore.getState().callTimeBeforeHangup;
  };

  static get CallUserType() {
    return CALL_USER_TYPE;
  }

  static setCallController = (callController) => {
    RaStore.get().dispatch(RaReduxActions.setCallController(callController));
  };

  static getCallController = () => {
    return RaStore.getState().callController;
  };

  static connectToCall = (shareVideo, shareAudio) => {
    // RaStore.get().dispatch(RaReduxActions.connectToComunicationProvider());
    return new Promise((resolve, reject) => {
      if (RaApiCalls.amICaller()) {
        RaApiCalls.playAudioFromPhoneState(RaApiCalls.PhoneState.PHONE_CALLING);
      }
      RaApiCallsCommunicationProvider.connectToRoom(
        RaStore.getState().activeCall.CallToken,
        shareAudio,
        shareVideo,
        function (dataTrack) {
          RaStore.get().dispatch(RaReduxActions.setDataTrack(dataTrack));
        }
      )
        .then((room) => {
          //avvio il processing della traccia video in AR, dato che richiede molto tempo
          if (RaMedia.isArVideoInputDeviceEnable()) {
            RaMedia.getArVideoProcessedTrack()
              .then()
              .catch(() => {
                RaApiCalls._onArVideoProcessError();
              });
          }
          if (RaApiCalls.amICaller() && RaStore.getState().activeCall) {
            RaApiCalls.startCallTimer();
          }
          resolve();
        })
        .catch((error) => {
          if (RaApiCalls.amICaller() && RaStore.getState().activeCall) {
            RaStore.get().dispatch(RaReduxActions.setCallingState(false));
          }
          RaStore.get().dispatch(
            RaReduxActions.connectedToComunicationProviderError(error)
          );
          reject(error);
        })
        .finally(function () {
          if (
            RaApiCalls.amICaller() &&
            RaStore.getState().callingState === false
          ) {
            RaApiCalls.closeActiveCall();
          }
        });
    });
  };

  static deleteLockedCalls = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "delete",
        url: Ra.getConfig().apiUrl + "/services/calls",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static GetDecryptedCall = (call, storedRecents) => {
    let index = storedRecents
      ? RaCommons.getElementIndexFromUniqueId(storedRecents, call.UniqueId)
      : -1;
    let localCall = index >= 0 ? storedRecents[index] : null;

    call.Subject = localCall
      ? localCall.Subject
      : RaCrypto.decrypt(call.Subject, call.CryptoSchema);
    if (!call.Subject) {
      call.Subject = "";
    }

    return call;
  };

  static _gettingCallHistory = false;

  static getCallHistory = (progressCallback) => {
    return new Promise((resolve, reject) => {
      if (RaApiCalls._gettingCallHistory === true) {
        resolve({
          result: {
            success: false,
            code: 409,
            message: "getCallHistory already in progress",
          },
        });
        return;
      }
      RaApiCalls._gettingCallHistory = true;

      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/calls",
      })
        .then(function (response) {
          if (response.result.success === true) {
            let storedRecents = RaStorage.getRecents();

            let calls = response.data;
            RaCommons.changeArrayItemsAsync(
              calls,
              (item) => {
                return RaApiCalls.GetDecryptedCall(item, storedRecents);
              },
              1,
              progressCallback
            ).then((decryptedCalls) => {
              response.data = decryptedCalls;
              RaStorage.setRecents(decryptedCalls);
              resolve(response);
              RaApiCalls._gettingCallHistory = false;
            });
          } else {
            resolve(response);
            RaApiCalls._gettingCallHistory = false;
          }
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
          RaApiCalls._gettingCallHistory = false;
        });
    });
  };

  static getCurrentCalls = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/calls?online",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getContactsUnavailableAudioFile = (targetContacts) => {
    if (
      targetContacts.length === 1 &&
      targetContacts[0].Company &&
      targetContacts[0].Company.UnavailableAudioUrl
    ) {
      return new Audio(targetContacts[0].Company.UnavailableAudioUrl);
    }
    return null;
  };

  static setIncomingCall = (call) => {
    RaStore.get().dispatch(RaReduxActions.setIncomingCall(call));
    if (!RaCordova.isCordova()) {
      RaApiCalls.playAudioFromPhoneState(RaApiCalls.PhoneState.PHONE_RING);
    }
  };

  static startNewCall = (subject, targetContacts, productCode) => {
    RaStore.get().dispatch(RaReduxActions.setCallingState(true));

    return new Promise((resolve, reject) => {
      RaApiCallsPics.resetCallPics();

      let targetCustomUnavailableAudio =
        RaApiCalls.getContactsUnavailableAudioFile(targetContacts);
      if (targetCustomUnavailableAudio) {
        targetCustomUnavailableAudio.load();
      }

      let targetContactsIds = [];
      targetContacts.forEach((contact) => {
        targetContactsIds.push(contact.UniqueId);
      });

      if (!targetContactsIds.includes(RaStorage.getProfile().UniqueId)) {
        targetContactsIds.push(RaStorage.getProfile().UniqueId);
      }

      const bodyFormData = new FormData();
      bodyFormData.set(
        "subject",
        RaCrypto.encrypt(subject, [
          RaApiContacts.getContactIdentities(
            RaStorage.getProfile().UniqueId,
            RaStorage.getProfile().PublicKey
          ),
        ])
      );
      bodyFormData.set("targetContacts", targetContactsIds.toString());
      bodyFormData.set("productCode", productCode);
      bodyFormData.set("cryptoschema", Ra.getConfig().cryptoSchema);

      RaApiNet.callApi({
        method: "post",
        url: Ra.getConfig().apiUrl + "/services/calls",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            RaStore.get().dispatch(
              RaReduxActions.setActiveCall(
                {
                  CallToken: response.data.Participant.Token,
                  Call: response.data.Call,
                  AnnotationsColor: response.data.Participant.AnnotationsColor,
                },
                response.data.Participant.CallTargets
              )
            );
            //                    RaLog.error('[startNewCall] success - response.data.Participant.CallTargets:', response.data.Participant.CallTargets);
          } else if (response.result.code === 409) {
            RaApiCalls.playAudioFromPhoneState(
              RaApiCalls.PhoneState.PHONE_BUSY
            );
          } else if (response.result.code === 404) {
            if (targetCustomUnavailableAudio) {
              RaMedia.playAudio(targetCustomUnavailableAudio);
            } else {
              RaApiCalls.playAudioFromPhoneState(
                RaApiCalls.PhoneState.PHONE_UNAVAILABLE
              );
            }
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static startCallTimer = () => {
    var countdownTimer = new Date();
    countdownTimer.setSeconds(
      countdownTimer.getSeconds() + RaApiCalls.getCallTimeBeforeHangUp()
    );
    var countDownDate = countdownTimer.getTime();

    RaStorage.setCurrentCallTimer(
      setInterval(function () {
        var now = new Date().getTime();
        var distance = countDownDate - now;

        if (distance < 0) {
          RaApiCalls.stopCallTimer();
          RaMedia.stopAudio();
          RaApiCalls.closeActiveCall();
        }
      }, 1000)
    );
  };

  static stopCallTimer = () => {
    clearInterval(RaStorage.getCurrentCallTimer());
    RaStorage.setCurrentCallTimer(null);
  };

  static getAudioFromPhoneState = (audioState) => {
    let audio = null;
    switch (audioState) {
      case RaApiCalls.PhoneState.PHONE_CALLING:
        audio = phoneCallingAudioRef;
        break;
      case RaApiCalls.PhoneState.PHONE_BUSY:
        audio = phoneBusyAudioRef;
        break;
      case RaApiCalls.PhoneState.PHONE_RING:
        audio = phoneRingAudioRef;
        break;
      case RaApiCalls.PhoneState.PHONE_UNAVAILABLE:
        audio = phoneUnavailableAudioRef;
        break;
      default:
        break;
    }
    return audio;
  };

  static playAudioFromPhoneState = (audioState) => {
    let audio = RaApiCalls.getAudioFromPhoneState(audioState);
    RaMedia.playAudio(audio);
  };

  static _onTargetConnected = (contactUniqueId) => {
    RaApiCalls.stopCallTimer();
    if (RaApiCalls.amICaller() && RaStore.getState().callingState === true) {
      RaStore.get().dispatch(RaReduxActions.setCallingState(false));
    }

    RaMedia.stopAudio();
    if (
      RaApiCalls.onTargetConnected &&
      RaApiCalls.onTargetConnected instanceof Function
    ) {
      RaApiCalls.onTargetConnected(contactUniqueId);
    }
  };

  static _onCallDeclinedByTarget = () => {
    if (RaApiCalls.amICaller() && RaStore.getState().callingState === true) {
      RaStore.get().dispatch(RaReduxActions.setCallingState(false));
    }

    if (
      RaApiCalls.onCallDeclinedByTarget &&
      RaApiCalls.onCallDeclinedByTarget instanceof Function
    ) {
      RaApiCalls.onCallDeclinedByTarget();
    }
  };

  static onTargetConnected = null;
  static onParticipantDisconnected = null;
  static onActiveCallClosed = null;
  static onCallDeclinedByTarget = null;
  static onVideoTrackAdded = null;
  static onParticipantTrackAdded = null;
  static onParticipantTrackRemoved = null;
  static onParticipantTrackDimensionChanged = null;
  static onArVideoProcessError = null;
  static onCameraAccessError = null;
  static onMicrophoneAccessError = null;

  static _onSelectedSnapshot = (pic) => {
    if (RaMedia.isArVideoInputDeviceEnable()) {
      RaApiCalls._switchArVideoTrack(pic);
    }
  };

  static _onArVideoProcessError = () => {
    if (RaApiCalls.onArVideoProcessError) {
      RaApiCalls.onArVideoProcessError();
    }
  };

  static _switchArVideoTrack = (pic) => {
    const switchToAr = () => {
      RaApiCallsCommunicationProvider.switchToArVideoCamera()
        .then((newTrack) => {
          RaApiCallsCommunicationProvider.sendArVideoTrackEnableMessage(
            true,
            newTrack
          );
          RaLog.log("Sended track", newTrack);
        })
        .catch(() => {
          RaApiCalls._onArVideoProcessError();
        });
    };

    let arDevice = RaMedia.getSelectedArVideoInputDevice();
    if (arDevice) {
      //switch to AR
      if (pic && !RaStore.getState().selectedSnapshot) {
        //lo switch si esegue solo se la webcam è già trainata,
        //altrimenti potrebbe trasmettere un video in cui il background è segmentato male e si sovrappone allo snapshot
        if (RaArVideoSegmentation.isTrainingEnded()) {
          switchToAr();
        } else {
          if (!RaArVideoSegmentation.onTrainingEnded) {
            RaArVideoSegmentation.onTrainingEnded = () => {
              if (
                RaApiCalls.getActiveCall() &&
                RaMedia.isArVideoInputDeviceEnable() &&
                RaStore.getState().selectedSnapshot
              ) {
                switchToAr();
              }
            };
          }
        }
      }
      //switch to VIDEO
      else if (!pic && RaStore.getState().selectedSnapshot) {
        RaApiCallsCommunicationProvider.switchToVideoCamera().then(
          (newTrack) => {
            RaApiCallsCommunicationProvider.sendArVideoTrackEnableMessage(
              false,
              newTrack
            );
            RaLog.log("Sended track", newTrack);
          }
        );
      }
    }
  };

  static _onSelectedVideoInputDeviceChanged = (device) => {
    if (
      RaApiCallsCommunicationProvider._getCurrentRoom() &&
      RaStore.getState().callGUIMode === RaApiCalls.GUIMODE_LIVECAMERA
    ) {
      RaApiCallsCommunicationProvider.publishDeviceTrack(device, "video")
        .then()
        .catch((errorMsg) => {
          if (RaApiCalls.onCameraAccessError) {
            RaApiCalls.onCameraAccessError(errorMsg);
          }
        });
    }
  };

  static _onSelectedAudioInputDeviceChanged = (device) => {
    if (RaApiCallsCommunicationProvider._getCurrentRoom()) {
      RaApiCallsCommunicationProvider.publishDeviceTrack(device, "audio")
        .then()
        .catch((errorMsg) => {
          if (RaApiCalls.onMicrophoneAccessError) {
            RaApiCalls.onMicrophoneAccessError(errorMsg);
          }
        });
    }
  };

  static getIncomingCall = () => {
    return RaStore.getState().incomingCall;
  };

  static getIncomingCaller = () => {
    return RaStore.getState().incomingCall != null
      ? RaStore.getState().incomingCall.Caller
      : null;
  };

  static getActiveCall = () => {
    return RaStore.getState() != null && RaStore.getState().activeCall != null
      ? RaStore.getState().activeCall.Call
      : null;
  };

  static _onDisconnectedFromCall = () => {
    if (RaApiCalls.amICaller() && RaStore.getState().callingState === true) {
      RaStore.get().dispatch(RaReduxActions.setCallingState(false));
    }
  };

  static closeActiveCall = () => {
    RaApiCallsParticipants.stopPartecipation()
      .then(function () {
        RaApiCalls.stopCallTimer();
        RaApiCallsMessages.clearUnreadMessages();
        RaStore.get().dispatch(RaReduxActions.setTakenSnapshots([]));
        RaStore.get().dispatch(RaReduxActions.setSelectedSnapshot(null));
        RaStore.get().dispatch(RaReduxActions.setNotSeenPhotos([]));

        RaStore.get().dispatch(RaReduxActions.setProductCode(null));

        RaStore.get().dispatch(RaReduxActions.setCallerVideoTrackShared(false));
        RaStore.get().dispatch(
          RaReduxActions.setCallerVideoTrackShareAllowed(false)
        );

        RaStore.get().dispatch(
          RaReduxActions.setCallGUIMode(RaApiCalls.GUIMODE_LIVECAMERA)
        );

        let activeCall = RaStore.getState().activeCall;
        if (activeCall && activeCall.Call) {
          RaStorage.addClosedCallId(activeCall.Call.UniqueId);
        }

        if (
          RaApiCalls.amICaller() &&
          RaStore.getState().callingState === true
        ) {
          RaStore.get().dispatch(RaReduxActions.setCallingState(false));
        }

        if (
          RaApiCalls.onActiveCallClosed &&
          RaApiCalls.onActiveCallClosed instanceof Function
        )
          RaApiCalls.onActiveCallClosed();
      })
      .catch((error) => {
        RaLog.error("closeActiveCall error", error);
      })
      .finally(function () {
        RaMedia.stopAudio();
        RaUITracksNodesManager.removeAllParticipantsTracks();

        if (RaApiCallsCommunicationProvider._getCurrentRoom()) {
          RaApiCallsCommunicationProvider.disconnectFromRoom(
            RaStore.getState().comunicationProviderRoom
          );
          RaStore.get().dispatch(RaReduxActions.setActiveCall(null));
          RaStore.get().dispatch(RaReduxActions.setActiveCallParticipants([]));
        }

        if (RaCordova.isCordova() && isIOS) {
          RaVoipNotifications.endVoipCall();
        }
      });
  };

  static setMuteUnmuteAudio = (audioMuted) => {
    document.getElementById("videoPlaceholder").muted = !audioMuted;
  };

  static setMuteUnmuteMic = () => {
    const room = RaStore.getState().comunicationProviderRoom;
    if (room) {
      room.localParticipant.audioTracks.forEach(function (
        localAudioTrackPublication
      ) {
        if (localAudioTrackPublication.track.isEnabled === true) {
          localAudioTrackPublication.track.disable();
        } else {
          localAudioTrackPublication.track.enable();
        }
      });
      return true;
    }
    return false;

    /*if (micMuted) {
            room.localParticipant.audioTracks.forEach(function (audioTrack) {
                if (track.kind === "audio")
                    track.enable();
            });
        }
        else {
            room.localParticipant.tracks.forEach(function (track) {
                if (track.kind === "audio")
                    track.disable();
            });
        }*/
  };

  static getCallCaller = (call) => {
    let caller = null;
    if (call != null && call.Targets !== null) {
      for (var t = 0; t < call.Targets.length; t++) {
        if (call.Targets[t].Contact.UniqueId === call.CallerUniqueId) {
          caller = call.Targets[t].Contact;
          break;
        }
      }
    }
    return caller;
  };

  static checkIncomingCall = () => {
    return new Promise((resolve, reject) => {
      const finish = (success) => {
        if (!RaApiCalls.getIncomingCall()) {
          RaApiCalls.stopIncomingCallAudioAndVibration();
        }
        resolve(success);
      };

      if (RaApiAuth.isAuthenticated()) {
        RaApiCalls.getCurrentCalls()
          .then(function (response) {
            if (response.result.success === true) {
              let calls = response.data;
              const closedCallsIds = RaStorage.getClosedCallsIds();
              for (let i = 0; i < calls.length; i++) {
                const call = calls[i];
                const isCallInClosedCalls = closedCallsIds.includes(
                  call.UniqueId
                );
                if (call.IsIstantCall && !isCallInClosedCalls) {
                  RaLog.log("found incomingCall");
                  RaApiCalls.setIncomingCall(call);
                  break;
                }
              }
              finish(true);
            } else {
              RaLog.error("checkIncomingCall", response.result.message);
              finish(false);
            }
          })
          .catch(function (response) {
            RaLog.error("checkIncomingCall", response.message);
            finish(false);
          });
      } else {
        finish(false);
      }
    });
  };

  static acceptIncomingCall = () => {
    return new Promise((resolve, reject) => {
      RaApiCalls.stopIncomingCallAudioAndVibration();
      RaApiCallsPics.resetCallPics();
      RaApiCalls.thereArePeopleInCall(RaStore.getState().incomingCall)
        .then(function (response) {
          if (
            Ra.getConfig().maxUsersInCall < 0 ||
            response.length < Ra.getConfig().maxUsersInCall
          ) {
            RaApiCallsParticipants.startToPartecipate(
              RaStore.getState().incomingCall
            )
              .then(function (response) {
                RaLog.log(response);

                if (response.result.success === true) {
                  RaStore.get().dispatch(RaReduxActions.setIncomingCall(null));
                  resolve();
                } else {
                  reject(new Error(response.result.message));
                }
              })
              .catch(function (response) {
                reject(new Error(response.message));
              });
          } else {
            reject(new Error("Reached the maximum number of participants"));
          }
        })
        .catch(function (errorMsg) {
          reject(new Error(errorMsg));
        });

      //reject(new Error('Call already closed'));
    });
  };

  static thereArePeopleInCall = (call) => {
    return new Promise((resolve, reject) => {
      RaApiCallsParticipants.getCallParticipants(call.UniqueId)
        .then(function (response) {
          if (response.data.length > 0) resolve(response.data);
          else reject("Call already closed");
        })
        .catch(function (response) {
          reject(response);
        });
    });
    //TODO: verificare quante persone ci sono in chiamata
    //return RaStore.getState().activeCallParticipantsCount !== 0;
  };

  static stopIncomingCallAudioAndVibration = () => {
    if (RaCordova.isCordova()) {
      RaCordovaUi.stopAudioAndVibration();
    } else {
      RaMedia.stopAudio();
    }
  };

  static declineIncomingCall = () => {
    return new Promise((resolve, reject) => {
      RaApiCalls.stopIncomingCallAudioAndVibration();
      RaApiCallsInvitations.declineInvitation(
        RaApiCalls.getIncomingCall().UniqueId
      )
        .then(function () {
          RaStore.get().dispatch(RaReduxActions.setIncomingCall(null));
          resolve();
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static declineTargetCall = (call) => {
    return new Promise((resolve, reject) => {
      RaApiCallsInvitations.declineInvitation(call.UniqueId)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static switchToLiveCameraGUI = () => {
    if (
      RaApiCalls.getActiveCall() &&
      RaApiCalls.amICallController() &&
      RaStore.getState().callGUIMode !== RaApiCalls.GUIMODE_LIVECAMERA
    ) {
      const videoScreenState = RaStore.getState().videoScreenState
        ? RaStore.getState().videoScreenState
        : JSON.parse(
          '{"liveView": true, "selectedImage": 0, "responseToUniqueId":"null","date": "null"}'
        );
      videoScreenState.responseToUniqueId = null;
      videoScreenState.liveView = true;
      videoScreenState.selectedImage = RaStore.getState().selectedSnapshot;
      videoScreenState.date = Date.now();

      RaApiCallsCommunicationProvider.sendMessage(
        '{"type": "update_video_screen_state", "data": ' +
        JSON.stringify(videoScreenState) +
        "}"
      );

      RaStore.get().dispatch(
        RaReduxActions.setVideoScreenState(videoScreenState)
      );
      RaStore.get().dispatch(
        RaReduxActions.setCallGUIMode(RaApiCalls.GUIMODE_LIVECAMERA)
      );
    }
  };

  static switchToAnnotationsGUI = () => {
    //RaApiCalls.askCallerVideoState();
    //RaApiCalls.askAnnotationSelection();
    RaStore.get().dispatch(RaReduxActions.setNotSeenPhotos([]));
    if (
      RaApiCalls.getActiveCall() &&
      RaApiCalls.amICallController() &&
      RaStore.getState().callGUIMode !== RaApiCalls.GUIMODE_ANNOTATIONS
    ) {
      const videoScreenState = RaStore.getState().videoScreenState
        ? RaStore.getState().videoScreenState
        : JSON.parse(
          '{"liveView": false, "selectedImage": 0, "responseToUniqueId":"null","date": "null"}'
        );
      videoScreenState.responseToUniqueId = null;
      videoScreenState.liveView = false;
      videoScreenState.selectedImage = RaStore.getState().selectedSnapshot;
      videoScreenState.date = Date.now();

      RaApiCallsCommunicationProvider.sendMessage(
        '{"type": "update_video_screen_state", "data": ' +
        JSON.stringify(videoScreenState) +
        "}"
      );

      RaStore.get().dispatch(
        RaReduxActions.setVideoScreenState(videoScreenState)
      );
      RaStore.get().dispatch(
        RaReduxActions.setCallGUIMode(RaApiCalls.GUIMODE_ANNOTATIONS)
      );
    }
  };

  /**
   * da usare solo quando voglio far cambiare immagine stando nella stessa schermata (GUIMODE_ANNOTATIONS)
   */
  static updateSelectedImage = () => {
    if (RaApiCalls.getActiveCall() && RaApiCalls.amICallController()) {
      const videoScreenState = RaStore.getState().videoScreenState
        ? RaStore.getState().videoScreenState
        : JSON.parse(
          '{"liveView": false, "selectedImage": 0, "responseToUniqueId":"null","date": "null"}'
        );
      videoScreenState.responseToUniqueId = null;
      videoScreenState.liveView = false;
      videoScreenState.selectedImage = RaStore.getState().selectedSnapshot;
      videoScreenState.date = Date.now();

      RaApiCallsCommunicationProvider.sendMessage(
        '{"type": "update_video_screen_state", "data": ' +
        JSON.stringify(videoScreenState) +
        "}"
      );

      RaStore.get().dispatch(
        RaReduxActions.setVideoScreenState(videoScreenState)
      );
      RaStore.get().dispatch(
        RaReduxActions.setCallGUIMode(RaApiCalls.GUIMODE_ANNOTATIONS)
      );
    }
  };

  /*static askCallerVideoState = () => {
        const jsonCallerVideoState = '{"type": "ask_video_screen_state", "data": {"uniqueId": "' + RaStorage.getProfile().UniqueId + '","date": "' + Date.now() + '"}}';
        RaLog.log('ask_video_screen_state ', jsonCallerVideoState);
        const videoScreenState = RaStore.getState().videoScreenState ? RaStore.getState().videoScreenState : JSON.parse('{"liveView": false, "selectedImage": 0, "responseToUniqueId":"null","date": "null"}');
        videoScreenState.uniqueId = RaStorage.getProfile().UniqueId;
        videoScreenState.selectedImage = RaStore.getState().selectedSnapshot;
        videoScreenState.date = Date.now();

        RaApiCallsCommunicationProvider.sendMessage(
            '{"type": "ask_video_screen_state", "data": ' + JSON.stringify(videoScreenState) + '}'
        );
        //}
    }*/

  /*static askAnnotationSelection = () => {
        if (RaApiCalls.getActiveCall().CallerUniqueId !== RaStorage.getProfile().UniqueId) {
            RaApiCallsCommunicationProvider.sendMessage('{"type": "askAnnotationSelection", "data": {"uniqueId": "' + RaStorage.getProfile().UniqueId + '","date": "' + Date.now() + '"}}');
        }
    }*/

  static amICallController = () => {
    /*   let currentUserType = false;
           let amICaller = RaApiCalls.amICaller();
   
           switch (process.env.REACT_APP_CAN_CALL) {
               case RaApiCalls.CallUserType.ASSISTED:
                   if (amICaller)
                       currentUserType = RaApiCalls.CallUserType.ASSISTED;
                   else
                       currentUserType = RaApiCalls.CallUserType.OPERATOR;
                   break;
               case RaApiCalls.CallUserType.OPERATOR:
                   if (amICaller)
                       currentUserType = RaApiCalls.CallUserType.OPERATOR;
                   else
                       currentUserType = RaApiCalls.CallUserType.ASSISTED;
                   break;
               case RaApiCalls.CallUserType.GENERIC:
                   currentUserType = RaApiCalls.CallUserType.GENERIC;
                   break;
               default:
                   break;
           }
   
           return RaStore.getState().callController === currentUserType || currentUserType === RaApiCalls.CallUserType.GENERIC;*/
    let profile = RaStorage.getProfile();
    return (
      profile &&
      profile.Role &&
      (profile.Role.Code === CONTACT_ROLE.OPERATOR ||
        profile.Role.Code === CONTACT_ROLE.ADMIN)
    );
  };

  static amICaller = () => {
    const reduxState = RaStore.getState();

    if (
      reduxState.activeCall &&
      reduxState.activeCall.Call &&
      reduxState.profile
    ) {
      return (
        reduxState.activeCall.Call.CallerUniqueId ===
        reduxState.profile.UniqueId
      );
    }

    return false;
  };

  static amICallerOfSpecifiedCall = (callerId) => {
    const reduxState = RaStore.getState();

    if (reduxState.profile) {
      return callerId === reduxState.profile.UniqueId;
    }

    return false;
  };

  static GUIMODE_LIVECAMERA = "GUIMODE_LIVECAMERA";
  static GUIMODE_ANNOTATIONS = "GUIMODE_ANNOTATIONS";

  static latestPicSelected = null;

  static dataTrackChangesHandler = (dataTrack, prevdataTrack) => {
    let jsonPrevdataTrack = null;
    if (prevdataTrack !== null) jsonPrevdataTrack = JSON.parse(prevdataTrack);

    if (dataTrack !== null) {
      const jsonDataTrack = JSON.parse(dataTrack);

      /*if (RaApiCalls.amICallController()) {
                if (jsonDataTrack.type === 'askSelectedPic') {
                    RaApiCallsPics.sendSelectedPicMessage(RaStore.getState().selectedSnapshot);
                }
            }*/

      //if (dataTrack !== prevdataTrack) {
      if (jsonDataTrack !== jsonPrevdataTrack) {
        RaLog.log("datatrack changed - ", jsonDataTrack.type);

        if (RaApiCalls.getActiveCall() !== null) {
          if (jsonDataTrack.type === "participant") {
            if (
              jsonDataTrack.data.UniqueId !== RaStorage.getProfile().UniqueId
            ) {
              RaApiCallsCommunicationProvider.setParticipantPublicKey(
                jsonDataTrack.data.UniqueId,
                jsonDataTrack.data.PublicKey
              );
            }
          } else if (jsonDataTrack.type === "msg") {
            let message = RaApiCallsMessages.GetDecryptedMessage(
              jsonDataTrack.data
            );
            if (RaStore.getState().unreadMessages) {
              RaStore.get().dispatch(
                RaReduxActions.setUnreadMessages([
                  ...RaStore.getState().unreadMessages,
                  message,
                ])
              );
            } else {
              RaStore.get().dispatch(
                RaReduxActions.setUnreadMessages([message])
              );
            }
          } else if (jsonDataTrack.type === "pic") {
            RaStore.get().dispatch(
              RaReduxActions.setTakenSnapshots(jsonDataTrack.data)
            );

            if (RaStore.getState().notSeenPhotos) {
              RaStore.get().dispatch(
                RaReduxActions.setNotSeenPhotos([
                  ...RaStore.getState().notSeenPhotos,
                  jsonDataTrack.data,
                ])
              );
            } else {
              RaStore.get().dispatch(
                RaReduxActions.setNotSeenPhotos([jsonDataTrack.data])
              );
            }
          } else if (
            /*else if (jsonDataTrack.type === 'response_video_screen_state') {

                        if (prevdataTrack === null || (jsonDataTrack.data != null && 
                            jsonPrevdataTrack.data != null && 
                            jsonDataTrack.data.responseToUniqueId !== jsonPrevdataTrack.data.responseToUniqueId &&
                            jsonDataTrack.data.responseToUniqueId === RaStore.getState().profile.UniqueId)) {
                            if (jsonDataTrack.data.liveView) {
                                RaStore.get().dispatch(RaReduxActions.setCallGUIMode(RaApiCalls.GUIMODE_LIVECAMERA));
                            }
                            else {
                                //RaStore.get().dispatch(RaReduxActions.setSelectedSnapshot(RaStore.getState().takenSnapshots[jsonDataTrack.data.selectedImage]));
                                RaStore.get().dispatch(RaReduxActions.setSelectedSnapshot(jsonDataTrack.data.selectedImage));
                                RaStore.get().dispatch(RaReduxActions.setCallGUIMode(RaApiCalls.GUIMODE_ANNOTATIONS));
                            }



                            RaStore.get().dispatch(RaReduxActions.setTakenSnapshots(jsonDataTrack.data.takenSnapshots));

                            if (RaStore.getState().notSeenPhotos) {
                                RaStore.get().dispatch(RaReduxActions.setNotSeenPhotos([...RaStore.getState().notSeenPhotos, jsonDataTrack.data.takenSnapshots]));
                            }
                            else {
                                RaStore.get().dispatch(RaReduxActions.setNotSeenPhotos([jsonDataTrack.data.takenSnapshots]));
                            }
                        }
                    }*/
            jsonDataTrack.type === "update_video_screen_state" &&
            dataTrack !== prevdataTrack
          ) {
            if (jsonDataTrack.data.liveView) {
              RaStore.get().dispatch(
                RaReduxActions.setCallGUIMode(RaApiCalls.GUIMODE_LIVECAMERA)
              );
              RaApiCallsPics.selectPic(null, null);
            } else {
              RaApiCallsPics.selectPic(jsonDataTrack.data.selectedImage);
              //RaStore.get().dispatch(RaReduxActions.setSelectedSnapshot(jsonDataTrack.data.selectedImage));
              RaStore.get().dispatch(
                RaReduxActions.setCallGUIMode(RaApiCalls.GUIMODE_ANNOTATIONS)
              );
            }
          }

          if (
            jsonDataTrack.type === "onPicSelected" &&
            dataTrack !== prevdataTrack &&
            JSON.stringify(jsonDataTrack.data) !==
            JSON.stringify(RaApiCalls.latestPicSelected)
          ) {
            RaApiCalls.latestPicSelected = jsonDataTrack.data;
            RaApiCallsPics.selectPic(jsonDataTrack.data);
            //RaStore.get().dispatch(RaReduxActions.setSelectedSnapshot(jsonDataTrack.data));
          }

          //if (RaApiCalls.amICallController()) {
          //TODO: passare anche l'array di foto
          /*if (jsonDataTrack.type === 'ask_video_screen_state') {
                            let videoScreenState = null;
                            if (RaStore.getState().videoScreenState === null) {
                                videoScreenState = JSON.parse('{"liveView": true, "selectedImage": 0, "responseToUniqueId":"' + jsonDataTrack.data.uniqueId + '","date": "' + Date.now() + '"}');
                                videoScreenState.takenSnapshots = RaStore.getState().takenSnapshots;
                                //TODO: aggiungere messaggi
                                RaApiCallsCommunicationProvider.sendMessage(
                                    '{"type": "response_video_screen_state", "data": ' + JSON.stringify(videoScreenState) + '}'
                                );
                            }
                            else {
                                videoScreenState = RaStore.getState().videoScreenState;
                                videoScreenState.responseToUniqueId = jsonDataTrack.data.uniqueId;
                                videoScreenState.date = Date.now();

                                if (!videoScreenState.liveView) {
                                    videoScreenState.selectedImage = RaStore.getState().selectedSnapshot;/*RaStore.getState().takenSnapshots.indexOf(RaStore.getState().selectedSnapshot);
                                }

                                videoScreenState.takenSnapshots = RaStore.getState().takenSnapshots;

                                //TODO: aggiungere messaggi
                                RaApiCallsCommunicationProvider.sendMessage(
                                    '{"type": "response_video_screen_state", "data": ' + JSON.stringify(videoScreenState) + '}'
                                );
                            }
                        }*/
          //}
        }
      }
    }
  };
}

export default RaApiCalls;
