import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    no_name: "N.A.",
  },
  it: {
    no_name: "N.A.",
  },
});

export default localeStrings;
