import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    loading: "loading roles...",
    api_error: "unable to load roles",
  },
  it: {
    loading: "caricamento in corso...",
    api_error: "impossibile caricare i ruoli",
  },
});

export default localeStrings;
