import { RaApiCalls } from "../../../api/calls/calls";
import { RaLog } from "../../../log";
import { RaStore } from "../../../redux/store";
import { RaReduxActions } from "../../../redux/actions";
import { RaApiNet } from "../../../api/net";
import { RaCrypto } from "../../../crypto/crypto";
import { RaApiContacts } from "../../../api/contacts";
import { RaStorage } from "../../../storage";
import { Ra } from "../../../index";
import { RaApiCallsCommunicationProvider } from "../../../api/calls/communicationProvider";

export class RaApiCallsPics {
  static saveNewPic = (base64Url) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      let headerSeparator = base64Url.indexOf(",");
      if (headerSeparator) {
        base64Url = base64Url.substring(headerSeparator + 1);
      }
      let recipients = RaStore.getState().activeCallParticipants;
      recipients.push(
        RaApiContacts.getContactIdentities(
          RaStorage.getProfile().UniqueId,
          RaStorage.getProfile().PublicKey
        )
      );

      bodyFormData.set("picbase64", RaCrypto.encrypt(base64Url, recipients));

      bodyFormData.set("cryptoschema", Ra.getConfig().cryptoSchema);

      RaApiNet.callApi({
        method: "post",
        url:
          Ra.getConfig().apiUrl +
          "/services/calls/" +
          RaStore.getState().activeCall.Call.UniqueId +
          "/pics",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            const takenSnapshots = [
              ...RaStore.getState().takenSnapshots,
              response.data,
            ];
            RaStore.get().dispatch(
              RaReduxActions.setTakenSnapshots(takenSnapshots)
            );
            RaStore.get().dispatch(
              RaReduxActions.setNotSeenPhotos(takenSnapshots)
            );
            RaApiCallsPics.sendPicsChangedMessage();
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getActiveCallPics = () => {
    return new Promise((resolve, reject) => {
      RaApiCallsPics.getCallPics(RaStore.getState().activeCall.Call.UniqueId)
        .then(function (response) {
          if (response.result.success === true) {
            RaStore.get().dispatch(
              RaReduxActions.setTakenSnapshots(response.data)
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getCallPics = (callUniqueId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url:
          Ra.getConfig().apiUrl + "/services/calls/" + callUniqueId + "/pics",
      })
        .then(function (response) {
          if (response.result.success === true) {
            RaStore.get().dispatch(
              RaReduxActions.setTakenSnapshots(response.data)
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getPicBase64 = (callUniqueId, pic) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url:
          Ra.getConfig().apiUrl +
          "/storage/calls/" +
          callUniqueId +
          "/" +
          pic.ImageFileName,
      })
        .then(function (response) {
          if (response.result.success === true) {
            let cryptedFile = JSON.parse(atob(response.data.Base64));
            let fileBase64 = RaCrypto.decrypt(
              cryptedFile,
              response.data.CryptoSchema
            );
            resolve(fileBase64);
          } else {
            RaLog.error(response.result.message);
            resolve(null);
          }
        })
        .catch(function (errorMessage) {
          RaLog.error(errorMessage);
          resolve(null);
        });
    });
  };

  static selectPic = (pic, imgSrc) => {
    if (RaApiCalls.getActiveCall()) {
      RaApiCalls._onSelectedSnapshot(pic);
    }
    RaStore.get().dispatch(RaReduxActions.setSelectedSnapshot(pic, imgSrc));
  };

  static resetCallPics = () => {
    RaStore.get().dispatch(RaReduxActions.setSelectedSnapshot(null));
    RaStore.get().dispatch(RaReduxActions.setTakenSnapshots([]));
  };

  static sendSelectedPicMessage = (pic) => {
    if (RaApiCalls.amICallController() && pic) {
      RaApiCallsCommunicationProvider.sendMessage(
        '{"type": "onPicSelected", "data": ' + JSON.stringify(pic) + "}"
      );
    }
  };

  static sendSelectedPicRequestMessage = () => {
    if (!RaApiCalls.amICallController()) {
      RaApiCallsCommunicationProvider.sendMessage(
        '{"type": "askSelectedPic", "data": "" }'
      );
    }
  };

  static clearNotSeenPhotos = () => {
    RaStore.get().dispatch(RaReduxActions.setNotSeenPhotos([]));
  };

  static deletePic = (picId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "delete",
        url:
          Ra.getConfig().apiUrl +
          "/services/calls/" +
          RaStore.getState().activeCall.Call.UniqueId +
          "/pics/" +
          picId,
      })
        .then(function (response) {
          if (response.result.success === true) {
            let snapshots = RaStore.getState().takenSnapshots;
            RaStore.getState().takenSnapshots.forEach((snapshot, index) => {
              if (snapshot.UniqueId === picId) {
                if (index !== -1) {
                  snapshots.splice(index, 1);
                }
                RaStore.get().dispatch(
                  RaReduxActions.setDeletedSnapshot(snapshot)
                );
                if (
                  RaStore.getState().selectedSnapshot &&
                  RaStore.getState().selectedSnapshot.UniqueId ===
                    snapshot.UniqueId
                )
                  RaApiCallsPics.selectPic(null);
              }
            });
            RaStore.get().dispatch(RaReduxActions.setTakenSnapshots(snapshots));
            RaApiCallsPics.sendPicsChangedMessage();
          }

          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static sendPicsChangedMessage = () => {
    RaApiCallsCommunicationProvider.sendMessage(
      '{"type": "pic", "data": ' +
        JSON.stringify(RaStore.getState().takenSnapshots) +
        "}"
    );
  };
}
export default RaApiCallsPics;
