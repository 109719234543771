import { RaLog } from "../log";

export class RaCordovaNet {
  static openWiFiSettings = (successCallback, errorCallBack) => {
    var settings = window.cordova.plugins.settings;

    if (settings != null) {
      settings.open(
        "wifi",
        function () {
          RaLog.log("opened settings");
          successCallback(true);
        },
        function () {
          RaLog.log("failed to open settings");
          errorCallBack(false);
        }
      );
    } else {
      RaLog.log("settings null");
      errorCallBack(false);
    }
  };

  static getConnectionInfo = (successCallback, errorCallBack) => {
    var WifiManager = window.cordova.plugins.WifiManager;
    if (WifiManager != null) {
      WifiManager.getConnectionInfo(function (err, connInfo) {
        if (err == null) {
          RaLog.log(connInfo);
          successCallback(connInfo);
        } else {
          RaLog.log("err " + err);
          errorCallBack(err);
        }
      });
    } else {
      errorCallBack("err");
    }
  };

  static getDhcpInfo = (successCallback, errorCallBack) => {
    var WifiManager = window.cordova.plugins.WifiManager;
    if (WifiManager != null) {
      WifiManager.getDhcpInfo(function (err, dhcpInfo) {
        if (err == null) {
          RaLog.log(dhcpInfo);
          successCallback(dhcpInfo);
        } else {
          RaLog.log("err " + err);
          errorCallBack(err);
        }
      });
    } else {
      errorCallBack("err");
    }
  };

  static startWifiScan = (successCallback, errorCallBack) => {
    var WifiManager = window.cordova.plugins.WifiManager;
    if (WifiManager != null) {
      WifiManager.startScan(function (err, scanSuccess) {
        if (err == null) {
          RaLog.log(scanSuccess);
          successCallback(scanSuccess);
        } else {
          RaLog.log("err " + err);
          errorCallBack(err);
        }
      });
    } else {
      errorCallBack("err");
    }
  };

  static wifiScanResults = (successCallback, errorCallBack) => {
    var WifiManager = window.cordova.plugins.WifiManager;
    if (WifiManager != null) {
      WifiManager.getScanResults(function (err, scanResults) {
        if (err == null) {
          successCallback(scanResults);
        } else {
          errorCallBack(err);
        }
      });
    } else {
      errorCallBack("err");
    }
  };

  static isWifiHotspotEnabled = (successCallback, errorCallBack) => {
    //RaLog.log('isWiFiHotSpotEnabled');
    var WifiManager = window.cordova.plugins.WifiManager;
    if (WifiManager != null) {
      WifiManager.isWifiApEnabled(function (err, result) {
        if (err != null) {
          errorCallBack(err);
        } else if (result != null) {
          successCallback(result);
        }
      });
    } else {
      errorCallBack("");
    }
  };

  static getWifiApState = (successCallback, errorCallBack) => {
    RaLog.log("getWifiApState");
    var WifiManager = window.cordova.plugins.WifiManager;
    if (WifiManager != null) {
      WifiManager.getWifiApState(function (err, result) {
        if (err != null) {
          errorCallBack(err);
        } else if (result != null) {
          successCallback(result);
        }
      });
    } else {
      errorCallBack("");
    }
  };

  static getListOfConnectedDevice = (successCallback, errorCallBack) => {
    //RaLog.log('getListOfConnectedDevice');
    var WifiManager = window.cordova.plugins.WifiManager;
    if (WifiManager != null) {
      WifiManager.getListOfConnectedDevice(function (err, result) {
        //RaLog.log(result);
        if (err != null) {
          errorCallBack(err);
        } else if (result != null) {
          successCallback(result);
        }
      });
    } else {
      errorCallBack("");
    }
  };
}

export default RaCordovaNet;
