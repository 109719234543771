import React from "react";
import { connect } from "react-redux";
import Commons from "../../Commons";
import Icons from "../../icons";
import {
  Link,
  List,
  ListItem,
  Panel,
  View,
  Page,
  Block,
  Row,
  Col,
  Icon,
} from "framework7-react";
import localeStrings from "./left-local";

import { RaStorage } from "../../ra-lib/storage";
import { RaUI } from "../../ra-lib/ui/ui";

import f7 from "framework7";
import { isBrowser } from "react-device-detect";

class MenuLeft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let leftMenu = null;

    if (Commons.isPortrait(f7)) {
      let cameraSettings = null;
      if (Commons.hasIpCam()) {
        cameraSettings = (
          <ListItem
            panelClose
            href="/ipcam-settings/"
            reloadAll={true}
            view={".view-main"}
          >
            <div className="item-title-row">
              <Icon
                slot="inner-start"
                ios="f7:camera_viewfinder"
                aurora="f7:camera_viewfinder"
                md="material:linked_camera"
              ></Icon>
              <div className="item-title">Live Glasses settings</div>
            </div>
          </ListItem>
        );
      }

      let chat = null;
      if (process.env.REACT_APP_CHAT_ENABLED === "true") {
        let unreadMessages = Commons.getUnreadMessages(this.props.contactRooms);
        chat = (
          <ListItem
            badge={unreadMessages > 0 ? unreadMessages : null}
            href="/rooms/"
            reloadAll={true}
            view={".view-main"}
            panelClose
          >
            <div className="item-title-row">
              <Icon
                slot="inner-start"
                ios="f7:chat_bubble_2"
                aurora="f7:chat_bubble_2"
                md="material:chat_bubble_text"
              ></Icon>
              <div className="item-title">{localeStrings.tab_left_chat}</div>
            </div>
          </ListItem>
        );
      }

      let documents = null;
      if (process.env.REACT_APP_DOCUMENTS_ENABLED === "true") {
        documents = (
          <ListItem
            href="/documents/"
            reloadAll={true}
            view={".view-main"}
            panelClose
          >
            <div className="item-title-row">
              <Icon
                slot="inner-start"
                ios="f7:paperclip"
                aurora="f7:paperclip"
                md="material:attach_file"
              ></Icon>
              <div className="item-title">
                {localeStrings.tab_left_documents}
              </div>
            </div>
          </ListItem>
        );
      }

      let recents = null;
      if (process.env.REACT_APP_RECENTS_ENABLED === "true") {
        recents = (
          <ListItem
            href="/recents/"
            reloadAll={true}
            view={".view-main"}
            panelClose
          >
            <div className="item-title-row">
              <Icon
                slot="inner-start"
                ios="f7:clock"
                aurora="f7:clock"
                md="material:query_builder"
              ></Icon>
              <div className="item-title">{localeStrings.tab_left_recents}</div>
            </div>
          </ListItem>
        );
      }

      let settings = null;
      if (process.env.REACT_APP_SETTINGS_ENABLED === "true" && isBrowser) {
        settings = (
          <ListItem
            href="/settings/"
            reloadAll={true}
            view={".view-main"}
            panelClose
          >
            <div className="item-title-row">
              <Icon slot="inner-start" md="material:settings"></Icon>
              <div className="item-title">
                {localeStrings.tab_left_settings}
              </div>
            </div>
          </ListItem>
        );
      }

      let credits = null;
      if (process.env.REACT_APP_CREDITS_ENABLED === "true") {
        credits = (
          <ListItem
            href="/credits/"
            reloadAll={true}
            view={".view-main"}
            panelClose
          >
            <div className="item-title-row">
              <Icon
                slot="inner-start"
                ios="f7:info_circle"
                aurora="f7:info_circle"
                md="material:info_outline"
              ></Icon>
              <div className="item-title">{localeStrings.tab_left_credits}</div>
            </div>
          </ListItem>
        );
      }

      let addressBook = null;
      if (Commons.addressBookEnabled()) {
        addressBook = (
          <ListItem
            href="/addressbook/"
            reloadAll={true}
            view={".view-main"}
            panelClose
          >
            <div className="item-title-row">
              <Icon
                slot="inner-start"
                ios={Icons.ADDRESSBOOK_IOS}
                aurora={Icons.ADDRESSBOOK_AURORA}
                md={Icons.ADDRESSBOOK_ANDROID}
              ></Icon>
              <div className="item-title">
                {localeStrings.tab_left_addressbook}
              </div>
            </div>
          </ListItem>
        );
      }

      let homeIcon = "home";
      if (process.env.REACT_APP_VIDEOCALL_ENABLED !== "true") {
        homeIcon = "chat_bubble_text";
      }
      leftMenu = (
        <Panel
          id="menu-left"
          left
          reveal
          resizable
          className="menu-panel"
          data-testid="menu-left"
        >
          <View>
            <Page>
              <Block textAlign="center" className="intro">
                <Link
                  panelClose
                  tabLink="#tab-4"
                  style={{ width: "100%" }}
                  href="/profile/"
                  reloadAll={true}
                  view={".view-main"}
                >
                  <Row style={{ width: "100%" }}>
                    <Col width="30" style={{ textAlign: "right" }}>
                      <img
                        alt={
                          RaStorage.getProfile() && RaStorage.getProfile().Name
                            ? RaStorage.getProfile().Name
                            : "N.A."
                        }
                        src={RaUI.getAvatar(
                          RaStorage.getProfile()
                            ? RaStorage.getProfile().Name
                            : "",
                          "#EFEFF4",
                          "#212121"
                        )}
                        width="48"
                        style={{ borderRadius: "50%" }}
                      />
                    </Col>
                    <Col width="70">
                      <strong style={{ display: "block", fontSize: "120%" }}>
                        {RaStorage.getProfile() && RaStorage.getProfile().Name
                          ? RaStorage.getProfile().Name
                          : "N.A."}
                      </strong>
                      <div
                        style={{
                          display:
                            RaStorage.getProfile() &&
                            RaStorage.getProfile().Company
                              ? "block"
                              : "none",
                        }}
                      >
                        {RaStorage.getProfile() &&
                        RaStorage.getProfile().Company
                          ? RaStorage.getProfile().Company.Name
                          : null}
                      </div>
                    </Col>
                  </Row>
                </Link>
              </Block>
              <List className="components-list searchbar-found menu-list">
                <ListItem
                  href="/main/"
                  reloadAll={true}
                  view={".view-main"}
                  panelClose
                >
                  <div className="item-title-row">
                    <Icon
                      slot="inner-start"
                      ios={"f7:" + homeIcon}
                      aurora={"f7:" + homeIcon}
                      md={"material:" + homeIcon}
                    ></Icon>
                    <div className="item-title">
                      {localeStrings.tab_left_home}
                    </div>
                  </div>
                </ListItem>
                {chat}
                {recents}
                {documents}
                {addressBook}
                {cameraSettings}
                {settings}
                <ListItem
                  external={true}
                  target="_blank"
                  href={
                    this.props.appCompany
                      ? this.props.appCompany.PrivacyUrl
                      : null
                  }
                  panelClose
                >
                  <div className="item-title-row">
                    <Icon
                      slot="inner-start"
                      ios="f7:info_circle"
                      aurora="f7:info_circle"
                      md="material:info_outline"
                    ></Icon>
                    <div className="item-title">
                      {localeStrings.tab_left_privacy}
                    </div>
                  </div>
                </ListItem>
                {credits}
              </List>
            </Page>
          </View>
        </Panel>
      );
    }

    return leftMenu;
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    contactRooms: state.remoteAssistance.contactRooms,
    appCompany: state.remoteAssistance.appCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(MenuLeft);
