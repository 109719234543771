import React from "react";
import {
  BlockTitle,
  Button,
  Card,
  CardContent,
  CardFooter,
  Preloader,
} from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./invitation-email-exists-local";
import ProfileInfo from "../profile/profile-info";

import { RaApiAddressBook } from "../../ra-lib/api/addressbook";
import { RaLog } from "../../ra-lib/log";

class InvitationEmailExists extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addingToAddressbook: false,
      errorMessage: null,
    };
  }

  render() {
    const contact = this.props.contact;

    const preloader =
      this.state.addingToAddressbook === true ? (
        <Preloader size={28} style={{ position: "absolute" }}></Preloader>
      ) : null;

    const errorMessage = this.state.errorMessage ? (
      <div style={{ color: "red" }}>{localeStrings.api_errorMessage}</div>
    ) : null;

    return (
      <div style={{ clear: "both", textAlign: "center" }}>
        <BlockTitle
          style={{
            whiteSpace: "normal",
          }}
        >
          {localeStrings.existingContact_cardHeader}
        </BlockTitle>
        <Card style={{ display: "inline-block", textAlign: "initial" }}>
          <CardContent style={{ padding: "40px 15px 40px 5px" }}>
            <ProfileInfo contact={contact}></ProfileInfo>
          </CardContent>
          <CardFooter>
            <Button
              raised
              fill
              style={{ width: "100%" }}
              onClick={() => {
                this.addToAddressbook(contact);
              }}
            >
              {localeStrings.existingContact_addButton}
            </Button>
          </CardFooter>
        </Card>
        {preloader}
        {errorMessage}
      </div>
    );
  }

  addToAddressbook = (contact) => {
    if (this.state.addingToAddressbook === true) {
      return;
    }

    if (contact) {
      this.setState({ addingToAddressbook: true, errorMessage: null });
      let errorMessage = null;
      let self = this;
      RaApiAddressBook.addToAddressBook(contact)
        .then((response) => {
          if (response.result.success === true) {
            setTimeout(() => {
              //chiamo al funzione onContactAdded del parent passando il risultato
              if (self.props.onContactAdded) {
                self.props.onContactAdded(response.data);
              }
            }, 100);
          } else {
            errorMessage = localeStrings.api_errorMessage;
            RaLog.error(response.result.message);
          }
        })
        .catch((error) => {
          errorMessage = localeStrings.api_errorMessage;
          RaLog.error(error);
        })
        .finally(() => {
          self.setState({
            addingToAddressbook: false,
            errorMessage: errorMessage,
          });
        });
    }
  };

  componentDidMount = () => {
    // Commons.checkAuthentication(this, this.props.f7router);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //  Commons.checkAuthentication(this, this.props.f7router);
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationEmailExists);
