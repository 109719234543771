import React from "react";
import f7 from 'framework7';
import {
  List,
  ListItem,
  Col,
  Block,
  Preloader,
  ListIndex,
  ListGroup,
  Popover,
  Searchbar,
  f7 as f7React,
  theme
} from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./contacts-local";
import Contact from "./contact";

import { CONTACT_TYPE } from "../../ra-lib/api/contacts";
import { RaApiAddressBook } from "../../ra-lib/api/addressbook";

// import moment from 'moment-timezone';

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: null,
      selectedContact: null,
      updating: false,
    };
  }

  render() {
    if (this.props.userAddressBook === null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center contacts">
          <Col>
            <Preloader size={28}></Preloader>
          </Col>
        </Block>
      );
    }

    const loadingPreloader =
      this.state.updating === true ? (
        <Block
          className="row demo-preloaders align-items-stretch text-align-center spinner"
          style={{
            marginTop: "50px",
            position: "fixed",
            width: "100%",
            zIndex: 999,
          }}
        >
          <Col>
            <Preloader size={28} style={{ marginTop: "30px" }}></Preloader>
          </Col>
        </Block>
      ) : null;

    if (this.props.userAddressBook.length === 0) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center">
          <Col>{localeStrings.no_contacts}</Col>
          {loadingPreloader}
        </Block>
      );
    }

    return (
      <div>
        {loadingPreloader}

        <ListIndex
          indexes="auto"
          listEl=".list.contacts-list"
          scrollList={true}
          label={true}
        ></ListIndex>
        {this.getContacts()}
        <Popover
          className="popover-contact"
          onPopoverClose={(e) => {
            this.setState({ selectedContact: null });
          }}
        >
          <Contact
            contact={this.state.selectedContact}
            onCloseClick={() => {
              this.setState({ selectedContact: null });
            }}
            onRemoveContactFromAddressBook={this.removedContactFromAddressBook}
          ></Contact>
        </Popover>
      </div>
    );
  }

  removedContactFromAddressBook = () => {
    f7React.popup.close(f7.$(".popover-contact"), true);
    this.setState({ selectedContact: null });
  };

  updateAddressbook = () => {
    if (this.state.updating === true) {
      return;
    }

    this.setState({ updating: true });
    let self = this;
    RaApiAddressBook.getAddressBook().then(() => {
      self.setState({ updating: false });
    });
  };

  onIndexSelect(itemContent, el) {
    this.setState({ selectedContact: itemContent });
    if (this.props.onContactSelected) {
      this.props.onContactSelected(itemContent, el);
    }
  }

  getContacts = () => {
    if (
      this.props.userAddressBook === null ||
      this.props.userAddressBook.length === 0
    ) {
      return null;
    }

    const getGroupContact = (firstLetter) => {
      let contacts = [];
      this.props.userAddressBook.forEach((item) => {
        if (item.Name.indexOf(firstLetter) === 0) {
          if (this.props.noGroups !== true || item.Type === CONTACT_TYPE.USER) {
            contacts.push(
              <ListItem
                key={"contact_" + item.UniqueId}
                onClick={() => {
                  this.onIndexSelect(item, "contact_" + item.UniqueId);
                }}
                title={item.Name}
                footer={
                  (item.Company != null
                    ? item.Company.Name + (item.CompanyRole ? " - " : "")
                    : "") + item.CompanyRole
                }
                href="#"
                popoverOpen=".popover-contact"
                className="no-chevron"
              ></ListItem>
            );
          }
        }
      });

      return contacts;
    };

    let lastLetter = null;
    let groups = [];
    this.props.userAddressBook.forEach((item) => {
      let firstLetter = item.Name.substring(0, 1);
      if (groups.length === 0 || lastLetter !== firstLetter) {
        if (this.props.noGroups !== true || item.Type === CONTACT_TYPE.USER) {
          groups.push(
            <ListGroup key={"group_" + firstLetter}>
              <ListItem
                title={firstLetter}
                groupTitle
                key={"item_" + firstLetter}
              ></ListItem>
              {getGroupContact(firstLetter)}
            </ListGroup>
          );
        }

        lastLetter = firstLetter;
      }
    });

    return (
      <List contactsList className={this.props.className}>
        <Searchbar
          slot="before-list"
          className="searchbar"
          searchContainer={"." + this.props.className}
          searchIn=".item-title"
          disableButton={!theme.aurora}
          placeholder={localeStrings.search_placeholder}
        ></Searchbar>

        {groups}
      </List>
    );
  };

  componentDidMount = () => {};

  componentDidUpdate = () => {};
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    userAddressBook: state.remoteAssistance.userAddressBook,
  };
};

const mapDispatchToProps = () => {
  return {
    //    setToken: (token) => dispatch(RaReduxActions.setToken(token)),
  };
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
