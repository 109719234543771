import { RaApiNet } from "../api/net";
import { RaCordovaPayments } from "../cordova/payments";
import { Ra } from "../index";
import { RaLog } from "../log";

export class RaApiPayments {
  static getBrainTreeToken = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/payments?token",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  /**
   * es paymentMethodNonce:
   * {
   *    cardType: "PayPal"
   *    description: "bt_buyer_us@paypal.com"
   *    nonce: "c13b8e71-4d06-07db-5fed-d66838a71bbe"
   * }
   *
   * {
   *    cardType: "Visa"
   *    description: "ending in ••11"
   *    nonce: "tokencc_bh_trgrp6_nd4533_bmfz65_nfpg9m_zn5"
   * }
   *
   */
  static getPaymentMethodNonce = (token) => {
    return new Promise((resolve, reject) => {
      RaCordovaPayments.askPaymentMethodNonce(token)
        .then((paymentMethodNonce) => {
          resolve(paymentMethodNonce);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  static getPaymentMethod = () => {
    return new Promise((resolve, reject) => {
      RaApiPayments.getBrainTreeToken()
        .then(function (response) {
          if (response.result.success === true) {
            brainTreeTokenSuccessFn(response.data);
          } else {
            brainTreeTokenErrorFn(response.result.message);
          }
        })
        .catch(function (response) {
          brainTreeTokenErrorFn(response.message);
        });

      const brainTreeTokenErrorFn = (errorMessage) => {
        reject(new Error(errorMessage));
      };

      const brainTreeTokenSuccessFn = (token) => {
        RaApiPayments.getPaymentMethodNonce(token)
          .then(function (paymentMethodNonce) {
            RaLog.log("PaymentMethodNonce ", paymentMethodNonce);
            paymentMethodNonceSuccessFn(paymentMethodNonce);
          })
          .catch(function (response) {
            paymentMethodNonceErrorFn(response.message);
          });
      };

      const paymentMethodNonceErrorFn = (errorMessage) => {
        reject(new Error(errorMessage));
      };

      const paymentMethodNonceSuccessFn = (paymentMethodNonce) => {
        resolve(paymentMethodNonce);
      };
    });
  };

  static sendPayment = (productCode, paymentMethodNonceToken) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("productCode", productCode);
      bodyFormData.set("payment_method_nonce", paymentMethodNonceToken);

      RaApiNet.callApi({
        method: "post",
        url: Ra.getConfig().apiUrl + "/services/payments",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getPayments = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/payments",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default RaApiPayments;
