import React from "react";
import f7 from "framework7";
import {
  Link,
  Popover,
  PageContent,
  List,
  ListItem,
  BlockTitle,
  Popup,
  Progressbar,
  Navbar,
  NavRight,
  NavLeft,
  ListGroup,
  Page,
  f7 as f7React,
} from "framework7-react";
import { connect } from "react-redux";
import moment from "moment-timezone";
//import initReactFastclick from 'react-fastclick';

import { RaApiDocuments } from "../../ra-lib/api/documents";
import { RaApiAuth } from "../../ra-lib/api/auth";
import { RaLog } from "../../ra-lib/log";

import localeStrings from "./documents-local";
import Commons from "../../Commons";
import Icons from "../../icons";
import MenuToolbar from "../../components/menu/toolbar";
import DocumentsFilter from "./documents-filter";
import AppPageComponent from "../../components/commons/app-page-component";
import LogoutLoader from "../../components/logout/logout-loader";
import RedirectEvents from "../../components/commons/redirect-events";

//import RouterModule from 'framework7/modules/router/router';

class Documents extends AppPageComponent {
  constructor(props) {
    super(props);

    this.state = {
      documentsNotOrdered: null,
      documents: null,
      errormessage: null,
      spinner: false,
      showFiltersPopup: false,
    };
    this.fetchingDocs = false;
  }

  render() {
    let pageContent = null;

    if (!RaApiAuth.isAuthenticated()) {
      pageContent = <LogoutLoader router={this.props.f7router}></LogoutLoader>;
    } else if (this.state.errormessage !== null) {
      console.error(this.state.errormessage);
      pageContent = (
        <div className="row demo-preloaders align-items-stretch text-align-center">
          {localeStrings.list_error}
        </div>
      );
    } else if (this.state.spinner === true || this.state.documents === null) {
      pageContent = (
        <div style={{ textAlign: "center", width: "100%", paddingLeft: "25%" }}>
          <div style={{ width: "50%" }}>
            <BlockTitle>{localeStrings.loading}</BlockTitle>
            <p>
              <Progressbar infinite></Progressbar>
            </p>
          </div>
        </div>
      );
    } else {
      pageContent = this.getDocuments();
    }

    const portrait = Commons.isPortrait(f7);

    let navLeft = null;
    if (portrait) {
      navLeft = (
        <NavLeft>
          <Link
            panelOpen="left"
            iconIos={Icons.MENULEFT_IOS}
            iconAurora={Icons.MENULEFT_AURORA}
            iconMd={Icons.MENULEFT_ANDROID}
          ></Link>
        </NavLeft>
      );
    }

    return (
      <Page pageContent={false} colorTheme="custom">
        <Navbar
          noShadow={true}
          noHairline={true}
          large
          largeTransparent
          sliding={false}
        >
          {navLeft}
          <NavRight>
            <Link
              iconIos={Icons.FILTERS_IOS}
              iconAurora={Icons.FILTERS_AURORA}
              iconMd={Icons.FILTERS_ANDROID}
              onClick={(contact) => {
                this.setState({ showFiltersPopup: true });
              }}
            ></Link>
          </NavRight>
        </Navbar>

        <PageContent style={{ paddingTop: "60px" }}>
          <div key="content">{pageContent}</div>
          <Popup
            id="filters-popup"
            className="filters-popup ra-popup"
            opened={this.state.showFiltersPopup === true}
            onPopupClosed={(e) => {
              this.setState({ showFiltersPopup: false });
            }}
          >
            <DocumentsFilter
              onCloseFiltersPopup={this.closeFiltersPopupClickHandler}
              onApplyFilters={this.applyFiltersHandler}
            ></DocumentsFilter>
          </Popup>
        </PageContent>
        <MenuToolbar router={this.props.f7router}></MenuToolbar>
        <RedirectEvents router={this.props.f7router}></RedirectEvents>
      </Page>
    );
  }

  applyFiltersHandler = (filters) => {
    RaLog.log(filters);
    this.setState({
      documents: [],
      documentsNotOrdered: [],
      showFiltersPopup: false,
      spinner: true,
    });
    this.fetchDocuments(filters);
  };

  closeFiltersPopupClickHandler = () => {
    this.setState({ showFiltersPopup: false });
  };

  getDocuments = () => {
    if (!this.state.documents || this.state.documentsNotOrdered.length === 0) {
      return <div className="no-contents-message">{localeStrings.no_docs}</div>;
    }

    const getContactName = (document) => {
      let contactId = document.SenderUniqueId;
      if (contactId === this.props.profile.UniqueId) {
        contactId = document.RecipientUniqueId;
      }

      let contact = Commons.getContact(contactId, this.props.userAddressBook);
      if (contact) {
        return contact.Name;
      } else {
        return contactId;
      }
    };

    let docscards = [];
    Object.keys(this.state.documents).forEach((key) => {
      const docslist = this.state.documents[key].map((item, index) => {
        return (
          <ListItem
            className="long-touch"
            external
            target="_blank"
            key={"doc-" + item.FileName}
            id={this.getDocumentId(item.Document.FileName)}
            title={item.FileName}
            text={getContactName(item)}
            onClick={() => {
              this.documentClickHandler(item.Document);
            }}
          >
            <Link
              popoverOpen={".popover-menu-" + item.UniqueId}
              style={{ float: "left", padding: "12px" }}
              key={"doc-icon-" + item.UniqueId}
              id={item.UniqueId}
              slot="root-start"
              iconIos={Icons.OPTIONS_IOS}
              iconAurora={Icons.OPTIONS_AURORA}
              iconMd={Icons.OPTIONS_ANDROID}
              onClick={this.optionMenuClickHandler}
            ></Link>
          </ListItem>
        );
      });

      const docsPopover = this.state.documents[key].map((item, index) => {
        return (
          <Popover
            className={"popover-menu-" + item.UniqueId}
            key={"popover-menu-key-" + item.UniqueId}
          >
            <List>
              <ListItem
                style={{ display: "none" }}
                link="#"
                popoverClose
                title="Rename"
                key={"rename-key-" + item.UniqueId}
                onClick={this.renameFile}
              />
              <ListItem
                link="#"
                popoverClose
                title="Delete"
                key={"delete-key-" + item.UniqueId}
                onClick={this.deleteFile}
              />
            </List>
          </Popover>
        );
      });

      docscards.push(
        <div key={"key-" + key} className={key}>
          <List style={{ marginTop: 0 }} mediaList>
            <ListGroup title={key} key={"group-key-" + key}></ListGroup>
            <ListItem key={"title-" + key} title={key} groupTitle></ListItem>
            {docslist}
          </List>
          {docsPopover}
        </div>
      );
    });

    return <div>{docscards}</div>;
  };

  renameFile = (e) => {
    alert("rename: " + this._selectedItem);
  };

  deleteFile = (e) => {
    f7React.dialog.confirm(
      localeStrings.delete_confirm_message,
      localeStrings.delete_confirm_button_yes,
      () => {
        this.setState({ spinner: true });
        RaApiDocuments.deleteDocument(this._selectedItem)
          .then(function (response) {
            if (response.result.success === true) {
              successFn();
            } else {
              errorFn(response.result.message);
            }
          })
          .catch(function (response) {
            errorFn(response.message);
          });

        const errorFn = (errorMsg) => {
          RaLog.log(errorMsg);
        };

        const successFn = () => {
          let documents = this.state.documentsNotOrdered;
          this.state.documentsNotOrdered.forEach((userDocument, index) => {
            if (userDocument.UniqueId === this._selectedItem) {
              remove(documents, index);
            }
          });

          let docs = documents.map((item, index) => {
            return {
              date: moment(item.Date).tz("Europe/Rome").format("DD/MM/YYYY"),
              document: item,
              spinner: false,
            };
          });

          let docsbydate = [];
          if (docs && docs.length > 0) {
            for (const doc of docs) {
              if (typeof docsbydate[doc.date] === "undefined") {
                docsbydate[doc.date] = [doc.document];
              } else {
                docsbydate[doc.date].push(doc.document);
              }
            }
          }
          this.setState({
            documentsNotOrdered: documents,
            documents: docsbydate,
            errormessage: null,
            spinner: false,
          });
          /*this.state.document
           */
        };

        const remove = (array, index) => {
          if (index !== -1) {
            array.splice(index, 1);
          }
        };
      }
    );
  };

  getDocumentId = (documentId) => {
    return "doc_" + documentId;
  };
  static _selectedItem = null;

  documentClickHandler = (document) => {
    let self = this;
    RaLog.log("docClicked", document);
    if (document.FileName) {
      RaLog.log("is attachment");
      self.setMessageFooter(self, document.FileName, "downloading...");

      RaApiDocuments.openStorageFile(document.FileName).then((success) => {
        if (success) {
          self.removeMessageFooter(self, document.FileName);
        } else {
          self.setMessageFooter(self, document.FileName, "download error");
        }
      });
    }
  };

  removeMessageFooter = (self, documentId) => {
    let itemDiv = document.getElementById(self.getDocumentId(documentId));
    if (itemDiv) {
      let titleDiv = itemDiv.getElementsByClassName("item-title");
      if (titleDiv.length > 0) {
        let footerDiv = titleDiv[0].getElementsByClassName("item-footer");
        if (footerDiv.length > 0) {
          footerDiv[0].remove();
        }
      }
    }
  };

  setMessageFooter = (self, documentId, text) => {
    let itemDiv = document.getElementById(self.getDocumentId(documentId));
    if (itemDiv) {
      let titleDiv = itemDiv.getElementsByClassName("item-title");
      if (titleDiv.length > 0) {
        let footerDiv = titleDiv[0].getElementsByClassName("item-footer");
        if (footerDiv.length > 0) {
          footerDiv[0].innerText = text;
        } else {
          titleDiv[0].insertAdjacentHTML(
            "beforeend",
            '<div class="item-footer">' + text + "</div>"
          );
        }
      }
    }
  };

  optionMenuClickHandler = (e) => {
    this._selectedItem = e.currentTarget.id;
  };

  tapHoldHandler = (e) => {
    this._selectedItem = e.currentTarget.id;
  };

  componentDidMount = () => {
    Commons.checkAuthentication(this, this.props.f7router);
    this.setState({ spinner: true });

    if (this.state.documents === null) {
      this.fetchDocuments();
    }
  };

  componentDidUpdate = () => {
    Commons.checkAuthentication(this, this.props.f7router);
    if (this.state.documents === null) {
      let self = this;
      setTimeout(function () {
        self.fetchDocuments();
      }, 1000);
    }
    f7.$(".long-touch").off("taphold", this.tapHoldHandler);
    f7.$(".long-touch").on("taphold", this.tapHoldHandler);
  };

  fetchDocuments = (filters) => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          documentsNotOrdered: [],
          documents: [],
          errormessage: errorMsg,
          spinner: false,
        });
      }
    };

    const successFn = (documents) => {
      let docs = documents.map((item, index) => {
        return {
          date: moment(item.Date).tz("Europe/Rome").format("DD/MM/YYYY"),
          document: item,
          spinner: false,
        };
      });
      let docsbydate = [];
      if (docs && docs.length > 0) {
        for (const doc of docs) {
          if (typeof docsbydate[doc.date] === "undefined") {
            docsbydate[doc.date] = [doc.document];
          } else {
            docsbydate[doc.date].push(doc.document);
          }
        }
      }
      if (
        this.state.errormessage ||
        this.state.spinner === true ||
        JSON.stringify(documents) !==
          JSON.stringify(this.state.documentsNotOrdered)
      ) {
        this.setState({
          documentsNotOrdered: documents,
          documents: docsbydate,
          errormessage: null,
          spinner: false,
        });
      }
    };

    let self = this;
    if (self.fetchingDocs !== true) {
      self.fetchingDocs = true;
      RaApiDocuments.getDocuments(filters)
        .then(function (response) {
          //handle success
          //RaLog.log(response);

          if (response.result.success === true) {
            // handle success
            successFn(response.data);
          } else {
            // handle error
            errorFn(response.result.message);
          }
        })
        .catch(function (errorMessage) {
          //handle error
          errorFn(errorMessage);
        })
        .then(() => {
          self.fetchingDocs = false;
        });
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    userAddressBook: state.remoteAssistance.userAddressBook,
    profile: state.remoteAssistance.profile,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     cambiaTestoDummy: (testo) => dispatch(actions.cambiaTestoDummy(testo)),
//   }
// };

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps)(Documents);
