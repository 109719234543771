import React from "react";
import {
  Block,
  PageContent,
  Page,
  Col,
  NavLeft,
  Link,
  Navbar,
  NavTitle,
} from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./invitation-local";
import Icons from "../../icons";
import InvitationEmailCheck from "./invitation-email-check";
import InvitationEmailExists from "./invitation-email-exists";
import InvitationAdd from "./invitation-add";

class Invitation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailChecked: false,
      email: null,
      existingContact: null,
    };
  }

  render() {
    let pageContent = this.state.errormessage ? (
      <Block className="row demo-preloaders align-items-stretch text-align-center">
        <Col>
          <h3>{localeStrings.list_error}</h3>
        </Col>
      </Block>
    ) : (
      this.getPageContent()
    );

    const navLeftLink =
      this.state.emailChecked === false ? (
        <Link
          back
          iconIos={Icons.CLOSE_IOS}
          iconAurora={Icons.CLOSE_AURORA}
          iconMd={Icons.CLOSE_ANDROID}
          onClick={() => {
            if (this.props.onCloseClick) {
              this.props.onCloseClick();
            }
          }}
        ></Link>
      ) : (
        <Link
          back
          iconIos={Icons.BACK_IOS}
          iconAurora={Icons.BACK_AURORA}
          iconMd={Icons.BACK_ANDROID}
          onClick={() => {
            this.setState({ emailChecked: false, existingContact: null });
          }}
        ></Link>
      );

    return (
      <Page pageContent={false} colorTheme="custom">
        <Navbar noShadow={true} noHairline={true} sliding={false}>
          <NavLeft>{navLeftLink}</NavLeft>
          <NavTitle>{localeStrings.title}</NavTitle>
        </Navbar>

        <PageContent>{pageContent}</PageContent>
      </Page>
    );
  }

  getPageContent = () => {
    const invitationEmailCheck =
      this.state.emailChecked === true ? null : (
        <InvitationEmailCheck
          onEmailChecked={(result, email) => {
            this.onEmailChecked(result, email);
          }}
        ></InvitationEmailCheck>
      );

    const existingContactCard =
      this.state.emailChecked === true && this.state.existingContact != null ? (
        <InvitationEmailExists
          onContactAdded={this.onContactAdded}
          contact={this.state.existingContact}
        ></InvitationEmailExists>
      ) : null;

    const submitInvitation =
      this.state.emailChecked === true && this.state.existingContact == null ? (
        <InvitationAdd
          onInvitationSubmitted={this.onInvitationSubmitted}
          email={this.state.email}
        ></InvitationAdd>
      ) : null;

    return (
      <div>
        {invitationEmailCheck}
        {existingContactCard}
        {submitInvitation}
      </div>
    );
  };

  onInvitationSubmitted = (invitation) => {
    this.setState({ emailChecked: false, existingContact: null, email: null });
    if (this.props.onInvitationSubmitted) {
      this.props.onInvitationSubmitted(invitation);
    }
  };

  onEmailChecked = (contact, email) => {
    this.setState({
      emailChecked: true,
      existingContact: contact,
      email: email,
    });
  };

  onContactAdded = () => {
    this.setState({ emailChecked: false, existingContact: null, email: null });
    if (this.props.onContactAdded) {
      this.props.onContactAdded();
    }
  };

  componentDidMount = () => {
    // Commons.checkAuthentication(this, this.props.f7router);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //  Commons.checkAuthentication(this, this.props.f7router);
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Invitation);
