import { RaLog } from "../../log";
import { RaApiNet } from "../../api/net";
import { Ra } from "../../index";
import { RaStorage } from "../../storage";
import { RaStore } from "../../redux/store";
import { RaCommons } from "../../commons";
import { RaCrypto } from "../../crypto/crypto";
import { RaApiRoomsParticipants } from "../../api/rooms/participants";
import { RaApiContacts } from "../../api/contacts";
import { RaApiRooms } from "../../api/rooms/rooms";
import { RaReduxActions } from "../../redux/actions";

export class RaApiRoomsMessages {
  static GetDecryptedMessage = (message) => {
    if (!message.decrypted) {
      message.Message = RaCrypto.decrypt(message.Message, message.CryptoSchema);
      message.decrypted = true;
    }

    if (!message.Message) {
      message.Message = "";
    }

    return message;
  };

  static getMessages = (roomUniqueId, toId, fromId, progressCallback) => {
    return new Promise((resolve, reject) => {
      let queryParams = [];

      if (toId) queryParams.push("toId=" + toId);
      if (fromId) queryParams.push("fromId=" + fromId);
      queryParams.push("limit=" + Ra.getConfig().messagesPerPage);

      RaApiNet.callApi({
        method: "get",
        url:
          Ra.getConfig().apiUrl +
          "/services/rooms/" +
          roomUniqueId +
          "/messages" +
          (queryParams.length > 0 ? "?" + queryParams.join("&") : ""),
      })
        .then(function (response) {
          if (response.result.success === true) {
            let messagesToDecrypt = [...response.data];

            let storedMessages = null;
            //se sto caricando gli ultimi messaggi prendo i messaggi già salvati e decriptati, se ne ho in comune evito di decriptare nuovamente i messaggi
            if (!toId) {
              storedMessages = RaStorage.getMessages(roomUniqueId);
              if (storedMessages && storedMessages.length > 0) {
                let storedMessagesFirstId = storedMessages[0].UniqueId;
                for (let i = 0; i < messagesToDecrypt.length; i++) {
                  if (messagesToDecrypt[i].UniqueId === storedMessagesFirstId) {
                    //da questo indice in avanti utilizzerò i messaggi già salvati, quindi li rimuovo dall'array dei messaggi da decriptare
                    messagesToDecrypt.splice(i);
                    break;
                  }
                }
              }
            }

            RaCommons.changeArrayItemsAsync(
              messagesToDecrypt,
              (message) => {
                return RaApiRoomsMessages.GetDecryptedMessage(message);
              },
              1,
              progressCallback
            )
              .then((decryptedMessages) => {
                response.data = storedMessages
                  ? decryptedMessages.concat(storedMessages)
                  : decryptedMessages;
                if (!toId) {
                  //se sono gli ultimi messaggi ricevuti li salvo (non lo faccio se è una pagina precedente)
                  RaStorage.setMessages(roomUniqueId, response.data);
                }
                resolve(response);
              })
              .catch(function (errorMessage) {
                reject(new Error(errorMessage));
              });
          } else {
            resolve(response);
          }
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static sendMessage = (
    roomUniqueId,
    message,
    file,
    participantsIdentities
  ) => {
    const send = (roomUniqueId, message, file, participantsIdentities) => {
      return new Promise((resolve, reject) => {
        if (!participantsIdentities || participantsIdentities.length === 0) {
          reject("there are no participants");
        }

        const bodyFormData = new FormData();
        bodyFormData.set(
          "message",
          RaCrypto.encrypt(message, participantsIdentities)
        );
        if (file) {
          let headerSeparator = file.base64.indexOf(",");
          if (headerSeparator) {
            file.base64 = file.base64.substring(headerSeparator + 1);
          }
          bodyFormData.set(
            "base64file",
            RaCrypto.encrypt(file.base64, participantsIdentities)
          );
          bodyFormData.set("fileName", file.fileName);
        }
        bodyFormData.set("cryptoschema", Ra.getConfig().cryptoSchema);

        RaApiNet.callApi({
          method: "post",
          url:
            Ra.getConfig().apiUrl +
            "/services/rooms/" +
            roomUniqueId +
            "/messages",
          data: bodyFormData,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
          .then(function (response) {
            if (response.result.success === true) {
              response.data = RaApiRoomsMessages.GetDecryptedMessage(
                response.data
              );
              RaApiRoomsMessages.incomingMessageNotification(
                response.data
              ).then(() => {
                resolve(response);
              });
            } else {
              resolve(response);
            }
          })
          .catch(function (errorMessage) {
            reject(new Error(errorMessage));
          });
      });
    };

    return new Promise((resolve, reject) => {
      //se non devo crittografare o mi è già stata ata la lista dei partecipanti (per crittografare) invio il messaggio
      if (
        Ra.getConfig().cryptoSchema === RaCrypto.CRYPTOSCHEMA_NONE ||
        (participantsIdentities && participantsIdentities.length > 0)
      ) {
        send(roomUniqueId, message, file).then(resolve).catch(reject);
      }
      //altrimenti leggo i partecipanti, poi invio
      else {
        RaApiRoomsParticipants.getParticipants(roomUniqueId).then(
          (response) => {
            if (response.result.success === true) {
              let participantsIdentities = [];
              response.data.forEach((participant) => {
                participantsIdentities.push(
                  RaApiContacts.getContactIdentities(
                    participant.Contact.UniqueId,
                    participant.Contact.PublicKey
                  )
                );
              });
              send(roomUniqueId, message, file, participantsIdentities)
                .then(resolve)
                .catch(reject);
            } else {
              reject(response.result.message);
            }
          }
        );
      }
    });
  };

  static _getLastStoredMessageId = (roomUniqueId) => {
    let lastStoredMessageId = null;
    let storedMessages = RaStorage.getMessages(roomUniqueId);
    if (storedMessages && storedMessages.length > 0) {
      lastStoredMessageId = storedMessages[0].UniqueId;
    }
    return lastStoredMessageId;
  };

  static incomingMessageNotification = (message) => {
    return new Promise((resolve, reject) => {
      //salvo in locale tutti i messaggi della chat (nel limite di messagesPerPage, dopo l'ultimo salvato)
      let lastStoredMessageId = RaApiRoomsMessages._getLastStoredMessageId(
        message.RoomUniqueId
      );
      RaApiRoomsMessages.getMessages(
        message.RoomUniqueId,
        null,
        lastStoredMessageId,
        null
      ).then(() => {
        //aggiorno i dati della stanza a cui fa riferimento il messaggio e la ordino nell'array
        let rooms =
          RaStore.getState().contactRooms !== null
            ? [...RaStore.getState().contactRooms]
            : [];
        let messageRoomIndex = RaCommons.getElementIndexFromUniqueId(
          rooms,
          message.RoomUniqueId
        );
        if (messageRoomIndex >= 0) {
          if (message.Read === false) {
            rooms[messageRoomIndex].UnreadMessages += 1;
          }
          rooms[messageRoomIndex].LastMessageText = message.Message;
          rooms[messageRoomIndex].LastMessageDate = message.Date;
          RaCommons.moveArrayElement(rooms, messageRoomIndex, 0);
          RaStore.get().dispatch(RaReduxActions.setContactRooms(rooms));
          resolve();
        }
        //se non ho la stanza in locale le ricarico tutte
        else {
          RaApiNet.callApi({
            method: "get",
            url:
              Ra.getConfig().apiUrl + "/services/rooms/" + message.RoomUniqueId,
          })
            .then(function (response) {
              if (response.result.success === true) {
                let rooms =
                  RaStore.getState().contactRooms !== null
                    ? [...RaStore.getState().contactRooms]
                    : [];
                RaCommons.insertAt(
                  rooms,
                  0,
                  RaApiRooms.GetDecryptedRoom(response.data)
                );
                RaStore.get().dispatch(RaReduxActions.setContactRooms(rooms));
              } else {
                RaLog.error(response.result.message);
              }
              resolve();
            })
            .catch(function (errorMessage) {
              RaLog.error(errorMessage);
              resolve();
            });
        }
      });
    });
  };
}

export default RaApiRoomsMessages;
