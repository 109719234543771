import { RaApiNet } from "../api/net";
import { Ra } from "../index";
import { RaStorage } from "../storage";
import { RaApiAuth } from "../api/auth";
import { RaStore } from "../redux/store";
import { RaLog } from "../log";

export class RaApiContacts {
  static getContactPublicKey = (contactUniqueId) => {
    if (
      RaStorage.getProfile() &&
      contactUniqueId === RaStorage.getProfile().UniqueId
    ) {
      return RaStorage.getProfile().PublicKey;
    }

    let addressBook = RaStore.getState().userAddressBook;
    if (addressBook) {
      for (let i = 0; i < addressBook.length; i++) {
        if (addressBook[i].UniqueId === contactUniqueId) {
          return addressBook[i].PublicKey;
        }
      }
    }
    return null;
  };

  static getContactsPublicKeys = (contactsUniqueId) => {
    if (!contactsUniqueId) {
      return null;
    }
    let publicKeys = [];
    contactsUniqueId.forEach((contactUniqueId) => {
      let publicKey = RaApiContacts.getContactPublicKey(contactUniqueId);
      if (publicKey) {
        publicKeys.push(
          RaApiContacts.getContactIdentities(contactUniqueId, publicKey)
        );
      }
    });
    return publicKeys;
  };

  static getContactIdentities = (uniqueId, publicKey) => {
    return { UniqueId: uniqueId, PublicKey: publicKey };
  };

  static updateAvailability = (availability) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("availability", availability);

      RaApiNet.callApi({
        method: "put",
        data: bodyFormData,
        url: Ra.getConfig().apiUrl + "/services/contacts",
      })
        .then(function (response) {
          if (response.result.success === true) {
            RaStorage.setProfile(response.data);
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static sessionId = null;

  static startOnlineSession = () => {
    if (RaApiContacts.sessionId == null) {
      RaApiContacts.sessionId = setInterval(RaApiContacts.sendPing, 30000);
    }
  };

  static sendPing = () => {
    if (RaApiAuth.isAuthenticated()) {
      let profile = RaStorage.getProfile();
      if (profile.Availability === AVAILABILITY.ONLINE) {
        RaApiNet.callApi({
          method: "get",
          url: Ra.getConfig().apiUrl + "/services/contacts?ping",
        }).catch(function (response) {
          RaLog.log("ping response error");
        });
      }
    }
  };

  static doLogout = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "delete",
        url: Ra.getConfig().apiUrl + "/services/contacts",
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getCompanies = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/companies",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static isAssistedContact = (contact) => {
    return (
      contact && contact.Role && contact.Role.Code === CONTACT_ROLE.ASSISTED
    );
  };

  static emailExists = (email) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);

      RaApiNet.callApi({
        method: "post",
        url: Ra.getConfig().apiUrl + "/services/contacts?checkemail",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export const CONTACT_TYPE = {
  USER: 1,
  GROUP: 2,
};

export const CONTACT_ROLE = {
  OPERATOR: "OPERATOR",
  ASSISTED: "ASSISTED",
  ADMIN: "ADMIN",
};

export const AVAILABILITY = {
  ONLINE: 2,
  OFFLINE: 1,
};

export default RaApiContacts;
