import * as CryptoJS from 'crypto-js';

export class RaCryptoAES {
   
    static keySize = 256;
    static ivSize = 128;
    static saltSize = 256;
    static iterations = 1000;


    /*
    * AES hexToBase64
    */
   static hexToBase64 = (str) => {
        let array = str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ");
        let string = '';

        for (let i = 0; i < array.length; i++) {
            string += String.fromCharCode(array[i]);
        }

        return btoa(string);
    }

    /*
    * AES base64ToHex
    */
   static base64ToHex = (str) => {
        for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
            var tmp = bin.charCodeAt(i).toString(16);
            if (tmp.length === 1) tmp = "0" + tmp;
            hex[hex.length] = tmp;
        }
        return hex.join("");
    }

    /*
    * Encript with AES
    */
   static encryptAES = (msg, pass) => {
        var salt = CryptoJS.lib.WordArray.random(RaCryptoAES.saltSize / 8);

        var key = CryptoJS.PBKDF2(pass, salt, {
            keySize: RaCryptoAES.keySize / 32,
            iterations: RaCryptoAES.iterations
        });

        var iv = CryptoJS.lib.WordArray.random(RaCryptoAES.ivSize / 8);

        var encrypted = CryptoJS.AES.encrypt(msg, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC

        });

        var encryptedHex = RaCryptoAES.base64ToHex(encrypted.toString());
        var base64result = RaCryptoAES.hexToBase64(salt + iv + encryptedHex);


        return base64result;
    }

    /*
    * Decrypt with AES
    */
   static decryptAES = (transitmessage, pass) => {

        var hexResult = RaCryptoAES.base64ToHex(transitmessage)

        var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
        var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
        var encrypted = RaCryptoAES.hexToBase64(hexResult.substring(96));

        var key = CryptoJS.PBKDF2(pass, salt, {
            keySize: RaCryptoAES.keySize / 32,
            iterations: RaCryptoAES.iterations
        });

        var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC

        })

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

}
export default RaCryptoAES;