import { RaCordova } from "./cordova/index";
import { RaLog } from "./log";
import { RaStorage } from "./storage";
import { RaNotifications } from "./notifications";

export class RaVoipNotifications {
    static initVoipNotifications = () => {

        RaVoipNotifications.initVoipPlugin();

        const onReceiveCall = (data) => {
            console.log('receiveCall', data)
        }
        window.cordova.plugins.CordovaCall.on('receiveCall', onReceiveCall);

        const onSendCall = (data) => {
            console.log('sendCall', data)
        }
        window.cordova.plugins.CordovaCall.on('sendCall', onSendCall);

        const onMute = (data) => {
            console.log('mute', data)
        }
        window.cordova.plugins.CordovaCall.on('mute', onMute);

        const onUnmute = (data) => {
            console.log('unmute', data)
        }
        window.cordova.plugins.CordovaCall.on('unmute', onUnmute);

        const onSpeakerOn = (data) => {
            console.log('speakerOn', data)
        }
        window.cordova.plugins.CordovaCall.on('speakerOn', onSpeakerOn);

        const onSpeakerOff = (data) => {
            console.log('speakerOff', data)
        }
        window.cordova.plugins.CordovaCall.on('speakerOff', onSpeakerOff);

        const onDTMF = (data) => {
            console.log('DTMF', data)
        }
        window.cordova.plugins.CordovaCall.on('DTMF', onDTMF);
    }

    static checkServiceRegistration = () => {
        return new Promise((resolve, reject) => {
            console.log('voip checkServiceRegistration');

            if (RaCordova.isCordova()) {
                RaVoipNotifications.initVoipPlugin();
            }
            else {
                reject('Cordova NON rilevato');
            }
        });
    }

    static initVoipPlugin = () => {
        var push = window.VoIPPushNotification.init();
        push.on('registration', function (data) {
            const token = data.deviceToken;
            RaStorage.setVoipToken(token);

            if (RaStorage.getVoipToken() != null) {
                RaLog.log("sending VOIP TOKEN to server");
                RaNotifications.sendTokenToServer(RaStorage.getVoipToken(), 'voip')
                    .then(function () {
                        RaLog.log("VOIP TOKEN to server: " + RaStorage.getVoipToken());
                    })
                    .catch(function () {
                        RaLog.log("VOIP TOKEN to server: error");
                    });
            }
        });

        push.on('notification', function (data) {
            RaLog.log("notification callback called", data);

            if (data && data.function) {
                const voipMessage = JSON.parse(data.function.payload);

                const message = {
                    messageType: voipMessage.MessageType,
                    messageContent: {
                        Call: voipMessage.MessageContent.Call,
                        Invitation: voipMessage.MessageContent.Invitation,
                    }
                };

                RaNotifications.handleMessageEvent(message);
            }
        });

        push.on('error', function (e) {
            RaLog.log(e);
        })
    }

    static onAnswerEventHandler = (onAnswer) => {
        window.cordova.plugins.CordovaCall.on('answer', onAnswer);
    }

    static onRejectEventHandler = (onReject) => {
        window.cordova.plugins.CordovaCall.on('reject', onReject);
    }

    static onHangupEventHandler = (onHangup) => {
        window.cordova.plugins.CordovaCall.on('hangup', onHangup);
    }

    static endVoipCall = () => {
        const success = (data) => {
            console.log('endvoipcall success', data);
        }

        const error = (err) => {
            console.log('endvoipcall error', err);
        }

        window.cordova.plugins.CordovaCall.endCall(success, error);
    }

    static speakerOn = () => {
        const success = (data) => {
            console.log('speaker on success', data);
        }
        const error = (err) => {
            console.log('speaker on failed', err);
        }
        window.cordova.plugins.CordovaCall.speakerOn(success, error);
    }

    // static _speakerOff = () => {
    //     const success = () => {
    //         console.log('speaker off success');
    //     }
    //     const error = () =>{
    //         console.log('speaker off failed');          
    //     }
    //     cordova.plugins.CordovaCall.speakerOff (success, error);
    // }
}
export default RaVoipNotifications;