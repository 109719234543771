import React from "react";
import f7 from "framework7";
import {
  Page,
  Icon,
  Fab,
  Link,
  Navbar,
  Block,
  Popup,
  Badge,
  FabButtons,
  FabButton,
  Popover,
  Card,
  CardContent,
  f7 as f7React,
} from "framework7-react";
import { connect } from "react-redux";

import ChatMessages from "../../components/commons/chat-messages";
import VideoStreamSwitchButton from "./buttons/local-video-stream-switch-button";
import CallHome from "./call-home";
import ChatButton from "./buttons/chat-button";
import Participants from "./participants";
import ContactsList from "../../components/commons/contacts-list";
import SettingsForm from "../settings/settings-form";
import Commons from "../../Commons";
import Icons from "../../icons";
import { isBrowser } from "react-device-detect";

import * as actions from "../../redux/actions";

import { RaCordova } from "../../ra-lib/cordova/index";
import { RaCordovaUi } from "../../ra-lib/cordova/ui";
import { RaApiCalls } from "../../ra-lib/api/calls/calls";
import { RaApiCallsTargets } from "../../ra-lib/api/calls/targets";
import { RaLog } from "../../ra-lib/log";
import { RaApiCallsPics } from "../../ra-lib/api/calls/pics/pics";
import { RaApiCallsMessages } from "../../ra-lib/api/calls/message";
import { RaApiCallsPicsAnnotations } from "../../ra-lib/api/calls/pics/annotations";

import localeStrings from "./main-calls-local";
import appLocaleStrings from "../../app-local";

class MainCalls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      spinner: false,
      newInstantCallContact: props.newInstantCallContact,
      clickedCallId: null,
      activeCallDuration: null,
      incomingCall: RaApiCalls.getIncomingCall(),
      showMessages: false,
      showInfo: false,
      showSettings: false,
      updateIntervalId: null,
      showcontactslist: false,
    };
  }

  render() {
    let notSeenPhotosBadge;
    if (this.props.takenSnapshots.length > 0) {
      notSeenPhotosBadge = (
        <Badge color="red">{this.props.takenSnapshots.length}</Badge>
      );
    }

    let addParticipantButton;
    if (process.env.REACT_APP_MAX_USERS_IN_ROOM > 2) {
      addParticipantButton = (
        <FabButton
          label={localeStrings.button_addparticipant}
          onClick={this.addUserToRoom}
        >
          <Icon
            size="20"
            ios="f7:person_badge_plus"
            aurora="f7:person_badge_plus"
            md="f7:person_add"
          ></Icon>
        </FabButton>
      );
    }

    let participantsButton;
    if (process.env.REACT_APP_MAX_USERS_IN_ROOM > 2) {
      participantsButton = (
        <FabButton
          label={localeStrings.button_participants}
          onClick={() => {
            this.setState({ showInfo: true });
          }}
        >
          <Icon
            size="20"
            ios="f7:person_2"
            aurora="f7:person_2"
            md="f7:person_2"
          ></Icon>
        </FabButton>
      );
    }

    let devicesOptionsButton = isBrowser ? (
      <Fab
        onClick={(e) => {
          if (this.state.showSettings === false) {
            this.setState({ showSetting: true });
          }
        }}
        className="left-bottom"
        slot="fixed"
      >
        <Icon
          md="material:settings"
          ios="material:settings"
          aurora="material:settings"
        ></Icon>
      </Fab>
    ) : (
      <VideoStreamSwitchButton
        position="left-bottom"
        slot="fixed"
        isVideoStreamSwitchButtonEnabled={
          this.isVideoStreamSwitchButtonEnabledHandler
        }
      ></VideoStreamSwitchButton>
    );

    const settingsPopup = isBrowser ? (
      <Popup
        className="settings-popup ra-popup"
        id="settings-popup"
        colorTheme="custom"
        opened={
          this.state.showSetting === true && this.props.activeCall != null
        }
        onPopupClose={(e) => {
          if (this.state.showSetting === true) {
            this.setState({ showSetting: false });
          }
        }}
      >
        <Page>
          <Navbar title={localeStrings.popup_settings_title}>
            <Link
              slot="right"
              iconMd={Icons.CLOSE_ANDROID}
              iconIos={Icons.CLOSE_IOS}
              iconAurora={Icons.CLOSE_AURORA}
              onClick={(e) => {
                this.setState({ showSetting: false });
              }}
            ></Link>
          </Navbar>
          <Block className="setting-cards-containers">
            <Card noShadow outline style={{ margin: 0 }}>
              <CardContent>
                <SettingsForm callContext={true}></SettingsForm>
              </CardContent>
            </Card>
          </Block>
        </Page>
      </Popup>
    ) : null;

    return (
      <Page
        id="call-page"
        noToolbar={true}
        style={{ backgroundColor: "#000", overflow: "hidden" }}
      >
        <div
          style={{
            position: "absolute",
            display: this.callingState() ? "block" : "none",
            textAlign: "center",
            color: "white",
            width: "100%",
            zIndex: 1,
            marginTop: "20px",
          }}
        >
          <h1>
            {this.state.newInstantCallContact != null
              ? this.state.newInstantCallContact.Name
              : ""}
          </h1>
          <div>
            {(this.state.newInstantCallContact != null &&
            this.state.newInstantCallContact.Company != null
              ? this.state.newInstantCallContact.Company.Name +
                (this.state.newInstantCallContact.CompanyRole ? " - " : "")
              : "") +
              (this.state.newInstantCallContact != null
                ? this.state.newInstantCallContact.CompanyRole
                : "")}
          </div>
          <br></br>
          <div>
            <i>{localeStrings.connecting}</i>
          </div>
        </div>

        <Fab
          onClick={(e) => {
            this.closeCalling();
          }}
          position="center-bottom"
          slot="fixed"
          color="red"
          style={{
            opacity: 1,
            display: this.callingState() ? "block" : "none",
            marginBottom: "100px",
          }}
        >
          <Icon
            ios="f7:phone_down_fill"
            aurora="f7:phone_down_fill"
            md="material:call_end"
          ></Icon>
        </Fab>

        <CallHome
          callId={
            RaApiCalls.getActiveCall()
              ? RaApiCalls.getActiveCall().UniqueId
              : null
          }
          connectToCallError={this.connectToCallErrorHandler}
          isCallingState={this.callingState}
        ></CallHome>

        <div className="call-commands">
          {devicesOptionsButton}

          <ChatButton
            position="right-bottom"
            onClick={() => {
              this.setState({ showMessages: true });
            }}
          ></ChatButton>

          <Fab
            id="annotations-fab"
            position="right-bottom"
            slot="fixed"
            onClick={() => {
              f7React.popover.open(".annotations-popover-menu");
            }}
            style={{
              opacity:
                this.props.takenSnapshots != null &&
                this.props.takenSnapshots.length > 0
                  ? 1
                  : 0.4,
            }}
          >
            <Icon
              ios={Icons.ANNOTATIONS_IOS}
              aurora={Icons.ANNOTATIONS_AURORA}
              md={Icons.ANNOTATIONS_ANDROID}
            >
              {notSeenPhotosBadge}
            </Icon>
          </Fab>

          <Fab position="right-bottom" slot="fixed">
            <Icon md="f7:phone" ios="f7:phone" aurora="f7:phone"></Icon>
            <Icon
              ios={Icons.CLOSE_IOS}
              aurora={Icons.CLOSE_AURORA}
              md={Icons.CLOSE_ANDROID}
            ></Icon>

            <FabButtons position="top">
              <FabButton
                label={
                  this.state.micMuted
                    ? localeStrings.button_unmute
                    : localeStrings.button_mute
                }
                onClick={this.muteUnmuteMic}
              >
                <Icon
                  size="20"
                  ios={this.state.micMuted ? "f7:mic_slash" : "f7:mic"}
                  aurora={this.state.micMuted ? "f7:mic_slash" : "f7:mic"}
                  md={this.state.micMuted ? "f7:mic_slash" : "f7:mic"}
                ></Icon>
              </FabButton>
              {participantsButton}
              {addParticipantButton}
              <FabButton
                style={{
                  visibility: this.callingState() ? "hidden" : "visible",
                }}
                label={localeStrings.button_close_call}
                id="btn-close-call"
                onClick={this.closeCall}
              >
                <Icon
                  size="20"
                  ios="f7:phone_down_fill"
                  aurora="f7:phone_down_fill"
                  md="material:call_end"
                  color="white"
                ></Icon>
              </FabButton>
            </FabButtons>
          </Fab>
        </div>

        {settingsPopup}

        <Popup
          className="messages-popup ra-popup"
          id="messages-popup"
          colorTheme="custom"
          opened={
            this.state.showMessages === true && this.props.activeCall != null
          }
          onPopupClosed={() => {
            this.chatPageClose();
          }}
          onPopupOpened={() => {
            this.chatPageAfterIn();
          }}
        >
          <Page>
            <Navbar title={localeStrings.popup_messages_title}>
              <Link
                slot="right"
                iconMd={Icons.CLOSE_ANDROID}
                iconIos={Icons.CLOSE_IOS}
                iconAurora={Icons.CLOSE_AURORA}
                onClick={(e) => {
                  this.setState({ showMessages: false });
                }}
              ></Link>
            </Navbar>
            <ChatMessages
              callId={
                RaApiCalls.getActiveCall() != null
                  ? RaApiCalls.getActiveCall().UniqueId
                  : null
              }
              sendMessageEnabled={true}
              attachmentElementId="calls"
              isPageAfterIn={() => {
                return this.isChatPageAfterIn;
              }}
            />
          </Page>
        </Popup>

        <Popup
          className="info-popup ra-popup"
          id="info-popup"
          colorTheme="custom"
          opened={this.state.showInfo === true && this.props.activeCall != null}
        >
          <Page>
            <Navbar title={localeStrings.popup_participants_title}>
              <Link
                slot="right"
                iconMd={Icons.CLOSE_ANDROID}
                iconIos={Icons.CLOSE_IOS}
                iconAurora={Icons.CLOSE_AURORA}
                onClick={(e) => {
                  this.setState({ showInfo: false });
                }}
              ></Link>
            </Navbar>
            <Participants />
          </Page>
        </Popup>

        <Popover className="annotations-popover-menu" style={{ width: "100%" }}>
          {this.getPictures()}
        </Popover>

        <Popup
          className="contacts-popup ra-popup"
          id="main-call-contacts-list-popup"
          colorTheme="custom"
          opened={
            this.state.showcontactslist === true &&
            this.props.incomingCall === null
          }
          onPopupClose={(e) => {
            this.closeContactList();
          }}
        >
          <ContactsList
            id="main-call-contacts-list"
            key="main-call-contacts-list"
            backPageClicked={(contact) => {
              this.inviteUserHandler(contact);
            }}
            closeContacts={(e) => {
              this.closeContactList();
            }}
          />
        </Popup>

        <audio style={{ display: "none" }} id="audio-player" controls></audio>
      </Page>
    );
  }

  chatPageClose = () => {
    if (this.state.showMessages === false) {
      this.isChatPageAfterIn = false;
    } else {
      this.setState({ showMessages: false });
    }
    RaApiCallsMessages.clearUnreadMessages();
  };

  chatPageAfterIn = () => {
    if (this.state.showMessages === true) {
      this.isChatPageAfterIn = true;
    }
  };

  callingState = () => {
    return (
      this.props.callingState === true && !this.props.comunicationProviderError
    );
  };

  connectToCallErrorHandler = (error) => {
    console.error(error);
    this.closeCall();
  };
  toggleSwipeStep = () => {
    f7React.sheet.stepToggle(".demo-sheet-swipe-to-step");
  };

  isVideoStreamSwitchButtonEnabledHandler = () => {
    return (
      /*this.props.callerVideoTrackShareAllowed && */ RaApiCalls.getActiveCall() &&
      Commons.shareUserVideoTrack() /*
    RaApiCalls.amICallController()*/
    );
  };

  /***********************
   * GALLERY
   **********************/

  showImage = (picItem) => {
    let imgSrc = null;
    if (picItem !== null) {
      let htmlImage = document.getElementById("img_" + picItem.UniqueId);
      if (htmlImage) {
        imgSrc = htmlImage.src;
      }
    }

    f7React.popover.close(".annotations-popover-menu");
    // f7.$('#annotations-popover-menu').opened = false;

    //this.setState({ spinner: true });
    //this.fetchAnnotationsForImage(img);

    if (RaApiCalls.amICallController()) {
      RaApiCallsPics.selectPic(picItem, imgSrc);
      RaApiCalls.switchToAnnotationsGUI();
    }
  };

  getDefaultImageUrl = () => {
    return "img/loader.gif";
  };

  getPictures = () => {
    if (
      !this.props.takenSnapshots ||
      this.props.takenSnapshots === null ||
      this.props.takenSnapshots.length === 0
    ) {
      return null;
    }

    const images = this.props.takenSnapshots.map((item, index) => {
      if (RaApiCalls.getActiveCall()) {
        RaApiCallsPics.getPicBase64(
          RaApiCalls.getActiveCall().UniqueId,
          item
        ).then((base64) => {
          if (base64) {
            RaLog.log("loaded image " + item.UniqueId);
            let img = document.getElementById("img_" + item.UniqueId);
            if (img) {
              if (img.complete) {
                img.src = "data:image/jpg;base64," + base64;
              } else {
                img.addEventListener("load", () => {
                  img.src = "data:image/jpg;base64," + base64;
                });
              }
            }
          }
        });
      }

      return (
        <div
          key={"swiperslide" + index}
          style={{ textAlign: "initial", marginRight: "15px" }}
        >
          <span className="picIndex"># {index + 1}</span>
          <br></br>
          <img
            id={"img_" + item.UniqueId}
            src={this.getDefaultImageUrl()}
            alt="placeholder"
            onClick={(e) => {
              if (e.target.src.indexOf(this.getDefaultImageUrl()) < 0) {
                if (RaApiCalls.amICallController()) {
                  this.showImage(item, this);
                  RaApiCalls.updateSelectedImage();
                }
              }
            }}
          />

          {RaApiCalls.amICallController() && (
            <span
              className="badge"
              onClick={(e) => {
                this.deletePic(item.UniqueId);
              }}
            >
              X
            </span>
          )}
        </div>
      );
    });

    return (
      <div className="gallery-tabbar">
        <div className="picsGallery" style={{ position: "fixed" }}>
          {images}
        </div>
      </div>
    );
  };

  fetchAnnotationsForImage = (image) => {
    let self = this;
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        self.setState({
          spinner: false,
          errormessage: errorMsg,
        });
      }
    };

    const successFn = (annotations) => {
      self.setState({ annotations: annotations, spinner: false });
    };

    RaApiCallsPicsAnnotations.getPicAnnotations(
      this.props.activeCall.Call.UniqueId,
      image.UniqueId
    )
      .then(function (response) {
        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  };

  deletePic = (picId) => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          spinner: false,
          errormessage: errorMsg,
        });
      }
    };

    const successFn = () => {
      this.setState({ spinner: false, errormessage: null });
    };

    f7React.dialog.confirm(
      localeStrings.pic_delete_alert_text,
      localeStrings.pic_delete_alert_title,
      () => {
        this.setState({ spinner: true });
        RaApiCallsPics.deletePic(picId)
          .then(function (response) {
            if (response.result.success === true) {
              successFn();
            } else {
              errorFn(response.result.message);
            }
          })
          .catch(function (response) {
            //handle error
            errorFn(response.message);
          });
      }
    );
  };

  /***********************
   * CALLING POPUP
   **********************/

  closeCalling() {
    RaApiCalls.closeActiveCall();
  }

  /***********************
   * CALL COMMANDS
   **********************/

  closeCall = () => {
    try {
      RaApiCalls.closeActiveCall();
    } catch (e) {
      this.setState({ errormessage: e.message });
    }
  };

  muteUnmuteAudio = () => {
    RaApiCalls.setMuteUnmuteAudio(this.state.audioMuted);

    const audioMuted = !this.state.audioMuted;
    this.setState({ audioMuted: audioMuted });
  };

  muteUnmuteMic = () => {
    if (RaApiCalls.setMuteUnmuteMic()) {
      const micMuted = !this.state.micMuted;
      this.setState({ micMuted: micMuted });
    }
  };

  /**************************
   * PARTICIPANTS COMMANDS
   *************************/
  addUserToRoom = () => {
    this.setState({ showcontactslist: true });
  };

  closeContactList = () => {
    if (this.state.showcontactslist === true)
      this.setState({ showcontactslist: false });
  };

  inviteUserHandler = (contact) => {
    let self = this;
    setTimeout(function () {
      if (contact !== null) {
        self.fetchInviteUser(contact);
      }
    }, 1000);
  };

  fetchInviteUser = (contact) => {
    this.setState({ invitedContact: contact, showcontactslist: false });
  };

  onIndexSelect(itemContent) {
    RaLog.log(itemContent);
  }

  fetchUpdateContacts = () => {
    this.fetchContactsList();
    this.updateNewUserState(this.state.invitedContact);
  };

  fetchContactsList = () => {
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
      this.props.setMustUpdateTargets(false);
    };

    const successFn = (contacts, lastContactList) => {
      if (lastContactList === null) {
        this.setState({ errormessage: null });
      } else if (JSON.stringify(lastContactList) !== JSON.stringify(contacts)) {
        this.setState({ errormessage: null });
        this.props.setMustUpdateTargets(false);
        clearHistoryInterval(this.state.updateIntervalId);
      }
    };

    const clearHistoryInterval = (intervalId) => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        this.setState({ updateIntervalId: null });
      }
    };

    let currentContacts = this.props.activeCallTargets;
    RaApiCallsTargets.getActiveCallTargets()
      .then(function (response) {
        if (response.result.success === true) {
          successFn(response.data, currentContacts);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      });
  };

  updateNewUserState = (invitedContact) => {
    //  if (invitedContact !== null) {
    if (invitedContact) {
      RaLog.log(invitedContact);

      this.setState({ invitedContact: null });
      this.props.setMustUpdateTargets(false);
    } else {
      RaLog.log("invitedContact === null");
    }
  };

  /**************************
   * ALTRI METODI
   *************************/

  chatCallIdHandler = (callId, callerId) => {
    this.setState({ clickedCallId: callId, clickedCallerId: callerId });
  };

  showToastBottom(text) {
    const self = this;
    // Create toast
    if (!self.toastBottom) {
      self.toastBottom = f7React.toast.create({
        text: text,
        closeTimeout: 2000,
      });
    }
    // Open it
    self.toastBottom.open();
  }

  wait = function (time) {
    return new Promise(function (resolve) {
      setTimeout(resolve, time);
    });
  };

  onPicSelected = (pic) => {
    //aprire il popup delle annotazioni se è chiuso (non dovrebbe mai succedere)
  };

  componentDidMount() {
    if (RaCordova.isCordova()) {
      RaCordovaUi.enableProximitySensor();
    }

    let self = this;
    //chiusura dei FAB quando si clicca fuori da essi
    f7.$(document).on("click", function (e) {
      if (f7.$(e.target).closest(".fab").length === 0) {
        f7React.fab.close();
      }
    });

    /****************************
     * GESTIONE EVENTI CHIAMATA
     ****************************/
    RaApiCalls.onTargetConnected = (contactUniqueId) => {
      /*    if (this.state.showcalling === true) {
            this.setState({ showcalling: false });
          }*/
      this.props.setMustUpdateTargets(true);
    };

    RaApiCalls.onParticipantDisconnected = () => {
      RaLog.log("home onParticipantDisconnected");
      this.props.setMustUpdateTargets(true);
    };

    RaApiCalls.onArVideoProcessError = () => {
      Commons.showErrorMessage(self, localeStrings.ar_switch_error);
    };

    RaApiCalls.onActiveCallClosed = () => {
      if (RaCordova.isCordova()) {
        RaCordovaUi.disableProximitySensor();
      }

      RaLog.log("active call closed");
      this.setState({ showInfo: false, showMessages: false });
      /*   if (this.state.showcalling == true)
           this.setState({ showcalling: false });*/

      f7React.popover.close(".annotations-popover-menu"); //se è aperto quando si chiude la chiamata si blocca la UI

      let self = this;
      //si da il tempo necessario per chiudere il popup della chiamata in corso, senza che il cambio pagine interferisca, generanto scatti

      setTimeout(function () {
        self.props.f7router.navigate("/main/", { reloadAll: true });
      }, 750);
      //this.setState({ newInstantCallContact: null });
    };

    RaApiCalls.onCallDeclinedByTarget = () => {
      RaLog.log("onCallDeclinedByTarget");
    };

    /*RaApiCalls.onVideoTrackAdded = (track) => {
      RaLog.log('onVideoTrackAdded');
      setTimeout(function () {
        RaLog.log('onVideoTrackAdded setTimeout');
        // track.pause();
      }, 5000);
    };*/
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /***************************
     * ADD PARTICIPANTS
     **************************/

    if (
      prevState.invitedContact === null &&
      this.state.invitedContact !== null
    ) {
      const errorFn = (errorMsg, errorCode) => {
        Commons.callContactErrorHandler(
          this,
          this.state.invitedContact,
          errorMsg,
          errorCode,
          appLocaleStrings
        );
      };

      const successFn = () => {
        this.fetchContactsList();
        this.setState({ spinner: false, errormessage: null });
      };

      RaApiCallsTargets.addTargetToActiveCall(
        this.state.invitedContact.UniqueId
      )
        .then(function (response) {
          RaLog.log(response);

          if (response.result.success === true) {
            successFn();
          } else {
            errorFn(response.result.message, response.result.code);
          }
        })
        .catch(function (response) {
          errorFn(response.message);
        });
    }

    if (this.props.mustUpdateTargets) {
      if (this.state.updateIntervalId === null) {
        this.setState({
          updateIntervalId: setInterval(this.fetchUpdateContacts, 5000),
        });
      }
    }
  }
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    incomingCall: state.remoteAssistance.incomingCall,
    takenSnapshots: state.remoteAssistance.takenSnapshots,
    profile: state.remoteAssistance.profile,
    activeCall: state.remoteAssistance.activeCall,
    callerVideoTrackShareAllowed:
      state.remoteAssistance.callerVideoTrackShareAllowed,
    callerVideoTrackShared: state.remoteAssistance.callerVideoTrackShared,
    mustUpdateTargets: state.app.mustUpdateTargets,
    comunicationProviderError: state.remoteAssistance.comunicationProviderError,
    callingState: state.remoteAssistance.callingState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMustUpdateTargets: (mustUpdateTargets) =>
      dispatch(actions.setMustUpdateTargets(mustUpdateTargets)),
  };
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(MainCalls);
