import React from "react";
import {
  Page,
  LoginScreenTitle,
  Button,
  Block,
  List,
  ListInput,
  PageContent,
  BlockFooter,
  Link,
  f7 as f7React,
} from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./signup-local";
import ReactCodeInput from "react-code-input";
import Commons from "../../Commons";
import { deviceModel, isBrowser, isAndroid } from "react-device-detect";

import { RaApiAuth } from "../../ra-lib/api/auth";
import { RaLog } from "../../ra-lib/log";

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = { email: null, errorMessage: null, registering: false };
    this.inputCode = null;
  }

  render() {
    const codeProps = {
      autoFocus: false,
      inputStyle: {
        fontFamily: "monospace",
        borderRadius: "6px",
        border: "1px solid lightgrey",
        boxShadow: "rgb(0 0 0 / 10%) 0px 0px 10px 0px",
        margin: "4px",
        paddingLeft: "8px",
        width: "36px",
        height: "42px",
        fontSize: "32px",
        boxSizing: "border-box",
        color: "black",
        backgroundColor: "white",
        float: "left",
      },
    };

    const errorMessage = this.state.errorMessage ? (
      <div style={{ color: "red", marginTop: "15px" }}>
        {this.state.errorMessage}
      </div>
    ) : null;

    return (
      // <Page noToolbar noNavbar noSwipeback loginScreen>
      <Page noToolbar noNavbar noSwipeback loginScreen>
        <PageContent>
          <LoginScreenTitle>{localeStrings.title}</LoginScreenTitle>
          <List form>
            <ListInput
              inputId="email"
              label={localeStrings.input_label_email}
              type="email"
              validate={true}
              placeholder={localeStrings.sample_email}
              info={localeStrings.email_info}
            />
          </List>
          <Block>
            <ReactCodeInput
              type="text"
              fields={6}
              {...codeProps}
              onChange={(e) => {
                this.inputCode = e;
              }}
            />
            <div
              className="item-input-info"
              style={{
                clear: "both",
                display: "block",
                marginTop: 0,
                position: "relative",
              }}
            >
              {localeStrings.code_info}
            </div>
          </Block>
          <BlockFooter>
            <Button
              style={{ marginTop: "50px" }}
              fill
              onClick={(e) => {
                this.signUp();
              }}
            >
              {localeStrings.signup_button}
            </Button>
            <div style={{ textAlign: "right", marginTop: "15px" }}>
              <Link href="/login/" reloadAll={true} view={".view-main"}>
                {localeStrings.login_label}
              </Link>
            </div>
            {errorMessage}
          </BlockFooter>
        </PageContent>
      </Page>
    );
  }

  signUp = () => {
    this.setState({ registering: true, errorMessage: null });
    let self = this;

    const emailEl = document.getElementById("email");

    if (!emailEl || !emailEl.value || !this.inputCode) {
      self.setState({
        registering: false,
        errorMessage: localeStrings.formdata_missing,
      });
    } else {
      self.showPreloader(true);
      RaApiAuth.registerFromInvitation(
        emailEl.value,
        this.inputCode,
        Commons.getDeviceId(),
        isBrowser ? "web" : isAndroid ? "android" : "ios",
        deviceModel
      )
        .then((response) => {
          if (response.result.success === true) {
            self.showPreloader(false);
            self.setState({
              registering: false,
              errorMessage: null,
            });
          } else {
            self.showPreloader(false);
            RaLog.error(response.result.message);
            let errorMessage = localeStrings.register_error_generic;
            if (response.result.code === 404) {
              errorMessage = localeStrings.register_error_wrongdata;
            } else if (response.result.code === 409) {
              errorMessage = localeStrings.register_error_alreadyregistered;
            }
            self.setState({
              registering: false,
              errorMessage: errorMessage,
            });
          }
        })
        .catch((error) => {
          self.showPreloader(false);
          RaLog.error(error);
          self.setState({
            registering: false,
            errorMessage: localeStrings.register_error_generic,
          });
        });
    }
  };

  componentDidMount = () => {
    if (this.isUserAuthenticated()) {
      return;
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.isUserAuthenticated()) {
      return;
    }
  };

  showPreloader = (show) => {
    if (show) {
      f7React.preloader.show("black");
    } else {
      f7React.preloader.hide();
    }
  };

  isUserAuthenticated = () => {
    if (
      RaApiAuth.isAuthenticated() &&
      this.props.f7router.currentRoute.url !== "/main/"
    ) {
      this.showPreloader(false);
      this.props.f7router.navigate("/main/", {
        reloadAll: true,
        props: { fade: true },
      });
      return true;
    }
    return false;
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
