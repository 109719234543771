import { connect } from 'react-redux';
import React from 'react';
import { Page, Block, Col, Preloader } from 'framework7-react';
import localeStrings from './logout-loader-local';


export class LogoutLoader extends React.Component {

  
  render() {

  
    return (<Page pageContent={false} colorTheme="custom">
              <Block className="row demo-preloaders align-items-stretch text-align-center spinner">
                <Col>
                  <Preloader size={42}></Preloader>
                  <p>{localeStrings.text}</p>
                </Col>
              </Block>
              
            </Page>);
  }

  componentDidMount = () => {
    
  }
}

// #region Redux
const mapStateToProps = state => {
    return {
      
    };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(LogoutLoader);