import { Ra } from "../index";
import { RaApiNet } from "../api/net";

export class RaApiRoles {
  static getRoles = (rolesType) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url:
          Ra.getConfig().apiUrl +
          "/services/roles" +
          (rolesType ? "?code=" + rolesType : null),
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default RaApiRoles;
