import LocalizedStrings from "react-localization";

export const RaLocalization = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    media_frontcamera: "Front",
    media_backcamera: "Back",
  },
  it: {
    media_frontcamera: "Anteriore",
    media_backcamera: "Posteriore",
  },
});

export default RaLocalization;
