import { RaApiProducts } from "../api/products";
import { RaReduxActions } from "../redux/actions";
import { RaStore } from "../redux/store";
import { RaApiNet } from "../api/net";
import { Ra } from "../index";

export class RaApiAppCompany {
  static getAppCompany = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/app-company",
      })
        .then(function (response) {
          if (response.result.success === true) {
            RaStore.get().dispatch(RaReduxActions.setAppCompany(response.data));
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default RaApiProducts;
