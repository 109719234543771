import React from "react";
import {
  BlockTitle,
  Button,
  List,
  ListInput,
  Preloader,
} from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./invitation-email-check-local";

import { RaApiContacts } from "../../ra-lib/api/contacts";
import { RaLog } from "../../ra-lib/log";
class InvitationEmailCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checking: false,
      errorMessage: null,
    };
  }

  render() {
    const preloader =
      this.state.checking === true ? (
        <Preloader size={28} style={{ position: "absolute" }}></Preloader>
      ) : null;

    const errorMessage = this.state.errorMessage ? (
      <span style={{ color: "red" }}>{localeStrings.api_errorMessage}</span>
    ) : null;

    return (
      <div>
        <BlockTitle
          style={{
            whiteSpace: "normal",
          }}
        >
          {localeStrings.title}
        </BlockTitle>
        <List noHairlinesMd>
          <ListInput
            type="email"
            inputId="txt-email"
            placeholder={localeStrings.email_palceholder}
            clearButton
            required
            validate
            errorMessage={localeStrings.email_errorMessage}
            disabled={this.state.checking}
          />
        </List>
        <div style={{ textAlign: "center", width: "100%" }}>
          {preloader}
          {errorMessage}
          <Button
            raised
            fill
            style={{
              display: "inline-block",
              float: "right",
              marginRight: "15px",
              minWidth: "100px",
            }}
            onClick={() => {
              this.startCheck();
            }}
          >
            {localeStrings.button_label}
          </Button>
        </div>
      </div>
    );
  }

  startCheck = () => {
    if (this.state.checking === true) {
      return;
    }

    let inputEl = document.getElementById("txt-email");
    if (inputEl && inputEl.value) {
      this.setState({ checking: true, errorMessage: null });
      let errorMessage = null;
      let self = this;
      RaApiContacts.emailExists(inputEl.value)
        .then((response) => {
          if (response.result.success === true) {
            setTimeout(() => {
              //chiamo al funzione onEmailChecked del parent passando il risultato true o false
              if (self.props.onEmailChecked) {
                self.props.onEmailChecked(response.data, inputEl.value);
              }
            }, 100);
          } else {
            errorMessage = localeStrings.api_errorMessage;
            RaLog.error(response.result.message);
          }
        })
        .catch((error) => {
          errorMessage = localeStrings.api_errorMessage;
          RaLog.error(error);
        })
        .finally(() => {
          self.setState({ checking: false, errorMessage: errorMessage });
        });
    }
  };

  componentDidMount = () => {
    // Commons.checkAuthentication(this, this.props.f7router);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //  Commons.checkAuthentication(this, this.props.f7router);
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationEmailCheck);
