import React from "react";
import { Block, Col, Row } from "framework7-react";
import { connect } from "react-redux";
import localeStrings from "./profile-info-local";
import { RaUI } from "../../ra-lib/ui/ui";

class ProfileInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const profile = this.props.contact;

    return (
      <Block textAlign="center">
        <Row>
          <Col width="30" style={{ textAlign: "right" }}>
            <img
              alt={profile.Name ? profile.Name : localeStrings.no_name}
              src={RaUI.getAvatar(profile.Name)}
              width="48"
              style={{ borderRadius: "50%" }}
            />
          </Col>
          <Col width="70">
            <strong style={{ display: "block", fontSize: "120%" }}>
              {profile.Name ? profile.Name : localeStrings.no_name}
            </strong>
            <div style={{ display: profile.Company ? "block" : "none" }}>
              {profile.Company ? profile.Company.Name : null}
            </div>
            <div style={{ display: profile.CompanyRole ? "block" : "none" }}>
              {profile.CompanyRole}
            </div>
            <div style={{ display: profile.Email ? "block" : "none" }}>
              {profile.Email}
            </div>
          </Col>
        </Row>
      </Block>
    );
  }

  componentDidMount = () => {
    // Commons.checkAuthentication(this, this.props.f7router);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //  Commons.checkAuthentication(this, this.props.f7router);
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
