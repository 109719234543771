
export class RaReduxActionTypes {
    static SET_TOKEN = 'SET_TOKEN';
    static SET_USER_INFO = 'SET_USER_INFO';
    static SET_ACTIVE_CALL = 'SET_ACTIVE_CALL';
    static SET_DATA_TRACK = 'SET_DATA_TRACK';
 //   static SET_VIDEO_TRACK = 'SET_VIDEO_TRACK';
    static SET_COMUNICATION_PROVIDER_DATA = 'SET_COMUNICATION_PROVIDER_DATA';
    static SET_COMUNICATION_PROVIDER_ERROR = 'SET_COMUNICATION_PROVIDER_ERROR';
    static SET_INCOMING_CALL = 'SET_INCOMING_CALL';
    static SET_ACTIVE_CALL_PARTICIPANTS = 'SET_ACTIVE_CALL_PARTICIPANTS';
    static SET_ACTIVE_CALL_TARGETS = 'SET_ACTIVE_CALL_TARGETS';
    static SET_MUST_UPDATE_TARGETS = 'SET_MUST_UPDATE_TARGETS';
    static SET_CORDOVA_READY = 'SET_CORDOVA_READY';
    static SET_CORDOVA_FIREBASE_TOKEN = 'SET_CORDOVA_FIREBASE_TOKEN';
    static SET_VIDEO_SCREEN_STATE = 'SET_VIDEO_SCREEN_STATE';
    static SET_NOT_SEEN_PHOTOS = 'SET_NOT_SEEN_PHOTOS';
    static SET_DELETED_SNAPSHOT = 'SET_DELETED_SNAPSHOT';
    static SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
    static SET_TAKEN_SNAPSHOTS = 'SET_TAKEN_SNAPSHOTS';
    static SET_SELECTED_SNAPSHOT = 'SET_SELECTED_SNAPSHOT';
    static SET_CALL_GUI_MODE = 'SET_CALL_GUI_MODE';
    static SET_CALLER_VIDEOTRACK_SHARE_ALLOWED = 'SET_CALLER_VIDEOTRACK_SHARE_ALLOWED';
    static SET_CALLER_VIDEOTRACK_SHARED = 'SET_CALLER_VIDEOTRACK_SHARED';
    static SET_CALL_CONTROLLER = 'SET_CALL_CONTROLLER';
    static SET_ARVIDEOINPUTDEVICE_ENABLE = 'SET_ARVIDEOINPUTDEVICE_ENABLE';
    static SET_ARVIDEOTRAINING_STATE = 'SET_ARVIDEOTRAINING_STATE';
    static SET_ARVIDEOPROCESS_STATE = 'SET_ARVIDEOPROCESS_STATE';
    static SET_VIDEOINPUTDEVICES = 'SET_VIDEOINPUTDEVICES';
    static SET_SELECTED_VIDEOINPUTDEVICE = 'SET_SELECTED_VIDEOINPUTDEVICE';
    static SET_SELECTED_ARVIDEOINPUTDEVICE = 'SET_SELECTED_ARVIDEOINPUTDEVICE';
    static SET_AUDIOINPUTDEVICES = 'SET_AUDIOINPUTDEVICES';
    static SET_SELECTED_AUDIOINPUTDEVICE = 'SET_SELECTED_AUDIOINPUTDEVICE';
    static SET_AUDIOOUTPUTDEVICES = 'SET_AUDIOOUTPUTDEVICES';
    static SET_SELECTED_AUDIOOUTPUTDEVICE = 'SET_SELECTED_AUDIOOUTPUTDEVICE';
    static SET_INPUTDEVICESDETECTIONCOMPLEATED = 'SET_INPUTDEVICESDETECTIONCOMPLEATED';
    static SET_SELECTED_VIDEOINPUTIPCAM = 'SET_SELECTED_VIDEOINPUTIPCAM';
    static SET_CALL_TRACKS_NODE = 'SET_CALL_TRACKS_NODE';
    static SET_CALL_TIME_BEFORE_HANG_UP = 'SET_CALL_TIME_BEFORE_HANG_UP';
    static SET_CALLING_STATE = "SET_CALLING_STATE";
    static SET_PRODUCT_CODE = "SET_PRODUCT_CODE";
    static SET_CONTACT_ROOMS = "SET_CONTACT_ROOMS";
    static SET_CONTACT_ROOMS_LOADING_PREC = "SET_CONTACT_ROOMS_LOADING_PREC";
    static SET_APP_COMPANY = 'SET_APP_COMPANY';
    static SET_USER_ADDRESSBOOK = 'SET_USER_ADDRESSBOOK';
}

export default RaReduxActionTypes;
