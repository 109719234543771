import { RaApiNet } from "../api/net";
import { Ra } from "../index";
import { RaStore } from "../redux/store";
import { RaReduxActions } from "../redux/actions";
import { RaCommons } from "../commons";

export class RaApiAddressBook {
  static getAddressBook = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/addressbook",
      })
        .then(function (response) {
          if (response.result.success === true) {
            RaStore.get().dispatch(
              RaReduxActions.setUserAdressBook(response.data)
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static addToAddressBook = (contact) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("contactUniqueId", contact.UniqueId);

      RaApiNet.callApi({
        method: "post",
        data: bodyFormData,
        url: Ra.getConfig().apiUrl + "/services/addressbook",
      })
        .then(function (response) {
          if (response.result.success === true) {
            let addressbook =
              RaStore.getState().userAddressBook !== null
                ? [...RaStore.getState().userAddressBook]
                : [];
            let contactIndex = RaCommons.getElementIndexFromUniqueId(
              addressbook,
              contact.UniqueId
            );
            //se non c'è lo aggiungo
            if (contactIndex < 0) {
              addressbook = RaApiAddressBook.addContactToAddressBookArray(
                addressbook,
                contact
              );
              RaStore.get().dispatch(
                RaReduxActions.setUserAdressBook(addressbook)
              );
            }
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static addContactToAddressBookArray = (array, contact) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].Name > contact.Name) {
        array.splice(i, 0, contact);
        break;
      }
    }
    return array;
  };

  static removeFromAddressBook = (contactUniqueId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "delete",
        url: Ra.getConfig().apiUrl + "/services/addressbook/" + contactUniqueId,
      })
        .then(function (response) {
          if (response.result.success === true) {
            let addressbook =
              RaStore.getState().userAddressBook !== null
                ? [...RaStore.getState().userAddressBook]
                : [];
            let contactIndex = RaCommons.getElementIndexFromUniqueId(
              addressbook,
              contactUniqueId
            );
            if (contactIndex >= 0) {
              addressbook.splice(contactIndex, 1);
              RaStore.get().dispatch(
                RaReduxActions.setUserAdressBook(addressbook)
              );
            }
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getAddressBookInvitations = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/addressbook-invitations",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static submitAddressBookInvitation = (email, name, roleUniqueId) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);
      bodyFormData.set("name", name);
      bodyFormData.set("roleUniqueId", roleUniqueId);

      RaApiNet.callApi({
        method: "post",
        data: bodyFormData,
        url: Ra.getConfig().apiUrl + "/services/addressbook-invitations",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static resendAddressBookInvitation = (uniqueId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "put",
        url:
          Ra.getConfig().apiUrl +
          "/services/addressbook-invitations/" +
          uniqueId,
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static deleteAddressBookInvitation = (uniqueId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "delete",
        url:
          Ra.getConfig().apiUrl +
          "/services/addressbook-invitations/" +
          uniqueId,
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default RaApiAddressBook;
