import React, { Component } from "react";
import parse from "html-react-parser";

import { RaUITracksNodesManager } from "../ui/tracksNodesManager";
import { RaApiCalls } from "../api/calls/calls";
import { RaMedia } from "../media/media";
import { RaLog } from "../log";
import { RaUI } from "../ui/ui";
import { RaStorage } from "../storage";
import { RaApiCallsParticipants } from "../api/calls/participants";
import { isIOS } from "react-device-detect";

export class RaUiTrackRenderer extends Component {
  constructor(props) {
    super(props);

    RaUiTrackRenderer._tracksContainer = null;
    let self = this;

    const removeSubNodes = (parentNode, nodeName) => {
      for (var i = 0; i < parentNode.childNodes.length; i++) {
        if (
          parentNode.childNodes[i].nodeName.toLowerCase() ===
          nodeName.toLowerCase()
        ) {
          parentNode.childNodes[i].remove();
          i--;
        }
      }
    };

    const getParticipants = () => {
      let participantsArray = [];
      if (
        this.props.callTracksNodes &&
        this.props.callTracksNodes.participants
      ) {
        this.props.callTracksNodes.participants.forEach((participant) => {
          participantsArray.push(participant);
        });
      }
      return participantsArray;
    };

    const getRemoteParticipants = (remote) => {
      let participantsArray = [];
      if (
        this.props.callTracksNodes &&
        this.props.callTracksNodes.participants
      ) {
        this.props.callTracksNodes.participants.forEach((participant) => {
          if (
            (remote === true &&
              participant.id !==
              RaUITracksNodesManager.getTrackNodeId(
                RaStorage.getProfile().UniqueId
              )) ||
            (remote === false &&
              participant.id ===
              RaUITracksNodesManager.getTrackNodeId(
                RaStorage.getProfile().UniqueId
              ))
          ) {
            participantsArray.push(participant);
          }
        });
      }
      return participantsArray;
    };

    const getParticipantsNodes = (participantsArray) => {
      let participantsNodes = [];
      if (participantsArray) {
        participantsNodes = participantsArray.map((item, index) => {
          return parse(item.node);
        });
      }
      return participantsNodes;
    };

    const renderLayout = () => {
      let participants = getParticipants();

      let participantsNodes = [];
      let mainviewParticipantsNodes = [];

      //se ci sono degli assistiti li metto nella scena principale
      /*if (assistedParticipants.length > 0) {
                participantsNodes = getParticipantsNodes(participants);
                mainviewParticipantsNodes = getParticipantsNodes(assistedParticipants);
            }
            //se c'è un solo utente connesso ci metto lui
            else */ if (participants.length === 1) {
        mainviewParticipantsNodes = getParticipantsNodes(participants);
      }
      //altrimenti ci metto quelli remoti
      else {
        participantsNodes = getParticipantsNodes(getRemoteParticipants(false));
        mainviewParticipantsNodes = getParticipantsNodes(
          getRemoteParticipants(true)
        );
      }

      let trackContainer = RaUiTrackRenderer._getTracksContainer();
      if (trackContainer) {
        let participantsDiv =
          trackContainer.getElementsByClassName("participants");
        let mainViewDiv = trackContainer.getElementsByClassName(
          "mainview-participants"
        );
        let tempTracksDiv =
          trackContainer.getElementsByClassName("temp-tracks");
        if (tempTracksDiv.length > 0) {
          tempTracksDiv = tempTracksDiv[0];
        }

        if (participantsDiv) {
          participantsNodes.forEach((participantNode) => {
            let domNode = trackContainer.getElementsByClassName(
              participantNode.props.id
            );
            if (domNode.length > 0) {
              domNode = domNode[0];
            }
            participantsDiv[0].appendChild(domNode);
            removeParticipantTempTracks(
              tempTracksDiv,
              participantNode.props.id
            );
          });
        }

        if (mainViewDiv) {
          mainviewParticipantsNodes.forEach((mainviewParticipantsNode) => {
            let domNode = trackContainer.getElementsByClassName(
              mainviewParticipantsNode.props.id
            );
            if (domNode.length > 0) {
              domNode = domNode[0];
            }
            mainViewDiv[0].appendChild(domNode);
            removeParticipantTempTracks(
              tempTracksDiv,
              mainviewParticipantsNode.props.id
            );
          });
        }
      }
    };

    const removeParticipantTempTracks = (tempTracksDiv, participantId) => {
      if (tempTracksDiv) {
        let participantTempTracks =
          tempTracksDiv.getElementsByClassName(participantId);
        while (participantTempTracks.length > 0) {
          participantTempTracks[0].remove();
        }
      }
    };

    const removeParticipantTracks = (tracks, participantId) => {
      RaLog.log(
        "RaUiTrackRenderer.onParticipantTrackRemoved " + participantId,
        tracks
      );
      //prendo il nodo del partecipante, se esistente
      let trackContainer = RaUiTrackRenderer._getTracksContainer();
      if (trackContainer) {
        let trackNode = trackContainer.getElementsByClassName(
          RaUITracksNodesManager.getTrackNodeId(participantId)
        );

        //rimuovo le tracce
        if (trackNode.length > 0) {
          trackNode = trackNode[0];
          tracks.forEach(function (track) {
            if (track.kind === "audio") {
              removeSubNodes(trackNode, "audio");
            } else if (track.kind === "video") {
              removeSubNodes(trackNode, "video");
            }
          });
        }
      }
    };

    RaApiCalls.onParticipantTrackDimensionChanged = (track, participantId) => {
      if (track.kind === "video") {
        RaLog.log("track", track);
        applyTrackDimensions(track.dimensions, participantId);
      }
    };

    const applyTrackDimensions = (trackDimensions, participantId) => {

      let trackContainer = RaUiTrackRenderer._getTracksContainer();
      if (trackContainer) {
        let trackNode = trackContainer.getElementsByClassName(
          RaUITracksNodesManager.getTrackNodeId(participantId)
        );
        if (trackNode.length > 0 && trackDimensions) {
          trackNode = trackNode[0];
          let videoNode = RaUI.getFirstSubNode(trackNode, "video");
          if (videoNode) {
            videoNode.width = trackDimensions.width;
            videoNode.height = trackDimensions.height;
            videoNode.setAttribute("track-height", trackDimensions.height);
            videoNode.setAttribute("track-width", trackDimensions.width);
            if (
              trackDimensions.width > trackDimensions.height &&
              !videoNode.classList.contains("landscape")
            ) {
              videoNode.classList.add("landscape");
            }
          }
        }
      }
    };

    RaApiCalls.onParticipantTrackAdded = (tracks, participantId) => {
      let isLocalParticipant =
        participantId === RaStorage.getProfile().UniqueId;
      let isAssistedParticipant =
        RaApiCallsParticipants.isAssistedCallParticipant(participantId);

      RaLog.log(
        "RaUiTrackRenderer.onParticipantTrackAdded" +
        (isLocalParticipant ? " (LOCAL)" : "") +
        (isAssistedParticipant ? " (ASSISTED)" : "") +
        " " +
        participantId,
        tracks
      );

      //ricreo il div del partecipante
      RaUITracksNodesManager.createParticipantTrackNode(
        participantId,
        isAssistedParticipant
      );
      //forzo il render del DOM
      self.forceUpdate();

      let trackContainer = RaUiTrackRenderer._getTracksContainer();

      if (trackContainer) {
        //prendo il nodo, appena creato, del partecipante
        let trackNodeContainer = trackContainer.getElementsByClassName(
          RaUITracksNodesManager.getTrackNodeId(participantId)
        );

        //renderizzo le tracce
        if (trackNodeContainer.length > 0) {
          trackNodeContainer = trackNodeContainer[0];
          if (isLocalParticipant) {
            if (trackNodeContainer.className.indexOf("local-participant") < 0) {
              trackNodeContainer.className += " local-participant";
            }
          } else {
            if (
              trackNodeContainer.className.indexOf("remote-participant") < 0
            ) {
              trackNodeContainer.className += " remote-participant";
            }
          }
          if (isAssistedParticipant) {
            if (
              trackNodeContainer.className.indexOf("assisted-participant") < 0
            ) {
              trackNodeContainer.className += " assisted-participant";
            }
          }

          tracks.forEach(function (track) {
            if (track.kind === "audio") {
              removeSubNodes(trackNodeContainer, "audio");
            } else if (track.kind === "video") {
              removeSubNodes(trackNodeContainer, "video");
            }
            let trackNode = track.attach();
            trackNode.setAttribute("track-name", track.name);
            if (isIOS) {
              applyTrackDimensions({ width: 480, height: 640 }, participantId);
            }

            //se la traccia è audio è necessario prima impostare il device di output, poi renderizzarlo in HTML
            let audioDevice = RaMedia.getSelectedAudioOutputDevice();
            if (
              track.kind === "audio" &&
              audioDevice &&
              typeof trackNode.setSinkId === "function"
            ) {
              trackNode.setSinkId(audioDevice.deviceId).then(() => {
                trackNodeContainer.appendChild(trackNode);
              });
            } else {
              trackNodeContainer.appendChild(trackNode);
            }
          });

          renderLayout();
        } else {
          RaLog.error("onParticipantTrackAdded trackNode null");
        }
      } else {
        RaLog.log("onParticipantTrackAdded: no trackContainer");
      }
    };

    RaApiCalls.onParticipantTrackRemoved = (tracks, participantId) => {
      removeParticipantTracks(tracks, participantId);
    };
  }

  static removeParticipantTrackNodes = (participantId, kind) => {
    let tracksContainer = RaUiTrackRenderer._getTracksContainer();
    if (tracksContainer) {
      let participantsDiv = RaUI.getFirstElementByClassName(
        "participants",
        tracksContainer
      );
      let mainViewDiv = RaUI.getFirstElementByClassName(
        "mainview-participants",
        tracksContainer
      );
      let tempTracksDiv = RaUI.getFirstElementByClassName(
        "temp-tracks",
        tracksContainer
      );

      let participantNodeClassName =
        RaUITracksNodesManager.getTrackNodeId(participantId);

      if (participantsDiv) {
        RaUiTrackRenderer._removeParticipantTrackNode(
          participantsDiv,
          participantNodeClassName,
          kind
        );
      }
      if (mainViewDiv) {
        RaUiTrackRenderer._removeParticipantTrackNode(
          mainViewDiv,
          participantNodeClassName,
          kind
        );
      }
      if (tempTracksDiv) {
        RaUiTrackRenderer._removeParticipantTrackNode(
          tempTracksDiv,
          participantNodeClassName,
          kind
        );
      }
    }
  };

  static _tracksContainer = null;

  static _getTracksContainer = () => {
    if (!RaUiTrackRenderer._tracksContainer) {
      RaUiTrackRenderer._tracksContainer =
        document.getElementsByClassName("tracksContainer");
      if (RaUiTrackRenderer._tracksContainer.length > 0) {
        RaUiTrackRenderer._tracksContainer =
          RaUiTrackRenderer._tracksContainer[0];
      } else {
        RaUiTrackRenderer._tracksContainer = null;
      }
    }
    return RaUiTrackRenderer._tracksContainer;
  };

  static _removeParticipantTrackNode = (container, participantId, kind) => {
    if (container) {
      let participantTrackNodes =
        container.getElementsByClassName(participantId);

      for (let i = 0; i < participantTrackNodes.length; i++) {
        if (participantTrackNodes[i].nodeName.toLowerCase() === kind) {
          participantTrackNodes[i].remove();
        }
      }
    }
  };

  static getParticipantTrackNode = (participantId, kind) => {
    let trackNode = null;
    let participantNode = RaUiTrackRenderer.getParticipantNode(participantId);
    if (participantNode) {
      trackNode = RaUI.getFirstSubNode(participantNode, kind);
    }
    return trackNode;
  };

  static getParticipantNode = (participantId) => {
    let participantNode = null;
    let tracksContainer = RaUiTrackRenderer._getTracksContainer();

    if (tracksContainer) {
      participantNode = tracksContainer.getElementsByClassName(
        RaUITracksNodesManager.getTrackNodeId(participantId)
      );
      if (participantNode.length > 0) {
        participantNode = participantNode[0];
      } else {
        participantNode = null;
      }
    }
    return participantNode;
  };

  updateOutputAudioDevice = () => {
    let device = RaMedia.getSelectedAudioOutputDevice();
    if (device) {
      let tracksContainer = RaUiTrackRenderer._getTracksContainer();
      let audioTracks = tracksContainer
        ? RaUiTrackRenderer._getTracksContainer().getElementsByTagName("AUDIO")
        : null;
      if (audioTracks) {
        for (let i = 0; i < audioTracks.length; i++) {
          if (typeof audioTracks[i].setSinkId === "function") {
            audioTracks[i].setSinkId(device.deviceId);
          }
        }
      }
    }
  };

  render() {
    let participantsNodes = "";
    if (this.props.callTracksNodes && this.props.callTracksNodes.participants) {
      this.props.callTracksNodes.participants.forEach((item) => {
        participantsNodes += item.node;
      });
    }
    /*
        return (
            <div id='tracksContainer'>
                <div className='participants'>{participantsNodes}</div>
                <div className='mainview-participants'>{mainviewParticipantsNodes}</div>
            </div>
        );*/

    return (
      <div className="tracksContainer">
        <div className="participants"></div>
        <div className="mainview-participants"></div>
        <div
          className="temp-tracks"
          dangerouslySetInnerHTML={{ __html: participantsNodes }}
        ></div>
      </div>
    );
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => { };

  componentDidMount = () => { };
}

// #endregion

export default RaUiTrackRenderer;
//export default connect(mapStateToProps, mapDispatchToProps)(RaUiTrackRender);
