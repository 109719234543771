import { Ra } from "../index";
import { RaApiNet } from "../api/net";

export class RaApiProducts {
  static getProducts = () => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "get",
        url: Ra.getConfig().apiUrl + "/services/products",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default RaApiProducts;
