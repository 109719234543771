// import {
//   connect,
//   createLocalVideoTrack,
//   createLocalAudioTrack,
//   LocalVideoTrack,
//   LocalDataTrack,
//   LocalAudioTrack,
// } from "twilio-video";
import { isIOS } from "react-device-detect";

import { RaCordova } from "../../cordova/index";
import { RaApiContacts } from "../../api/contacts";
import { RaApiCalls } from "../../api/calls/calls";
import { RaStorage } from "../../storage";
import { RaMedia } from "../../media/media";
import { RaLog } from "../../log";
import { RaStore } from "../../redux/store";
import { RaReduxActions } from "../../redux/actions";
import { RaUITracksNodesManager } from "../../ui/tracksNodesManager";
import { RaCommons } from "../../commons";
import Ra from "../..";

let localDataTrack;
let dataTrackCallback;

export class RaApiCallsCommunicationProvider {
  static _videoCallInProgress = false;

  static init = () => {
    return new Promise((resolve, reject) => {
      if (RaCordova.isCordova() && isIOS) {
        window.cordova.plugins.iosrtc.registerGlobals();
      }

      RaCommons.injectJsScript("webrtc-injected-js", Ra.getConfig().webRtcAdapter)
        .then(() => {
          RaLog.log("success to load " + Ra.getConfig().webRtcAdapter);
          RaCommons.injectJsScript("twilio-video-injected-js", Ra.getConfig().twilioVideoUrl)
            .then(() => {
              RaLog.log("success to load " + Ra.getConfig().twilioVideoUrl);

              resolve();
            })
            .catch((error) => {
              RaLog.error("failed to load " + Ra.getConfig().twilioVideoUrl);
              reject();
            });
        })
        .catch((error) => {
          RaLog.error("failed to load " + Ra.getConfig().webRtcAdapter);
          reject();
        });
    });
  }

  static _getCurrentRoom = () => {
    return RaStore.getState().comunicationProviderRoom;
  };

  static getParticipantUniqueId = (participantIdentity) => {
    /* let identity = JSON.parse(participantIdentity);
        return identity.Id;*/
    return participantIdentity;
  };
  /*
    static getParticipantPublicKey = (participantIdentity) => {
        let identity = JSON.parse(participantIdentity);
        return identity.PublicKey;
    }*/

  static isParticipantOnline = (participantId) => {
    let isOnline;
    let participants = RaStore.getState().activeCallParticipants;
    if (participants) {
      participants.forEach((participant) => {
        if (
          RaApiCallsCommunicationProvider.getParticipantUniqueId(
            participant.UniqueId
          ) === participantId
        ) {
          isOnline = true;
        }
      });
    }
    return isOnline;
  };

  static isVideoCallInProgress = () => {
    return RaApiCallsCommunicationProvider._videoCallInProgress;
  };

  static isVideoCallActive = () => {
    return RaStore.getState().comunicationProviderRoom != null;
  };

  static isVideoTrackLocal = (videoTrack) => {
    return videoTrack instanceof window.Twilio.Video.LocalVideoTrack;
  };

  static connectToRoom = (
    token,
    shareAudio,
    shareVideo,
    setDataTrackCallback
  ) => {
    RaApiCallsCommunicationProvider._videoCallInProgress = true;
    return new Promise((resolve, reject) => {
      const errorCallback = (error) => {
        if (RaStore.getState().activeCallParticipants.length === 0) {
          RaApiCalls.closeActiveCall();
        }
        reject(new Error(error));
      };

      dataTrackCallback = setDataTrackCallback;
      localDataTrack = RaApiCallsCommunicationProvider.setupLocalDataTrack();

      RaMedia.getDevices()
        .then(function (devices) {
          RaApiCallsCommunicationProvider.setupLocalTracks(
            shareAudio,
            shareVideo
          )
            .then((audioAndVideoTrack) => {
              const tracks = audioAndVideoTrack.concat(localDataTrack);

              try {
                RaLog.log("comunicationProvider: Attempting to connect...");

                window.Twilio.Video.connect(token, {
                  tracks,
                  // audio: true, video: true
                }).then((room) => {
                  room.once(
                    "disconnected",
                    RaApiCallsCommunicationProvider.didDisconnect
                  );
                  RaApiCallsCommunicationProvider.localParticipantConnected(
                    room.localParticipant,
                    audioAndVideoTrack
                  );

                  //room.participants sono tutti i partecipanti remoti (non quello locale)
                  room.participants.forEach(
                    RaApiCallsCommunicationProvider.participantConnected
                  );
                  room.on(
                    "participantConnected",
                    RaApiCallsCommunicationProvider.participantConnected
                  );
                  room.on(
                    "participantDisconnected",
                    RaApiCallsCommunicationProvider.participantDisconnected
                  );

                  RaLog.log("comunicationProvider: Connected");

                  //const tracks = audioAndVideoTrack.concat(localDataTrack);

                  //room.localParticipant.publishTracks(tracks);

                  RaStore.get().dispatch(
                    RaReduxActions.setComunicationProviderRoom(room)
                  );

                  resolve(room);
                });
                //promise.then(successCallback, failureCallback);
              } catch (error) {
                errorCallback(error);
              }
            })
            .catch(function (error) {
              errorCallback(error);
            });
        })
        .catch(function (error) {
          errorCallback(error);
        });
    });
  };

  /**
   * Handle a connected RemoteParticipant.
   * @param {RemoteParticipant} participant
   * @retruns {void}
   */
  static participantConnected(participant) {
    RaLog.log("participantConnected");
    let participants = RaStore.getState().activeCallParticipants;
    participants.push(
      RaApiContacts.getContactIdentities(participant.identity, null)
    );
    RaStore.get().dispatch(
      RaReduxActions.setActiveCallParticipants(participants)
    );

    let participantId = RaApiCallsCommunicationProvider.getParticipantUniqueId(
      participant.identity
    );

    RaApiCalls._onTargetConnected(participantId);

    participant.tracks.forEach((publication) => {
      RaApiCallsCommunicationProvider.trackPublished(
        publication,
        participantId
      );
    });

    participant.on("trackPublished", (publication) => {
      RaLog.log(participant.identity + " trackPublished", publication);
      RaApiCallsCommunicationProvider.trackPublished(
        publication,
        participantId
      );
    });

    participant.on("trackDimensionsChanged", (track) => {
      RaLog.log(participant.identity + " trackDimensionsChanged");
      RaApiCallsCommunicationProvider.trackDimensionsChanged(
        track,
        participantId
      );
    });

    participant.on("trackStarted", (track) => {
      RaLog.log(participant.identity + " trackStarted", track);
      RaApiCallsCommunicationProvider.trackStarted(track, participantId);
    });

    participant.on("trackUnpublished", (publication) => {
      RaLog.log(participant.identity + " trackUnpublished");
    });
  }

  static trackStarted(track, participantId) {
    if (RaApiCalls.onParticipantTrackDimensionChanged) {
      RaApiCalls.onParticipantTrackDimensionChanged(track, participantId);
    }
  }

  static trackDimensionsChanged(track, participantId) {
    if (RaApiCalls.onParticipantTrackDimensionChanged) {
      RaApiCalls.onParticipantTrackDimensionChanged(track, participantId);
    }
  }

  static trackPublished(publication, participantId) {
    publication.on("subscribed", (track) => {
      RaLog.log(participantId + " publication subscribed", publication);
      RaApiCallsCommunicationProvider.trackSubscribed(participantId, track);

      if (track.kind === "data") {
        track.on("message", (data) => {
          RaApiCalls.dataTrackChangesHandler(
            data,
            RaStore.getState().dataTrack
          );
          RaStore.get().dispatch(RaReduxActions.setDataTrack(data));

          if (dataTrackCallback) {
            dataTrackCallback(data);
          }

          const jsonDataTrack = JSON.parse(data);
          RaLog.log("dataTrakType: " + jsonDataTrack.type);
        });
      } else if (track.kind === "video") {
        track.once("started", () =>
          RaLog.log("track started, dimensions:", track.dimensions)
        );
        RaApiCallsCommunicationProvider._videoTrackAdded(track, participantId);
      }
    });

    publication.on("unsubscribed", (track) => {
      RaLog.log(participantId + " publication unsubscribed");
      RaApiCallsCommunicationProvider.trackUnsubscribed(participantId, track);
    });
  }

  static setParticipantPublicKey = (uniqueId, publicKey) => {
    let participants = RaStore.getState().activeCallParticipants;
    for (let i = 0; i < participants.length; i++) {
      if (participants[i].UniqueId === uniqueId) {
        participants[i].PublicKey = publicKey;
        break;
      }
    }

    RaStore.get().dispatch(
      RaReduxActions.setActiveCallParticipants(participants)
    );
  };

  /**
   * Handle a disconnnected RemoteParticipant.
   * @param {RemoteParticipant} participant
   * @returns {void}
   */
  static participantDisconnected(participant) {
    RaLog.log(participant.identity + " participantDisconnected");

    let participants = RaStore.getState().activeCallParticipants;
    for (let i = 0; i < participants.length; i++) {
      if (participants[i].UniqueId === participant.identity) {
        participants.splice(i, 1);
        break;
      }
    }

    RaStore.get().dispatch(
      RaReduxActions.setActiveCallParticipants(participants)
    );

    RaUITracksNodesManager.removeParticipantTracks(participant.identity);
    if (RaStore.getState().activeCallParticipants.length === 0) {
      /*
            const participantDiv = document.getElementById(participant.identity);
            if (participantDiv) {
                participantDiv.remove();
            }*/

      RaApiCalls.closeActiveCall();
    } else {
      if (
        RaApiCalls.onParticipantDisconnected &&
        RaApiCalls.onParticipantDisconnected instanceof Function
      )
        RaApiCalls.onParticipantDisconnected();
    }
  }

  static trackSubscribed = (participantId, track) => {
    if (track.kind !== "data") {
      RaApiCallsCommunicationProvider._participantTracksAdded(
        [track],
        participantId
      );
    } else {
      //ad ogni nuovo partecipante si fa un giro di chiavi pubbliche
      let participant = RaApiContacts.getContactIdentities(
        RaStorage.getProfile().UniqueId,
        RaStorage.getProfile().PublicKey
      );
      RaApiCallsCommunicationProvider.sendMessage(
        '{"type": "participant", "data": ' + JSON.stringify(participant) + "}"
      );
    }
  };

  static trackUnsubscribed = (participantId, track) => {
    if (track.kind !== "data") {
      if (track.track) {
        track = track.track;
      }
      /*   if (localVideoTrack != null) localVideoTrack.mediaStreamTrack.stop();
            if (localAudioTrack !== null) localAudioTrack.mediaStreamTrack.stop();*/
      track.mediaStreamTrack.stop();
      RaApiCallsCommunicationProvider._participantTracksRemoved(
        [track],
        participantId
      );
    }
  };

  /**
   * Update the UI in response to disconnecting.
   * @returns {void}
   */
  static didDisconnect = (error) => {
    RaLog.log("DISCONNECT");
    let currentRoom = this._getCurrentRoom();

    if (currentRoom) {
      if (error && error !== currentRoom) {
        RaLog.error(error);
      }

      if (RaStore.getState().activeCallParticipants.length === 0) {
        currentRoom.participants.forEach(
          RaApiCallsCommunicationProvider.participantDisconnected
        );
      }

      currentRoom.localParticipant.tracks.forEach(function (track) {
        if (track.kind !== "data" && track.track) {
          track.track.stop();
          if (track.track.mediaStreamTrack) {
            track.track.mediaStreamTrack.stop();
          }
          track.track.detach().forEach((element) => element.remove());
          currentRoom.localParticipant.unpublishTrack(track.track);
        }
      });

      RaUITracksNodesManager.removeParticipantTracks(
        currentRoom.localParticipant.identity
      );

      RaMedia.stopArVideoInputDeviceTrack();

      RaApiCallsCommunicationProvider._videoCallInProgress = false;
    }

    RaApiCalls._onDisconnectedFromCall();
  };

  /**
   * Setup a LocalDataTrack.
   * @returns {LocalDataTrack} dataTrack
   */
  static setupLocalDataTrack = () => {
    const dataTrack = new window.Twilio.Video.LocalDataTrack();
    return dataTrack;
  };

  static _participantTracksAdded = (tracks, participantId) => {
    /*
        if (participantId === RaStorage.getProfile().UniqueId) {
            RaMedia.setCurrentLocalTracks(tracks);
        }
*/
    if (RaApiCalls.onParticipantTrackAdded)
      RaApiCalls.onParticipantTrackAdded(
        tracks,
        RaApiCallsCommunicationProvider.getParticipantUniqueId(participantId)
      );
  };

  static _participantTracksRemoved = (tracks, participantId) => {
    /*
        if (participantId === RaStorage.getProfile().UniqueId) {
            RaMedia.setCurrentLocalTracks(null);
        }*/

    if (RaApiCalls.onParticipantTrackRemoved)
      RaApiCalls.onParticipantTrackRemoved(
        tracks,
        RaApiCallsCommunicationProvider.getParticipantUniqueId(participantId)
      );
  };

  static _videoTrackAdded = (track, participantId) => {
    RaStore.get().dispatch(RaReduxActions.setCallerVideoTrackShared(true));
    RaStore.get().dispatch(
      RaReduxActions.setCallerVideoTrackShareAllowed(true)
    );
    if (RaApiCalls.onVideoTrackAdded)
      RaApiCalls.onVideoTrackAdded(
        track,
        RaApiCallsCommunicationProvider.getParticipantUniqueId(participantId)
      );
  };

  static _getTwilioTrack = (track, mediaStream, kind) => {
    return {
      track: track,
      name: track.id,
      kind: track.kind,
      attach: () => {
        let el =
          typeof document !== "undefined" ? document.createElement(kind) : null;
        if (el) {
          el.srcObject = mediaStream;
          el.autoplay = true;
          el.playsInline = true;
          return el;
        } else {
          RaLog.error("_getTwilioTrack: document undefined");
          return null;
        }
      },
    };
  };

  static getLocalAudioTrack = (track, mediaStream) => {
    if (RaCordova.isCordova() && isIOS) {
      return RaApiCallsCommunicationProvider._getTwilioTrack(
        track,
        mediaStream,
        "audio"
      );
    } else {
      return new window.Twilio.Video.LocalAudioTrack(track);
    }
  };

  static getLocalVideoTrack = (track, mediaStream) => {
    if (RaCordova.isCordova() && isIOS) {
      return RaApiCallsCommunicationProvider._getTwilioTrack(
        track,
        mediaStream,
        "video"
      );
    } else {
      return new window.Twilio.Video.LocalVideoTrack(track);
    }
  };

  static stopCurrentLocalTracks = () => {
    RaApiCallsCommunicationProvider.stopCurrentLocalAudioTrack();
    RaApiCallsCommunicationProvider.stopCurrentLocalVideoTrack();
  };

  static stopCurrentLocalAudioTrack = () => {
    RaApiCallsCommunicationProvider.stopCurrentLocalTrack("audio");
  };

  static stopCurrentLocalVideoTrack = () => {
    RaApiCallsCommunicationProvider.stopCurrentLocalTrack("video");
  };

  static stopCurrentLocalTrack = (kind) => {
    let currentRoom = this._getCurrentRoom();

    if (currentRoom) {
      currentRoom.localParticipant.tracks.forEach(function (track) {
        if (track.kind === kind && track.track) {
          track.track.stop();
          if (track.track.mediaStreamTrack) {
            track.track.mediaStreamTrack.stop();
          }
        }
      });
    } else {
      RaLog.log("stopLocalVideoTrack (" + kind + "): room unavailable");
    }
  };

  static getCurrentLocalAudioTrack = () => {
    return RaApiCallsCommunicationProvider.getCurrentLocalTrack("audio");
  };

  static getCurrentLocalVideoTrack = () => {
    return RaApiCallsCommunicationProvider.getCurrentLocalTrack("video");
  };

  static getCurrentLocalTrack = (kind) => {
    let currentRoom = this._getCurrentRoom();
    let localTrack = null;
    if (currentRoom) {
      currentRoom.localParticipant.tracks.forEach(function (track) {
        if (!localTrack && track.kind === kind) {
          localTrack = track;
        }
      });
    }

    return localTrack;
  };

  static getTrackFromLocalVideoInputDevice = (
    device,
    preventStopTracksOfTheSameKind
  ) => {
    RaLog.log(
      "getTrackFromLocalVideoInputDevice (preventStopTracksOfTheSameKind:" +
      preventStopTracksOfTheSameKind +
      ")",
      device
    );
    return new Promise((resolve, reject) => {
      if (!preventStopTracksOfTheSameKind) {
        RaApiCallsCommunicationProvider.stopCurrentLocalVideoTrack();
      }

      //   if (!RaCordova.isCordova() || !isIOS) {
      window.Twilio.Video.createLocalVideoTrack(device.constraint)
        .then((videoTrack) => {
          resolve(videoTrack);
        })
        .catch((error) => {
          /* createLocalVideoTrack().then(videoTrack => {
                      resolve(videoTrack);
                  })
                  .catch (error => {
                      reject(error);
                  })*/
          reject(error);
        });
      /* } else {
         navigator.mediaDevices
           .getUserMedia({
             video: device.constraint,
             audio: false,
           })
           .then(function (stream) {
             let videoTrack = null;
             stream.getTracks().forEach(function (track) {
               videoTrack = RaApiCallsCommunicationProvider.getLocalVideoTrack(
                 track,
                 stream
               );
             });
             resolve(videoTrack);
           })
           .catch(function (err) {
             reject(err);
           });
       }*/
    });
  };

  static getTrackFromLocalAudioInputDevice = (
    device,
    preventStopTracksOfTheSameKind
  ) => {
    return new Promise((resolve, reject) => {
      if (!preventStopTracksOfTheSameKind) {
        RaApiCallsCommunicationProvider.stopCurrentLocalAudioTrack();
      }

      //    if (!RaCordova.isCordova() || !isIOS) {
      window.Twilio.Video.createLocalAudioTrack(device.constraint)
        .then((track) => {
          resolve(track);
        })
        .catch((error) => {
          /* createLocalAudioTrack().then(track => {
                      resolve(track);
                  })
                  .catch (error => {
                      reject(error);
                  })*/
          reject(error);
        });
      /*     } else {
             navigator.mediaDevices
               .getUserMedia({
                 video: false,
                 audio: device.constraint,
               })
               .then(function (stream) {
                 let audioTrack = null;
                 stream.getTracks().forEach(function (track) {
                   audioTrack = RaApiCallsCommunicationProvider.getLocalAudioTrack(
                     track,
                     stream
                   );
                 });
                 resolve(audioTrack);
               })
               .catch(function (err) {
                 reject(err);
               });
           }*/
    });
  };

  static publishNewTracks = (newTracks) => {
    return new Promise((resolve, reject) => {
      /*****************************
       *
       * CLASSE TRACK DI TWILIO (dopo il metodo getAvailableTracksForVideoComunication): LocalVideoTrack > LocalMediaTrack > VideoTrack > MediaTrack > Track
       * CLASSE TRACK DELLA IP CAM: CanvasCaptureMediaStreamTrack > MediaStreamTrack > EventTarget
       *
       ****************************/

      let currentRoom = this._getCurrentRoom();
      /*
            if (!currentRoom)
                reject(new Error('room unavailable'));
            else if (!currentRoom.localParticipant)
                reject(new Error('room participant unavailable'));*/

      if (currentRoom && currentRoom.localParticipant) {
        newTracks.forEach(function (newTrack) {
          if (newTrack.track) {
            newTrack = newTrack.track;
          }

          //non si deve fare l'unpublish delle tracce correnti, altrimenti il ricevente riceve sempre tracce di 2 pixel di width e height, non so per quale motivo

          currentRoom.localParticipant.publishTrack(newTrack);
          RaLog.log("publishNewTracks ", newTrack);
        });
      } else {
        RaLog.error("publishNewTracks ERROR: no currentRoom.localParticipant");
      }
      resolve();
    });
  };

  /**
   * Apply the selected input device.
   * @param {string} device
   * @param {'audio' | 'video'} kind
   * @returns {Promise<LocalTrack>} - The created or restarted LocalTrack
   */
  static publishDeviceTrack = (device, kind) => {
    return new Promise((resolve, reject) => {
      /*var constraints = { deviceId: { exact: device.deviceId } };
            device.constraint = constraints;*/

      if (kind === "audio") {
        RaApiCallsCommunicationProvider.getTrackFromLocalAudioInputDevice(
          device
        )
          .then(function (newTrack) {
            RaApiCallsCommunicationProvider.publishNewTracks([newTrack])
              .then(resolve)
              .catch(reject);
          })
          .catch(reject);
      } else if (kind === "video") {
        RaApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(
          device
        )
          .then(function (newTrack) {
            RaApiCallsCommunicationProvider.publishNewTracks([newTrack])
              .then(resolve)
              .catch(reject);
          })
          .catch(reject);
      }
    });
  };

  static localParticipantConnected = (localParticipant, tracks) => {
    RaApiCallsCommunicationProvider._participantTracksAdded(
      tracks,
      localParticipant.identity
    );

    localParticipant.on("trackPublished", (publication) => {
      RaLog.log(
        "LOCAL PARTICIPANT trackPublished",
        publication,
        publication.track
      );

      RaApiCallsCommunicationProvider.trackSubscribed(
        RaApiCallsCommunicationProvider.getParticipantUniqueId(
          localParticipant.identity
        ),
        publication.track
      );

      /*
            publication.on('unsubscribed', track => {
                RaLog.log('LOCAL PARTICIPANT trackPublished trackStopped');
                RaApiCallsCommunicationProvider.trackUnsubscribed(localParticipant.identity, track);
            });*/
    });
    localParticipant.on(
      "networkQualityLevelChanged",
      (networkQualityLevel, networkQualityStats) => {
        RaLog.log(
          "LOCAL PARTICIPANT networkQualityLevelChanged",
          networkQualityLevel,
          networkQualityStats
        );
      }
    );
    localParticipant.on("trackDimensionsChanged", (track) => {
      RaLog.log("LOCAL PARTICIPANT trackDimensionsChanged", track);
    });
    localParticipant.on("trackDisabled", (track) => {
      RaLog.log("LOCAL PARTICIPANT trackDisabled", track);
    });
    localParticipant.on("trackEnabled", (track) => {
      RaLog.log("LOCAL PARTICIPANT trackEnabled", track);
    });
    localParticipant.on("trackPublicationFailed", (error, localTrack) => {
      RaLog.log("LOCAL PARTICIPANT trackPublicationFailed", error, localTrack);
    });
    localParticipant.on("trackStarted", (track) => {
      RaLog.log("LOCAL PARTICIPANT trackStarted", track);
    });
    localParticipant.on("trackStopped", (track) => {
      RaLog.log("LOCAL PARTICIPANT trackStopped", track);
    });
  };

  /**
   * Setup a LocalAudioTrack and LocalVideoTrack to render to a <video> element.
   * @param {HTMLVideoElement} video
   * @returns {Promise<Array<LocalAudioTrack|LocalVideoTrack>>} audioAndVideoTrack
   */
  static setupLocalTracks = (shareAudio, shareVideo) => {
    RaLog.log("setupLocalTracks");
    return new Promise((resolve, reject) => {
      if (
        RaMedia.isInputDevicesDetectionCompleated() &&
        RaMedia.getSelectedVideoInputDevice() &&
        RaMedia.getSelectedAudioInputDevice()
      ) {
        RaLog.log("TRACKS WITH SELECTED DEVICES");
        RaLog.log("video", RaMedia.getSelectedVideoInputDevice());
        /*RaApiCallsCommunicationProvider.getTracksFromLocalInputDevices(shareVideo?[RaMedia.getSelectedVideoInputDevice()]:[], shareAudio?[RaMedia.getSelectedAudioInputDevice()]:[]).then(localTracks => {
                    resolve(localTracks);
                });       */
        RaMedia.getAudioVideoTracks().then((localTracks) => {
          resolve(localTracks);
        });
      } else {
        RaMedia.selectDefaultDevices()
          .then(function (devices) {
            RaLog.log("TRACKS WITH DEFAULT DEVICES", devices);
            /*RaApiCallsCommunicationProvider.getTracksFromLocalInputDevices(shareVideo?[devices.input.video]:[], shareAudio?[devices.input.audio]:[]).then(localTracks => {
                        resolve(localTracks);
                    });   */
            RaMedia.getAudioVideoTracks().then((localTracks) => {
              resolve(localTracks);
            });
          })
          .catch(function (error) {
            reject(new Error(error));
          });
      }
    });
  };
  static disconnectFromRoom = (room) => {
    if (!room) {
      room = this._getCurrentRoom();
    }

    try {
      this._getCurrentRoom().disconnect();
    } catch (error) {
      RaLog.error("disconnectFromRoom", error);
    }

    RaStore.get().dispatch(RaReduxActions.setComunicationProviderRoom(null));
  };

  static sendMessage = (message) => {
    if (localDataTrack) {
      localDataTrack.send(message);
      RaLog.log("sending data track", message);
    }
  };

  static switchToArVideoCamera = () => {
    RaLog.log("switchToArVideoCamera BEFORE");
    RaMedia.logVideoTracks();
    return new Promise((resolve, reject) => {
      const error = (message) => {
        RaLog.error(message);
        reject(message);
      };

      RaMedia.stopVideoInputDeviceTrack();

      //attivo la traccia ar
      RaMedia.getArVideoProcessedTrack()
        .then((track) => {
          try {
            if (track) {
              RaApiCallsCommunicationProvider.publishNewTracks([track]);
              resolve(track);
            } else {
              error("getArVideoProcessedTrack null");
            }
          } catch (errorMsg) {
            error(errorMsg);
          }
        })
        .catch((errorMsg) => {
          error(errorMsg);
        })
        .finally(() => {
          RaLog.log("switchToArVideoCamera AFTER");
          RaMedia.logVideoTracks();
        });
    });
  };

  static switchToVideoCamera = () => {
    RaLog.log("switchToVideoCamera BEFORE");
    RaMedia.logVideoTracks();

    return new Promise((resolve, reject) => {
      //fermo la traccia processata (una traccia non viene pubblicata più di una volta se non viene stoppata),
      //non fermo quella originale del device, altrimenti alla sua riattivazione viene ripetuto il training
      RaMedia.stopArVideoProcessedTrack();

      let newTrack = null;
      RaMedia.getVideoInputDeviceTrack()
        .then((track) => {
          try {
            if (track) {
              RaApiCallsCommunicationProvider.publishNewTracks([track]);
            } else {
              RaLog.error("getVideoInputDeviceTrack null");
            }
          } catch (error) {
            RaLog.error(error);
          }
        })
        .finally(() => {
          RaLog.log("switchToVideoCamera AFTER");
          RaMedia.logVideoTracks();
          resolve(newTrack);
        });
    });
  };

  static sendArVideoTrackEnableMessage = (enabled, newTrack) => {
    RaApiCallsCommunicationProvider.sendMessage(
      '{"type": "ar", "data": ' +
      JSON.stringify({
        participantId: RaStorage.getProfile().UniqueId,
        enabled: enabled,
        trackName: newTrack ? newTrack.name : null,
      }) +
      "}"
    );
  };
}

export default RaApiCallsCommunicationProvider;
