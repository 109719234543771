import { RaApiNet } from "../api/net";
import { Ra } from "../index";

export class RaApiDocuments {
  static openStorageFile = (fileName) => {
    return new Promise((resolve, reject) => {
      RaApiNet.openStorageFile(fileName, "docs").then(resolve);
    });
  };

  static getDocuments = (filters) => {
    return new Promise((resolve, reject) => {
      let queryParams = [];

      if (filters) {
        if (filters.dateFrom) queryParams.push("dateFrom=" + filters.dateFrom);
        if (filters.dateTo) queryParams.push("dateTo=" + filters.dateTo);
        if (filters.contactId)
          queryParams.push("contactId=" + filters.contactId);
        if (filters.fileName)
          queryParams.push("fileName=" + encodeURI(filters.fileName));
        if (filters.sentReceived)
          queryParams.push("sentReceived=" + filters.sentReceived);
      }

      RaApiNet.callApi({
        method: "get",
        url:
          Ra.getConfig().apiUrl +
          "/services/docs" +
          (queryParams.length > 0 ? "?" + queryParams.join("&") : ""),
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static deleteDocument = (docId) => {
    return new Promise((resolve, reject) => {
      RaApiNet.callApi({
        method: "delete",
        url: Ra.getConfig().apiUrl + "/services/docs/" + docId,
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}
export default RaApiDocuments;
