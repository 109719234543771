import { isAndroid, isIOS, isTablet } from "react-device-detect";

import { RaCordova } from "../cordova/index";
import { RaApiCalls } from "../api/calls/calls";
import { RaLog } from "../log";
import { RaStore } from "../redux/store";
import { RaReduxActions } from "../redux/actions";
import { RaStorage } from "../storage";
import { Ra } from "../index";
import { RaApiCallsCommunicationProvider } from "../api/calls/communicationProvider";
import { RaCommons } from "../commons";
import { RaArVideoSegmentation } from "../ar/segmentation";
import { RaLocalization } from "../localization";

let playingAudio;

export class RaMedia {
  static _devicesCheckIntervalId = null;

  static isInputDeviceCheckRunning = () => {
    return RaMedia._devicesCheckIntervalId != null;
  };

  static isInputDevicesDetectionCompleated = () => {
    return RaStore.getState().inputDevicesDetectionCompleated;
  };

  static hasBrowserMediaPermission = (detectedDevices) => {
    //navigator.mediaDevices.enumerateDevices() will return an empty label attribute value if the permission for accessing the mediadevice is not given
    //https://stackoverflow.com/questions/60297972/navigator-mediadevices-enumeratedevices-returns-empty-labels
    return (
      detectedDevices &&
      detectedDevices.length > 0 &&
      detectedDevices[0].deviceId &&
      detectedDevices[0].label
    );
  };

  static _askingBrowserMediaPermission = false;
  static askBrowserMediaPermission = () => {
    return new Promise((resolve, reject) => {
      if (!RaMedia._askingBrowserMediaPermission) {
        RaMedia._askingBrowserMediaPermission = true;
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((stream) => {
            const tracks = stream.getTracks();
            for (let i = 0; i < tracks.length; i++) {
              tracks[i].stop();
            }
          })
          .catch((errorMsg) => {
            RaLog.error(errorMsg);
          })
          .finally(() => {
            RaMedia._askingBrowserMediaPermission = false;
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  static startDevicesCheck = () => {
    RaMedia._devicesCheckIntervalId = setInterval(function () {
      //   navigator.mediaDevices.addEventListener('devicechange', function(event) {

      if (!RaApiCalls.getActiveCall() || !RaMedia._devices) {
        RaMedia.getCurrentDevices()
          .then(function (devices) {
            if (
              /* !RaCordova.isCordova() &&*/
              !RaMedia.hasBrowserMediaPermission(devices.input.audio) //testo solo l'audio, perchè su iOS e Android i device video sono chiodati dall'applicazione
            ) {
              //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
              RaMedia.askBrowserMediaPermission();
              return;
            }

            RaMedia.syncReduxDevices(devices);
          })
          .catch(function (error) {
            RaLog.error("getCurrentDevices", error);
          });
      }
    }, 5000);
  };

  static stopDevicesCheck() {
    clearInterval(RaMedia._devicesCheckIntervalId);
    RaMedia._devicesCheckIntervalId = null;
  }

  static syncReduxDevices = (devices) => {
    let reduxVideoDevices = RaStore.getState().videoInputDevices;
    if (!RaMedia.equalInputDevices(reduxVideoDevices, devices.input.video)) {
      RaStore.get().dispatch(
        RaReduxActions.setVideoInputDevices(devices.input.video)
      );
      if (
        !RaMedia.devicesListContains(
          devices.input.video,
          RaMedia.getSelectedVideoInputDevice()
        )
      )
        RaMedia.selectVideoInputDevice(null);
    }

    let reduxAudioDevices = RaStore.getState().audioInputDevices;
    if (!RaMedia.equalInputDevices(reduxAudioDevices, devices.input.audio)) {
      RaStore.get().dispatch(
        RaReduxActions.setAudioInputDevices(devices.input.audio)
      );
      if (
        !RaMedia.devicesListContains(
          devices.input.audio,
          RaMedia.getSelectedAudioInputDevice()
        )
      )
        RaMedia.selectAudioInputDevice(null);
    }

    let reduxAudioOutputDevices = RaStore.getState().audioOutputDevices;
    if (
      !RaMedia.equalInputDevices(reduxAudioOutputDevices, devices.output.audio)
    ) {
      RaStore.get().dispatch(
        RaReduxActions.setAudioOutputDevices(devices.output.audio)
      );
      if (
        !RaMedia.devicesListContains(
          devices.output.audio,
          RaMedia.getSelectedAudioOutputDevice()
        )
      )
        RaMedia.selectAudioOutputDevice(null);
    }
  };

  static selectDefaultDevices = () => {
    return new Promise((resolve, reject) => {
      RaMedia.getDevices()
        .then(function (devices) {
          let defaultDevices = {
            input: { video: null, audio: null },
            output: { audio: null },
          };

          if (devices) {
            if (devices.input) {
              //l'ultimo della lista nei dispositivi mobile è di solito la fotocamera posteriore
              let video = devices.input.video
                ? devices.input.video[devices.input.video.length - 1]
                : null;
              let audio = devices.input.audio ? devices.input.audio[0] : null;
              RaMedia.selectVideoInputDevice(video);
              RaMedia.selectAudioInputDevice(audio);

              defaultDevices.input.video = video;
              defaultDevices.input.audio = audio;
            }
            if (devices.output) {
              let audio = devices.output.audio ? devices.output.audio[0] : null;
              RaMedia.selectAudioOutputDevice(audio);

              defaultDevices.output.audio = audio;
            }
          }

          resolve(defaultDevices);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  static _devices = null;

  static _setDevices = (devices) => {
    RaMedia._devices = devices;

    if (!RaMedia.isInputDevicesDetectionCompleated()) {
      RaStore.get().dispatch(
        RaReduxActions.setInputDevicesDetectionCompleated()
      );
    }

    RaMedia.syncReduxDevices(devices);
  };

  static getDevices = () => {
    return new Promise((resolve, reject) => {
      if (!RaMedia._devices) {
        RaMedia.getCurrentDevices()
          .then((devices) => {
            resolve(RaMedia._devices);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(RaMedia._devices);
      }
    });
  };

  static getCurrentDevices = () => {
    return new Promise((resolve, reject) => {
      try {
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            let video = [];
            let audio = [];
            let audioOutput = [];

            devices.forEach(function (device) {
              let newDevice = {
                label: device.label,
                deviceId: device.deviceId,
                constraint: { deviceId: { exact: device.deviceId } },
              };
              if (
                device.kind === "videoinput" &&
                device.deviceId !== "default"
              ) {
                if (isTablet) {
                  newDevice.constraint.width = {
                    min: 640,
                    ideal: 1280,
                    max: 1920,
                  };
                  newDevice.constraint.height = {
                    min: 480,
                    ideal: 720,
                    max: 1080,
                  };
                }
                video.push(newDevice);
              } else if (device.kind === "audioinput") {
                audio.push(newDevice);
              } else if (device.kind === "audiooutput") {
                audioOutput.push(newDevice);
              }
            });

            if (isAndroid || isIOS) {
              audio = [];
              video = [];
              let video1 = {
                label: RaLocalization.media_backcamera,
                deviceId: "environment",
                constraint: {
                  facingMode: { exact: "environment" },
                },
              };
              if (isTablet) {
                video1.constraint.width = { min: 640, ideal: 1280, max: 1920 };
                video1.constraint.height = { min: 480, ideal: 720, max: 1080 };
              }
              video.push(video1);

              let video2 = {
                label: RaLocalization.media_frontcamera,
                deviceId: "user",
                constraint: {
                  facingMode: { exact: "user" },
                },
              };
              if (isTablet) {
                video2.constraint.width = { min: 640, ideal: 1280, max: 1920 };
                video2.constraint.height = { min: 480, ideal: 720, max: 1080 };
              }
              video.push(video2);

              let audio1 = {
                label: "default",
                deviceId: "default",
                constraint: true,
              };
              audio.push(audio1);
            }

            RaMedia._setDevices({
              input: { video: video, audio: audio },
              output: { audio: audioOutput },
            });
            resolve(RaMedia._devices);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(new Error(error));
      }
    });
  };

  static getAudioVideoTracks = () => {
    return new Promise((resolve, reject) => {
      let tracks = [];
      //RaMedia.getVideoInputDeviceTrack()
      RaApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(
        RaMedia.getSelectedVideoInputDevice()
      )
        .then((track) => {
          if (track) {
            tracks.push(track);
          }
        })
        .catch((error) => {
          RaLog.error(error);
        })
        .finally(() => {
          RaMedia.getAudioInputDeviceTrack()
            .then((track) => {
              if (track) {
                tracks.push(track);
              }
            })
            .catch((error) => {
              RaLog.error(error);
            })
            .finally(() => {
              resolve(tracks);
            });
        });
    });
  };

  static stopTrack = (track) => {
    if (track) {
      if (track.track) {
        track.track.stop();
        if (track.track.mediaStreamTrack) {
          track.track.mediaStreamTrack.stop();
        }
      } else {
        track.stop();
        if (track.mediaStreamTrack) {
          track.mediaStreamTrack.stop();
        }
      }
    }
  };

  /***************************
   * VIDEO INPUT
   **************************/
  static _gettingVideoInputDeviceTrack = false;
  static _videoInputDeviceTrack = null;
  static getVideoInputDeviceTrack = () => {
    return new Promise((resolve, reject) => {
      const error = (errorMsg) => {
        RaLog.error("getVideoInputDeviceTrack ERROR", errorMsg);
        RaMedia._videoInputDeviceTrack = null;
        RaMedia._gettingVideoInputDeviceTrack = false;
        reject(errorMsg);
      };

      if (!RaMedia._gettingVideoInputDeviceTrack) {
        RaMedia._gettingVideoInputDeviceTrack = true;

        RaMedia.stopTrack(RaMedia._videoInputDeviceTrack);

        setTimeout(() => {
          let device = RaMedia.getSelectedVideoInputDevice();
          if (!device) {
            RaMedia._videoInputDeviceTrack = null;
            RaMedia._gettingVideoInputDeviceTrack = false;
            resolve(RaMedia._videoInputDeviceTrack);
          } else {
            if (
              RaMedia.isDeviceTrackEnabled(
                device,
                RaMedia._videoInputDeviceTrack
              )
            ) {
              RaMedia._gettingVideoInputDeviceTrack = false;
              return RaMedia._videoInputDeviceTrack;
            } else {
              RaApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(
                device,
                true
              )
                .then((track) => {
                  RaCommons.waitUntil(() => {
                    return (
                      track &&
                      track.dimensions &&
                      track.dimensions.width &&
                      track.dimensions.height
                    );
                  })
                    .then(() => {
                      RaMedia._videoInputDeviceTrack = track;
                      RaMedia._gettingVideoInputDeviceTrack = false;
                      resolve(RaMedia._videoInputDeviceTrack);
                    })
                    .catch((errorMsg) => {
                      error(errorMsg);
                    });
                })
                .catch((errorMsg) => {
                  error(errorMsg);
                });
            }
          }
        }, 200);
      } else {
        RaCommons.waitUntil(() => {
          return !RaMedia._gettingVideoInputDeviceTrack;
        }).then(() => {
          resolve(RaMedia._videoInputDeviceTrack);
        });
      }
    });
  };

  static stopVideoInputDeviceTrack = () => {
    if (
      RaMedia._videoInputDeviceTrack &&
      !RaMedia._videoInputDeviceTrack.isStopped
    ) {
      RaMedia._videoInputDeviceTrack.stop();
    }
  };

  static selectVideoInputDeviceId = (deviceId) => {
    let device = RaMedia.getDeviceFromList(
      RaStore.getState().videoInputDevices,
      deviceId
    );
    RaMedia.selectVideoInputDevice(device);
  };

  static selectVideoInputDevice = (device) => {
    let deviceId = device ? device.deviceId : null;
    if (!device) {
      device = null;
    }

    let selectedDevice = RaStore.getState().selectedVideoInputDevice;
    if (!RaMedia.equalDevice(device, selectedDevice)) {
      RaStorage.setMediaInputVideo(deviceId);
      RaStore.get().dispatch(
        RaReduxActions.setSelectedVideoInputDevice(device)
      );

      //se c'è una chiamata in corso pubblico la nuova traccia
      if (device && RaApiCalls.getActiveCall()) {
        RaApiCalls._onSelectedVideoInputDeviceChanged(device);
      }
    }
  };

  static getSelectedVideoInputDevice = () => {
    let videoInput = RaStore.getState().selectedVideoInputDevice;

    //se non ho selezionato alcun video, ma ne avevo selezionato uno nella sessione precedente
    if (
      !videoInput &&
      RaMedia._devices &&
      RaMedia._devices.input &&
      RaMedia._devices.input.video
    ) {
      let storageDeviceId = RaStorage.getMediaInputVideo();
      if (storageDeviceId) {
        videoInput = RaMedia.getDeviceFromList(
          RaMedia._devices.input.video,
          storageDeviceId
        );
        RaMedia.selectVideoInputDevice(videoInput);
      }
    }

    return videoInput;
  };

  /***************************
   * AR VIDEO INPUT
   **************************/

  static isArEnabled = () => {
    return Ra.getConfig().arEnabled && !isAndroid && !isIOS;
  };

  static isArVideoInputDeviceEnable = () => {
    let enabled = RaMedia.isArEnabled();
    if (enabled) {
      enabled = RaStorage.isArVideoInputDeviceEnable();
      if (enabled !== RaStore.getState().isArVideoInputDeviceEnable) {
        RaStore.get().dispatch(
          RaReduxActions.setArVideoInputDeviceEnable(enabled)
        );
      }
    }
    return enabled;
  };

  static setArVideoInputDeviceEnable = (enable) => {
    RaStorage.setArVideoInputDeviceEnable(enable);
    if (enable !== RaStore.getState().isArVideoInputDeviceEnable) {
      RaStore.get().dispatch(
        RaReduxActions.setArVideoInputDeviceEnable(enable)
      );
    }
  };

  static selectArVideoInputDeviceId = (deviceId) => {
    return new Promise((resolve, reject) => {
      let device = RaMedia.getDeviceFromList(
        RaStore.getState().arVideoInputDevices,
        deviceId
      );
      RaMedia.selectArVideoInputDevice(device);
    });
  };

  static _createArCanvas = (track) => {
    let canvas = document.createElement("CANVAS");
    canvas.width = track.dimensions.width;
    canvas.height = track.dimensions.height;
    return canvas;
  };

  static createTrackFromCanvas = (canvas) => {
    let mediaStream = canvas.captureStream();
    let tracks = mediaStream.getTracks();
    if (tracks && tracks.length > 0) {
      return RaApiCallsCommunicationProvider.getLocalVideoTrack(tracks[0]);
    }
    return null;
  };

  static _gettingArVideoInputDeviceTrack = false;
  static _gettingArVideoProcessedTrack = false;
  static _arVideoInputDeviceTrack = null;
  static _arVideoProcessedTrack = null;
  static _arVideoCanvas = null;
  static _arVideoSegmentation = null;
  static _arVideo = null;

  static getArVideoCanvas = () => {
    return RaMedia._arVideoCanvas;
  };

  static _resetArVideoSegmentation = () => {
    if (RaMedia._arVideoSegmentation) {
      if (RaMedia._arVideoSegmentation.isProcessing()) {
        RaMedia._arVideoSegmentation.stopProcessing();
      }
      RaMedia._arVideoSegmentation = null;
    }
  };

  static resetArVideoInputDeviceTrack = () => {
    if (RaMedia.isArEnabled()) {
      RaMedia._resetArVideoSegmentation();

      if (RaMedia._arVideo) {
        RaMedia._arVideo.remove();
        RaMedia._arVideo = null;
      }

      RaMedia.stopTrack(RaMedia._arVideoProcessedTrack);
      RaMedia.stopTrack(RaMedia._arVideoInputDeviceTrack);

      RaMedia._arVideoInputDeviceTrack = null;
      RaMedia._arVideoProcessedTrack = null;
      RaMedia._arVideoCanvas = null;
    }
  };

  static getArVideoNode = () => {
    return RaMedia._arVideo;
  };

  static getArVideoSegmentation = () => {
    return RaMedia._arVideoSegmentation;
  };

  static getArVideoInputDeviceTrack = () => {
    return new Promise((resolve, reject) => {
      const success = () => {
        resolve(RaMedia._arVideoInputDeviceTrack);
      };
      const error = (errorMsg) => {
        RaLog.error("getVideoInputDeviceTrack ERROR", errorMsg);
        RaMedia.resetArVideoInputDeviceTrack();
        RaMedia._gettingArVideoInputDeviceTrack = false;
        reject(errorMsg);
      };

      if (RaMedia.isArEnabled()) {
        if (!RaMedia._gettingArVideoInputDeviceTrack) {
          RaMedia._gettingArVideoInputDeviceTrack = true;

          let device = RaMedia.getSelectedArVideoInputDevice();
          if (!device) {
            RaMedia.resetArVideoInputDeviceTrack();

            RaMedia._gettingArVideoInputDeviceTrack = false;
            success();
          } else {
            if (
              RaMedia.isDeviceTrackEnabled(
                device,
                RaMedia._arVideoInputDeviceTrack
              )
            ) {
              RaMedia._gettingArVideoInputDeviceTrack = false;
              success();
            } else {
              //  RaMedia.stopArVideoInputDeviceTrack();
              RaMedia.stopTrack(RaMedia._arVideoProcessedTrack);
              RaMedia.stopTrack(RaMedia._arVideoInputDeviceTrack);
              RaApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(
                device,
                true
              )
                .then((track) => {
                  RaCommons.waitUntil(() => {
                    return (
                      track &&
                      track.dimensions &&
                      track.dimensions.width &&
                      track.dimensions.height
                    );
                  })
                    .then(() => {
                      RaMedia._arVideoInputDeviceTrack = track;
                      RaMedia._arVideoInputDeviceTrack.deviceId =
                        device.deviceId;
                      RaMedia._gettingArVideoInputDeviceTrack = false;
                      success();
                    })
                    .catch((errorMsg) => {
                      error(errorMsg);
                    });
                })
                .catch((errorMsg) => {
                  error(errorMsg);
                });
            }
          }
        } else {
          RaCommons.waitUntil(() => {
            return !RaMedia._gettingArVideoInputDeviceTrack;
          }).then(() => {
            success();
          });
        }
      } else {
        reject("ar is not enabled");
      }
    });
  };

  static logVideoTracks = () => {
    const getTrackState = (track) => {
      if (track) {
        return (
          "isStarted=" +
          track.isStarted +
          " isStopped=" +
          track.isStopped +
          " isEnabled=" +
          track.isEnabled
        );
      } else {
        return null;
      }
    };

    RaLog.log(
      "_videoInputDeviceTrack:" + getTrackState(RaMedia._videoInputDeviceTrack),
      RaMedia._videoInputDeviceTrack
    );
    RaLog.log(
      "_arVideoInputDeviceTrack" +
      getTrackState(RaMedia._arVideoInputDeviceTrack),
      RaMedia._arVideoInputDeviceTrack
    );
    RaLog.log(
      "_arVideoProcessedTrack" + getTrackState(RaMedia._arVideoProcessedTrack),
      RaMedia._arVideoProcessedTrack
    );
    RaLog.log("_arVideo", RaMedia._arVideo);
    RaLog.log("_arVideoCanvas", RaMedia._arVideoCanvas);
    RaLog.log("_arVideoSegmentation", RaMedia._arVideoSegmentation);
  };

  static getArVideoProcessedTrack = (trainingOnly) => {
    return new Promise((resolve, reject) => {
      const success = () => {
        resolve(RaMedia._arVideoProcessedTrack);
      };
      const error = (errorMsg) => {
        RaLog.error("getArVideoProcessedTrack ERROR", errorMsg);
        RaMedia.resetArVideoInputDeviceTrack();
        RaMedia._gettingArVideoProcessedTrack = false;
        reject(errorMsg);
      };

      if (RaMedia.isArEnabled()) {
        if (!RaMedia._gettingArVideoProcessedTrack) {
          RaMedia._gettingArVideoProcessedTrack = true;

          let device = RaMedia.getSelectedArVideoInputDevice();
          if (!device) {
            RaMedia.resetArVideoInputDeviceTrack();

            RaMedia._gettingArVideoProcessedTrack = false;
            success();
          } else {
            if (
              RaMedia._arVideo &&
              RaMedia._arVideoCanvas &&
              RaMedia._arVideoSegmentation &&
              RaMedia.isDeviceTrackEnabled(
                device,
                RaMedia._arVideoProcessedTrack
              )
            ) {
              if (!RaMedia._arVideoSegmentation.isProcessing()) {
                RaMedia._arVideoSegmentation.startProcessing();
              }

              RaMedia._gettingArVideoProcessedTrack = false;
              success();
            } else {
              RaMedia.getArVideoInputDeviceTrack()
                .then((track) => {
                  try {
                    RaMedia._arVideo = track.attach();
                    RaMedia._arVideo.width = track.dimensions.width;
                    RaMedia._arVideo.height = track.dimensions.height;

                    RaMedia._resetArVideoSegmentation();

                    RaMedia._arVideoCanvas = RaMedia._createArCanvas(track);

                    RaMedia._arVideoSegmentation =
                      RaArVideoSegmentation.initVideo(RaMedia._arVideo, {
                        targetCanvas: RaMedia._arVideoCanvas,
                        trainingOnly: trainingOnly,
                      });
                    RaMedia._arVideoSegmentation.onProcessError = (
                      errorMsg
                    ) => {
                      RaMedia._arVideoSegmentation.stopProcessing();
                      error(errorMsg);
                    };
                    RaMedia._arVideoSegmentation.startProcessing();

                    RaMedia._arVideoProcessedTrack =
                      RaMedia.createTrackFromCanvas(RaMedia._arVideoCanvas);
                    RaMedia._arVideoProcessedTrack.deviceId = device.deviceId;

                    RaCommons.waitUntil(
                      () => {
                        return (
                          RaMedia._arVideoProcessedTrack &&
                          RaMedia._arVideoProcessedTrack.isStarted === true
                        );
                      },
                      50,
                      30
                    )
                      .then(() => {
                        RaMedia._gettingArVideoProcessedTrack = false;
                        success();
                      })
                      .catch((errorMsg) => {
                        error(errorMsg);
                      });
                  } catch (errorMsg) {
                    error(errorMsg);
                  }
                })
                .catch((errorMsg) => {
                  error(errorMsg);
                });
            }
          }
        } else {
          RaCommons.waitUntil(() => {
            return !RaMedia._gettingArVideoProcessedTrack;
          }).then(() => {
            success();
          });
        }
      } else {
        reject("ar is not enabled");
      }
    });
  };

  static stopArVideoProcessedTrack = () => {
    if (RaMedia.isArEnabled()) {
      RaMedia.stopTrack(RaMedia._arVideoProcessedTrack);
      RaMedia._resetArVideoSegmentation();
    }
  };

  static stopArVideoInputDeviceTrack = () => {
    if (RaMedia.isArEnabled()) {
      RaMedia.stopArVideoProcessedTrack();
      RaMedia.stopTrack(RaMedia._arVideoInputDeviceTrack);
    }
  };

  static selectArVideoInputDevice = (device) => {
    if (RaMedia.isArEnabled()) {
      let deviceId = device ? device.deviceId : null;
      if (!device) {
        device = null;
      }

      let selectedDevice = RaStore.getState().selectedVideoInputDevice;
      let selectedArDevice = RaStore.getState().selectedArVideoInputDevice;
      if (
        !RaMedia.equalDevice(device, selectedArDevice) &&
        !RaMedia.equalDevice(selectedArDevice, selectedDevice)
      ) {
        RaStorage.setMediaInputVideoAR(deviceId);
        RaStore.get().dispatch(
          RaReduxActions.setSelectedArVideoInputDevice(device)
        );
        RaArVideoSegmentation.resetTraining();
        RaMedia.resetArVideoInputDeviceTrack();
      }
    }
  };

  static getSelectedArVideoInputDevice = () => {
    let videoInput = null;
    if (RaMedia.isArEnabled()) {
      videoInput = RaStore.getState().selectedArVideoInputDevice;

      //se non ho selezionato alcun video, ma ne avevo selezionato uno nella sessione precedente
      if (
        !videoInput &&
        RaMedia._devices &&
        RaMedia._devices.input &&
        RaMedia._devices.input.video
      ) {
        let storageDeviceId = RaStorage.getMediaInputVideoAR();
        if (storageDeviceId) {
          videoInput = RaMedia.getDeviceFromList(
            RaMedia._devices.input.video,
            storageDeviceId
          );
          RaMedia.selectArVideoInputDevice(videoInput);
        }
      }
    }

    return videoInput;
  };

  /***************************
   * AUDIO INPUT
   **************************/
  static _gettingAudioInputDeviceTrack = false;
  static _audioInputDeviceTrack = null;
  static getAudioInputDeviceTrack = () => {
    return new Promise((resolve, reject) => {
      if (!RaMedia._gettingAudioInputDeviceTrack) {
        RaMedia._gettingAudioInputDeviceTrack = true;
        RaMedia.stopTrack(RaMedia._audioInputDeviceTrack);

        let device = RaMedia.getSelectedAudioInputDevice();
        if (!device) {
          RaMedia._audioInputDeviceTrack = null;
          RaMedia._gettingAudioInputDeviceTrack = false;
          resolve(RaMedia._audioInputDeviceTrack);
        } else {
          if (
            RaMedia.isDeviceTrackEnabled(device, RaMedia._audioInputDeviceTrack)
          ) {
            RaMedia._gettingAudioInputDeviceTrack = false;
            resolve(RaMedia._audioInputDeviceTrack);
          } else {
            RaApiCallsCommunicationProvider.getTrackFromLocalAudioInputDevice(
              device,
              true
            )
              .then((track) => {
                RaMedia._audioInputDeviceTrack = track;
                RaMedia._audioInputDeviceTrack.deviceId = device.deviceId;
                resolve(RaMedia._audioInputDeviceTrack);
              })
              .catch((error) => {
                RaLog.error("getAudioInputDeviceTrack ERROR", error);
                RaMedia._audioInputDeviceTrack = null;
                reject(error);
              })
              .finally(() => {
                RaMedia._gettingAudioInputDeviceTrack = false;
              });
          }
        }
      } else {
        RaCommons.waitUntil(() => {
          return !RaMedia._gettingAudioInputDeviceTrack;
        }).then(() => {
          resolve(RaMedia._audioInputDeviceTrack);
        });
      }
    });
  };

  static stopAudioInputDeviceTrack = () => {
    if (
      RaMedia._audioInputDeviceTrack &&
      !RaMedia._audioInputDeviceTrack.isStopped
    ) {
      RaMedia._audioInputDeviceTrack.stop();
    }
  };

  static selectAudioInputDeviceId = (deviceId) => {
    let device = RaMedia.getDeviceFromList(
      RaStore.getState().audioInputDevices,
      deviceId
    );
    RaMedia.selectAudioInputDevice(device);
  };

  static selectAudioInputDevice = (device) => {
    let deviceId = device ? device.deviceId : null;
    if (!device) {
      device = null;
    }

    let selectedDevice = RaStore.getState().selectedAudioInputDevice;
    if (!RaMedia.equalDevice(device, selectedDevice)) {
      RaStorage.setMediaInputAudio(deviceId);
      RaStore.get().dispatch(
        RaReduxActions.setSelectedAudioInputDevice(device)
      );

      if (device && RaApiCalls.getActiveCall()) {
        RaApiCalls._onSelectedAudioInputDeviceChanged(device);
      }
    }
  };

  static getSelectedAudioInputDevice = () => {
    let audioInput = RaStore.getState().selectedAudioInputDevice;

    //se non ho selezionato alcun video, ma ne avevo selezionato uno nella sessione precedente
    if (
      !audioInput &&
      RaMedia._devices &&
      RaMedia._devices.input &&
      RaMedia._devices.input.audio
    ) {
      let storageDeviceId = RaStorage.getMediaInputAudio();
      if (storageDeviceId) {
        audioInput = RaMedia.getDeviceFromList(
          RaMedia._devices.input.audio,
          storageDeviceId
        );
        RaMedia.selectAudioInputDevice(audioInput);
      }
    }

    return audioInput;
  };

  /***************************
   * AUDIO OUTPUT
   **************************/
  static selectAudioOutputDeviceId = (deviceId) => {
    let device = RaMedia.getDeviceFromList(
      RaStore.getState().audioOutputDevices,
      deviceId
    );
    RaMedia.selectAudioOutputDevice(device);
  };

  static selectAudioOutputDevice = (device) => {
    let deviceId = device ? device.deviceId : null;

    if (!device) {
      device = null;
    }

    let selectedDevice = RaStore.getState().selectedAudioOutputDevice;
    if (!RaMedia.equalDevice(device, selectedDevice)) {
      RaStorage.setMediaOutputAudio(deviceId);
      RaStore.get().dispatch(
        RaReduxActions.setSelectedAudioOutputDevice(device)
      );
    }
  };

  static getSelectedAudioOutputDevice = () => {
    let audioOutput = RaStore.getState().selectedAudioOuputDevice;

    //se non ho selezionato alcun video, ma ne avevo selezionato uno nella sessione precedente
    if (
      !audioOutput &&
      RaMedia._devices &&
      RaMedia._devices.output &&
      RaMedia._devices.output.audio
    ) {
      let storageDeviceId = RaStorage.getMediaOutputAudio();
      if (storageDeviceId) {
        audioOutput = RaMedia.getDeviceFromList(
          RaMedia._devices.output.audio,
          storageDeviceId
        );
      }
    }

    return audioOutput;
  };

  /***************************
   * COMMONS
   **************************/

  static isDeviceTrackEnabled = (device, track) => {
    return (
      device && track && track.deviceId === device.deviceId && !track.isStopped
    );
  };

  static equalDevice = (deviceA, deviceB) => {
    return !(
      (deviceA && deviceB === null) ||
      (!deviceA && deviceB !== null) ||
      (deviceA && deviceB && deviceA.deviceId !== deviceB.deviceId)
    );
  };

  static equalInputDevices = (listA, listB) => {
    if ((listA === null && listB !== null) || (listA != null && listB === null))
      return false;

    if (listA === null) return true;

    if (listA.length !== listB.length) return false;

    for (var a = 0; a < listA.length; a++) {
      let containsDevice = false;
      for (var b = 0; b < listB.length; b++) {
        if (listA[a].deviceId === listB[b].deviceId) {
          containsDevice = true;
          break;
        }
      }
      if (!containsDevice) return false;
    }
    return true;
  };

  static getDeviceFromList = (deviceList, deviceId) => {
    if (!deviceList || !deviceId) return null;

    for (var i = 0; i < deviceList.length; i++) {
      if (deviceList[i].deviceId === deviceId) return deviceList[i];
    }
    return null;
  };

  static devicesListContains = (deviceList, device) => {
    if (!deviceList || !device) return false;

    for (var i = 0; i < deviceList.length; i++) {
      if (deviceList[i].deviceId === device.deviceId) return true;
    }
    return false;
  };

  static playAudio = (audio) => {
    if (audio) {
      RaMedia.stopAudio();
      playingAudio = audio;
      const audioPromise = audio.play();
      if (audioPromise !== undefined) {
        audioPromise
          .then((_) => { })
          .catch((err) => {
            console.info(err);
          });
      }
    }
  };

  static stopAudio = () => {
    if (playingAudio) {
      playingAudio.pause();
    }
  };
}
export default RaMedia;
