import * as moment from "moment";
import { isTablet } from "react-device-detect";
export class RaUI {
  static getCanvasContext = (canvas) => {
    return canvas.getContext("2d");
  };

  static getAvatar = (name, backgroundColor, textColor) => {
    if (!backgroundColor) backgroundColor = "#ccc";

    if (!textColor) textColor = "#999";

    var canvas = document.createElement("canvas");
    canvas.style.display = "none";
    canvas.width = "48";
    canvas.height = "48";
    document.body.appendChild(canvas);
    var context = RaUI.getCanvasContext(canvas);
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "20px Arial";
    context.fillStyle = textColor;
    let nameSurname = name.split(" ");
    var first, last;
    if (
      nameSurname.length > 0 &&
      nameSurname[0] !== "" &&
      nameSurname[0] !== null
    ) {
      first = nameSurname[0][0];
      last =
        nameSurname.length > 1 &&
        nameSurname[1] !== "" &&
        nameSurname[1] !== null
          ? nameSurname[1][0]
          : null;
      let sideMargin = 12;
      var initials = null;
      if (last) {
        initials = first + last;
      } else {
        initials = first;
        sideMargin = 18;
      }
      context.fillText(
        initials.toUpperCase(),
        sideMargin,
        31,
        48 - sideMargin * 2
      );

      var data = canvas.toDataURL();
      document.body.removeChild(canvas);
      return data;
    } else {
      return null;
    }
  };

  static getElementFitData(
    placeHolderMaxWidth,
    placeHolderMaxHeight,
    elemWidth,
    elemHeight
  ) {
    let aspectRatio = elemWidth / elemHeight;

    let placeHolderWidth = placeHolderMaxWidth;
    let placeHolderHeight = placeHolderWidth / aspectRatio;
    if (placeHolderHeight > placeHolderMaxHeight) {
      placeHolderHeight = placeHolderMaxHeight;
      placeHolderWidth = placeHolderHeight * aspectRatio;
    }

    return {
      width: placeHolderWidth,
      height: placeHolderHeight,
      aspectRatio: aspectRatio,
      margin: { top: 0, left: 0 },
    };
  }

  static getElementCropData(
    placeHolderMaxWidth,
    placeHolderMaxHeight,
    elemWidth,
    elemHeight
  ) {
    let aspectRatio = elemWidth / elemHeight;

    let cropWidth = placeHolderMaxWidth;
    let cropHeight = cropWidth / aspectRatio;
    if (cropHeight < placeHolderMaxHeight) {
      cropHeight = placeHolderMaxHeight;
      cropWidth = cropHeight * aspectRatio;
    }

    let top = 0,
      left = 0;
    if (cropWidth > placeHolderMaxWidth)
      left = ((cropWidth - placeHolderMaxWidth) / 2) * -1;
    if (cropHeight > placeHolderMaxHeight)
      top = ((cropHeight - placeHolderMaxHeight) / 2) * -1;

    return {
      width: cropWidth,
      height: cropHeight,
      aspectRatio: aspectRatio,
      margin: { top: top, left: left },
    };
  }

  static getCallDurationTime = (callStartedAt) => {
    return moment
      .utc(
        moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
          moment.utc(callStartedAt, "YYYY-MM-DDTHH:mm:ss")
        )
      )
      .format("HH:mm:ss");
    /*
    let diffTime = Math.abs(new Date - callStartedAt);
    
    var sec_num = parseInt(diffTime / 1000, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;*/
  };

  static lockOrientation = () => {
    if (isTablet) {
      window.screen.orientation.lock("landscape");
    } else {
      window.screen.orientation.lock("portrait");
    }
  };

  static unlockOrientation = () => {
    window.screen.orientation.unlock();
  };

  static getFirstSubNode = (parentNode, nodeName) => {
    for (var i = 0; i < parentNode.childNodes.length; i++) {
      if (
        parentNode.childNodes[i].nodeName.toLowerCase() ===
        nodeName.toLowerCase()
      ) {
        return parentNode.childNodes[i];
      }
    }
    return null;
  };

  static getSubNodes = (parentNode, nodeName) => {
    let nodes = [];
    for (var i = 0; i < parentNode.childNodes.length; i++) {
      if (
        parentNode.childNodes[i].nodeName.toLowerCase() ===
        nodeName.toLowerCase()
      ) {
        nodes.push(parentNode.childNodes[i]);
      }
    }
    return nodes;
  };

  static removeChildNodes = (node, childNodesName) => {
    for (let i = 0; i < node.length; i++) {
      if (node[i].nodeName.toLowerCase() === childNodesName) {
        node[i].remove();
      }
    }
  };

  static getFirstElementByClassName = (className, containerElement) => {
    if (!containerElement) {
      containerElement = document;
    }

    let element = containerElement.getElementsByClassName(className);
    if (element.length > 0) {
      return element[0];
    } else {
      return null;
    }
  };
}
export default RaUI;
